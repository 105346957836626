import * as React from 'react';

import classNames from 'classnames';
import { Form, FormikProps } from 'formik';
import { batch } from 'react-redux';
import { Action, Dispatch } from 'redux';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { JurisdictionsEnum } from '@sympli/api-gateway/enums';
import ButtonLink from '@sympli/ui-framework/components/button-link';
import FormGroup from '@sympli/ui-framework/components/form/layout/form-group';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import RadioField from '@sympli/ui-framework/components/formik/radio-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import { IconCaretLeft } from '@sympli/ui-framework/icons';

import BsbNumberField from 'src/components/bsb-NumberInput';
import Formik from 'src/components/formik';
import { Header } from 'src/components/layout';
import { actionFetchDirectionsList } from 'src/containers/workspace/financial/directions/actions';
import { resolveDirectionsLink } from 'src/containers/workspace/financial/directions/helpers';
import WorkflowPanel from 'src/containers/workspace/shared/components/workflow-panel';
import { resolveWorkspaceDetailLink } from 'src/containers/workspace/shared/detail/helpers';
import WorkspacePageContentWrapper from 'src/containers/workspace/shared/WorkspacePageContentWrapper';
import { WorkspaceDetailRouteParams } from 'src/pages/workspace/detail/WorkspaceDetailPageContainer';
import { createModelKeyAppender, modelKey } from 'src/utils/formUtils';
import withRedirectRouter, { RouteRedirectProps } from 'src/utils/withRedirectRouter';
import { actionUpdateStampDutyDetails } from '../../actions';
import StampDutyDocumentList from '../../components/stamp-duty-document-list';
import TransactionStatus from '../../components/transaction-status';
import TransferDetails from '../../components/transfer-details';
import { resolveVerificationTimestampDescription } from '../../helpers';
import { StampDutyApiResponse, StampDutyProgressStatusEnum, VerifyStampDutyApiResponse } from '../../models';
import ProgressMessageNotification from '../components/progress-message-notification';
import { getInitialValues } from './helpers';
import { QldStampDutyFormDataModel, StampDutyPaymentBankDetailsModel, StampDutyPaymentMethodEnum, stampDutyPaymentOptions } from './models';
import styles, { ClassKeys } from './styles';
import getValidationSchema from './validationSchema';

interface OwnProps {
  queryParams: WorkspaceDetailRouteParams;
  detail: StampDutyApiResponse;
  dispatch: Dispatch<Action>;
  isLocked: boolean;
}

interface State {
  isWorkflowLoading: boolean;
}

type Props = OwnProps & WithStyles<ClassKeys> & RouteRedirectProps;

const fieldName = modelKey<QldStampDutyFormDataModel>();
const bankDetailsAppender = createModelKeyAppender<StampDutyPaymentBankDetailsModel>('bankDetails');

class QldStampDuty extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    isWorkflowLoading: false
  };
  private formikProps: FormikProps<QldStampDutyFormDataModel>;

  render() {
    const {
      classes,
      detail,
      queryParams: { workspaceId, participantId },
      isLocked
    } = this.props;
    const { isWorkflowLoading } = this.state;
    const disableVerify = !detail.canVerify || isLocked;
    const action = `/workspaces/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/stampduties`;

    return (
      <FlexLayout flexDirection="column" fullWidth className={classes.rootV2}>
        <ProgressMessageNotification jurisdictionId={JurisdictionsEnum.QLD} detail={detail} />
        <WorkflowPanel mode="light">
          {this.renderVerificationTimestamp()}
          {this.renderSubmitButton(disableVerify, isWorkflowLoading)}
        </WorkflowPanel>
        <WorkspacePageContentWrapper className={undefined}>
          <Formik //
            method="post"
            action={action}
            initialValues={getInitialValues(detail)}
            validationSchema={getValidationSchema()}
            onPreSubmit={this.handleOnPreSubmit}
            onPostSubmit={this.handleOnPostSubmit}
          >
            {(formikProps: FormikProps<QldStampDutyFormDataModel>) => this.renderForm(formikProps)}
          </Formik>
        </WorkspacePageContentWrapper>
      </FlexLayout>
    );
  }

  private renderVerificationTimestamp() {
    const { classes, detail } = this.props;
    const description = resolveVerificationTimestampDescription(detail);
    if (!description) {
      return null;
    }
    return <Typography className={classes.timestamp}>{description}</Typography>;
  }

  private renderForm(formikProps: FormikProps<QldStampDutyFormDataModel>) {
    const { classes, queryParams, detail, isLocked } = this.props;
    const disableVerify = !detail.canVerify || isLocked;

    const { isSubmitting } = formikProps;
    this.formikProps = formikProps;

    return (
      <Form>
        <Header>Stamp Duty</Header>
        <FormGroup title="Transfer details" classes={{ title: classes.formGroupTitle }}>
          {this.renderTransactionId()}
          {this.renderTransferDetails()}
          <StampDutyDocumentList items={detail.stampDutyDocuments} queryParams={queryParams} />
        </FormGroup>
        <FormGroup title="Payment option" classes={{ title: classes.formGroupTitle }} description={'You can change the payment method at any point before the workspace lockdown.'}>
          {({ titleId }) => (
            <Field //
              aria-labelledby={titleId}
              name={fieldName('paymentOption')}
              format="number"
              component={RadioField}
              options={stampDutyPaymentOptions}
              fullWidth
              vertical
              disabled={disableVerify}
            />
          )}
        </FormGroup>
        {this.renderBankDetails(formikProps)}
        <FlexLayout justifyContent="space-between" className={classes.formSubmitButtonContainer}>
          <ButtonLink onClick={this.handleOnBackClick} icon={<IconCaretLeft width="18" height="18" />} color="inherit">
            Cancel
          </ButtonLink>
          {this.renderSubmitButton(disableVerify, isSubmitting)}
        </FlexLayout>
      </Form>
    );
  }

  private renderSubmitButton = (disableVerify: boolean, isLoading: boolean) => {
    const { classes, detail, queryParams } = this.props;
    switch (detail.status) {
      case StampDutyProgressStatusEnum.ReadyForTransactionCreation:
      case StampDutyProgressStatusEnum.TransactionCreationError:
        return (
          <SympliButton onClick={this.handleOnStepperBarSubmit} arrowRight isLoading={isLoading} disabled={disableVerify || isLoading} color="primary" variant="contained">
            Create transaction
          </SympliButton>
        );
      case StampDutyProgressStatusEnum.CreatingTransaction:
        return (
          <SympliButton onClick={this.handleOnStepperBarSubmit} arrowRight isLoading={isLoading} disabled={true} color="primary" variant="contained">
            Create transaction
          </SympliButton>
        );
      case StampDutyProgressStatusEnum.TransactionCreated:
      case StampDutyProgressStatusEnum.VerificationError:
        return (
          <SympliButton onClick={this.handleOnStepperBarSubmit} arrowRight isLoading={isLoading} disabled={disableVerify || isLoading} color="primary" variant="contained">
            Verify Stamp Duty
          </SympliButton>
        );
      case StampDutyProgressStatusEnum.Verifying:
        return (
          <SympliButton onClick={this.handleOnStepperBarSubmit} arrowRight isLoading={isLoading} disabled={true} color="primary" variant="contained">
            Verify Stamp Duty
          </SympliButton>
        );
      case StampDutyProgressStatusEnum.Verified:
        return (
          <FlexLayout>
            <SympliButton
              onClick={this.handleOnStepperBarSubmit}
              isLoading={isLoading}
              disabled={disableVerify || isLoading}
              color="primary"
              variant="outlined"
              className={classes.buttonMarginRight}
            >
              Reverify
            </SympliButton>
            <SympliButton href={resolveDirectionsLink(queryParams)} arrowRight color="primary" variant="contained">
              See settlement distributions
            </SympliButton>
          </FlexLayout>
        );
      case StampDutyProgressStatusEnum.Paid:
        return (
          <SympliButton href={resolveDirectionsLink(queryParams)} arrowRight color="primary" variant="contained">
            See settlement distributions
          </SympliButton>
        );

      case StampDutyProgressStatusEnum.NotApplicable:
      case StampDutyProgressStatusEnum.PreparingDocument:
      default:
        return (
          <SympliButton onClick={this.handleOnStepperBarSubmit} arrowRight isLoading={isLoading} disabled={true} color="primary" variant="contained">
            Create transaction
          </SympliButton>
        );
    }
  };

  private renderTransactionId() {
    const { detail } = this.props;
    const { status, referenceNumber, assessmentNumber } = detail;
    switch (status) {
      case StampDutyProgressStatusEnum.TransactionCreated:
      case StampDutyProgressStatusEnum.VerificationError:
        return <TransactionStatus referenceNumber={referenceNumber} />;
      case StampDutyProgressStatusEnum.Verified:
        return <TransactionStatus referenceNumber={referenceNumber} assessmentNumber={assessmentNumber} verified />;
      default:
        return null;
    }
  }

  private renderTransferDetails() {
    const { detail } = this.props;
    const { consideration, dutyAmount, utiAmount, payableDuty, paymentOption } = detail;
    if (paymentOption === StampDutyPaymentMethodEnum.PaidToCommissioner || paymentOption === StampDutyPaymentMethodEnum.ReceivedBySelfAssessor) {
      return <TransferDetails items={[{ label: 'Consideration ($)', value: consideration }]} />;
    }
    switch (detail.status) {
      case StampDutyProgressStatusEnum.PreparingDocument:
      case StampDutyProgressStatusEnum.ReadyForTransactionCreation:
      case StampDutyProgressStatusEnum.CreatingTransaction:
        return <TransferDetails items={[{ label: 'Consideration ($)', value: consideration }]} />;
      case StampDutyProgressStatusEnum.TransactionCreationError:
      case StampDutyProgressStatusEnum.TransactionCreated:
      case StampDutyProgressStatusEnum.Verifying:
      case StampDutyProgressStatusEnum.Verified:
      case StampDutyProgressStatusEnum.VerificationError:
      case StampDutyProgressStatusEnum.Paid:
        return (
          <TransferDetails
            items={[
              { label: 'Consideration ($)', value: consideration },
              { label: 'Stamp Duty ($)', value: dutyAmount },
              { label: 'UTI ($)', value: utiAmount },
              { label: 'Payable duty ($)', value: payableDuty }
            ]}
          />
        );
      default:
        return null;
    }
  }
  private renderBankDetails(formikProps: FormikProps<QldStampDutyFormDataModel>) {
    const { classes } = this.props;
    const { paymentOption } = formikProps.values;
    if (paymentOption !== StampDutyPaymentMethodEnum.CommittedAsPartOfElno) {
      return null;
    }
    const description = 'This is your trust account to receive stamp duty funds and then forwarded to the OSR.';
    return (
      <FormGroup title="Nominated account details" description={description} fieldAreaDirection="column" classes={{ title: classes.formGroupTitle }}>
        <Field label="Account name" name={bankDetailsAppender('accountName')} component={InputField} className={classes.largeField} />
        <FlexLayout>
          <BsbNumberField name={bankDetailsAppender('bsb')} label="BSB" className={classNames(classes.smallField, classes.marginRight)} />
          <Field label="Account number" name={bankDetailsAppender('accountNumber')} component={InputField} />
        </FlexLayout>
      </FormGroup>
    );
  }

  private handleOnStepperBarSubmit = () => {
    this.formikProps && this.formikProps.submitForm();
  };

  private handleOnPreSubmit = (values: QldStampDutyFormDataModel): QldStampDutyFormDataModel => {
    this.setState({ isWorkflowLoading: true });
    if (values.paymentOption !== StampDutyPaymentMethodEnum.CommittedAsPartOfElno) {
      return { paymentOption: values.paymentOption };
    }
    return values;
  };

  private handleOnPostSubmit = (args: FormikPostSubmitArgs<QldStampDutyFormDataModel, VerifyStampDutyApiResponse>) => {
    this.setState({ isWorkflowLoading: false });
    if (!args.error) {
      batch(() => {
        this.props.dispatch(actionUpdateStampDutyDetails({ ...args.response }));
        this.props.dispatch(actionFetchDirectionsList.request(this.props.queryParams));
      });
    }
  };

  private handleOnBackClick = () => {
    const { queryParams } = this.props;
    this.props.navigate(resolveWorkspaceDetailLink(queryParams));
  };
}

const styledComponent = withStyles(styles)(withRedirectRouter(QldStampDuty));
export default styledComponent;
