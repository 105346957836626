import { NswDocumentPartyJustification } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { AddressBookEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { DocumentAttachmentItemModel } from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';
import { GenericTenancyDetailModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/generic';
import { PartLandAffectedWithDescriptionsModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { TenancyPartyModel } from '@sympli-mfe/document-forms-framework/core/models';
import { BaseAddress } from '@sympli-mfe/document-forms-framework/shared-config/address';
import { ApiDocumentPartyModel, ApiPartySourceModel } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { TitleReferenceModel } from 'src/containers/documents/scaffolded-form/shared/components/title-references/models';
import { ActionProhibitedEnum, ClaimCategoryEnum, ClaimPartyCapacityEnum, EstateOfInterestClaimedEnum } from './enums';

export interface ApiCaveatModel_2_21_2 {
  titleReferences: TitleReferenceModel<PartLandAffectedWithDescriptionsModel, NswDocumentPartyJustification>[];
  extentOfProhibitions: ExtentOfProhibition[];
  caveators: GenericTenancyDetailModel;
  proprietors: GenericTenancyDetailModel;
  contactDetails: TenancyPartyModel[];
  addressBook: BaseAddress[];
  partyBook: ApiDocumentPartyModel<NswDocumentPartyJustification>[];
  caveatStatementsAgreed: boolean;
  attachments: DocumentAttachmentItemModel[];
  claimDetails?: ClaimDetailModel;
  dutyAssessment?: DutyAssessmentModel;
  workspaceTitleReferences?: string[];
  invalidParties: boolean;
  subscriberParties: ApiPartySourceModel<NswDocumentPartyJustification>[];
  hasCommonProprietors: boolean;
}

export interface DutyAssessmentModel {
  dutyAssessmentNumber: string;
}

export const ADDRESS_BOOK_KEY = 'addressBook';
export const PARTY_BOOK_KEY = 'partyBook';

export type CaveatModel_2_21_2 = Omit<
  Required<ApiCaveatModel_2_21_2>,
  | 'addressBook' //
  | 'partyBook'
  | 'detailsForServiceOfNotice'
  | 'dutyAssessmentNumber'
  | 'claimDetails'
> & {
  addressBook: AddressBookEntityModel[];
  partyBook: PartyModel[];
  claimDetails: Required<ClaimDetailModel>;

  // custom properties
  // relinquishingPartyIds: string[];
  // partyBookIdsFromTitle: string[];
  partyBookApi: ApiDocumentPartyModel<NswDocumentPartyJustification>[];
  // custom UI properties
  proprietorsOnTitle: {
    partyBook: PartyModel[];
    partyBookIds: string[];
    partyNamesMap: Map<string, string>;
    isOriginal(party: PartyModel): boolean;
  };
  isProprietorsResetTenancyAvailable: boolean;
};

export interface ClaimDetailModel {
  estateOrInterestClaimed: EstateOfInterestClaimedEnum | null; // number
  claimCategory?: ClaimCategoryEnum | null; // number
  detailsSupportingTheClaim?: string;
  claimParties?: ClaimParty[] | null;
  claimDate?: Date | string | null;
  lrDocumentID?: string | null;
}

export interface ClaimParty {
  claimPartyCapacity: ClaimPartyCapacityEnum | null; // number
  partyBookId?: string; // reference to party store
}

export interface ExtentOfProhibition {
  actionProhibitedOption?: ActionProhibitedEnum | null; // we made this optional
  lrDocumentId?: string;
  qualification?: string;
}
