import { endOfToday } from 'date-fns';
import * as yup from 'yup';

import { NSW_PARTY_FORM_CONFIG as NSW_CAVEAT_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/nsw/2-21/config';
import { createPartyBookItemTestForPartyBookId } from '@sympli-mfe/document-forms-framework/components/party-form';
import { isNotNullOrEmpty } from '@sympli-mfe/document-forms-framework/utils';
import { getLookupValuesAllowingEmpty, memoizeSchemaWithContext, validateWhenVisible2 } from '@sympli-mfe/document-forms-framework/validation';
import * as v from '@sympli-mfe/document-forms-framework/validation/validators';
import { yupDatePicker } from '@sympli/ui-framework/components/formik/date-picker-field';
import msg from '@sympli/ui-framework/utils/messages';

import { VISIBILITY_CHECK_CLAIM_CATEGORY, VISIBILITY_CHECK_CLAIM_PARTIES } from '../../checks';
import { ClaimPartyCapacityEnum, ENUM_CLAIM_CATEGORY_OPTIONS, ENUM_CLAIM_PARTY_CAPACITY_OPTIONS, ENUM_ESTATE_OR_INTEREST_CLAIMED_OPTIONS } from '../../enums';
import { CaveatModel_2_21_2, ClaimParty } from '../../models';
import { REQUIRED_CHECK_CLAIM_DATE, REQUIRED_CHECK_DETAILS_SUPPORTING_THE_CLAIM, VISIBILITY_CHECK_CLAIM_DATE, VISIBILITY_CHECK_DETAILS_SUPPORTING_THE_CLAIM } from './checks';

const END_OF_TODAY = endOfToday();

type FormModel = CaveatModel_2_21_2;

interface ClaimDetailsContext {
  partyBook: FormModel['partyBook'];
}

interface ClaimPartiesContext {
  partyBook: FormModel['partyBook'];
}

interface ClaimPartyItemContext {
  partyBook: FormModel['partyBook'];
}

const yupClaimPartyItem: yup.ObjectSchema<ClaimParty, ClaimPartyItemContext> = yup
  .object<ClaimParty, ClaimPartyItemContext>({
    partyBookId: yup // reference to party store
      .string()
      .default('')
      .nullable(true)
      .required(msg.REQUIRED)
      .test(createPartyBookItemTestForPartyBookId(NSW_CAVEAT_PARTY_FORM_CONFIG)),
    claimPartyCapacity: yup //
      .mixed<ClaimPartyCapacityEnum>()
      .oneOf(getLookupValuesAllowingEmpty(ENUM_CLAIM_PARTY_CAPACITY_OPTIONS), msg.INVALID_SELECTION)
      .required(msg.REQUIRED)
  })
  .defined();

const yupClaimParties = memoizeSchemaWithContext<FormModel['claimDetails']['claimParties'], ClaimDetailsContext, ClaimPartiesContext>(
  yup //
    .array<ClaimParty, ClaimPartiesContext>()
    .of<ClaimParty>(yupClaimPartyItem)
    .defined(),
  (parentContext: ClaimDetailsContext): ClaimPartiesContext => {
    return {
      partyBook: parentContext.partyBook
    };
  }
);

// path: claimDetails
const yupClaimDetails: yup.ObjectSchema<FormModel['claimDetails'], ClaimDetailsContext> = yup
  .object<FormModel['claimDetails'], ClaimDetailsContext>({
    estateOrInterestClaimed: v //
      .number()
      .required(msg.REQUIRED)
      .oneOf(getLookupValuesAllowingEmpty(ENUM_ESTATE_OR_INTEREST_CLAIMED_OPTIONS), msg.INVALID_SELECTION),
    claimCategory: validateWhenVisible2<FormModel['claimDetails']['claimCategory'], ClaimDetailsContext>({
      visibilityCheck: (parent: FormModel['claimDetails']) => VISIBILITY_CHECK_CLAIM_CATEGORY(parent),
      validationWhenVisible: v //
        .number()
        .required(msg.REQUIRED)
        .oneOf(getLookupValuesAllowingEmpty(ENUM_CLAIM_CATEGORY_OPTIONS), msg.INVALID_SELECTION)
    }),
    detailsSupportingTheClaim: validateWhenVisible2<FormModel['claimDetails']['detailsSupportingTheClaim'], ClaimDetailsContext>({
      visibilityCheck: (parent: FormModel['claimDetails']) => VISIBILITY_CHECK_DETAILS_SUPPORTING_THE_CLAIM(parent),
      validationWhenVisible: yup //
        .string()
        .default('')
        .trim()
        .max(255, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(255))
        .testContextualRule({
          uniqueTestName: 'detailsSupportingTheClaim required check',
          onlyIf: (parent: FormModel['claimDetails']) => REQUIRED_CHECK_DETAILS_SUPPORTING_THE_CLAIM(parent),
          requirement: (parent: FormModel['claimDetails']) => isNotNullOrEmpty(parent.detailsSupportingTheClaim),
          message: msg.REQUIRED
        })
    }),
    claimParties: validateWhenVisible2<FormModel['claimDetails']['claimParties'], ClaimDetailsContext>({
      isObjectOrArray: true,
      visibilityCheck: (parent: FormModel['claimDetails']) => VISIBILITY_CHECK_CLAIM_PARTIES(parent),
      validationWhenVisible: yupClaimParties
    }),
    claimDate: validateWhenVisible2<FormModel['claimDetails']['claimDate'], ClaimDetailsContext>({
      visibilityCheck: (parent: FormModel['claimDetails']) => VISIBILITY_CHECK_CLAIM_DATE(parent),
      validationWhenVisible: yupDatePicker
        .max(END_OF_TODAY) //
        .testContextualRule({
          uniqueTestName: 'claimDate required check',
          onlyIf: (parent: FormModel['claimDetails']) => REQUIRED_CHECK_CLAIM_DATE(parent),
          requirement: (parent: FormModel['claimDetails']) => isNotNullOrEmpty(parent.claimDate),
          message: msg.REQUIRED
        })
    }),
    lrDocumentID: yup.mixed<string | null>()
  })
  .defined();

const yupSchema = memoizeSchemaWithContext<FormModel['claimDetails'], FormModel, ClaimDetailsContext>(yupClaimDetails, (parentContext: FormModel): ClaimDetailsContext => {
  return {
    partyBook: parentContext.partyBook
  };
});

export default yupSchema;
