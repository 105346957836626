import { Action } from 'redux';

import { SettleSmartStatusEnum, WorkspaceStatusEnum } from '@sympli/api-gateway/enums';

import MyTasks from 'src/containers/workspace/shared/detail/components/my-tasks';
import { WorkspaceTasksState } from 'src/containers/workspace/shared/detail/reducers/workspaceTasks';

interface Props {
  // route props
  workspaceId: string;
  participantId: string;
  // redux data
  // basic info
  isRollover?: boolean;
  workspaceStatusId?: WorkspaceStatusEnum;

  // full detail
  settlementStatusId?: SettleSmartStatusEnum;
  isLoadingWorkspace: boolean;
  // tasks
  workspaceTasksState: WorkspaceTasksState;
  // other
  backLink: string;
  onTaskAction(action: Action): void;
}

function TabTasks(props: Props) {
  const { isLoadingWorkspace, settlementStatusId, workspaceStatusId, isRollover, workspaceTasksState, onTaskAction } = props;
  const { items, status, error } = workspaceTasksState;

  // Show loader for task section when refetching to avoid old task list present to  customers
  // https://tickleme.atlassian.net/browse/WEB-18877
  if (isLoadingWorkspace || status === 'pending' || status === 'idle' || status === 'refetching') {
    return (
      <MyTasks
        //
        isLoading
        onTaskAction={onTaskAction}
      />
    );
  }

  // don't show tasks if settlement is completed
  // still show workspace detail loader for good UX
  if (settlementStatusId === SettleSmartStatusEnum.Completed) {
    return null;
  }

  if (status === 'rejected') {
    return (
      <MyTasks
        //
        error={error}
        onTaskAction={onTaskAction}
      />
    );
  }

  return (
    <MyTasks //
      items={items}
      workspaceStatusId={workspaceStatusId}
      isRollover={isRollover}
      onTaskAction={onTaskAction}
    />
  );
}

export default TabTasks;
