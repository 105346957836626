import { resolveGenericTenancyDetailFallback } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/generic';
import { resolveTenancyFallback } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { AttachmentTypeEnum } from '@sympli-mfe/enums-2-21/nsw';

import { Mortgage2_21_3Model } from './models';

// this file was automatically generated from fallback.ts.mustache
export const fallbackMap: FallbackMap<Mortgage2_21_3Model> = {
  workspaceTitleReferences: NO_FALLBACK,
  partyBook: NO_FALLBACK,
  needToGeneratePartyBook: NO_FALLBACK,
  titleReferences: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        isSelected: [false],
        reference: NO_FALLBACK,
        isPartLandAffected: NO_FALLBACK,
        landDescriptions: NO_FALLBACK,
        estateType: NO_FALLBACK,
        mortgagors: NO_FALLBACK,
        hasTransfer: NO_FALLBACK
      }
    }
  },
  mortgagors: resolveGenericTenancyDetailFallback(),
  mortgagees: resolveTenancyFallback(),
  termsAndConditions: {
    $children: {
      standardTermsDealingNumbers: {
        $shouldAddDefaultItemToEmptyArray: true,
        $arrayItem: {
          $children: {
            termNumber: ['']
          }
        }
      },
      warning: [''],
      preRegisteredStandardTermsDealingNumbers: {
        $shouldAddDefaultItemToEmptyArray: false,

        $arrayItem: ['']
      },
      preRegisteredStandardTerms: NO_FALLBACK,
      additionalCovenants: ['']
    }
  },
  additionalAttachments: {
    $shouldAddDefaultItemToEmptyArray: false,
    $arrayItem: {
      $children: {
        documentType: [AttachmentTypeEnum.TermsAndConditions],
        attachment: {
          $shouldAddDefaultItemToEmptyArray: false,
          $arrayItem: {
            $children: {
              id: NO_FALLBACK,
              fileName: [null],
              fileType: [null],
              uploadedByParticipantId: NO_FALLBACK,
              uploadedDateTimeUtc: NO_FALLBACK
            }
          }
        }
      }
    }
  },
  transferees: NO_FALLBACK,
  partyBookApi: NO_FALLBACK,
  // custom UI only properties
  mortgagorsOnTitle: NO_FALLBACK,
  isMortgagorsResetTenancyAvailable: NO_FALLBACK
};
