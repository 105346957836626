import * as yup from 'yup';

import { validateWhenVisible2 } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { VISIBILITY_CHECK_DUTY_ASSESSMENT_NUMBER } from '../../checks';
import { CaveatModel_2_21_2 } from '../../models';

const REGEXP_DAN = /^(\d+(-\d+)?)*$/;
type FormModel = CaveatModel_2_21_2;

// path: dutyAssessmentNumber
const yupDutyAssessmentNumber = validateWhenVisible2<FormModel['dutyAssessment'], FormModel>({
  visibilityCheck: (parent: FormModel) => VISIBILITY_CHECK_DUTY_ASSESSMENT_NUMBER(null, parent),
  validationWhenVisible: yup
    .object({
      dutyAssessmentNumber: yup //
        .string()
        .default('')
        .trim()
        .nullable()
        .required(msg.REQUIRED)
        .max(30, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(30))
        .test('duty assessment format test', msg.INVALID_VALUE, value => REGEXP_DAN.test(value ?? ''))
    })
    .defined()
});

export default yupDutyAssessmentNumber;
