import React, { useCallback } from 'react';

import { useFormikContext } from 'formik';
import Typography from '@mui/material/Typography';

import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import { ReceivingTenancyDetailModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving';
import { ReceivingProprietorGroupModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';

import { createModelKeyAppender, modelKey } from 'src/utils/formUtils';
import { NoticeOfDeath_2_21_2_Model } from '../../models';
import SurvivingProprietorArray from './components/surviving-proprietor-array';
import { useStyles } from './styles';

const fieldName = modelKey<NoticeOfDeath_2_21_2_Model>();
const survivingJointTenantsSectionName = fieldName('survivingJointTenants');

function SurvivingJointTenants(): JSX.Element {
  const fieldName = createModelKeyAppender<ReceivingTenancyDetailModel>(survivingJointTenantsSectionName);
  const { disabled } = useDocumentContext();
  const classes = useStyles();

  const formikProps = useFormikContext<NoticeOfDeath_2_21_2_Model>();
  const { values } = formikProps;
  const { survivingJointTenants } = values;
  const items = survivingJointTenants.proprietorGroups.flatMap(r => r.parties);

  const renderItem = useCallback(
    ({ itemBinding, itemIndex }: DocumentArrayItemRenderProps<ReceivingProprietorGroupModel>) => {
      const numberOfPartiesInPreceedingGroups = survivingJointTenants.proprietorGroups.slice(0, itemIndex).flatMap(group => group.parties ?? []).length;

      return (
        <SurvivingProprietorArray //
          itemBinding={itemBinding}
          disabledCheckbox={disabled}
          totalSurvivingProprietors={items.length}
          numberOfPartiesInPreceedingGroups={numberOfPartiesInPreceedingGroups}
        />
      );
    },
    [disabled, items.length, survivingJointTenants.proprietorGroups]
  );

  return (
    <div data-section={fieldName('proprietorGroups')} data-testid={survivingJointTenantsSectionName}>
      {!items.length ? (
        <Typography className={classes.noSurvivingsMessage}>Please select a deceased joint tenant</Typography>
      ) : (
        <DocumentFieldArray //
          arrayBinding={fieldName('proprietorGroups')}
          renderItem={renderItem}
          disabled={disabled}
          mode="fixed"
          itemStyle="none"
        />
      )}
    </div>
  );
}

export default React.memo(SurvivingJointTenants);
