import * as yup from 'yup';

import { DocumentAttachmentItemModel, DocumentAttachmentModel } from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';
import msg from '@sympli/ui-framework/utils/messages';

const yupAttachmentItem = yup
  .object<DocumentAttachmentItemModel>({
    documentType: yup.mixed<string>().required(msg.REQUIRED),
    attachment: yup //
      .array<DocumentAttachmentModel>()
      .min(1, msg.REQUIRED)
      .max(1, msg.MAX_ITEMS(1))
      .defined()
  })
  .defined();

// path: Attachments
const yupAttachments = yup.array<DocumentAttachmentItemModel>().min(0, msg.MIN_ITEMS(0)).max(1, msg.MAX_ITEMS(1)).of<DocumentAttachmentItemModel>(yupAttachmentItem);

export default yupAttachments;
