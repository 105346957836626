import { defaultMemoize } from 'reselect';

import { IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { DocumentTypeIdentifierEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDocumentSummaryApiResponse } from '@sympli/api-gateway/models';

import { convertFromApiToFormModel as convertFromApiToFormModelTAB_2_21_2 } from 'src/containers/documents/scaffolded-form/nsw/2-21/transmission-application-beneficiary-devisee-next-of-kin/2-21-2/utils/convertFromApiToFormModel';
import { convertFromApiToFormModel as convertFromApiToFormModelTAE_2_21_3 } from 'src/containers/documents/scaffolded-form/nsw/2-21/transmission-application-without-duty/2-21-3/utils/convertFromApiToFormModel';
import { getOtherDocumentsOfSameTypeAndVersionParsed, getOtherDocumentsParsed } from 'src/containers/documents/scaffolded-form/shared/crossDocumentUtils';
import { resolveDeceasedPartyBookNamesOnTAB as resolveDeceasedPartyBookNamesOnTAB_2_21_2 } from '../../../transmission-application-beneficiary-devisee-next-of-kin/2-21-2/utils/resolveDeceasedPartyBookNamesOnTAB';
import { resolveDeceasedPartyBookNamesOnTAE as resolveDeceasedPartyBookNamesOnTAE_2_21_3 } from '../../../transmission-application-without-duty/2-21-3/utils/resolveDeceasedPartyBookNamesOnTAE';
import { NoticeOfDeath2_21_1Model } from '../models';
import { convertFromApiToFormModel } from './convertFromApiToFormModel';
import { resolveDeceasedPartyBookNamesOnNOD } from './resolveDeceasedPartyBookNamesOnNOD';

type FormModel = NoticeOfDeath2_21_1Model;

// explicitly memoize this call so we can call it multiple times without re-calculating
const resolveDeceasedPartyBookNamesOnCurrentDocument = defaultMemoize(
  (
    //
    deceasedJointTenants: FormModel['deceasedJointTenants'],
    partyBook: FormModel['partyBook']
  ) => {
    return resolveDeceasedPartyBookNamesOnNOD(deceasedJointTenants, partyBook);
  }
);

const isDeceasedPartySelectedOnAnotherNOD = defaultMemoize(
  ({
    workspaceDocuments,
    documentId,
    context,
    deceasedJointTenants,
    partyBook
  }: {
    //
    workspaceDocuments: WorkspaceDocumentSummaryApiResponse[];
    /**
     * current documentId
     */
    documentId: string;
    /**
     * current form context
     */
    context: IConverterContext;
    /**
     * current form deceasedJointTenants
     */
    deceasedJointTenants: FormModel['deceasedJointTenants'];
    /**
     * current form partyBook
     */
    partyBook: FormModel['partyBook'];
  }): boolean => {
    // parse
    const otherDocumentsParsed: FormModel[] = getOtherDocumentsOfSameTypeAndVersionParsed(workspaceDocuments, documentId, context, convertFromApiToFormModel);
    // check
    if (otherDocumentsParsed.length) {
      const selectedPartyNamesInCurrentDocument: string[] = resolveDeceasedPartyBookNamesOnCurrentDocument(deceasedJointTenants, partyBook);
      const selectedPartyNamesInOtherDocuments: string[] = otherDocumentsParsed.flatMap(doc => resolveDeceasedPartyBookNamesOnNOD(doc.deceasedJointTenants, doc.partyBook));
      return selectedPartyNamesInCurrentDocument.some(p => selectedPartyNamesInOtherDocuments.includes(p));
    }

    return false;
  }
  //, shallowEqual
);

const isDeceasedPartySelectedOnTAB = defaultMemoize(
  ({
    workspaceDocuments,
    context,
    deceasedJointTenants,
    partyBook
  }: {
    //
    workspaceDocuments: WorkspaceDocumentSummaryApiResponse[];
    /**
     * current form context
     */
    context: IConverterContext;
    /**
     * current form deceasedJointTenants
     */
    deceasedJointTenants: FormModel['deceasedJointTenants'];
    /**
     * current form partyBook
     */
    partyBook: FormModel['partyBook'];
  }): boolean => {
    // parse NOD documents
    const tabDocumentsParsed: ReturnType<typeof convertFromApiToFormModelTAB_2_21_2>[] = getOtherDocumentsParsed(
      workspaceDocuments,
      {
        ...context,
        meta: {
          ...context.meta,
          // TODO update this to 2.21.2 once backend is updated
          schemaVersion: '2.21.1', // this version needs to match the source of convertFromApiToFormModelTAE method
          documentTypeId: DocumentTypeIdentifierEnum.TransmissionApplicationByBeneficiaryDeviseeNextOfKin
        }
      },
      convertFromApiToFormModelTAB_2_21_2
    );
    // check
    if (tabDocumentsParsed.length) {
      const selectedPartyNamesInCurrentDocument: string[] = resolveDeceasedPartyBookNamesOnCurrentDocument(deceasedJointTenants, partyBook);
      const selectedPartyNamesInOtherDocuments: string[] = tabDocumentsParsed.flatMap(doc => resolveDeceasedPartyBookNamesOnTAB_2_21_2(doc.deceasedTenancyDetail, doc.partyBook));
      return selectedPartyNamesInCurrentDocument.some(p => selectedPartyNamesInOtherDocuments.includes(p));
    }

    return false;
  }
  //, shallowEqual
);

const isDeceasedPartySelectedOnTAE = defaultMemoize(
  ({
    workspaceDocuments,
    context,
    deceasedJointTenants,
    partyBook
  }: {
    //
    workspaceDocuments: WorkspaceDocumentSummaryApiResponse[];
    /**
     * current form context
     */
    context: IConverterContext;
    /**
     * current form deceasedJointTenants
     */
    deceasedJointTenants: FormModel['deceasedJointTenants'];
    /**
     * current form partyBook
     */
    partyBook: FormModel['partyBook'];
  }): boolean => {
    // parse NOD documents
    const taeDocumentsParsed: ReturnType<typeof convertFromApiToFormModelTAE_2_21_3>[] = getOtherDocumentsParsed(
      workspaceDocuments,
      {
        ...context,
        meta: {
          ...context.meta,
          // TODO update this to 2.21.3 once backend is updated
          schemaVersion: '2.21.2', // this version needs to match the source of convertFromApiToFormModelTAE method
          documentTypeId: DocumentTypeIdentifierEnum.TransmissionApplicationByExecutorAdministratorTrustee
        }
      },
      convertFromApiToFormModelTAE_2_21_3
    );
    // check
    if (taeDocumentsParsed.length) {
      const selectedPartyNamesInCurrentDocument: string[] = resolveDeceasedPartyBookNamesOnCurrentDocument(deceasedJointTenants, partyBook);
      const selectedPartyNamesInOtherDocuments: string[] = taeDocumentsParsed.flatMap(doc => resolveDeceasedPartyBookNamesOnTAE_2_21_3(doc.deceasedTenancyDetail, doc.partyBook));
      return selectedPartyNamesInCurrentDocument.some(p => selectedPartyNamesInOtherDocuments.includes(p));
    }

    return false;
  }
  //, shallowEqual
);

export const isDeceasedPartySelectedOnAnotherDocument = ({
  workspaceDocuments,
  documentId,
  context,
  deceasedJointTenants,
  partyBook
}: {
  //
  workspaceDocuments: WorkspaceDocumentSummaryApiResponse[];
  documentId: string;
  context: IConverterContext;
  deceasedJointTenants: FormModel['deceasedJointTenants'];
  partyBook: FormModel['partyBook'];
}): string | undefined => {
  const docNames: string[] = [];

  if (isDeceasedPartySelectedOnTAE({ workspaceDocuments, context, deceasedJointTenants, partyBook })) {
    docNames.push('Transmission Application');
  }

  if (docNames.length === 0 && isDeceasedPartySelectedOnTAB({ workspaceDocuments, context, deceasedJointTenants, partyBook })) {
    docNames.push('Transmission Application');
  }

  if (isDeceasedPartySelectedOnAnotherNOD({ workspaceDocuments, documentId, context, deceasedJointTenants, partyBook })) {
    docNames.push('Notice of Death');
  }

  if (docNames.length) {
    return `This party is selected on another ${docNames.join(' or ')} document in this workspace; selecting them again may cause an issue in lodgement.`;
  }
};
