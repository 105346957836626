import { useFormikContext } from 'formik';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, resolveLabel, resolveSelectPlaceholder, resolveVisibleEnumOptions } from '@sympli-mfe/document-forms-framework/utils';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';

import { VISIBILITY_CHECK_CLAIM_CATEGORY, VISIBILITY_CHECK_CLAIM_DETAILS_LR_DOCUMENT_ID } from '../../../checks';
import { ENUM_CLAIM_CATEGORY_OPTIONS, ENUM_ESTATE_OR_INTEREST_CLAIMED_OPTIONS, EstateOfInterestClaimedEnum } from '../../../enums';
import { CaveatModel_2_21_2, ClaimDetailModel } from '../../../models';
import { useStyles } from '../styles';
import { ClaimDateAndDetails } from './ClaimDateAndDetails';

const debug = !import.meta.env.PROD;

type FormValues = CaveatModel_2_21_2;

interface Props {
  name: string;
}

export function EstateOrInterestClaimed(props: Props) {
  const fieldName = createModelKeyAppender<ClaimDetailModel>(props.name);

  const classes = useStyles();
  const { values, setValues } = useFormikContext<FormValues>();
  const { disabled } = useDocumentContext();

  const claim = values.claimDetails!;

  const handleEstateOrInterestClaimedChange = (event: React.ChangeEvent<HTMLInputElement>, newEstateOrInterestClaimed: EstateOfInterestClaimedEnum): void => {
    setValues(updatedValues => {
      const updatedClaimCategoryOptions = resolveVisibleEnumOptions<number>(ENUM_CLAIM_CATEGORY_OPTIONS, updatedValues, fieldName('claimCategory')); //
      const updatedClaimCategory = updatedClaimCategoryOptions.length === 1 ? updatedClaimCategoryOptions[0].id : null;

      return {
        ...updatedValues,
        claimDetails: {
          ...updatedValues.claimDetails,
          claimCategory: updatedClaimCategory,
          detailsSupportingTheClaim: ''
        }
      };
    });
  };

  return (
    <FormGroup title="Estate or interest claimed" fieldAreaDirection="column">
      {({ titleId }) => (
        <>
          <Field
            aria-labelledby={titleId}
            disabled={disabled}
            className={classes.fixedWidthDropdown}
            name={fieldName('estateOrInterestClaimed')}
            format="number"
            options={ENUM_ESTATE_OR_INTEREST_CLAIMED_OPTIONS}
            placeholder={resolveSelectPlaceholder(true)}
            component={SelectField}
            onChange={handleEstateOrInterestClaimedChange}
            debug={debug}
          />
          {VISIBILITY_CHECK_CLAIM_CATEGORY(claim) ? (
            <Field
              disabled={disabled}
              className={classes.fixedWidthDropdown}
              label={resolveLabel('Claim category', true)}
              name={fieldName('claimCategory')}
              format="number"
              options={resolveVisibleEnumOptions<number>(ENUM_CLAIM_CATEGORY_OPTIONS, values, fieldName('claimCategory'))}
              placeholder={resolveSelectPlaceholder(true)}
              component={SelectField}
              debug={debug}
            />
          ) : null}

          <ClaimDateAndDetails //
            name={props.name}
            claim={claim}
          />

          {VISIBILITY_CHECK_CLAIM_DETAILS_LR_DOCUMENT_ID(claim) ? (
            <Field //
              disabled={disabled}
              className={classes.halfWidth}
              label={resolveLabel('LRS Dealing number', false)}
              name={fieldName('lrDocumentID')}
              component={InputField}
            />
          ) : null}
        </>
      )}
    </FormGroup>
  );
}
