import * as React from 'react';

import classNames from 'classnames';

import { ColumnsModel, TableV2 as Table } from '@sympli/ui-framework/components/table';

import DidNotLoad from 'src/containers/dashboard/components/svg/DidNotLoad';
import { LinkedWorkspaceTableModel } from 'src/containers/workspace/shared/detail/components/linked-settlement-detail/linked-workspace-table/models';
import { resolveTableColumns } from './helpers';

const DeleteLinkedWorkspacesTable = ({ rows, lockedRows, isLoading }: { rows: LinkedWorkspaceTableModel[]; lockedRows?: Array<number>; isLoading?: boolean }) => {
  const columns: ColumnsModel<LinkedWorkspaceTableModel> = resolveTableColumns();

  return (
    <Table //
      className={classNames('border-b border-x-0 border-t-0 border-solid border-[var(--neutral-800)]')}
      noDataMessage={<DidNotLoad />}
      recordType="linked Settlement"
      columns={columns}
      lockedRows={lockedRows}
      rows={rows}
      isLoading={isLoading}
      pageSize={isLoading ? 5 : undefined}
      noFooter
    />
  );
};

export default React.memo(DeleteLinkedWorkspacesTable);
