import * as yup from 'yup';

import { getLookupValuesAllowingEmpty, validateWhenVisible2 } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { SET_OF_OPERATIVE_WORDS_LOOKUP_OPTIONS, SetOfOperativeWordsEnum } from '../../enums';
import { WithdrawalOfCaveat_2_26_0_Model } from '../../models';
import { VISIBILITY_CHECK_CAVEATORS, VISIBILITY_CHECK_WITHDRAW_CAVEAT } from '../../visibilityChecks';

// path: setOfOperativeWords
const yupSetOfOperativeWords = validateWhenVisible2<SetOfOperativeWordsEnum | null>({
  visibilityCheck: (parentValue: WithdrawalOfCaveat_2_26_0_Model) => VISIBILITY_CHECK_WITHDRAW_CAVEAT(parentValue) && VISIBILITY_CHECK_CAVEATORS(parentValue),
  validationWhenVisible: yup
    .mixed<SetOfOperativeWordsEnum>()
    .required(msg.REQUIRED)
    .oneOf(getLookupValuesAllowingEmpty(SET_OF_OPERATIVE_WORDS_LOOKUP_OPTIONS), msg.INVALID_SELECTION)
});
export default yupSetOfOperativeWords;
