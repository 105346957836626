import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  () => ({
    transactionTypeTitle: {
      color: colors.SUNNY_DAY_DARK,
      fontSize: 12,
      fontWeight: 500
    }
  }),
  { name: 'TransactionType' }
);
