import { $and, $noneOf, $oneOf, createContextCheckUsingRoot } from '@sympli-mfe/document-forms-framework/utils';

import { ActionProhibitedEnum, ClaimCategoryEnum, EstateOfInterestClaimedEnum } from './enums';
import { CaveatModel_2_21_2, ClaimDetailModel, ExtentOfProhibition } from './models';
import { VISIBILITY_CHECK_CLAIM_DATE } from './sections/claim-details/checks';

export const VISIBILITY_CHECK_QUALIFICATION = (extentOfProhibition: ExtentOfProhibition): boolean => {
  return [ActionProhibitedEnum.ActionProhibited1, ActionProhibitedEnum.ActionProhibited2, ActionProhibitedEnum.ActionProhibited5].includes(
    extentOfProhibition.actionProhibitedOption as number
  );
};

export const VISIBILITY_CHECK_EXTENT_OF_PROHIBITIONS_LR_DOCUMENT_ID = (extentOfProhibition: ExtentOfProhibition): boolean => {
  return [ActionProhibitedEnum.ActionProhibited3, ActionProhibitedEnum.ActionProhibited6a, ActionProhibitedEnum.ActionProhibited6b].includes(
    extentOfProhibition.actionProhibitedOption as number
  );
};

export const VISIBILITY_CHECK_CLAIM_CATEGORY = (claimDetails: ClaimDetailModel): boolean => {
  const estateOrInterestClaimed = claimDetails.estateOrInterestClaimed ?? null;
  return ![null, EstateOfInterestClaimedEnum.RegisteredProprietor].includes(estateOrInterestClaimed);
};

export const VISIBILITY_CHECK_CLAIM_PARTIES = (claimDetails: ClaimDetailModel): boolean => {
  const allowedClaimCategoryValues: ClaimCategoryEnum[] = [1, 6, 7, 11, 12, 13, 14, 15, 18, 19, 20, 21, 22, 23];

  return $and(
    //
    $oneOf(claimDetails.claimCategory, allowedClaimCategoryValues),
    $noneOf(claimDetails.estateOrInterestClaimed, [null, EstateOfInterestClaimedEnum.RegisteredProprietor])
  );
};

export const VISIBILITY_CHECK_CLAIM_DETAILS_LR_DOCUMENT_ID = (claimDetails: ClaimDetailModel): boolean => {
  const allowedClaimCategoryValues: ClaimCategoryEnum[] = [3, 6, 9, 10, 11, 13, 14, 15, 17, 18, 19, 20, 21, 22, 23, 24];

  return $and(
    //
    $oneOf(claimDetails.claimCategory, allowedClaimCategoryValues),
    claimDetails.estateOrInterestClaimed !== EstateOfInterestClaimedEnum.RegisteredProprietor
  );
};

const DAN_MIN_DATE = new Date('1987-01-01T00:00:00');
const DAN_MAX_DATE = new Date('2016-06-30T23:59:59');
const dateFallsWithinDanRange = (claimDate?: Date | string | null): boolean => {
  if (typeof claimDate !== 'string' && !(claimDate instanceof Date)) {
    return false;
  }
  const claimDateAsDate = typeof claimDate === 'string' ? new Date(claimDate) : claimDate;
  return claimDateAsDate >= DAN_MIN_DATE && claimDateAsDate <= DAN_MAX_DATE;
};
const danAppliesToClaimCategory = (estateOrInterestClaimed: EstateOfInterestClaimedEnum, claimCategory: ClaimCategoryEnum): boolean => {
  switch (estateOrInterestClaimed) {
    case EstateOfInterestClaimedEnum.Mortgage:
      return [ClaimCategoryEnum.LoanAgreement, ClaimCategoryEnum.MortgageOfEstateInFeeSimple].includes(claimCategory);
    case EstateOfInterestClaimedEnum.Charge:
      return [ClaimCategoryEnum.Agreement, ClaimCategoryEnum.ChargeOfEstateInFeeSimple].includes(claimCategory);
    default:
      return false;
  }
};
export const VISIBILITY_CHECK_DUTY_ASSESSMENT_NUMBER = createContextCheckUsingRoot(
  (
    _: any, // DutyAssessmentModel
    { claimDetails }: CaveatModel_2_21_2
  ): boolean => {
    if (VISIBILITY_CHECK_CLAIM_DATE(claimDetails)) {
      return false;
    }
    const { estateOrInterestClaimed, claimCategory, claimDate } = claimDetails!;
    return danAppliesToClaimCategory(estateOrInterestClaimed!, claimCategory!) && dateFallsWithinDanRange(claimDate);
  }
);

/*
 path:
 - caveators    
*/
export const VISIBILITY_CHECK_CAVEATORS = <
  T extends {
    //
    extentOfProhibitions: CaveatModel_2_21_2['extentOfProhibitions'];
    proprietors: CaveatModel_2_21_2['proprietors'];
  }
>({
  extentOfProhibitions,
  proprietors
}: T): boolean => {
  const caveatorsMustBeSameAsProprietors =
    proprietors.proprietorGroups.every(({ isSelected }) => isSelected) &&
    extentOfProhibitions.some(({ actionProhibitedOption }) => actionProhibitedOption === ActionProhibitedEnum.ActionProhibited5);

  return !caveatorsMustBeSameAsProprietors;
};
