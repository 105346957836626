import { INITIAL_FINAL_TENANCY_HOLDINGS_MODEL } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/final-holdings/models';
import { IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { fallbackMap } from '../fallback';
import { ApiTransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model, TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model } from '../models';
import { convertPartiesToFormModel_2_21_1, generateDeceasedTenancy } from './common';

type FormModel = TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model;

export function convertFromApiToFormModel(apiModel: ApiTransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model, context: IConverterContext): FormModel {
  const risInvolvedPartyIds = apiModel.partyBook.filter(p => p.propertiesMappedFromRis?.length).map(p => p.id!);
  let formModel: FormModel = {
    ...apiModel,
    finalTenancyHoldings: INITIAL_FINAL_TENANCY_HOLDINGS_MODEL,
    partyBookApi: apiModel.partyBook,
    partyBook: convertPartiesToFormModel_2_21_1(apiModel.partyBook, risInvolvedPartyIds),
    // UI only properties
    context
  };

  if (apiModel.invalidParties) {
    formModel = generateDeceasedTenancy(formModel);
  }

  const formModelWithDefaultMapApplied = applyDefaultMap(formModel, fallbackMap);
  return formModelWithDefaultMapApplied;
}
