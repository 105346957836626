import React, { useCallback } from 'react';

import { getIn, useFormikContext } from 'formik';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import { ProprietorGroupTypeEnum, TenancyTypeEnum } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import { PartyTypeEnum } from '@sympli/api-gateway/enums';

import {
  BaseDeceasedTenancyDetailComponentModel,
  DeceasedProprietorGroupModel,
  DeceasedProprietorModel,
  DeceasedTenancyDetailModel
} from 'src/containers/documents/scaffolded-form/nsw/2-21/components/deceased-tenancy-detail/models';
import { extractSurvivingJointTenants } from '../../../../helpers';
import { DEFAULT_DEATH_OF_DATE, DEFAULT_EVIDENCE } from '../../helpers';
import DeceasedProprietor from '../deceased-proprietor/DeceasedProprietor';
import { useStyles } from './styles';

interface Props {
  itemBinding: string;
  numberOfPartiesInPreceedingGroups: number;
}

function DeceasedProprietorArray({ itemBinding, numberOfPartiesInPreceedingGroups }: Props): JSX.Element {
  const fieldName = createModelKeyAppender<DeceasedProprietorGroupModel>(itemBinding);
  const name = fieldName('parties');
  const { disabled } = useDocumentContext();
  const classes = useStyles();
  const formikProps = useFormikContext<BaseDeceasedTenancyDetailComponentModel>();
  const { values, setValues } = formikProps;
  const {
    deceasedTenancyDetail: { tenancyType, proprietorGroups }
  } = values;

  const { proprietorGroupType } = getIn(values, itemBinding);

  const isJointTenants = tenancyType === TenancyTypeEnum.JointTenants || proprietorGroupType === ProprietorGroupTypeEnum.JointTenantsInterse;

  const renderItem = ({ item, itemBinding }: DocumentArrayItemRenderProps<DeceasedProprietorModel>) => {
    return (
      <DeceasedProprietor //
        name={itemBinding}
        proprietor={item}
      />
    );
  };

  const renderPartyLabel = (itemIndex: number): string => {
    const label = `Joint tenant ${numberOfPartiesInPreceedingGroups + itemIndex + 1}`;

    return label;
  };

  const renderItemTitle = ({ itemIndex, item }: DocumentArrayItemRenderProps<DeceasedProprietorModel>) => {
    const label = renderPartyLabel(itemIndex);
    const { values } = formikProps;
    const partyBook = values.partyBook ?? [];
    const disable = disabled || partyBook.find(party => party.id === item.partyBookId)?.partyType === PartyTypeEnum.Organisation;

    return (
      <FormControlLabel
        key={itemIndex}
        value={item.partyBookId}
        label={isJointTenants && label}
        checked={item.isSelected}
        disabled={disable}
        control={<Radio color="primary" aria-label="Deceased Joint Tenant" />}
        onChange={handleOnRadioButtonChange}
        className={classes.radioButtonRoot}
        classes={{ label: classes.radioButtonLabel }}
      />
    );
  };

  const handleOnRadioButtonChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const convertedDeceasedTenancyDetailModel: DeceasedTenancyDetailModel = {
        tenancyType,
        proprietorGroups: proprietorGroups.map(group => {
          return {
            ...group,
            isSelected: group.parties.some(proprietor => proprietor.partyBookId! === event.target.value),
            parties: group.parties.map(proprietor => {
              const isAffectedProprietor = proprietor.partyBookId! === event.target.value;

              const updatedProprietor: DeceasedProprietorModel = {
                ...proprietor,
                isSelected: isAffectedProprietor,
                dateOfDeath: proprietor.dateOfDeath ? { ...proprietor.dateOfDeath } : { ...DEFAULT_DEATH_OF_DATE },
                evidences: proprietor.evidences.length ? [...proprietor.evidences] : [{ ...DEFAULT_EVIDENCE }]
              };
              return updatedProprietor;
            })
          };
        })
      };

      const survivingJointTenants = extractSurvivingJointTenants(convertedDeceasedTenancyDetailModel);

      setValues(values => {
        return {
          ...values,
          deceasedTenancyDetail: convertedDeceasedTenancyDetailModel,
          survivingJointTenants: survivingJointTenants
        };
      });
    },
    [setValues, proprietorGroups, tenancyType]
  );

  return (
    <div data-section={name}>
      <DocumentFieldArray //
        arrayBinding={name}
        renderItem={renderItem}
        itemTitle={renderItemTitle}
        disabled={disabled}
        itemStyle="formGroup"
        mode="fixed"
      />
    </div>
  );
}

export default React.memo(DeceasedProprietorArray);
