import * as yup from 'yup';

import { TOTAL_SHARES_OVER_LIMIT_ERROR_MESSAGE } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/shared/components/total-shares/constants';

import { TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model } from '../../models';

type FormModel = TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model;

const TOTAL_SHARE_LIMIT: number = 100000;

const yupFinalHoldings = yup.mixed<FormModel['finalTenancyHoldings']>().test(
  //
  'Total shares should be under limit',
  `${TOTAL_SHARES_OVER_LIMIT_ERROR_MESSAGE(TOTAL_SHARE_LIMIT)}; please check share fractions for all the parties.`,
  function test(this: yup.TestContext<FormModel>, finalTenancyHoldings: FormModel['finalTenancyHoldings']) {
    return finalTenancyHoldings ? finalTenancyHoldings.lcm <= TOTAL_SHARE_LIMIT : true;
  }
);

export default yupFinalHoldings;
