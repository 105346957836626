import { ClaimParties } from './components/ClaimParties';
import { DutyAssessment } from './components/DutyAssessment';
import { EstateOrInterestClaimed } from './components/EstateOrInterestClaimed';

interface Props {
  name: string;
}

function SectionClaimDetails({ name }: Props) {
  return (
    <>
      <EstateOrInterestClaimed //
        name={name}
      />
      <DutyAssessment />
      <ClaimParties //
        name={name}
      />
    </>
  );
}

export default SectionClaimDetails;
