import React, { useCallback, useEffect } from 'react';

import { Form, useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import SectionTitleReference from '@sympli-mfe/document-forms-framework/components/sections/title-reference';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import { PortalTarget } from '@sympli/ui-framework/components/portal';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import { ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_OPTIONS } from './enums';
import { generateMortgagors, getPartyAdder } from './helper';
import { IS_REQUIRED_CHECK_DOCUMENT_ATTACHMENT } from './isRequiredChecks';
import { Mortgage2_21_3Model } from './models';
import DocumentAttachments from './sections/document-attachments';
import Mortgagees from './sections/mortgagees';
import Mortgagors from './sections/mortgagors/Mortgagors';
import TermsAndConditions from './sections/terms-and-conditions';

const fieldName = modelKey<Mortgage2_21_3Model>();

function RootForm({
  className, //
  hideActionsPanel,
  onSaveChanges,
  onCancel,
  queryParams
}: RootFormProps): JSX.Element {
  const { isLoading, disabled, nextActionDisabled, dialogPortalId, nextActionLabel, participants } = useDocumentContext();
  const formikProps = useFormikContext<Mortgage2_21_3Model>();
  const { values, setValues } = formikProps;
  const focusOnTitleReferencesRef = React.useRef<ButtonBaseActions>(null);
  const focusOnMortgagorsRef = React.useRef<ButtonBaseActions>(null);
  const focusOnMortgageesRef = React.useRef<ButtonBaseActions>(null);
  const {
    termsAndConditions: { standardTermsDealingNumbers, additionalCovenants },
    additionalAttachments
  } = values;

  useEffect(() => {
    const focusOnFirstItem = focusOnTitleReferencesRef.current || focusOnMortgagorsRef.current || focusOnMortgageesRef.current;
    if (!focusOnFirstItem) {
      Logger.console(SeverityEnum.Warning, 'Document has nothing to focus on. Please fix this');
      return;
    }
    focusOnFirstItem.focusVisible();
  }, []);

  const handleTitleReferenceSelectionChange = useCallback(() => {
    setValues(values => {
      return generateMortgagors(values, getPartyAdder(participants));
    });
  }, [participants, setValues]);

  return (
    <>
      <Form className={className}>
        <SectionTitleReference //
          name={fieldName('titleReferences')}
          data-testid={fieldName('titleReferences')}
          disabled={disabled}
          onChange={handleTitleReferenceSelectionChange}
          focusRef={focusOnTitleReferencesRef}
          skipTouchedCheck
        />

        <Mortgagors name={fieldName('mortgagors')} focusRef={focusOnMortgagorsRef} />

        <Mortgagees name={fieldName('mortgagees')} focusRef={focusOnMortgageesRef} />

        <Section //
          title="Terms and conditions"
          data-testid={fieldName('termsAndConditions')}
          data-name={fieldName('termsAndConditions')}
        >
          <TermsAndConditions name={fieldName('termsAndConditions')} queryParams={queryParams} additionalAttachments={additionalAttachments} />
        </Section>

        <DocumentAttachments //
          name={fieldName('additionalAttachments')}
          minItems={0}
          attachmentDocumentTypes={ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_OPTIONS}
          isRequired={IS_REQUIRED_CHECK_DOCUMENT_ATTACHMENT(standardTermsDealingNumbers, additionalCovenants)}
        />
        {!hideActionsPanel && (
          <DocumentActionPanel //
            isLoading={isLoading}
            disabled={nextActionDisabled}
            onBack={onCancel}
            nextLabel={nextActionLabel}
            onSaveChanges={onSaveChanges}
          />
        )}
      </Form>
      <PortalTarget id={dialogPortalId} />
    </>
  );
}

export default React.memo(RootForm);
