import * as yup from 'yup';

import { createPartyBookItemTestForPartyBookId, PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import {
  resolveProprietorGroupsValidationSchema,
  YUP_GENERIC_TENANCY_PARTY_BOOK_ID_REQUIRED
} from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/generic/validationSchema';
import { memoizeSchemaWithContext } from '@sympli-mfe/document-forms-framework/validation';

import PartyValidations from 'src/containers/documents/party-merge/PartyValidations';
import { getLegalEntityNameNSW } from '../../../../helpers';
import { NSW_LEASE_PARTY_FORM_CONFIG } from '../../config';
import { Lease2_21_2Model } from '../../models';
import { disablePartyFieldEditConfig } from './LessorsEditable';

// run party data validation only if party field edit is not disabled
const shouldValidateLessorPartyData = (party: PartyModel) => !disablePartyFieldEditConfig.disabled(party);

type FormModel = Lease2_21_2Model;

interface ProprietorGroupsContext {
  partyBook: FormModel['partyBook'];
}

const yupProprietorGroups: yup.MixedSchema<FormModel['lessors']['proprietorGroups'], ProprietorGroupsContext> = memoizeSchemaWithContext(
  resolveProprietorGroupsValidationSchema<ProprietorGroupsContext>({
    yupPartyBookId: YUP_GENERIC_TENANCY_PARTY_BOOK_ID_REQUIRED.test(
      createPartyBookItemTestForPartyBookId(
        //
        NSW_LEASE_PARTY_FORM_CONFIG,
        {
          shouldValidatePartyData: shouldValidateLessorPartyData
        }
      )
    ),
    // turn off unnecessary validations
    yupAddressBookId: yup.mixed(),
    yupPartyCapacity: yup.mixed()
  }),
  (parentContext: FormModel): ProprietorGroupsContext => {
    return {
      partyBook: parentContext.partyBook // this is required here for party validation
    };
  }
);

export const yupLessorsEditable: yup.Schema<FormModel['lessors'], FormModel> = yup
  .object<FormModel['lessors']>({
    proprietorGroups: yupProprietorGroups,
    // turn off unnecessary validations
    tenancyType: yup.mixed()
  })
  .test(
    //
    'Lessors merge check test',
    'The selected titles do not share the same Lessors/Registered Proprietor. Please review the selected titles',
    function test(this: yup.TestContext<Lease2_21_2Model>) {
      const { partyBook, mergeFailedReason } = this.options.context!;
      if (mergeFailedReason) {
        return false;
      }
      // TODO Kenneth: this mutates the data! It should not be here!!
      const validationResult = PartyValidations.verifyJustification(partyBook, getLegalEntityNameNSW);
      return validationResult;
    }
  )
  .defined();
