import * as yup from 'yup';
import { ObjectSchema } from 'yup';

import msg from '@sympli/ui-framework/utils/messages';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import { ProfileImageModel } from 'src/containers/shared/profile-image/models';
import http from 'src/utils/http';
import { REGEX_CHARACTER, yupMobileNumber } from 'src/utils/validation';
import { REGEX_OKTA_EMAIL } from 'src/utils/validation/regex';
import { UserDetailsFormModel } from './detail/models';

const yupString = yup.string().trim();

const yupStringRequired = yupString.required(msg.REQUIRED);

const yupName = yupString.matches(REGEX_CHARACTER, msg.CAN_ONLY_CONTAIN_LETTERS_DASHES_APOSTROPHES_AND_SPACES('name'));

const emailAsyncValidator = async function (value: string) {
  try {
    const response = await http.post<boolean, { email: string }>(
      '/settings/users/validate-email', //
      { email: value }
    );
    // backend returns true if email is already taken
    return !response;
  } catch (error) {
    Logger.console(SeverityEnum.Warning, 'Invalid email');
    Logger.captureException(error);
    return false;
  }
};

function oktaEmailValidation(value: string): boolean {
  return !value || REGEX_OKTA_EMAIL.test(value.toLowerCase());
}

export const getValidationSchema = (canUpdateEmail: boolean, allowedEmailAddress: string | undefined): ObjectSchema => {
  const nameCharacterLimit: number = 40;
  const yupEditModeSchemaMap = {
    firstName: yupName.required(msg.REQUIRED).max(nameCharacterLimit, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(nameCharacterLimit)),
    middleName: yupName.max(nameCharacterLimit, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(nameCharacterLimit)),
    lastName: yupName.required(msg.REQUIRED).max(nameCharacterLimit, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(nameCharacterLimit)),
    mobileNumber: yup.lazy((value: string) => (!value ? yup.mixed() : yupMobileNumber)),
    work: yupString.max(15, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(15)),
    timezone: yupStringRequired,
    avatarUrl: yupString.nullable(),
    profileImage: yup
      .object<ProfileImageModel>()
      .shape({
        base64String: yupString,
        imageType: yupString
      })
      .nullable()
  };

  if (!canUpdateEmail) {
    return yup.object<UserDetailsFormModel>().shape(yupEditModeSchemaMap);
  }

  return yup.object<UserDetailsFormModel>().shape({
    ...yupEditModeSchemaMap,
    email: yupStringRequired.test('oktaEmailCheck', msg.INVALID_EMAIL, oktaEmailValidation).debouncedTest(
      'This email is already taken',
      function emailCheck(this: yup.TestContext, value: string) {
        if (!value || value.toLowerCase() === allowedEmailAddress?.toLowerCase() || !oktaEmailValidation(value)) {
          return true;
        }
        return emailAsyncValidator(value);
      },
      { wait: 300 }
    )
  });
};
