import { LookupEnumModel } from '@sympli/ui-framework/models';

import { EvidenceTypeEnum } from 'src/containers/documents/scaffolded-form/nsw/2-21/components/deceased-tenancy-detail/components/evidence/enums';

export const EVIDENCE_TYPE_VALUE_LOOKUP_OPTIONS: LookupEnumModel<EvidenceTypeEnum>[] = [
  {
    id: EvidenceTypeEnum.CertificateUnderNSWTrusteeAndGuardianAct,
    name: EvidenceTypeEnum.CertificateUnderNSWTrusteeAndGuardianAct
  },
  {
    id: EvidenceTypeEnum.CoronersReport,
    name: EvidenceTypeEnum.CoronersReport
  },
  {
    id: EvidenceTypeEnum.DeathCertificate,
    name: EvidenceTypeEnum.DeathCertificate
  },
  {
    id: EvidenceTypeEnum.MedicalCertificateCertifyingDeath,
    name: EvidenceTypeEnum.MedicalCertificateCertifyingDeath
  }
];
