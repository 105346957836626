import { resolveConsiderationFallback } from '@sympli-mfe/document-forms-framework/components/sections/consideration';
import { resolveReceivingTenancyDetailFallback } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving';
import { resolveRelinquishingTenancyDetailFallback } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/relinquishing/fallback';
import { resolveTenancyFallback } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { Transfer2_21_1Model } from './models';

// this file was automatically generated from fallback.ts.mustache
export const fallbackMap: FallbackMap<Transfer2_21_1Model> = {
  workspaceTitleReferences: NO_FALLBACK,
  partyBookApi: NO_FALLBACK,
  partyBook: NO_FALLBACK,
  needToGeneratePartyBook: NO_FALLBACK,
  titleReferences: {
    $shouldAddDefaultItemToEmptyArray: false,
    $arrayItem: {
      $children: {
        isSelected: [false],
        estateType: NO_FALLBACK,
        reference: NO_FALLBACK,
        isPartLandAffected: NO_FALLBACK,
        partDescription: NO_FALLBACK,
        landDescriptions: NO_FALLBACK,
        transferors: NO_FALLBACK
      }
    }
  },
  documentAttachments: {
    $shouldAddDefaultItemToEmptyArray: false,
    $arrayItem: {
      $children: {
        documentType: [null],
        attachment: {
          $shouldAddDefaultItemToEmptyArray: false,
          $arrayItem: {
            $children: {
              fileName: [null],
              fileType: [null],
              id: [null],
              uploadedByParticipantId: NO_FALLBACK,
              uploadedDateTimeUtc: NO_FALLBACK
            }
          }
        }
      }
    }
  },
  consideration: resolveConsiderationFallback(),
  transferees: resolveTenancyFallback({
    forceDobVisibilityOnReceivingIndividual: true
  }),
  transfereesNew: resolveReceivingTenancyDetailFallback(),
  transferors: NO_FALLBACK,
  finalTenancyHoldings: NO_FALLBACK,
  mortgagors: NO_FALLBACK,
  transferorsNew: resolveRelinquishingTenancyDetailFallback(),
  // custom UI only props
  isTransferorsResetTenancyAvailable: [false],
  transferorsOnTitle: NO_FALLBACK
};
