import Tooltip from 'src/components/tooltip/Tooltip';
import { titleAddress } from 'src/utils/formatters';

interface Props {
  matterReference: string;
  sympliId: string;
  addresses: Array<string>;
}

function LinkedSettlementToParticipantDetail({ matterReference, sympliId, addresses, children }: React.PropsWithChildren<Props>) {
  const formattedAddresses = addresses.map(address => titleAddress(address)).join(', ');
  const plusNValue = Math.max(addresses.length - 1, 0);
  return (
    <span>
      Matter Reference {matterReference} | ID {sympliId} | Address {addresses[0]}{' '}
      {formattedAddresses && plusNValue > 0 && (
        <Tooltip title={formattedAddresses} placement="bottom-end">
          <b>+{plusNValue}</b>
        </Tooltip>
      )}
      {children}
    </span>
  );
}

export default LinkedSettlementToParticipantDetail;
