import { formatPartyName } from '@sympli-mfe/document-forms-framework/components/party-form';
import Logger, { BusinessLogicError } from '@sympli/ui-logger';

import { NSW_NOD_PARTY_FORM_CONFIG } from '../config';
import { NoticeOfDeath2_21_1Model } from '../models';

type FormModel = NoticeOfDeath2_21_1Model;

export function resolveDeceasedPartyBookNamesOnNOD(deceasedJointTenants: FormModel['deceasedJointTenants'] | unknown, partyBook: FormModel['partyBook'] | unknown): string[] {
  if (Array.isArray(deceasedJointTenants) && Array.isArray(partyBook)) {
    try {
      const selectedPartyBookIds: string[] = deceasedJointTenants //
        .flatMap(group =>
          group.deceasedProprietors //
            .filter(p => p.isAffectedProprietor)
            .map(proprietor => proprietor.partyBookId)
        );
      const selectedPartyNames: string[] = selectedPartyBookIds //
        .map(partyBookId => partyBook.find(party => party.id === partyBookId)!)
        .map(party =>
          formatPartyName(party, {
            ...NSW_NOD_PARTY_FORM_CONFIG,
            nameChangeConfig: undefined // explicitly ignore name change when comparing selected deceased parties across documents
          })
        );

      return selectedPartyNames;
    } catch (e) {}
  }
  Logger.captureException(new BusinessLogicError('Unable to resolve deceased parties'));
  return [];
}
