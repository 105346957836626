import React from 'react';

import { useFormikContext } from 'formik';
import _get from 'lodash-es/get';

import { createModelKeyAppender, resolveSelectPlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import DatePickerField from '@sympli/ui-framework/components/formik/date-picker-field';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { BaseDeceasedTenancyDetailComponentModel, EvidenceModel } from 'src/containers/documents/scaffolded-form/nsw/2-21/components/deceased-tenancy-detail/models';
import { EVIDENCE_TYPE_VALUE_LOOKUP_OPTIONS } from './enums';
import { useStyles } from './styles';
import { VISIBILITY_CHECK_EVIDENCE_DATE_AND_EVIDENCE_REFERENCE } from './visibilityChecks';

interface Props {
  name: string;
  disabled: boolean;
}

function Evidence({ name, disabled }: Props): JSX.Element {
  const { values } = useFormikContext<BaseDeceasedTenancyDetailComponentModel>();
  const value: EvidenceModel = _get(values, name);
  const fieldName = createModelKeyAppender<EvidenceModel>(name);
  const classes = useStyles();

  return (
    <div data-section={name} className={classes.sectionWidth} data-testid={name}>
      <FlexLayout justifyContent="flex-start" flexDirection="column">
        <Field //
          name={fieldName('type')}
          component={SelectField}
          label="Evidence type"
          disabled={disabled}
          format="string"
          options={EVIDENCE_TYPE_VALUE_LOOKUP_OPTIONS}
          placeholder={resolveSelectPlaceholder(true)}
          className={classes.fieldWidth}
          data-testid={'evidenceTypeValue'}
        />
        {VISIBILITY_CHECK_EVIDENCE_DATE_AND_EVIDENCE_REFERENCE(value) && (
          <Field //
            name={fieldName('date')}
            label="Evidence date"
            component={DatePickerField}
            disabled={disabled}
            disableFuture={true}
            className={classes.fieldWidth}
          />
        )}
        {VISIBILITY_CHECK_EVIDENCE_DATE_AND_EVIDENCE_REFERENCE(value) && (
          <Field //
            name={fieldName('documentReference')}
            label="Evidence document reference"
            component={InputField}
            disabled={disabled}
            className={classes.fieldWidth}
          />
        )}
      </FlexLayout>
    </div>
  );
}

export default React.memo(Evidence);
