import _xor from 'lodash-es/xor';
import { defaultMemoize } from 'reselect';

import { NswDocumentPartyJustification, nswNameChangeConversion } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { formatPartyName, PartyFormConfig, PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { GenericTenancyDetailModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/generic';
import { PartLandAffectedModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { ProprietorGroupTypeEnum, TenancyTypeEnum } from '@sympli-mfe/document-forms-framework/core/models';
import { DataSource } from '@sympli-mfe/document-forms-framework/shared-config/common';
import { ApiDocumentPartyModel, resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { Container, ProprietorGroup, TenancyDetail } from 'src/containers/documents/party-merge/model';
import PartyMerger from 'src/containers/documents/party-merge/PartyMerger';
import { TitleReferenceModel } from 'src/containers/documents/scaffolded-form/shared/components/title-references/models';
import { convertPartiesToFormModel } from '../../helpers';
import { NSW_LEASE_PARTY_FORM_CONFIG } from './config';
import { Lease2_21_2Model } from './models';

export const { convertPartyBookFromApiToFormModel, convertPartyBookFromFormToApiModel } = resolvePartyBookConversion<NswDocumentPartyJustification>({
  partyFormConfig: NSW_LEASE_PARTY_FORM_CONFIG
});

/**
 * Returns list of title references selected by the user.
 */
export const getSelectedTitleReferences = defaultMemoize(
  (
    //
    titleReferences: TitleReferenceModel<PartLandAffectedModel, NswDocumentPartyJustification>[]
  ): TitleReferenceModel<PartLandAffectedModel, NswDocumentPartyJustification>[] => {
    return titleReferences.filter(({ isSelected }) => isSelected);
  }
);

/**
 * Returns full list of partyBook ids of parties selected by the user in the lessors section.
 * These may include lessors that were added by the user.
 */
export const getCurrentLessorPartyBookIds = defaultMemoize((lessors: GenericTenancyDetailModel): string[] => {
  const lessorsPartyBookIds: string[] = lessors.proprietorGroups
    .flatMap(pg =>
      pg.parties //
        .map(({ partyBookId }) => partyBookId)
    )
    .filter(Boolean) as string[];
  return lessorsPartyBookIds;
});

const NSW_LEASE_PARTY_FORM_CONFIG_WITHOUT_NAME_CHANGE: PartyFormConfig = {
  ...NSW_LEASE_PARTY_FORM_CONFIG,
  nameChangeConfig: undefined // explicitly ignore name change config
};

export function calculateLessorsOnTitle(apiLessors: ApiDocumentPartyModel<NswDocumentPartyJustification, {}>[]): Lease2_21_2Model['lessorsOnTitle'] {
  const partyBook: PartyModel[] = convertPartyBookFromApiToFormModel(apiLessors);
  const partyBookIds: string[] = partyBook.map(p => p.id);
  const partyNamesMap: Map<string, string> = new Map<string, string>(partyBook.map(p => [p.id, formatPartyName(p, NSW_LEASE_PARTY_FORM_CONFIG_WITHOUT_NAME_CHANGE)]));
  return {
    partyBook,
    partyBookIds,
    partyNamesMap,
    isOriginal(party: PartyModel): boolean {
      if (!this.partyBookIds.includes(party.id)) {
        return false;
      }
      return partyNamesMap.get(party.id) === formatPartyName(party, NSW_LEASE_PARTY_FORM_CONFIG_WITHOUT_NAME_CHANGE);
    }
  };
}

export function isLessorsResetTenancyAvailable({
  //
  partyBook,
  lessors,
  lessorsOnTitle
}: {
  partyBook: Lease2_21_2Model['partyBook'];
  lessors: Lease2_21_2Model['lessors'];
  lessorsOnTitle: Lease2_21_2Model['lessorsOnTitle'];
}): boolean {
  const displayedPartyBookIds = lessors.proprietorGroups.flatMap(pg => pg.parties.map(p => p.partyBookId));
  //* 1. has any tenant been added/removed by the user?
  // returns items that are not present across both arrays
  if (_xor(displayedPartyBookIds, lessorsOnTitle.partyBookIds).length > 0) {
    return true;
  }

  const displayedParties: PartyModel[] = displayedPartyBookIds.map(id => partyBook.find(p => p.id === id)).filter(Boolean) as PartyModel[];

  //* 2. has any tenant been updated by the user?
  const isUpdated = displayedParties.some(p => !lessorsOnTitle.isOriginal(p));

  return isUpdated;
}

/**
 * Generates Lessors with PartyMerger and sets
 * @returns
 */
export const generateLessors = (formModel: Lease2_21_2Model, includeCustomParties = true): Lease2_21_2Model => {
  const customParties = formModel.partyBook.filter(party => party.metadata?.source === DataSource.Custom || party.metadata?.addedFrom === DataSource.Custom);
  const selectedTitles = formModel.titleReferences.filter(tr => tr.isSelected);

  if (!selectedTitles.length) {
    return {
      ...formModel,
      lessors: {
        proprietorGroups: [],
        tenancyType: TenancyTypeEnum.None
      }
    };
  }

  const displayedLessorPartyBookIds: string[] = getCurrentLessorPartyBookIds(formModel.lessors);
  const customLessors: PartyModel[] = customParties.filter(p => displayedLessorPartyBookIds.includes(p.id!));

  const containers = selectedTitles.map(
    title =>
      new Container(
        title.reference!,
        new TenancyDetail(
          title.proprietor.proprietorGroups.map(
            pg =>
              new ProprietorGroup(
                pg.parties.map(p => p.party),
                pg,
                // ShareFraction doesn't matter for Lease party merging
                { numerator: 1, denominator: 1 },
                pg.proprietorGroupType
              )
          )
        )
      )
  );

  const result = PartyMerger.merge(containers);

  // lessors from title
  const parties = result.containers.flatMap(container => container.tenancyDetail.proprietorGroups.flatMap(pg => pg.mergedParties));

  const tenancyType = result.containers[0]?.tenancyDetail.tenancyType! ?? TenancyTypeEnum.None;

  const lessorsPartyBookIds: string[] = includeCustomParties ? parties.map(p => p.id!).concat(customLessors.map(p => p.id!)) : parties.map(p => p.id!);

  const lessors: GenericTenancyDetailModel = {
    tenancyType,
    proprietorGroups: [
      {
        parties: lessorsPartyBookIds.map((partyBookId: string) => ({ partyBookId, isSelected: true, partyCapacity: {} })),
        isSelected: true,
        proprietorGroupType: ProprietorGroupTypeEnum.None,
        shareFraction: { numerator: null, denominator: null }
      }
    ]
  };

  const partyBook: Lease2_21_2Model['partyBook'] = convertPartiesToFormModel(
    {
      partyFormConfig: NSW_LEASE_PARTY_FORM_CONFIG,
      nameChangeConversion: nswNameChangeConversion
    },
    parties,
    parties.map(p => p.id!)
  ).concat(customParties);

  const partyBookIds = partyBook.map(p => p.id);
  const lessees: Lease2_21_2Model['lessees'] = {
    ...formModel.lessees,
    proprietorGroups: formModel.lessees.proprietorGroups.map(pg => {
      let needsUpdate = false;
      const parties = pg.parties.map(p => {
        if (!partyBookIds.some(id => id === p.partyBookId)) {
          needsUpdate = true;
          return {
            ...p,
            partyBookId: undefined
          };
        }
        return p;
      });
      if (needsUpdate) {
        return {
          ...pg,
          parties
        };
      }
      return pg;
    })
  };

  const lessorsOnTitle: Lease2_21_2Model['lessorsOnTitle'] = calculateLessorsOnTitle(parties);
  return {
    ...formModel,
    partyBookApi: parties,
    partyBook,
    lessors,
    lessees,
    mergeFailedReason: result.failedReason,
    lessorsOnTitle
  };
};
