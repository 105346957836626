import { WorkspaceRoleEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { TitleReference } from '@sympli/api-gateway/shared';

import { WorkspaceParticipantsState } from 'src/containers/workspace/shared/detail/reducers/workspaceParticipants';

const ALLOWED_WORKSPACE_ROLES_ADDITIONAL_DOCUMENTS: WorkspaceRoleEnum[] = [
  //
  WorkspaceRoleEnum.DischargingMortgagee,
  WorkspaceRoleEnum.DischargingCaveator,
  WorkspaceRoleEnum.IncomingMortgagee,
  WorkspaceRoleEnum.IncomingCaveator,
  WorkspaceRoleEnum.ToDealWithAnInterest
];

const ALLOWED_WORKSPACE_ROLES_ADDITIONAL_DOCUMENTS_MLC: WorkspaceRoleEnum[] = [
  //
  WorkspaceRoleEnum.Purchaser,
  WorkspaceRoleEnum.Vendor,
  WorkspaceRoleEnum.Beneficiary
];

export function isAllowedAdditionalDocumentsForParticipant(props: {
  workspaceType?: WorkspaceTypeEnum; //
  currentParticipantId?: string;
  workspaceParticipants: WorkspaceParticipantsState;
  titleReferences: TitleReference[];
}) {
  const { workspaceParticipants, workspaceType, currentParticipantId, titleReferences } = props;
  if (titleReferences.length === 0) {
    return false;
  }

  if (workspaceType === WorkspaceTypeEnum.RegistrationOnly) {
    return true;
  }
  const allowedRoles = ALLOWED_WORKSPACE_ROLES_ADDITIONAL_DOCUMENTS.concat(ALLOWED_WORKSPACE_ROLES_ADDITIONAL_DOCUMENTS_MLC);

  const currentParticipant = workspaceParticipants.items.find(p => p.id === currentParticipantId);
  return Boolean(currentParticipant && allowedRoles.some(role => role === currentParticipant.workspaceRole.id));
}

export function isAllowedToDeletePrimaryLodgementCaseDocuments(props: {
  workspaceType?: WorkspaceTypeEnum; //
  currentParticipantId?: string;
  workspaceParticipants: WorkspaceParticipantsState;
}) {
  const { workspaceParticipants, workspaceType, currentParticipantId } = props;
  if (workspaceType === WorkspaceTypeEnum.RegistrationOnly) {
    return true;
  }
  const currentParticipant = workspaceParticipants.items.find(p => p.id === currentParticipantId);
  return Boolean(currentParticipant && ALLOWED_WORKSPACE_ROLES_ADDITIONAL_DOCUMENTS.some(role => role === currentParticipant.workspaceRole.id));
}
