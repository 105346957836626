import * as React from 'react';

import classNames from 'classnames';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconArrowDown } from '@sympli/ui-framework/icons';

import VerticalStatusLine, { VerticalStatusLineProps } from 'src/containers/dashboard/shared/formatters/VerticalStatusLine';
import LodgementCaseStatusContainer from 'src/containers/shared/lodgement-case-status';
import { colors } from 'src/theme';
import { useStyles } from './styles';

export interface Props extends VerticalStatusLineProps {
  name?: string;
  children: React.ReactNode;
  disableExpansion?: boolean;
  defaultOpenExpansion?: boolean; // first render, whether the expansion panel should open or collapse
  headerTypographyClassName?: string;
  lodgementCaseId?: string;
  isLocked?: boolean;
  shouldCollapse?: boolean; // later render, some outside behaviour makes the expansion panel to collapse
}

function MultipleLodgementCaseHeader({
  name,
  text,
  variant,
  className,
  textClassName,
  tooltipHandlers,
  children,
  disableExpansion,
  defaultOpenExpansion = true,
  headerTypographyClassName,
  lodgementCaseId,
  isLocked,
  shouldCollapse,
  dataTestId
}: React.PropsWithChildren<Props>) {
  const classes = useStyles();
  // user still can control whether to open or collapse as they like, this is done by setOpen
  const [isOpen, setIsOpen] = React.useState(defaultOpenExpansion);

  // useEffect will only run when dependency updates, here dep is a boolean flag.
  React.useEffect(() => {
    if (shouldCollapse) {
      // when flag changes to true, this will run.
      setIsOpen(false);
    }
  }, [shouldCollapse]);

  const renderHeader = () => {
    return (
      <>
        <Typography className={classNames('font-volkswagen-serial-medium text-[12px] font-[500] leading-[12px] text-[var(--sympli-green)]', headerTypographyClassName)}>
          {name}
        </Typography>
        {lodgementCaseId ? (
          <LodgementCaseStatusContainer
            dataTestId={dataTestId}
            lodgementCaseId={lodgementCaseId}
            verticalStatusLineProps={{ text, variant, tooltipHandlers, className, textClassName }}
          />
        ) : (
          <VerticalStatusLine //
            text={text}
            variant={variant}
            tooltipHandlers={tooltipHandlers}
            className={className}
            textClassName={textClassName}
            dataTestId={dataTestId}
          />
        )}
      </>
    );
  };

  if (disableExpansion)
    return (
      <>
        <FlexLayout justifyContent="space-between" alignItems="center" className="w-full border-0 border-b-[1px] border-solid border-[var(--greek-waters)] pb-[4px] pt-[2px]">
          {renderHeader()}
        </FlexLayout>
        {children}
      </>
    );

  return (
    <Accordion
      className={`w-full ${isLocked && 'opacity-40'}`}
      classes={{ root: classes.root }}
      expanded={isOpen}
      onChange={e => {
        e.preventDefault();
        setIsOpen(!isOpen);
      }}
    >
      <AccordionSummary classes={{ root: classes.summaryRoot, content: classes.summaryContent }} expandIcon={<IconArrowDown width={16} height={16} fill={colors.SYMPLI_GREEN} />}>
        <FlexLayout justifyContent="space-between" alignItems="center" className="w-full pb-[2px] pt-[2px]" flexDirection="row">
          {renderHeader()}
        </FlexLayout>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: '0px'
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
}

export default React.memo(MultipleLodgementCaseHeader);
