import { add, startOfDay } from 'date-fns';
import dateFormat from 'dateformat';
import { defaultMemoize } from 'reselect';
import * as yup from 'yup';

import { JurisdictionsEnum, WorkspaceTransactionTypeEnum } from '@sympli/api-gateway/enums';
import { yupDatePicker } from '@sympli/ui-framework/components/formik/date-picker-field';
import msg from '@sympli/ui-framework/utils/messages';
import Logger, { InvalidDataError } from '@sympli/ui-logger';

import yupVerifyProperty from 'src/containers/shared/verify-property-section/validationSchema';
import { DateFormatEnum } from 'src/containers/workspace/financial/settlement-date/models';
import { isBusinessDay } from 'src/utils/holiday/holidayCheck';
import { getTimeInTimeZone } from 'src/utils/timezone/timezoneHelper';
import { WorkspaceCreationErrorsMap } from '../../../../models';
import { CreateFinancialWorkspaceMatterDetailsStepFormModel } from '../../models';

export const HOURS_IN_ADVANCE = 2; // 2 hours in advance
const TODAY = startOfDay(new Date());
export const MAX_SETTLEMENT_DATE = add(TODAY, { years: 1 });

/**
 * @param { JurisdictionsEnum | '' } jurisdictionId
 * @param { Date | 'yyyy-mm-dd' | null } settleDate
 * @param { 'HH:MM' } settleTime
 */
export const settlementTimeCheck = defaultMemoize((jurisdictionId: JurisdictionsEnum | null, settleDate: Date | null | string, settleTime: string) => {
  if (!Number.isInteger(jurisdictionId) || !settleDate || isInvalidDateObject(settleDate) || !settleTime) {
    return true;
  }
  // * settleTime is in jurisdictionId's timezone, 06/03/2019
  const selectedDate = dateFormat(settleDate, DateFormatEnum.DATE);
  const selectTimeMs = Date.parse(`${selectedDate} ${settleTime}`);
  if (!isNaN(selectTimeMs)) {
    const currentTimeInJurisdiction = getTimeInTimeZone(new Date(), jurisdictionId as JurisdictionsEnum);
    const currentTimeInJurisdictionMs = currentTimeInJurisdiction.getTime();
    return selectTimeMs > currentTimeInJurisdictionMs;
  } else {
    Logger.captureException(new InvalidDataError('Invalid settlement date time', selectTimeMs));
  }
  return true;
});

export const isInvalidDateObject = (date: any) => {
  if (Object.prototype.toString.call(date) === '[object Date]') {
    // it is a Date Object
    if (isNaN(date.getTime())) {
      // it is a 'Invalid date'
      return true;
    }
  }
  // otherwise return false
  return false;
};

const getSettleTimeValidationSchema = (isSettlementDateOnlyEnabled: boolean, isSettlementNullDateTimeEnabled: boolean) => {
  const basicValidation = yup
    .string()
    .test(
      'settleTimeTwoHours',
      'This time has passed. Please select a new time.',
      function (this: { parent: CreateFinancialWorkspaceMatterDetailsStepFormModel }, settleTime: string) {
        // ! settleDate should be transformed to Date object by yup.date()
        const { jurisdictionId, settleDate } = this.parent;
        if (!settleDate) {
          return true;
        }
        return settlementTimeCheck(jurisdictionId, settleDate, settleTime);
      }
    );

  // when no feature flag is enabled, we want to make Time as required
  if (!isSettlementDateOnlyEnabled && !isSettlementNullDateTimeEnabled) {
    return basicValidation.required(msg.REQUIRED);
  }
  // when only nullDateTime flag enabled, if Date is set, Time also needs to be set a value
  else if (!isSettlementDateOnlyEnabled && isSettlementNullDateTimeEnabled) {
    return basicValidation.test(
      'when Date is provided, then Time is required',
      'required',
      function (this: { parent: CreateFinancialWorkspaceMatterDetailsStepFormModel }, settleTime?: string) {
        const { settleDate } = this.parent;
        if (!settleDate) {
          return true;
        }
        return Boolean(settleTime && settleTime.length > 0);
      }
    );
  }

  // when any flag is enabled, Time is optional, but if it has value, it still needs basic validation
  return basicValidation;
};

const getSettleDateValidationSchema = (isSettlementNullDateTimeEnabled: boolean) => {
  const basicValidation = yupDatePicker //
    .min(TODAY, msg.DATE_MUST_BE_TODAY_OR_FUTURE_DATE)
    .max(MAX_SETTLEMENT_DATE, 'Must be within one year')
    .test(
      'settleDateBusinessDay',
      'Must be a business day', //
      function test(this: yup.TestContext, settleDate: Date | null) {
        if (!settleDate) {
          return true;
        }

        // ! settleDate should be transformed to Date object by yup.date()
        // ! we need to manually check if it is 'Invalid Date' otherwise it will generate an uncaught Error
        if (isInvalidDateObject(settleDate)) {
          return false;
        }
        const { jurisdictionId } = this.parent as CreateFinancialWorkspaceMatterDetailsStepFormModel;
        const testRes = isBusinessDay(settleDate, jurisdictionId as JurisdictionsEnum);
        return testRes;
      }
    );

  // when non SDT flag is disabled, we want to make Date as required
  if (!isSettlementNullDateTimeEnabled) {
    return basicValidation.required(msg.REQUIRED);
  }

  // when non SDT flag is enabled, Date would still be required if Time is already set up with a value
  return basicValidation.test(
    'when Date is provided, then Time is required',
    'Required',
    function (this: { parent: CreateFinancialWorkspaceMatterDetailsStepFormModel }, settleDate: Date | null) {
      const { settleTime } = this.parent;
      if (settleTime && settleTime.length > 0) {
        return Boolean(settleDate);
      }
      return true;
    }
  );
};

export const getValidationSchema = (isSettlementDateOnlyEnabled: boolean, isSettlementNullDateTimeEnabled: boolean) => {
  const validationSchema = yup.object<CreateFinancialWorkspaceMatterDetailsStepFormModel>({
    createdByReference: yup.string().trim().required(msg.REQUIRED).max(200, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(200)),
    createdByRoleId: yup.number().nullable().required(msg.REQUIRED),
    groupId: yup.string().required(msg.REQUIRED),
    jurisdictionId: yup.number().nullable().required(msg.REQUIRED),
    // ! this will always trying to transform settleDate to Date object
    settleDate: getSettleDateValidationSchema(isSettlementNullDateTimeEnabled),
    settleTime: getSettleTimeValidationSchema(isSettlementDateOnlyEnabled, isSettlementNullDateTimeEnabled),
    titleInformation: yupVerifyProperty,
    instructingOrganisationSettlementAgentId: yup.mixed(), // we are not validating this one
    workspaceCreationErrorsMap: yup
      .mixed<WorkspaceCreationErrorsMap>()
      .nullable(false)
      .test(
        //
        'workspaceCreationErrorsMap test',
        'The selected document cannot be lodged.',
        function test(this: yup.TestContext<CreateFinancialWorkspaceMatterDetailsStepFormModel>, workspaceCreationErrorsMap?: WorkspaceCreationErrorsMap) {
          return !workspaceCreationErrorsMap || !Object.keys(workspaceCreationErrorsMap as WorkspaceCreationErrorsMap).length;
        }
      ),
    isInteroperable: yup.mixed(), // we are not validating this one
    isControllingParty: yup.mixed(), // we are not validating this one,
    transactionTypeId: yup.number().nullable().required(msg.REQUIRED),
    transactionTypeOtherDescription: yup.mixed<string>().when('transactionTypeId', {
      is: WorkspaceTransactionTypeEnum.Other,
      then: yup.string().trim().required(msg.REQUIRED).max(100, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(100))
    })
  });

  return validationSchema;
};
