import * as React from 'react';

import classNames from 'classnames';
import { Form, FormikTouched, useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import { DocumentAttachments } from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';
import SectionTitleReferences from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import { VISIBILITY_CHECK_CAVEATORS } from './checks';
import { SUPPORTING_DOCUMENT_TYPE_LOOKUP_OPTIONS } from './enums';
import { generateProprietors } from './helpers';
import { CaveatModel_2_21_2 } from './models';
import ActionProhibited from './sections/action-prohibited';
import CaveatStatement from './sections/caveat-statement';
import Caveator from './sections/caveator';
import ClaimDetails from './sections/claim-details';
import ServiceOfNotice from './sections/contact-details';
import SectionRegisteredProprietor from './sections/registered-proprietor/RegisteredProprietorEditable';
import { useStyles } from './styles';

const SHOULD_VALIDATE: boolean = true;

type FormValues = CaveatModel_2_21_2;

const fieldName = modelKey<CaveatModel_2_21_2>();

const touchProprietors = (touched: FormikTouched<CaveatModel_2_21_2>) => {
  if (touched.proprietors?.proprietorGroups) {
    return touched;
  }
  return {
    ...touched,
    proprietors: {
      proprietorGroups: []
    }
  };
};

function RootForm({
  className, //
  hideActionsPanel,
  onSaveChanges,
  onCancel
}: RootFormProps): JSX.Element {
  const classes = useStyles();
  const { isLoading, disabled, nextActionDisabled, nextActionLabel } = useDocumentContext();

  const { values, errors, setValues, setTouched } = useFormikContext<FormValues>();
  const focusOnTitleReferenceRef = React.useRef<ButtonBaseActions>(null);
  const focusOnCaveatorRef = React.useRef<ButtonBaseActions>(null);
  const focusOnContactDetailsRef = React.useRef<ButtonBaseActions>(null);

  React.useEffect(() => {
    function focusOnFirst() {
      const focusOnFirstItem = focusOnTitleReferenceRef.current || focusOnCaveatorRef.current || focusOnContactDetailsRef.current;
      if (!focusOnFirstItem) {
        Logger.console(SeverityEnum.Warning, 'Document has nothing to focus on. Please fix this');
        return;
      }
      focusOnFirstItem.focusVisible();
    }
    focusOnFirst();
  }, [focusOnCaveatorRef, focusOnContactDetailsRef, focusOnTitleReferenceRef]);

  const handleTitleReferenceSelectionChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // make sure proprietors is marked as touched to display possible error on proprietors
      setTouched(touchProprietors, !SHOULD_VALIDATE); // no need to validate, since we will be running setValues
      setValues(generateProprietors);
    },
    [setValues, setTouched]
  );

  return (
    <Form className={classNames(classes.root, className)}>
      <SectionTitleReferences //
        name={fieldName('titleReferences')}
        disabled={disabled}
        skipTouchedCheck
        focusRef={focusOnTitleReferenceRef}
        data-name={fieldName('titleReferences')}
        data-testid="title-section"
        onChange={handleTitleReferenceSelectionChange}
      />

      {VISIBILITY_CHECK_CAVEATORS(values) ? (
        <Caveator //
          name={fieldName('caveators')}
          focusRef={focusOnCaveatorRef}
          data-testid="caveator"
        />
      ) : (
        <Section //
          title="Caveator"
          error={typeof errors.caveators === 'string' ? errors.caveators : undefined}
          data-name={fieldName('caveators')}
        >
          <Typography className={classes.caveatorsUnselectableMessage} data-testid="caveators-unselectable-message">
            Action Prohibited #5 has been selected, which requires the Registered Proprietor(s) to be listed as the Caveator(s) - should you wish to change the Caveator(s), please
            select a different Action Prohibited{values.proprietors.proprietorGroups.flatMap(pg => pg.parties).length > 1 ? ' or mark one of the proprietors as unaffected' : ''}.
          </Typography>
        </Section>
      )}

      <Section //
        title="Service of notice"
        data-name={fieldName('contactDetails')}
      >
        <ServiceOfNotice //
          name={fieldName('contactDetails')}
          focusRef={focusOnContactDetailsRef}
        />
      </Section>

      <SectionRegisteredProprietor //
        name={fieldName('proprietors')}
      />
      <Section //
        title="Action prohibited"
        error={typeof errors.extentOfProhibitions === 'string' ? errors.extentOfProhibitions : undefined}
        data-name={fieldName('extentOfProhibitions')}
        data-testid="action-prohibited"
      >
        <ActionProhibited //
          name={fieldName('extentOfProhibitions')}
        />
      </Section>
      <Section //
        title="Claim details"
        data-name={fieldName('claimDetails')}
        data-testid="claim-details"
      >
        <ClaimDetails //
          name={fieldName('claimDetails')}
        />
      </Section>
      <div data-testid="attachments">
        <DocumentAttachments //
          name={fieldName('attachments')}
          minItems={0}
          maxItems={1}
          isRequired={!values.attachments?.length}
          attachmentDocumentTypes={SUPPORTING_DOCUMENT_TYPE_LOOKUP_OPTIONS}
        />
      </div>

      <Section //
        title="Caveat statement"
        data-name={fieldName('caveatStatementsAgreed')}
        data-testid="caveat-statement"
      >
        <CaveatStatement //
          name={fieldName('caveatStatementsAgreed')}
        />
      </Section>
      {!hideActionsPanel && (
        <DocumentActionPanel //
          isLoading={isLoading}
          disabled={nextActionDisabled}
          onBack={onCancel}
          nextLabel={nextActionLabel}
          onSaveChanges={onSaveChanges}
        />
      )}
    </Form>
  );
}

export default RootForm;
