import { Action } from 'redux';

import { LodgementCaseStatusEnum, WorkspaceStatusEnum } from '@sympli/api-gateway/enums';

import MyTasks from 'src/containers/workspace/shared/detail/components/my-tasks';
import { WorkspaceTasksState } from 'src/containers/workspace/shared/detail/reducers/workspaceTasks';

interface Props {
  // route props
  workspaceId: string;
  participantId: string;
  // redux data
  // basic info
  workspaceStatusId?: WorkspaceStatusEnum;
  lodgementCaseStatusId?: LodgementCaseStatusEnum;
  isLoadingWorkspace: boolean;

  // tasks
  workspaceTasksState: WorkspaceTasksState;
  // other
  backLink: string;
  onTaskAction(action: Action): void;
}

function TabTasks(props: Props) {
  const { isLoadingWorkspace, workspaceStatusId, lodgementCaseStatusId, workspaceTasksState, onTaskAction } = props;
  const { items, status, error } = workspaceTasksState;

  // Show loader for task section when refetching to avoid old task list present to  customers
  // https://tickleme.atlassian.net/browse/WEB-18877
  if (isLoadingWorkspace || status === 'pending' || status === 'idle' || status === 'refetching') {
    return (
      <MyTasks
        //
        isLoading
        onTaskAction={onTaskAction}
      />
    );
  }

  // don't show tasks if lodgement from ELN point of view is completed
  // still show workspace detail loader for good UX
  if (
    [
      //
      LodgementCaseStatusEnum.LodgementSuccess,
      LodgementCaseStatusEnum.Registered,
      LodgementCaseStatusEnum.Rejected,
      LodgementCaseStatusEnum.Unnecessary
    ].includes(lodgementCaseStatusId!)
  ) {
    return null;
  }

  if (status === 'rejected') {
    return (
      <MyTasks
        //
        error={error}
        onTaskAction={onTaskAction}
      />
    );
  }

  return (
    <MyTasks //
      items={items}
      workspaceStatusId={workspaceStatusId}
      onTaskAction={onTaskAction}
    />
  );
}

export default TabTasks;
