import { useMemo } from 'react';
import * as React from 'react';

import { WorkspaceStatusEnum } from '@sympli/api-gateway/enums';

import VerticalStatusLine, { VerticalStatusLineVariant } from 'src/containers/dashboard/shared/formatters/VerticalStatusLine';
import useScreenSize from 'src/hooks/useScreenSize';
import { ScreenSizeVariant } from 'src/theme/screens';
import { FinancialWorkspaceStatusTitle, resolveFinancialWorkspaceStatus } from 'src/utils/status-mapping/workspaceStatusHelper';
import { financialWorkspaceStatusTooltipContent } from './helpers';
import { useStyles } from './styles';

interface Props {
  workspaceStatusId: WorkspaceStatusEnum;
  extraContent?: React.ReactNode;
  verticalStatusLineClassName?: string;
  dataTestId?: string;
}

function FinancialWorkspaceStatusBadgeNew({ workspaceStatusId, extraContent, verticalStatusLineClassName, dataTestId }: Props) {
  const classes = useStyles();
  const screenVariant: ScreenSizeVariant = useScreenSize();

  const wsStatusInfo: {
    variant: VerticalStatusLineVariant;
    title: FinancialWorkspaceStatusTitle;
    tooltipContent?: NonNullable<React.ReactNode> | undefined;
  } | null = useMemo(() => {
    const wsStatus = resolveFinancialWorkspaceStatus(workspaceStatusId);

    if (!wsStatus) {
      return null;
    }

    const tooltipContent = financialWorkspaceStatusTooltipContent(wsStatus.variant, wsStatus.title);
    return { ...wsStatus, tooltipContent };
  }, [workspaceStatusId]);

  if (!wsStatusInfo) {
    return null;
  }

  const { variant, title, tooltipContent } = wsStatusInfo;

  if (extraContent) {
    return (
      <VerticalStatusLine //
        text={title}
        dataTestId={dataTestId}
        variant={variant}
        textClassName={screenVariant === 'small-laptop' ? 'clip' : 'truncate'}
        tooltipTitle={tooltipContent}
        extraContent={extraContent}
        className={verticalStatusLineClassName}
      />
    );
  }

  return (
    <div className={classes.FinancialWorkspaceStatusBadgeNew}>
      <VerticalStatusLine //
        text={title}
        variant={variant}
        dataTestId={dataTestId}
        textClassName={screenVariant === 'small-laptop' ? 'clip' : 'truncate'}
        tooltipTitle={tooltipContent}
        className={verticalStatusLineClassName}
      />
    </div>
  );
}

export default React.memo(FinancialWorkspaceStatusBadgeNew);
