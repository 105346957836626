import React from 'react';

import Typography from '@mui/material/Typography';

import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import { ReceivingProprietorGroupModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving/models';
import { TenancyPartyModel } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';

import SurvivingProprietor from '../surviving-proprietor/SurvivingProprietor';

interface Props {
  itemBinding: string;
  disabledCheckbox: boolean;
  numberOfPartiesInPreceedingGroups: number;
  totalSurvivingProprietors: number;
}

function SurvivingProprietorArray({ itemBinding, numberOfPartiesInPreceedingGroups, disabledCheckbox, totalSurvivingProprietors }: Props): JSX.Element {
  const fieldName = createModelKeyAppender<ReceivingProprietorGroupModel>(itemBinding);
  const name = fieldName('parties');
  const { disabled } = useDocumentContext();

  const renderItem = ({ item, itemBinding }: DocumentArrayItemRenderProps<TenancyPartyModel>) => {
    return (
      <SurvivingProprietor //
        name={itemBinding}
        proprietor={item}
        totalSurvivingProprietors={totalSurvivingProprietors}
      />
    );
  };

  const renderPartyLabel = (itemIndex: number): string => {
    const label = `Surviving joint tenant ${numberOfPartiesInPreceedingGroups + itemIndex + 1}`;

    return label;
  };

  const renderItemTitle = ({ itemIndex }: DocumentArrayItemRenderProps<TenancyPartyModel>) => {
    const label = renderPartyLabel(itemIndex);

    return <Typography>{label}</Typography>;
  };

  return (
    <div data-section={name}>
      <DocumentFieldArray //
        arrayBinding={name}
        renderItem={renderItem}
        itemTitle={renderItemTitle}
        disabled={disabled}
        itemStyle="formGroup"
        mode="fixed"
      />
    </div>
  );
}

export default React.memo(SurvivingProprietorArray);
