// this file was automatically generated from validationSchema.ts.mustache
import * as yup from 'yup';

import { NoticeOfDeath2_21_1Model } from './models';
import { resolveDeceasedJointTenantsValidationSchema, yupDeceasedJointTenantsSectionValidation } from './sections/deceased-joint-tenants/validationSchema';
import yupPartyBook from './sections/party-book/validationSchema';
import yupSurvivingJointTenants from './sections/surviving-joint-tenants/validationSchema';
import yupTitleReferences from './sections/title-references/validationSchema';

export default yup
  .object<NoticeOfDeath2_21_1Model>({
    titleReferences: yupTitleReferences,
    survivingJointTenants: yupSurvivingJointTenants,
    deceasedJointTenants: resolveDeceasedJointTenantsValidationSchema,
    deceasedJointTenantsValidation: yupDeceasedJointTenantsSectionValidation,
    partyBook: yupPartyBook,
    context: yup.mixed()
  })
  .defined()
  .log();
