import { defaultMemoize } from 'reselect';

import { DocumentTypeIdentifierEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDocumentSummaryApiResponse } from '@sympli/api-gateway/models';

import type { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';

const filterDocumentsByTypeAndVersion = (workspaceDocuments: WorkspaceDocumentSummaryApiResponse[], documentTypeId: DocumentTypeIdentifierEnum, schemaVersion: string) => {
  return workspaceDocuments.filter((doc: WorkspaceDocumentSummaryApiResponse) => {
    return (
      doc.documentIdentifier.id === documentTypeId &&
      // explicitly use only documents of the same version so we can guarantee the data structure is the same
      doc.documentFormVersion === schemaVersion
    );
  });
};

const parseDocuments = <FormValues extends object, InitalApiValues extends object>(
  workspaceDocuments: WorkspaceDocumentSummaryApiResponse[],
  context: IConverterContext,
  convertFromApiToFormModel: IConverter<FormValues, InitalApiValues>['fromApiToFormModel']
) => {
  return workspaceDocuments
    .map((doc: WorkspaceDocumentSummaryApiResponse) => {
      try {
        const apiValue: InitalApiValues = JSON.parse(doc.data || '{}');
        const formValue: FormValues = convertFromApiToFormModel(apiValue, context);
        return formValue;
      } catch (e) {
        return null;
      }
    })
    .filter(Boolean) as FormValues[];
};

// explicitly memoize the function to avoid re-parsing the same documents
export const getOtherDocumentsOfSameTypeAndVersionParsed = defaultMemoize(
  <FormValues extends object, InitalApiValues extends object>(
    //
    workspaceDocuments: WorkspaceDocumentSummaryApiResponse[],
    documentId: string,
    context: IConverterContext,
    convertFromApiToFormModel: IConverter<FormValues, InitalApiValues>['fromApiToFormModel']
  ): FormValues[] => {
    const otherDocumentsOfSameType: WorkspaceDocumentSummaryApiResponse[] = filterDocumentsByTypeAndVersion(
      //
      workspaceDocuments,
      context.meta.documentTypeId,
      // explicitly use only documents of the same version so we can guarantee the data structure is the same
      context.meta.schemaVersion
    )
      // exclude the current document
      .filter((doc: WorkspaceDocumentSummaryApiResponse) => doc.documentId !== documentId);

    const parsed = parseDocuments(otherDocumentsOfSameType, context, convertFromApiToFormModel);
    return parsed;
  }
);

// this is explicitly NOT memoized since we typically call this multiple times with different document types
export const getOtherDocumentsParsed = <FormValues extends object, InitalApiValues extends object>(
  //
  workspaceDocuments: WorkspaceDocumentSummaryApiResponse[],
  context: IConverterContext,
  convertFromApiToFormModel: IConverter<FormValues, InitalApiValues>['fromApiToFormModel']
): FormValues[] => {
  const otherDocumentsOfSameType: WorkspaceDocumentSummaryApiResponse[] = filterDocumentsByTypeAndVersion(
    //
    workspaceDocuments,
    context.meta.documentTypeId,
    // explicitly use only documents of the same version so we can guarantee the data structure is the same
    context.meta.schemaVersion
  );

  const parsed = parseDocuments(otherDocumentsOfSameType, context, convertFromApiToFormModel);
  return parsed;
};
