import { defaultMemoize } from 'reselect';

import { NswDocumentPartyJustification, NswNameChange, nswNameChangeConversion } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { formatPartyName, PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { ReceivingTenancyDetailModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving';
import { IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { ProprietorGroupModel, TenancyTypeEnum } from '@sympli-mfe/document-forms-framework/core/models';
import { applyDefaultMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { DataSource } from '@sympli-mfe/document-forms-framework/shared-config/common';
import { ApiDocumentPartyModel } from '@sympli-mfe/document-forms-framework/shared-config/party';
import { DocumentTypeIdentifierEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDocumentSummaryApiResponse } from '@sympli/api-gateway/models';
import Logger, { BusinessLogicError } from '@sympli/ui-logger';

import { Container, ProprietorGroup, TenancyDetail } from 'src/containers/documents/party-merge/model';
import PartyMerger from 'src/containers/documents/party-merge/PartyMerger';
import { updateProprietorWithCapacity } from '../../components/deceased-tenancy-detail/helpers';
import { DeceasedProprietorModel, DeceasedTenancyDetailModel, initDeceasedProprietor } from '../../components/deceased-tenancy-detail/models';
import { convertPartiesToFormModel } from '../../helpers';
import { NSW_NOD_PARTY_FORM_CONFIG, NSW_NOD_PARTY_FORM_CONFIG_WITH_NAME_CHANGE } from './configs';
import { fallbackMap } from './fallback';
import { ApiNoticeOfDeathModel_2_21_2, NoticeOfDeath_2_21_2_Model } from './models';

export function generateDeceasedTenancy(formModel: NoticeOfDeath_2_21_2_Model): NoticeOfDeath_2_21_2_Model {
  const customParties = formModel.partyBook.filter(pb => pb.metadata?.source === DataSource.Custom);
  const selectedTitles = formModel.titleReferences.filter(tr => tr.isSelected);

  const containers = selectedTitles.map(
    title =>
      new Container(
        title.reference!,
        new TenancyDetail(
          title.proprietor.proprietorGroups.map(
            pg =>
              new ProprietorGroup(
                pg.parties.map(p => p.party),
                pg,
                pg.shareFraction,
                pg.proprietorGroupType
              )
          ),
          title.proprietor.tenancyType
        )
      )
  );

  const mergeResult = PartyMerger.merge(containers);

  const groups: ProprietorGroupModel<ApiDocumentPartyModel<NswDocumentPartyJustification>>[] = mergeResult.containers.flatMap(container =>
    container.tenancyDetail.proprietorGroups.map(pg => ({
      parties: pg.mergedParties,
      isSelected: false,
      proprietorGroupType: pg.proprietorGroupType!,
      shareFraction: pg.shareFraction!
    }))
  );

  const tenancyType = mergeResult.containers[0]?.tenancyDetail.tenancyType ?? TenancyTypeEnum.None;
  const deceasedTenancyDetail: DeceasedTenancyDetailModel = {
    tenancyType,
    proprietorGroups: groups.map(pg => ({
      ...pg,
      isSelected: groups.length === 1,
      parties: pg.parties.map(p => {
        const defaultSelected = groups.length === 1 && pg.parties.length === 1;
        const proprietor: DeceasedProprietorModel = initDeceasedProprietor(defaultSelected, p.id!);

        return defaultSelected ? updateProprietorWithCapacity(proprietor, proprietor.isSelected) : proprietor;
      })
    }))
  };

  const unselectedProprietorParties = formModel.titleReferences
    .filter(tr => !tr.isSelected)
    .flatMap(tr => tr.proprietor.proprietorGroups.flatMap(pg => pg.parties))
    .map((x, index: number) => ({ ...x.party, id: (x.party.id = x.party.externalId ? x.party.externalId : `TITLE-${++index}`) }));

  const titleProprietors = groups.flatMap(pg => pg.parties).concat(unselectedProprietorParties);

  const partyBook = convertPartiesToFormModel_2_21_2(
    titleProprietors,
    titleProprietors.map(p => p.id!)
  ).concat(customParties);

  return {
    ...formModel,
    mergeFailedReason: mergeResult.failedReason,
    deceasedTenancyDetail: deceasedTenancyDetail,
    partyBookApi: titleProprietors,
    partyBook,
    survivingJointTenants: extractSurvivingJointTenants(deceasedTenancyDetail)
  };
}

export function extractSurvivingJointTenants(deceasedTenancyDetail: DeceasedTenancyDetailModel): ReceivingTenancyDetailModel {
  const selectedGroups = deceasedTenancyDetail.proprietorGroups.filter(p => p.parties.some(x => x.isSelected));
  const deceasedJointTenants = selectedGroups.flatMap(r => r.parties).filter(p => p.isSelected);
  const survivingJointTenantsCount = selectedGroups.flatMap(x => x.parties).filter(x => !x.isSelected).length;

  const survivingJointTenants: ReceivingTenancyDetailModel = {
    tenancyType: TenancyTypeEnum.None,
    shareSplitType: null,
    proprietorGroups:
      deceasedJointTenants.length === 0
        ? []
        : selectedGroups.map(pg => ({
            ...pg,
            shareQuantity: null,
            isSelected: false,
            parties: pg.parties
              .filter(p => !p.isSelected)
              .map(p => {
                return {
                  ...p,
                  isSelected: survivingJointTenantsCount === 1,
                  partyCapacity: {}
                };
              })
          }))
  };

  return survivingJointTenants;
}

export function convertPartiesToFormModel_2_21_2(parties: ApiDocumentPartyModel<NswDocumentPartyJustification>[], relinquishingIds?: string[]): PartyModel<NswNameChange>[] {
  return convertPartiesToFormModel(
    {
      partyFormConfig: NSW_NOD_PARTY_FORM_CONFIG_WITH_NAME_CHANGE,
      nameChangeConversion: nswNameChangeConversion
    },
    parties,
    relinquishingIds
  );
}

export const isDeceasedPartySelectedOnAnotherDocument = ({
  workspaceDocuments,
  documentId,
  context,
  deceasedTenancyDetail,
  partyBook
}: {
  //
  workspaceDocuments: WorkspaceDocumentSummaryApiResponse[];
  documentId: string;
  context: NoticeOfDeath_2_21_2_Model['context'];
  deceasedTenancyDetail: NoticeOfDeath_2_21_2_Model['deceasedTenancyDetail'];
  partyBook: NoticeOfDeath_2_21_2_Model['partyBook'];
}): boolean => {
  const otherDocumentsParsed: NoticeOfDeath_2_21_2_Model[] = getOtherDocumentsOfSameTypeAndVersionParsed(workspaceDocuments, documentId, context);

  if (otherDocumentsParsed.length) {
    const selectedPartyNamesInCurrentDocument: string[] = resolveDeceasedPartyBookNames(deceasedTenancyDetail, partyBook);
    const selectedPartyNamesInOtherDocuments: string[] = otherDocumentsParsed.flatMap(doc => resolveDeceasedPartyBookNames(doc.deceasedTenancyDetail, doc.partyBook));
    return selectedPartyNamesInCurrentDocument.some(p => selectedPartyNamesInOtherDocuments.includes(p));
  }

  return false;
};

function resolveDeceasedPartyBookNames(deceasedJointTenants: DeceasedTenancyDetailModel, partyBook: PartyModel<NswNameChange>[] | unknown): string[] {
  if (deceasedJointTenants && Array.isArray(partyBook)) {
    try {
      const selectedPartyBookIds: string[] = deceasedJointTenants.proprietorGroups.flatMap(group =>
        group.parties //
          .filter(p => p.isSelected)
          .map(proprietor => proprietor.partyBookId)
      );
      const selectedPartyNames: string[] = selectedPartyBookIds //
        .map(partyBookId => partyBook.find(party => party.id === partyBookId)!)
        .map(party =>
          formatPartyName(party, {
            ...NSW_NOD_PARTY_FORM_CONFIG,
            nameChangeConfig: undefined // explicitly ignore name change when comparing selected deceased parties across documents
          })
        );

      return selectedPartyNames;
    } catch (e) {}
  }
  Logger.captureException(new BusinessLogicError('Unable to resolve deceased parties'));
  return [];
}

export const getOtherDocumentsOfSameTypeAndVersionParsed = defaultMemoize(
  (
    //
    workspaceDocuments: WorkspaceDocumentSummaryApiResponse[],
    documentId: string,
    context: NoticeOfDeath_2_21_2_Model['context']
  ): NoticeOfDeath_2_21_2_Model[] => {
    const otherDocumentsOfSameType: WorkspaceDocumentSummaryApiResponse[] = workspaceDocuments
      .filter(
        (doc: WorkspaceDocumentSummaryApiResponse) =>
          doc.documentIdentifier.id === DocumentTypeIdentifierEnum.NoticeOfDeath && doc.documentFormVersion === context.meta.schemaVersion // explicitly use only documents of the same version so we can guarantee the data structure is the same
      )
      .filter((doc: WorkspaceDocumentSummaryApiResponse) => doc.documentId !== documentId);
    const parsed = otherDocumentsOfSameType
      .map((doc: WorkspaceDocumentSummaryApiResponse) => {
        try {
          const apiValue = JSON.parse(doc.data || '{}');
          const formValue: NoticeOfDeath_2_21_2_Model = convertFromApiToFormModel(apiValue, context);
          return formValue;
        } catch (e) {
          return null;
        }
      })
      .filter(Boolean) as NoticeOfDeath_2_21_2_Model[];
    return parsed;
  }
);

export function convertFromApiToFormModel(apiModel: ApiNoticeOfDeathModel_2_21_2, context: IConverterContext): NoticeOfDeath_2_21_2_Model {
  const risInvolvedPartyIds = apiModel.partyBook.filter(p => p.propertiesMappedFromRis?.length).map(p => p.id!);

  const { deceasedJointTenants, ...rest } = apiModel;

  let formModel: NoticeOfDeath_2_21_2_Model = {
    ...rest,
    partyBookApi: apiModel.partyBook,
    partyBook: convertPartiesToFormModel_2_21_2(apiModel.partyBook, risInvolvedPartyIds),
    deceasedTenancyDetail: deceasedJointTenants,
    context
  };

  if (apiModel.invalidParties) {
    formModel = generateDeceasedTenancy(formModel);
  }

  const formModelWithDefaultMapApplied = applyDefaultMap(formModel, fallbackMap);
  return formModelWithDefaultMapApplied;
}
