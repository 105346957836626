import React from 'react';

import classNames from 'classnames';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { LogTypeEnum } from '@sympli/api-gateway/enums';
import {
  IconCalculator,
  IconCalendar,
  IconCheck,
  IconDelete,
  IconEditDocument,
  IconEnvelope,
  IconError,
  IconGroup,
  IconLock,
  IconMapMaker,
  IconSearchPlus,
  IconTasks,
  IconTimes,
  IconUnlock,
  IconWorkspaceUser
} from '@sympli/ui-framework/icons';

import { SettlementNotificationStatusEnum } from '../../../../models';
import { LogWorkspaceSettlementInProgressEntity } from '../../models';
import { useStyles } from './styles';

interface Props {
  type: LogTypeEnum;
  dataObject?: object;
}

function ActivityLogItemIcon({ type, dataObject }: Props) {
  const classes = useStyles();

  switch (type) {
    case LogTypeEnum.SettlementLodgementFailed:
    case LogTypeEnum.SettlementLodgementSucceeded:
    case LogTypeEnum.SettlementLodgementInitiated:
    case LogTypeEnum.SettlementLodgementVerificationFailed:
    case LogTypeEnum.SettlementLodgementVerificationSucceeded:
    case LogTypeEnum.SettlementLodgementVerificationInitiated:
    case LogTypeEnum.SettlementInProgress: {
      const { StatusCode: statusCode } = dataObject as LogWorkspaceSettlementInProgressEntity;
      switch (statusCode) {
        case SettlementNotificationStatusEnum.Fail: {
          return <IconLock className={classNames(classes.icon, classes.iconFillRed)} />;
        }
        case SettlementNotificationStatusEnum.Success: {
          return <IconCheck color="primary" className={classes.icon} />;
        }
        default: {
          return <IconLock className={classes.icon} />;
        }
      }
    }
    case LogTypeEnum.WorkspaceLinkedPaymentCreated:
    case LogTypeEnum.WorkspaceLinkedSourceFundCreated:
    case LogTypeEnum.WorkspaceTitleAdded:
    case LogTypeEnum.DocumentSigned:
    case LogTypeEnum.SLCDocumentSigned:
    case LogTypeEnum.DocumentPartiallySigned:
    case LogTypeEnum.StampDutyVerifySuccess:
    case LogTypeEnum.LodgementVerificationSuccess:
    case LogTypeEnum.SLCLodgementVerificationSuccess:
    case LogTypeEnum.WorkspaceCreated: {
      return <IconCheck color="primary" className={classes.icon} />;
    }

    case LogTypeEnum.LodgementVerificationFailedTechnicalError:
    case LogTypeEnum.SLCLodgementVerificationFailedTechnicalError:
    case LogTypeEnum.LodgementFailedConnectivityError:
    case LogTypeEnum.LodgementFailedTechnicalError:
    case LogTypeEnum.SLCLodgementFailedConnectivityError:
    case LogTypeEnum.SLCLodgementFailedTechnicalError:
    case LogTypeEnum.PreSettlementCheckFailed:
    case LogTypeEnum.SettlementFailed:
    case LogTypeEnum.LodgementFailed:
    case LogTypeEnum.SLCLodgementVerificationFailed:
    case LogTypeEnum.SLCLodgementFailed:
    case LogTypeEnum.LodgementVerificationFailed: {
      return <IconError className={classes.icon} />;
    }

    case LogTypeEnum.InvitationsSent:
    case LogTypeEnum.InvitationResent:
    case LogTypeEnum.InvitationForwarded:
    case LogTypeEnum.InvitationAccepted:
    case LogTypeEnum.WorkspaceJoined:
    case LogTypeEnum.InvitationRejected:
    case LogTypeEnum.InvitationWithdrawn:
    case LogTypeEnum.InvitationStatusUpdated: {
      return <IconEnvelope className={classes.icon} />;
    }

    case LogTypeEnum.WorkspaceAutoBalanced:
    case LogTypeEnum.DirectionsUnsigned:
    case LogTypeEnum.SourceFundsUnsigned:
    case LogTypeEnum.PaymentsUnsigned:
    case LogTypeEnum.DirectionsReviewed:
    case LogTypeEnum.SourceFundsApproved:
    case LogTypeEnum.PaymentsApproved:
    case LogTypeEnum.DirectionsUpdated:
    case LogTypeEnum.SourceFundsSaved:
    case LogTypeEnum.PaymentsSaved:
    case LogTypeEnum.SourceFundsSavedBySystem:
    case LogTypeEnum.PaymentsSavedBySystem:
    case LogTypeEnum.AutoBalanceSurplusRemoved:
    case LogTypeEnum.SourceFundsOptedOut:
    case LogTypeEnum.PaymentsOptedOut: {
      return <IconCalculator className={classes.icon} />;
    }

    case LogTypeEnum.WorkspaceSettlementDatetimeSupported:
    case LogTypeEnum.WorkspaceSettlementDatetimeUnsupported:
    case LogTypeEnum.SettlementDateTimeCouldNotBeAccepted:
    case LogTypeEnum.SettlementDateProposed:
    case LogTypeEnum.SettlementDateApproved:
    case LogTypeEnum.SettlementDateUpdated:
    case LogTypeEnum.SettlementDateApprovalRevoked: {
      return <IconCalendar className={classes.icon} />;
    }

    case LogTypeEnum.LodgementInitated:
    case LogTypeEnum.LodgementVerificationInitated:
    case LogTypeEnum.SLCLodgementInitated:
    case LogTypeEnum.SLCLodgementVerificationInitated:
    case LogTypeEnum.WorkspaceLocked:
    case LogTypeEnum.WorkspaceLockedForLodgement:
    case LogTypeEnum.WorkspaceLockedForSettlement: {
      return <IconLock className={classes.icon} />;
    }

    case LogTypeEnum.WorkspaceUnlocked: {
      return <IconUnlock className={classes.icon} />;
    }

    case LogTypeEnum.MatterUpdated: {
      return <IconTasks className={classes.icon} />;
    }

    case LogTypeEnum.UserAssigned:
    case LogTypeEnum.UserUnassigned: {
      return <IconWorkspaceUser className={classes.icon} />;
    }

    case LogTypeEnum.StampDutySettlementVerifyInitated:
    case LogTypeEnum.StampDutyVerifyInitated: {
      return <IconSearchPlus className={classes.icon} />;
    }

    case LogTypeEnum.StampDutyVerifyFailed: {
      return <IconSearchPlus className={classNames(classes.icon, classes.iconFillRed)} />;
    }

    case LogTypeEnum.TitleActivityNoChange: {
      return <IconMapMaker className={classNames(classes.icon, classes.iconMapMaker)} />;
    }

    case LogTypeEnum.TitleActivityPositiveChange: {
      return <IconMapMaker className={classNames(classes.icon, classes.iconMapMaker, classes.iconFillOrange)} />;
    }

    case LogTypeEnum.TitleActivityPositiveChangeWarningDismissed: {
      return <CloseRoundedIcon className={classNames(classes.icon, classes.iconCloseWarning)} />;
    }

    case LogTypeEnum.WorkspaceLinkedPaymentUnlinked:
    case LogTypeEnum.WorkspaceLinkedSourceFundUnlinked:
    case LogTypeEnum.WorkspaceAbandoned:
    case LogTypeEnum.WorkspaceWithdraw: {
      return <IconTimes className={classNames(classes.icon, classes.iconFillOrange)} />;
    }

    case LogTypeEnum.WorkspaceFileDeleted: {
      return <IconDelete className={classNames(classes.icon, classes.iconFillBlack)} />;
    }

    case LogTypeEnum.WorkspaceGroupReassigned: {
      return <IconGroup className={classes.icon} />;
    }

    default:
      return <IconEditDocument className={classes.icon} />;
  }
}

export default React.memo(ActivityLogItemIcon);
