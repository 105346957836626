import * as React from 'react';

import SectionTenancyReceiving from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving';

import { NSW_RECEIVING_TENANCY_TOTAL_SHARE_CONFIG } from '../../../../components/tenancy/config';
import { NSW_TRANSFER_PARTY_FORM_CONFIG, NSW_TRANSFER_RECEIVING_TENANCY_LABEL_CONFIG } from '../../config';

interface Props {
  name: string;
}

function Transferees({ name }: Props) {
  return (
    <SectionTenancyReceiving
      name={name}
      partyFormConfig={NSW_TRANSFER_PARTY_FORM_CONFIG}
      componentLabels={NSW_TRANSFER_RECEIVING_TENANCY_LABEL_CONFIG}
      totalShareConfig={NSW_RECEIVING_TENANCY_TOTAL_SHARE_CONFIG}
      forceDobOnIndividual
    />
  );
}

export default React.memo(Transferees);
