import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    optionIcon: {
      '& path': {
        fill: theme.palette.error.main
      },
      marginRight: 5,
      width: 38,
      height: 36
    },
    deleteLinkedItemTitle: {
      '& .MuiDialogTitle-root': {
        background: colors.NEUTRAL_025
      }
    },
    unlinkButton: {
      alignItems: 'center',
      position: 'absolute',
      fontSize: 14,
      color: colors.SUNNY_DAY_DARK,
      fontStyle: 'normal',
      fontWeight: 700,
      textDecorationLine: 'underline',
      textDecorationStyle: 'solid',
      right: '20px'
    }
  }),
  {
    name: 'LinkedWorkspaceDisplayContainer'
  }
);
