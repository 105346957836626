import * as yup from 'yup';

import yupAdditionalAttachments from '@sympli-mfe/document-forms-framework/components/sections/document-attachment/validation-schema';
import { AttachmentTypeEnum } from '@sympli-mfe/enums-2-21/nsw';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { LEASE_ATTACHMENT_DOCUMENT_TYPE_CONDITIONS_AND_PROVISIONS, LEASE_ATTACHMENT_DOCUMENT_TYPE_OPTIONS } from '../../enums';
import { IS_PLAN_DOCUMENT_REQUIRED } from '../../isRequiredChecks';
import { Lease2_21_2Model } from '../../models';

const leaseDocumentAttachmentTypes: LookupEnumModel<AttachmentTypeEnum, string>[] = [
  ...LEASE_ATTACHMENT_DOCUMENT_TYPE_CONDITIONS_AND_PROVISIONS,
  ...LEASE_ATTACHMENT_DOCUMENT_TYPE_OPTIONS
];

const yupLeaseDocumentAttachments = yupAdditionalAttachments(leaseDocumentAttachmentTypes).test(
  //
  'Must have Plan document if lease length is over 25 years',
  'Please upload a Plan document',
  function test(this: yup.TestContext<Lease2_21_2Model>) {
    const lease = this.options.context!;
    const { leaseDetails, attachments, titleReferences } = lease;
    const selectedTitles = titleReferences.filter(tr => tr.isSelected);
    if (leaseDetails && IS_PLAN_DOCUMENT_REQUIRED(selectedTitles, leaseDetails.leasePeriod.dateLength)) {
      // check attachments has Plan document type
      return Boolean(attachments) && attachments!.some(a => a.documentType === AttachmentTypeEnum.Plan);
    }
    return true;
  }
);

export default yupLeaseDocumentAttachments;
