import { FinalTenancyHoldingsBuilder } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/final-holdings';

import { NSW_TRANSFER_PARTY_FORM_CONFIG } from '../../config';
import { Transfer2_21_1Model } from '../../models';
import { TenancyDetailExtractor } from './models';

type FormModel = Transfer2_21_1Model;

export const calculateFinalTenancyHoldings = (values: {
  transferorsNew: FormModel['transferorsNew'];
  transfereesNew: FormModel['transfereesNew'];
  partyBook: FormModel['partyBook'];
}) => {
  const tde = new TenancyDetailExtractor(values.transferorsNew, values.transfereesNew);
  return FinalTenancyHoldingsBuilder.build(tde, values.partyBook, NSW_TRANSFER_PARTY_FORM_CONFIG);
};
