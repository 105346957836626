import * as React from 'react';

import { ButtonBaseActions } from '@mui/material/ButtonBase';

import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { DisableTenancyFieldConfig } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail';
import SectionGenericTenancy from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/generic';
import { DataSource } from '@sympli-mfe/document-forms-framework/shared-config/common';
import { PartyTypeEnum } from '@sympli-mfe/enums-2-21/nsw';

import { NSW_CAVEAT_ADDRESS_FORM_CONFIG, NSW_CAVEAT_CAVEATOR_TENANCY_COMPONENT_LABEL_CONFIG, NSW_CAVEAT_PARTY_FORM_CONFIG } from '../../config';

interface Props {
  name: string;
  focusRef?: React.RefObject<ButtonBaseActions>;
}

// type FormValues = CaveatModel_2_21_2;

// function SectionCaveator(props: Props) {
//   const { name, focusRef } = props;
//   const { values } = useFormikContext<FormValues>();
//   const { disabled, dialogPortalId } = useDocumentContext();
//   const items: CaveatParty[] = values.caveators;
//   const partyBookOptions = usePartyBookOptions(values.partyBook, NSW_CAVEAT_PARTY_FORM_CONFIG);

//   return (
//     <DocumentFieldArray
//       arrayBinding={name}
//       createNewItem={resolveDefaultItem}
//       itemTitle="Caveator"
//       addButtonTitle="Add caveator"
//       minItems={1}
//       maxItems={20}
//       disabled={disabled}
//       isSimpleType={false}
//       itemStyle="formGroup"
//       focusRef={focusRef}
//       renderItem={({ itemIndex, itemBinding, itemFocusRef, nextItemFocusRef }) => (
//         <CaveatorItem //
//           name={itemBinding}
//           itemIndex={itemIndex}
//           items={items}
//           focusRef={itemFocusRef}
//           nextItemFocusRef={nextItemFocusRef}
//           disabled={disabled}
//           partyBookOptions={partyBookOptions}
//           dialogPortalId={dialogPortalId}
//         />
//       )}
//     />
//   );
// }

// interface CaveatorItemProps {
//   name: string;
//   disabled: boolean;
//   dialogPortalId: string;
//   partyBookOptions: LookupItemModel[];
//   items: CaveatParty[];
//   itemIndex: number;
//   focusRef: React.RefObject<ButtonBaseActions>;
//   nextItemFocusRef?: React.RefObject<ButtonBaseActions>;
// }

// function CaveatorItem({ name, itemIndex, items, dialogPortalId, disabled, focusRef, nextItemFocusRef, partyBookOptions }: CaveatorItemProps): JSX.Element {
//   const filteredPartyOptions = getPartyOptionsExcludingSiblings(partyBookOptions, items, itemIndex);
//   const fieldName = createModelKeyAppender<CaveatParty>(name);
//   const addressFieldFocusRef = React.useRef<ButtonBaseActions>(null);

//   return (
//     <>
//       <PartySelectField
//         name={fieldName('partyBookId')}
//         partyFormConfig={NSW_CAVEAT_PARTY_FORM_CONFIG}
//         disabled={disabled}
//         bookRef={PARTY_BOOK_KEY}
//         dialogPortalId={dialogPortalId}
//         optionsOverride={filteredPartyOptions}
//         focusRef={focusRef}
//         nextFocusRef={addressFieldFocusRef}
//       />
//       <AddressSelectField
//         name={fieldName('addressBookId')}
//         disabled={disabled}
//         bookRef={ADDRESS_BOOK_KEY}
//         dialogPortalId={dialogPortalId}
//         focusRef={addressFieldFocusRef}
//         nextFocusRef={nextItemFocusRef}
//         addressFormConfig={NSW_CAVEAT_ADDRESS_FORM_CONFIG}
//       />
//     </>
//   );
// }

export const disablePartyFieldEditConfig: DisableTenancyFieldConfig<PartyModel> = {
  disabled: (party: PartyModel) => party.partyType === PartyTypeEnum.Individual && party.metadata?.source === DataSource.Title,
  disabledTooltip: () => <span>This party cannot be edited as it has been returned from the title. Please add a new party.</span>
};

function SectionCaveator({
  //
  name
}: Props): JSX.Element {
  return (
    <SectionGenericTenancy //
      name={name}
      partyFormConfig={NSW_CAVEAT_PARTY_FORM_CONFIG}
      disablePartyEditConfig={disablePartyFieldEditConfig}
      componentLabels={NSW_CAVEAT_CAVEATOR_TENANCY_COMPONENT_LABEL_CONFIG}
      addressFormConfig={NSW_CAVEAT_ADDRESS_FORM_CONFIG}
      // disable adding additional joint group
      allowNewGroup={false}
    />
  );
}

export default React.memo(SectionCaveator);
