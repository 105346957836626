import React, { useEffect } from 'react';

import { Form, useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import Consideration from '@sympli-mfe/document-forms-framework/components/sections/consideration';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import { JurisdictionsEnum } from '@sympli/api-gateway/enums';

import { recalculateFormValues } from './helpers';
import { Transfer2_21_1Model } from './models';
import DocumentAttachments from './sections/document-attachments';
import FinalTenancyHoldings from './sections/final-tenancy-holdings';
import TitleReferences from './sections/title-references';
import { TransfereesNew } from './sections/transferees';
import { TransferorsNew } from './sections/transferors';
import { VISIBILITY_CHECK_FINAL_TENANCY_HOLDINGS } from './visibilityChecks';

// this file was automatically generated from DocumentForm.tsx.mustache
const fieldName = modelKey<Transfer2_21_1Model>();
function RootForm({
  className, //
  hideActionsPanel,
  onSaveChanges,
  onCancel
}: RootFormProps): JSX.Element {
  const { isLoading, disabled, nextActionLabel } = useDocumentContext();
  const formikProps = useFormikContext<Transfer2_21_1Model>();
  const {
    setValues,
    values: { transferorsNew }
  } = formikProps;

  const focusOnTitleReferencesRef = React.useRef<ButtonBaseActions>(null);
  const focusOnTransferorsRef = React.useRef<ButtonBaseActions>(null);

  useEffect(() => {
    const focusOnFirstItem = focusOnTitleReferencesRef.current || focusOnTransferorsRef.current;
    if (!focusOnFirstItem) {
      return;
    }
    focusOnFirstItem.focusVisible();
  }, []);

  const handleSelectedTitleReferenceChange = React.useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, checkedIndexes: number[]) => {
      setValues((values: Transfer2_21_1Model) => {
        const updatedValues: Transfer2_21_1Model = {
          ...values,
          ...recalculateFormValues({
            titleReferences: values.titleReferences,
            partyBook: values.partyBook,
            transfereesNew: values.transfereesNew
          })
        };
        return updatedValues;
      });
    },
    [setValues]
  );

  return (
    <Form className={className}>
      <TitleReferences //
        name={fieldName('titleReferences')}
        focusRef={focusOnTitleReferencesRef}
        onChange={handleSelectedTitleReferenceChange}
      />

      <TransferorsNew //
        name={fieldName('transferorsNew')}
        focusRef={focusOnTransferorsRef}
      />

      <Consideration //
        name={fieldName('consideration')}
        jurisdiction={JurisdictionsEnum.NSW}
      />

      <TransfereesNew //
        name={fieldName('transfereesNew')}
      />

      {VISIBILITY_CHECK_FINAL_TENANCY_HOLDINGS({ transferorsNew }) && (
        <FinalTenancyHoldings //
          name={fieldName('finalTenancyHoldings')}
        />
      )}

      <DocumentAttachments //
        name={fieldName('documentAttachments')}
      />

      {!hideActionsPanel && (
        <DocumentActionPanel //
          isLoading={isLoading}
          disabled={disabled}
          onBack={onCancel}
          nextLabel={nextActionLabel}
          onSaveChanges={onSaveChanges}
        />
      )}
    </Form>
  );
}

export default React.memo(RootForm);
