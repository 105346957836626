import DocumentFormWrapper from '@sympli-mfe/document-forms-framework/components/document-form-wrapper';
import { DocumentFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { DndContextProvider } from '@sympli-mfe/document-forms-framework/providers/dnd-context';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { useMemoizableValidation } from '@sympli-mfe/document-forms-framework/validation';

import converter from './conversion';
import { ApiCaveatModel_2_21_2, CaveatModel_2_21_2 } from './models';
import RootForm from './RootForm';
import validationSchema from './validationSchema';

export default function DocumentForm(
  //
  props: DocumentFormProps<CaveatModel_2_21_2, ApiCaveatModel_2_21_2>
): JSX.Element {
  const { validateDebounce } = useDocumentContext();
  const validate = useMemoizableValidation<CaveatModel_2_21_2>(validationSchema, validateDebounce);
  return (
    <DndContextProvider>
      <DocumentFormWrapper //
        {...props}
        converter={converter}
        validate={validate}
        RootForm={RootForm}
      />
    </DndContextProvider>
  );
}
