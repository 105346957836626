import * as yup from 'yup';

import { CaveatModel_2_21_2 } from './models';
import yupExtentOfProhibitions from './sections/action-prohibited/validationSchema';
import yupSupportingDocuments from './sections/attachments/validationSchema';
import yupCaveators from './sections/caveator/validationSchema';
import yupClaimDetails from './sections/claim-details/validationSchema';
import yupContactDetails from './sections/contact-details/validationSchema';
import yupDutyAssessment from './sections/duty-assessment/validationSchema';
import yupProprietorsEditable from './sections/registered-proprietor/validationSchema';
import yupTitleReferences from './sections/title-references/validationSchema';

const validationSchema = yup
  .object<CaveatModel_2_21_2>()
  .partial({
    titleReferences: yupTitleReferences,
    extentOfProhibitions: yupExtentOfProhibitions,
    claimDetails: yupClaimDetails,
    dutyAssessment: yupDutyAssessment,
    caveators: yupCaveators,
    proprietors: yupProprietorsEditable,
    contactDetails: yupContactDetails,
    caveatStatementsAgreed: yup.mixed<boolean>().test('Checked test', 'Must agree to caveat statement', Boolean),
    attachments: yupSupportingDocuments
  })
  .defined();

export default validationSchema;
