import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { colors } from '@sympli/ui-framework/theme';

import Breadcrumbs from 'src/components/workspace-status-rebuild/breadcrumbs/Breadcrumbs';
import { convertWorkspaceStatusColorIntoVerticalStatusLineColor } from 'src/containers/dashboard/shared/formatters/helpers';
import VerticalStatusLine from 'src/containers/dashboard/shared/formatters/VerticalStatusLine';
import { FinancialSettlementStatusSteps } from 'src/containers/workspace/financial/directions/components/settlement-directions-list/models';
import useScreenSize from 'src/hooks/useScreenSize';
import { ScreenSizeVariant } from 'src/theme/screens';
import { resolveFinancialSettlementStatus } from './helper';

interface Props {
  financialSettlementStatusId?: string;
}

function FinancialsBoxTitle({ financialSettlementStatusId }: Props) {
  const screenVariant: ScreenSizeVariant = useScreenSize();

  if (!financialSettlementStatusId) {
    return null;
  }

  const currentProgress = resolveFinancialSettlementStatus({
    financialSettlementStatusId
  });

  if (!currentProgress) {
    return null;
  }

  const { step, variant, tooltipContent } = currentProgress;
  const steps: string[] = FinancialSettlementStatusSteps.All.map(s => s.description.toUpperCase());

  return (
    <FlexLayout flexDirection="column" justifyContent="flex-start">
      <span>Financials</span>
      <VerticalStatusLine //
        dataTestId="workspace-financial-status"
        text={steps[step.value - 1]}
        variant={convertWorkspaceStatusColorIntoVerticalStatusLineColor(variant)}
        textClassName={screenVariant === 'small-laptop' ? 'clip' : 'truncate'}
        tooltipTitle={
          <Box display="flex" flexDirection="column">
            <Typography variant="body2_bold" sx={{ color: colors.WHITE, textTransform: 'uppercase' }}>
              {step.description}
            </Typography>
            {tooltipContent}
            <Breadcrumbs //
              steps={steps}
              currentStep={step.value}
              sxBox={{ margin: theme => theme.spacing(2, 0, 0, 0) }}
            />
          </Box>
        }
      />
    </FlexLayout>
  );
}

export default React.memo(FinancialsBoxTitle);
