import pluralize from 'pluralize';
import Typography from '@mui/material/Typography';

import {
  DocumentActionTypeEnum,
  DocumentStatusEnum,
  DocumentTypeIdentifierEnum,
  LodgementCaseStatusEnum,
  ParticipantStatusEnum,
  WorkspaceStatusEnum
} from '@sympli/api-gateway/enums';
import { WorkspaceDocumentSummaryApiResponse, WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import { LodgementCase } from '@sympli/api-gateway/shared';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import IconTypography from 'src/components/workspace-status-rebuild/icon-typography';
import { DocumentActionModel } from 'src/containers/documents/models';
import { SettlementDateTimeModel } from 'src/models';
import { DATE_DISPLAY_FORMAT, DATE_ONLY_FORMAT, dateTimeLine, tillSettlement } from 'src/utils/formatters';
import { SettlementDateReviewerItemModel } from '../workspace/financial/settlement-date/components/settlement-date-reviewer-list/models';
import { LinkedWorkspaceApiResponseModel, LinkedWorkspaceParticipantModel } from './linked-workspace-list/models';

// Other participant
export function resolveDocumentStatusDescriptionForNonParticipants(statusId: DocumentStatusEnum) {
  switch (statusId) {
    case DocumentStatusEnum.Awaiting:
      return 'Awaiting invitee';
    case DocumentStatusEnum.InPreparation:
    case DocumentStatusEnum.Reviewing:
      return 'In draft';
    case DocumentStatusEnum.Signing:
      return 'Approved';
    case DocumentStatusEnum.Signed:
      return 'Signed';
    case DocumentStatusEnum.LodgementInProgress:
      return 'Lodgement in progress';
    case DocumentStatusEnum.LodgementVerificationInProgress:
      return 'Verification in progress';
    case DocumentStatusEnum.Lodged:
      return 'Lodged';
    case DocumentStatusEnum.ResolveIssues:
      return 'Issues found';
    default:
      return 'In draft';
  }
}

export function getStatusDescriptionDetail(defaultText: string, actionType: DocumentActionTypeEnum, documentActions?: Array<DocumentActionModel>, numberOfActions?: number) {
  if (!documentActions || !numberOfActions) {
    return defaultText;
  }

  const signInfos = documentActions.filter(action => action.type === actionType);
  if (numberOfActions && numberOfActions > 1) {
    if (signInfos.length === 0) {
      return `${defaultText} (${numberOfActions} signers required)`;
    }
    return `${defaultText} (Signed by ${signInfos.length} of ${numberOfActions})`;
  }

  return defaultText;
}

interface LodgementDocumentSummaryEntityModel {
  lodgementOrder: number;
  documentIdentifier: LookupEnumModel<DocumentTypeIdentifierEnum>;
  documentId: string;
}

export function getLodgementDocumentOrders(workspaceDocuments: Array<WorkspaceDocumentSummaryApiResponse>): Array<LodgementDocumentSummaryEntityModel> {
  return workspaceDocuments.map((item, index) => ({
    documentId: item.documentId,
    documentIdentifier: item.documentIdentifier,
    lodgementOrder: index
  }));
}

export function getWorkspaceSettlementRebook(settlementDateTime: SettlementDateTimeModel | undefined, workspaceStatus: WorkspaceStatusEnum | undefined) {
  const hasWorkspaceStatus = Number.isInteger(workspaceStatus);
  const isWorkspaceArchived = hasWorkspaceStatus && (workspaceStatus === WorkspaceStatusEnum.Abandoned || workspaceStatus === WorkspaceStatusEnum.Withdrawn);
  //Archived workspaces can not rebook

  if (isWorkspaceArchived) {
    return false;
  }

  // overdue
  if (settlementDateTime) {
    return settlementDateTime.hoursTill < 0;
  }

  if (hasWorkspaceStatus) {
    switch (workspaceStatus) {
      case WorkspaceStatusEnum.Failed: {
        return true;
      }
      default:
        return false;
    }
  }

  return false;
}

export function resolveSettlementStatusTitle(settlementDateTimeData: SettlementDateTimeModel, workspaceStatus?: WorkspaceStatusEnum, isRollover: boolean = false) {
  const rebookOverdue = getWorkspaceSettlementRebook(settlementDateTimeData, workspaceStatus);
  if (Number.isInteger(workspaceStatus)) {
    switch (workspaceStatus) {
      case WorkspaceStatusEnum.Failed: {
        return 'Settlement failed';
      }
      case WorkspaceStatusEnum.Success:
      case WorkspaceStatusEnum.Archived: {
        return 'Settlement completed';
      }
      case WorkspaceStatusEnum.SettlementIssue: {
        return 'Settlement issue';
      }
      case WorkspaceStatusEnum.SettlementInProgress: {
        return 'Settlement in Progress';
      }
      case WorkspaceStatusEnum.Abandoned:
      case WorkspaceStatusEnum.Withdrawn: {
        return 'Settlement date and time';
      }
      case WorkspaceStatusEnum.OnSchedule:
      case WorkspaceStatusEnum.ReadyForSettlement: {
        return isRollover ? 'Settlement rolled' : tillSettlement(settlementDateTimeData, { suffix: true, rebookOverdue });
      }
      default: {
        return tillSettlement(settlementDateTimeData, { suffix: true, rebookOverdue });
      }
    }
  } else {
    return tillSettlement(settlementDateTimeData!, { suffix: true, rebookOverdue });
  }
}

// Due date settlement is based on calendar days
// WEB-4265
// 1. The weeks remaining until settlement - if settlement time is in 14 calendar days and over
// 2. The days remaining until settlement - if settlement time is in 13 days and under
// 3. "Settlement Today" - if the settlement is on the same day (after 12 am on the same day)
// 4. "Settlement Overdue" - if the settlement date and time has passed
export function tillSettlement101(data: SettlementDateTimeModel, config: { suffix?: boolean; rebookOverdue?: boolean } = { suffix: true, rebookOverdue: false }) {
  const { daysTill, hoursTill, weeksTill } = data;

  if (hoursTill < 0) {
    return `${config.rebookOverdue ? '' : ' - Overdue'}`;
  } else if (hoursTill > 0 && daysTill === 0) {
    return ' - Settling today';
  } else if (daysTill > 0 && daysTill < 14) {
    return ` - ${daysTill} ${pluralize('day', daysTill)}${config.suffix ? ' until settlement' : ''}`;
  } else if (daysTill >= 14 && weeksTill > 0) {
    return ` - ${pluralize('week', weeksTill, true)}${config.suffix ? ' until settlement' : ''}`;
  } else {
    return ' - Happening soon';
  }
}

const TIME_ONLY_DISPLAY_FORMAT = 'h:MM TT';

export function getSettlementDateTime(data: SettlementDateTimeModel, isRollover: boolean = false, workspaceStatus?: WorkspaceStatusEnum) {
  const getExtraDescription = () => {
    let extraDescription: string = '';
    const rebookOverdue = getWorkspaceSettlementRebook(data, workspaceStatus);

    switch (workspaceStatus) {
      case WorkspaceStatusEnum.Failed:
      case WorkspaceStatusEnum.Success:
      case WorkspaceStatusEnum.Abandoned:
      case WorkspaceStatusEnum.Withdrawn:
      case WorkspaceStatusEnum.SettlementIssue: //TODO review this because no mock up in Figma
      case WorkspaceStatusEnum.Archived: {
        break;
      }
      case WorkspaceStatusEnum.SettlementInProgress: {
        extraDescription = ' - In Progress';
        break;
      }
      case WorkspaceStatusEnum.OnSchedule:
      case WorkspaceStatusEnum.ReadyForSettlement: {
        extraDescription = isRollover ? ' - Rolled' : tillSettlement101(data, { suffix: false, rebookOverdue });
        break;
      }
      default:
        extraDescription = tillSettlement101(data, { suffix: false, rebookOverdue });
        break;
    }
    return extraDescription;
  };

  return (
    <Typography variant="body2">
      {getDateTimeString(data)}
      {getExtraDescription()}
    </Typography>
  );
}

export function getDateTimeString(data: SettlementDateTimeModel) {
  const { userLocalTime, userTimezone, workspaceLocalTime, workspaceTimezone } = data;
  return (
    <>
      {`${dateTimeLine(workspaceLocalTime, DATE_ONLY_FORMAT)}, at ${userTimezone !== workspaceTimezone ? workspaceTimezone.substring(0, 3) + ' ' : ''}${dateTimeLine(
        workspaceLocalTime,
        TIME_ONLY_DISPLAY_FORMAT
      )}`}
      {userTimezone !== workspaceTimezone && (
        <span className="user-localtime">{` (${userTimezone.substring(0, 3)} ${dateTimeLine(userLocalTime, TIME_ONLY_DISPLAY_FORMAT)})`}</span>
      )}
    </>
  );
}

export function getDateString(data: SettlementDateTimeModel) {
  const { workspaceLocalTime } = data;
  return <>{`${dateTimeLine(workspaceLocalTime, DATE_ONLY_FORMAT)} - Time not set`}</>;
}

export const activeParticipantsAndInvitations = (participants: Array<WorkspaceParticipantApiResponse['participantStatus']>) => {
  return participants.filter(p => [ParticipantStatusEnum.Accepted, ParticipantStatusEnum.Pending, ParticipantStatusEnum.InviteForwarded].includes(p.id)).length;
};

export const activeAcceptedSettlementDateParticipantsNumber = (participants: Array<SettlementDateReviewerItemModel>) => {
  return participants.filter(p => p.hasAccepted).length;
};

const getAcceptedNumber = (participantsInfo: Array<WorkspaceParticipantApiResponse['participantStatus']>, participantsSettlementInfo: Array<SettlementDateReviewerItemModel>) => {
  const acceptedNumber = activeAcceptedSettlementDateParticipantsNumber(participantsSettlementInfo);
  const totalNumber = activeParticipantsAndInvitations(participantsInfo);

  const text = `Accepted (${acceptedNumber}/${totalNumber})`;
  if (acceptedNumber === totalNumber) {
    return <Typography variant="body6">{text}</Typography>;
  }
  return <IconTypography text={text} />;
};

export const getSettlementDateTimeStatus = (
  participantsInfo: Array<WorkspaceParticipantApiResponse['participantStatus']>,
  participantsSettlementInfo: Array<SettlementDateReviewerItemModel>,
  data?: SettlementDateTimeModel,
  workspaceStatus?: WorkspaceStatusEnum,
  isInteroperable?: boolean
) => {
  //TODO review if these values can be undefined or not
  if (!data || workspaceStatus === undefined) {
    return null;
  }

  const { hoursTill } = data;

  switch (workspaceStatus) {
    case WorkspaceStatusEnum.Abandoned:
    case WorkspaceStatusEnum.Withdrawn:
      return null;
    case WorkspaceStatusEnum.Finalised:
    case WorkspaceStatusEnum.Archived:
    case WorkspaceStatusEnum.Success:
    case WorkspaceStatusEnum.SettlementInProgress:
      return getAcceptedNumber(participantsInfo, participantsSettlementInfo);
    case WorkspaceStatusEnum.SettlementIssue:
      return <IconTypography text="Settling" />;
    case WorkspaceStatusEnum.Failed:
      const activeAcceptedParticipants = activeAcceptedSettlementDateParticipantsNumber(participantsSettlementInfo);

      // if 3 participants in a workspace and one of it withdrawn and then the ws overdue
      if (activeAcceptedParticipants !== 0 && activeAcceptedParticipants < activeParticipantsAndInvitations(participantsInfo)) {
        return getAcceptedNumber(participantsInfo, participantsSettlementInfo);
      }

      return <IconTypography text="Rebook" />;
    default:
      if (hoursTill < 0 && !isInteroperable) {
        return <IconTypography text="Rebook" />;
      }

      if (hoursTill < 0 && isInteroperable && workspaceStatus !== WorkspaceStatusEnum.ReadyForSettlement) {
        return <IconTypography text="Rebook" />;
      }

      return getAcceptedNumber(participantsInfo, participantsSettlementInfo);
  }
};

// MR - not being used
// export function resolveHasLodgementError(lodgementDetail?: DocumentLodgementDetail) {
//   if (lodgementDetail != null) {
//     const { lrDocumentId, documentLodgementCompliances } = lodgementDetail;
//     if (lrDocumentId || documentLodgementCompliances == null) {
//       return false;
//     }
//     if (documentLodgementCompliances.some(compliance => compliance.level === ComplianceLevelEnum.Error)) {
//       return true;
//     }
//   }
//   return false;
// }

export const FINALIZED_WORKSPACE_STATUSES: WorkspaceStatusEnum[] = [
  WorkspaceStatusEnum.Abandoned,
  WorkspaceStatusEnum.Archived,
  WorkspaceStatusEnum.Success,
  WorkspaceStatusEnum.Finalised,
  WorkspaceStatusEnum.Withdrawn
];

export function isWorkspaceFinalized(workspaceStatus?: WorkspaceStatusEnum): boolean {
  if (!workspaceStatus) {
    return false;
  }

  return FINALIZED_WORKSPACE_STATUSES.includes(workspaceStatus);
}

const NON_WITHDRAWABLE_WORKSPACE_STATUSES: WorkspaceStatusEnum[] = [
  ...FINALIZED_WORKSPACE_STATUSES,
  WorkspaceStatusEnum.SettlementInProgress,
  WorkspaceStatusEnum.LodgementInQueue
];
const NON_WITHDRAWABLE_LODGEMENT_STATUSES: LodgementCaseStatusEnum[] = [LodgementCaseStatusEnum.LodgementSuccess];
export function resolveCanWithdrawLinkedWorkspace(
  subscriberId: string,
  workspaceId: string,
  linkedWorkspaces: LinkedWorkspaceApiResponseModel[]
): { canWithdrawLinkedWorkspace: boolean; isWithdraw: boolean } {
  let canWithdrawLinkedWorkspace = false;
  let isWithdraw = false;

  if (linkedWorkspaces.length > 0) {
    const linkedParticipants = linkedWorkspaces
      .filter(
        item =>
          item.workspaceId !== workspaceId &&
          !NON_WITHDRAWABLE_WORKSPACE_STATUSES.includes(item.workspaceStatusId) &&
          !NON_WITHDRAWABLE_LODGEMENT_STATUSES.includes(item.lodgementCaseStatusId)
      )
      .reduce((prev: LinkedWorkspaceParticipantModel[], current) => {
        const activeParticipants = current.participants.filter(item => item.invitationStatusId === ParticipantStatusEnum.Accepted && !item.archivedStatus);
        prev.push(...activeParticipants);
        return prev;
      }, []);
    const sameSubscriberParticipants = linkedParticipants.filter(item => item.subscriberId === subscriberId);

    canWithdrawLinkedWorkspace = sameSubscriberParticipants.length > 0;
    isWithdraw = sameSubscriberParticipants.length < linkedParticipants.length;
  }

  return { canWithdrawLinkedWorkspace, isWithdraw };
}

export function isSameDateTime(dateTimeOne: string, dateTimeTwo: string) {
  return dateTimeLine(dateTimeOne, DATE_DISPLAY_FORMAT) === dateTimeLine(dateTimeTwo, DATE_DISPLAY_FORMAT);
}

export function getDefaultLodgementCase(lodgementCases: LodgementCase[] = []): LodgementCase | undefined {
  if (lodgementCases.length === 0) {
    return;
  }

  // For non MLC Financial workspaces and Standalone workspaces we only have one lodgement cases
  if (lodgementCases.length === 1) {
    return lodgementCases[0];
  }

  return lodgementCases.find(x => x.isPrimary);
}

export function getDefaultLodgementCaseId(lodgementCases: LodgementCase[] = []): string | undefined {
  return getDefaultLodgementCase(lodgementCases)?.id;
}

export function resolveDefaultLodgementCaseStatus(lodgementCases: LodgementCase[] = []): LodgementCaseStatusEnum {
  // for iop there is a chance there is no lc, we will use default one if there is no lc
  // for MLC Workspace we will use PLC, otherwise take the first (also the only) one (This includes non MLC Financial workspace and standalone workspace)
  // const lodgementCaseStatusId =
  //   lodgementCases.length === 0
  //     ? LodgementCaseStatusEnum.Unverified
  //     : lodgementCases.length > 1
  //       ? lodgementCases.find(d => d.isPrimary)!.lodgementCaseStatusId
  //       : lodgementCases[0].lodgementCaseStatusId;

  return getDefaultLodgementCase(lodgementCases)?.lodgementCaseStatusId || LodgementCaseStatusEnum.Unverified;
}

export function resolveSecondaryLodgementCases(lodgementCases: LodgementCase[] = [], responsibleParticipantId?: string) {
  const filterByParticipantId = responsibleParticipantId ? (lc: LodgementCase) => lc.responsibleParticipantId === responsibleParticipantId : () => true;
  return lodgementCases.filter(lc => !lc.isPrimary).filter(filterByParticipantId);
}
