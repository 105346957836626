import _cloneDeep from 'lodash-es/cloneDeep';

import { IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { fallbackMap } from '../fallback';
import { ApiNoticeOfDeath2_21_1Model, NoticeOfDeath2_21_1Model, TitleReferenceModel } from '../models';
import { convertPartiesToFormModel2211, filterDeceasedProprietorGroups } from './common';

type FormModel = NoticeOfDeath2_21_1Model;

export function convertFromApiToFormModel(apiModel: ApiNoticeOfDeath2_21_1Model, context: IConverterContext): FormModel {
  const { titleReferences: apiTitleReferences } = apiModel;
  const convertedTitleReferences: TitleReferenceModel[] = apiTitleReferences.filter(title => title.deceasedJointTenants.length > 0);
  if (convertedTitleReferences.length === 1) {
    convertedTitleReferences[0].isSelected = true;
  }
  const risInvolvedPartyIds = apiModel.partyBook.filter(p => p.propertiesMappedFromRis?.length).map(p => p.id!);
  const partyBook = convertPartiesToFormModel2211(apiModel.partyBook || [], risInvolvedPartyIds);
  let formModel: FormModel = {
    ...apiModel,
    titleReferences: convertedTitleReferences,
    partyBook: partyBook,
    deceasedJointTenants: filterDeceasedProprietorGroups(_cloneDeep(convertedTitleReferences), partyBook),
    // custom UI only properties
    context
  };

  formModel = applyDefaultMap(formModel, fallbackMap);
  return formModel;
}
