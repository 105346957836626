import { NswNameChange, nswPartyNameChangeConfig } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { NSW_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/nsw/2-21/config';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';

import { formatNewName } from '../../helpers';

export const NSW_NOD_PARTY_FORM_CONFIG_WITH_NAME_CHANGE: PartyFormConfig<NswNameChange> = {
  ...NSW_PARTY_FORM_CONFIG,
  nameChangeConfig: { ...nswPartyNameChangeConfig, formatNewName }
};

export const NSW_NOD_PARTY_FORM_CONFIG: PartyFormConfig<NswNameChange> = {
  ...NSW_PARTY_FORM_CONFIG,
  nameChangeConfig: { ...nswPartyNameChangeConfig, formatNewName }
};

export const NSW_NOD_PARTY_FORM_CONFIG_WITHOUT_NAME_CHANGE: PartyFormConfig<NswNameChange> = {
  ...NSW_PARTY_FORM_CONFIG,
  nameChangeConfig: undefined
};

export const PARTY_SELECTED_ON_ANOTHER_DOCUMENT =
  'This party is selected on another Notice of Death document in this workspace; selecting them again may cause an issue in lodgement.';
