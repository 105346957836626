import React, { useEffect } from 'react';

import { useFormikContext } from 'formik';
import _get from 'lodash-es/get';
import Divider from '@mui/material/Divider';

import PartyForm, { PARTY_TYPE_OPTIONS } from '@sympli-mfe/document-forms-framework/components/party-form';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import { PartyTypeEnum } from '@sympli-mfe/enums-2-23/necds';
import Radio from '@sympli/ui-framework/components/form/base-components/radio';
import ReadOnlyField from '@sympli/ui-framework/components/form/base-components/read-only-field';
import Subform from '@sympli/ui-framework/components/formik/subform';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import DateOfDeath from '../../components/date-of-death';
import Evidence from '../../components/evidence';
import { NSW_NOD_PARTY_FORM_CONFIG } from '../../config';
import { DeceasedJointTenantsModel, DeceasedProprietorModel, NoticeOfDeath2_21_1Model } from '../../models';
import { DEFAULT_DEATH_OF_DATE, DEFAULT_EVIDENCE, extractSurvivingJointTenants } from '../../utils/common';
import { VISIBILITY_CHECK_DATE_OF_DEATH_AND_EVIDENCE } from '../../visibilityChecks';
import { useStyles } from './styles';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
}

function DeceasedProprietor({ name, className }: Props): JSX.Element {
  const formikProps = useFormikContext<NoticeOfDeath2_21_1Model>();
  const { values, setValues, setFieldValue } = formikProps;
  const { divider } = useStyles();
  const { partyBook } = values;
  const value: DeceasedProprietorModel = _get(values, name);
  const { disabled } = useDocumentContext();
  const fieldName = createModelKeyAppender<DeceasedProprietorModel>(name);
  const bookIndexOfPartyId = partyBook.findIndex(party => party.id === value.partyBookId);
  const partyBindingPath = `partyBook[${bookIndexOfPartyId}]`;
  const deceasedProprietorPartyBook = partyBook.find(party => party.id === value.partyBookId);
  const isOrg = deceasedProprietorPartyBook?.partyType === PartyTypeEnum.Organisation;
  const showCustomOrganizationParty = isOrg && !deceasedProprietorPartyBook.readonlyMap?.partyType;
  const isAffectedProprietor: boolean = value.isAffectedProprietor ?? false;

  //This for listening to party type change event from Party subform
  //In case this party type change to Organization and isAffectedProprietor is true it will change isAffectedProprietor to false
  useEffect(() => {
    if (isOrg && value.isAffectedProprietor) {
      setFieldValue(name, {
        ...value,
        isAffectedProprietor: false
      });
    }
  }, [isOrg, name, setFieldValue, setValues, value, value.isAffectedProprietor]);

  const handlePartyTypeChange = (_: React.ChangeEvent<HTMLInputElement>, newPartyType: PartyTypeEnum): void => {
    if (newPartyType === PartyTypeEnum.Individual) {
      const updatedDeceasedJointTenants: DeceasedJointTenantsModel[] = values.deceasedJointTenants.map(group => {
        const updatedDeceasedProprietors: DeceasedProprietorModel[] = group.deceasedProprietors.map(p => {
          return {
            ...p,
            isAffectedProprietor: value.deceasedProprietorId === p.deceasedProprietorId,
            dateOfDeath: p.dateOfDeath ? { ...p.dateOfDeath } : { ...DEFAULT_DEATH_OF_DATE },
            evidence: p.evidence ? { ...p.evidence } : { ...DEFAULT_EVIDENCE }
          };
        });
        return {
          ...group,
          deceasedProprietors: updatedDeceasedProprietors
        };
      });

      setValues(values => {
        return {
          ...values,
          deceasedJointTenants: updatedDeceasedJointTenants,
          survivingJointTenants: extractSurvivingJointTenants(updatedDeceasedJointTenants, value.deceasedProprietorId!)
        };
      });
    }
    setFieldValue(partyBindingPath, {
      ...deceasedProprietorPartyBook,
      partyType: newPartyType
    });
  };

  return (
    <div data-section={name} className={className} data-testid={name}>
      <FlexLayout justifyContent="flex-start" flexDirection="column">
        {isAffectedProprietor ? (
          <Subform subformBindingPath={partyBindingPath} component={PartyForm} disabled={disabled || isOrg} config={NSW_NOD_PARTY_FORM_CONFIG} showFormGroups={false} />
        ) : (
          <div>
            {showCustomOrganizationParty && (
              <Radio
                value={deceasedProprietorPartyBook?.partyType}
                name={partyBindingPath}
                disabled={false}
                onChange={handlePartyTypeChange}
                format="number"
                options={PARTY_TYPE_OPTIONS}
                aria-label="Party type"
              />
            )}
            <ReadOnlyField //
              label={deceasedProprietorPartyBook?.partyType === PartyTypeEnum.Organisation ? 'Organisation' : 'Individual'}
              value={
                deceasedProprietorPartyBook?.partyType === PartyTypeEnum.Organisation
                  ? deceasedProprietorPartyBook?.organisationName
                  : [deceasedProprietorPartyBook?.firstName, deceasedProprietorPartyBook?.lastName].filter(Boolean).join(' ')
              }
            />
          </div>
        )}
      </FlexLayout>

      <FlexLayout justifyContent="flex-start" flexDirection="column">
        {VISIBILITY_CHECK_DATE_OF_DEATH_AND_EVIDENCE(value) && (
          <>
            <Divider className={divider} />
            <DateOfDeath //
              name={fieldName('dateOfDeath')}
            />
          </>
        )}
        {VISIBILITY_CHECK_DATE_OF_DEATH_AND_EVIDENCE(value) && (
          <>
            <Divider className={divider} />
            <Evidence //
              name={fieldName('evidence')}
            />
          </>
        )}
      </FlexLayout>
    </div>
  );
}

export default React.memo(DeceasedProprietor);
