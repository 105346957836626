import * as yup from 'yup';

import { createPartyBookItemTestForPartyBookId } from '@sympli-mfe/document-forms-framework/components/party-form';
import {
  resolveProprietorGroupsValidationSchema,
  YUP_RELINQUISHING_PARTY_BOOK_ID_REQUIRED
} from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/relinquishing/validationSchema';
import { memoizeSchemaWithContext } from '@sympli-mfe/document-forms-framework/validation';

import PartyValidations from 'src/containers/documents/party-merge/PartyValidations';
import { getLegalEntityNameNSW } from '../../../../helpers';
import { NSW_TRANSFER_PARTY_FORM_CONFIG } from '../../config';
import { Transfer2_21_1Model } from '../../models';

// interface TransferorsOldContext {
//   partyBook: Transfer2_21_1Model['partyBook'];
//   selectedTitleReferences: Transfer2_21_1Model['titleReferences'];
//   transferors: Transfer2_21_1Model['transferors'];
// }

export const MISMATCH_NAME_ACROSS_TITLES_ERROR_MESSAGE = 'The parties on the selected titles do not match; please provide name justification or remove the mismatched titles.';
// const yupTransferorsOld: yup.Schema<Transfer2_21_1Model['transferors']> = resolveRelinquishingTenancyValidationSchema<Transfer2_21_1Model['transferors']>({
//   //
//   relinquishingPartyRole: NSW_TRANSFER_RELINQUISHING_TENANCY_CONFIG.relinquishingPartyRole
// }).test(
//   //
//   'Transferor requires name change test',
//   MISMATCH_NAME_ACROSS_TITLES_ERROR_MESSAGE,
//   function test(this: yup.TestContext<TransferorsOldContext>) {
//     const { selectedTitleReferences, partyBook } = this.options.context!;
//     if (selectedTitleReferences.length <= 1) return true;
//     return PartyValidations.verifyJustification(partyBook, getLegalEntityNameNSW);
//   }
// );

// const oldContextResolver = ({ partyBook, titleReferences, transferors }: Transfer2_21_1Model): TransferorsOldContext => ({
//   //
//   partyBook,
//   transferors,
//   selectedTitleReferences: titleReferences.filter(tr => tr.isSelected)
// });

// export default memoizeSchemaWithContext(yupTransferorsOld, oldContextResolver);

type FormModel = Transfer2_21_1Model;

interface TransferorsNewContext {
  partyBook: Transfer2_21_1Model['partyBook'];
  hasMultipleTitlesSelected: boolean;
  transferorsNew: Transfer2_21_1Model['transferorsNew'];
}

interface ProprietorGroupsContext {
  partyBook: FormModel['partyBook'];
  hasProprietorGroupSelected: boolean;
}

const yupProprietorGroups = resolveProprietorGroupsValidationSchema<ProprietorGroupsContext>({
  yupPartyBookId: YUP_RELINQUISHING_PARTY_BOOK_ID_REQUIRED.test(
    createPartyBookItemTestForPartyBookId(
      //
      NSW_TRANSFER_PARTY_FORM_CONFIG
      // {
      //   shouldValidatePartyData: shouldValidateLessorPartyData
      // }
    )
  ),
  yupAddressBookId: yup.mixed(),
  // turn off unnecessary validations
  yupPartyCapacity: yup.mixed()
}) //
  .testContextualRule({
    message: 'At least 1 relinquishing proprietor group must be selected.',
    uniqueTestName: 'proprietorsAffected',
    requirement: (parent: never, ctx: ProprietorGroupsContext): boolean => {
      return ctx.hasProprietorGroupSelected;
    }
  });

const memoizedYupProprietorGroups: yup.MixedSchema<FormModel['transferorsNew']['proprietorGroups'], ProprietorGroupsContext> = memoizeSchemaWithContext<
  FormModel['transferorsNew']['proprietorGroups'],
  TransferorsNewContext,
  ProprietorGroupsContext
>(
  yupProprietorGroups, //
  function contextResolver(parentContext: TransferorsNewContext): ProprietorGroupsContext {
    return {
      partyBook: parentContext.partyBook, // partyBook is required here for party validation
      // we calculate this to help memoizer with should recompute check
      hasProprietorGroupSelected: parentContext.transferorsNew.proprietorGroups.some(({ isSelected }) => {
        return isSelected;
      })
    };
  }
);

const yupTransferorsNew: yup.Schema<FormModel['transferorsNew'], TransferorsNewContext> = yup
  .object<FormModel['transferorsNew']>({
    proprietorGroups: memoizedYupProprietorGroups,
    // turn off unnecessary validations
    tenancyType: yup.mixed()
  })
  .testContextualRule({
    message: MISMATCH_NAME_ACROSS_TITLES_ERROR_MESSAGE,
    uniqueTestName: 'mismatchNameCheck',
    onlyIf: (parent: never, ctx: TransferorsNewContext) => {
      return ctx.hasMultipleTitlesSelected;
    },
    requirement: (parent: never, ctx: TransferorsNewContext): boolean => {
      const isValid = PartyValidations.verifyJustification(ctx.partyBook, getLegalEntityNameNSW);
      return isValid;
    }
  })
  .log()
  .defined();

const memoizedYupTransferorsNew = memoizeSchemaWithContext<FormModel['transferorsNew'], FormModel, TransferorsNewContext>( //
  yupTransferorsNew,
  function contextResolver(parentContext: FormModel): TransferorsNewContext {
    const { partyBook, titleReferences, transferorsNew } = parentContext;
    return {
      // this is required for context of yupProprietorGroups
      partyBook,
      // we calculate this to help memoizer with should recompute check
      hasMultipleTitlesSelected: titleReferences.length > 1,
      // this is required for context of yupProprietorGroups
      transferorsNew
    };
  }
);
export default memoizedYupTransferorsNew;
