import { NswNameChange, nswPartyNameChangeConfig } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { NSW_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/nsw/2-21/config';
import { PartyFormConfig, PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { DocumentAttachmentLabelConfig } from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';
import { TENANCY_COMPONENT_LABEL_CONFIG, TenancyComponentLabelConfig } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/config';
import { CommonTenancyLabelsEnum, ReceivingTenancyComponentLabelConfig } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import {
  RelinquishingTenancyComponentLabelConfig as OldRelinquishingTenancyComponentLabelConfig,
  SHARE_TRANSFERRED_TYPE_OPTIONS,
  ShareTransferredTypeEnum
} from '@sympli-mfe/document-forms-framework/components/sections/tenancy/relinquishing';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { modelKey } from 'src/utils/formUtils';
import { NSW_TENANCY_COMPONENT_LABEL_CONFIG } from '../../../2-17/components/tenancy/config';
import { NSW_RELINQUISHING_TENANCY_COMPONENT_LABEL_CONFIG } from '../../components/tenancy/config';
import { formatNewName } from '../../helpers';

// this file was automatically generated from config.ts.mustache
// Define any form-specific configuration in this file
const fieldName = modelKey<PartyModel>();

export const NSW_TRANSFER_PARTY_FORM_CONFIG: PartyFormConfig<NswNameChange> = {
  ...NSW_PARTY_FORM_CONFIG,
  nameChangeConfig: { ...nswPartyNameChangeConfig, formatNewName },
  upperCaseFields: [fieldName('firstName'), fieldName('lastName'), fieldName('organisationName'), fieldName('nameSuffixValue')]
};

export const NSW_TRANSFER_DOCUMENT_ATTACHMENT_LABEL_CONFIG: DocumentAttachmentLabelConfig = {
  sectionTitle: 'Upload supporting documents',
  uploaderTitle: 'Upload document',
  addButtonTitle: 'Add another document'
};

export const NSW_TRANSFER_RECEIVING_TENANCY_LABEL_CONFIG_OLD: ReceivingTenancyComponentLabelConfig = {
  ...NSW_TENANCY_COMPONENT_LABEL_CONFIG,
  receivingPartyRole: CommonTenancyLabelsEnum.Transferee
};

export const NSW_TRANSFER_RECEIVING_TENANCY_LABEL_CONFIG: TenancyComponentLabelConfig = {
  ...TENANCY_COMPONENT_LABEL_CONFIG,
  partyRole: CommonTenancyLabelsEnum.Transferee
};

export const NSW_TRANSFER_RELINQUISHING_TENANCY_CONFIG: OldRelinquishingTenancyComponentLabelConfig = {
  ...NSW_RELINQUISHING_TENANCY_COMPONENT_LABEL_CONFIG,
  relinquishingPartyRole: CommonTenancyLabelsEnum.Transferor
};

const allowedShareTransferredType: ShareTransferredTypeEnum[] = [ShareTransferredTypeEnum.Whole, ShareTransferredTypeEnum.Partial];
export const NSW_TRANSFER_RELINQUISHING_TENANCY_SHARE_TRANFERRED_TYPE: LookupEnumModel<ShareTransferredTypeEnum, string>[] = SHARE_TRANSFERRED_TYPE_OPTIONS.filter(x =>
  allowedShareTransferredType.includes(x.id)
);

export const NSW_TRANSFER_TRANSFERORS_TENANCY_COMPONENT_LABEL_CONFIG: TenancyComponentLabelConfig = {
  ...TENANCY_COMPONENT_LABEL_CONFIG,
  partyRole: CommonTenancyLabelsEnum.Transferor,
  tenancyChange: {
    warningMessage: 'The party details, such as party name, tenancy structure, or share fraction, do not match the title; this may cause an issue in lodgement.',
    dialogMessage: 'This will reset your party details, such as party name, tenancy structure, or share fraction, to match the title.'
  }
};
