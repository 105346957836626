import React from 'react';

import { FormikProps, FormikValues } from 'formik';
import _uniqueId from 'lodash-es/uniqueId';
import queryString from 'query-string';
import FocusLock from 'react-focus-lock';
import { batch, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { DocumentContextData, DocumentContextProvider } from '@sympli-mfe/document-forms-framework/providers/document-context';
import {
  AccessOptionEnum,
  DocumentPermissionEnum,
  DocumentStatusEnum,
  DocumentTypeIdentifierEnum,
  DocumentViewerTypeEnum,
  DocumentWorkflowTypeEnum,
  JurisdictionsEnum
} from '@sympli/api-gateway/enums';
import { UpdateWorkspaceDocumentApiResponse, WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import { actionCreateGlobalErrorMessage, GlobalErrorMessageModel } from 'src/actions/globalErrors';
import DocumentWorkflowPanel, { DocumentWorkflowPanelProps } from 'src/containers/documents/components/document-workflow-panel';
import { actionClearDocumentMessages } from 'src/containers/workspace/shared/components/workflow-panel/actions';
import { actionUpdateDocumentDataInWorkspaceDocuments } from 'src/containers/workspace/shared/detail/actions';
import { resolveWorkspaceDetailLink } from 'src/containers/workspace/shared/detail/helpers';
import { useWorkspaceDocuments } from 'src/containers/workspace/shared/detail/reducers/workspaceDocuments';
import { fetchWorkspaceDocuments } from 'src/containers/workspace/shared/detail/sagas';
import { useSafeDispatch } from 'src/hooks';
import { AxiosError } from 'src/utils/http';
import { actionResetDocumentData } from '../../actions';
import { resolveNextFinancialDocumentTaskLink } from '../../helpers';
import { DocumentFormAndDetailModel, DocumentsPageRouteAndQueryModel, DocumentWorkflowStepsEnum } from '../../models';
import * as documentApi from './api';
import EditDocument from './EditDocument';
import { docUpdateHelper } from './helpers';

const DEFAULT_FORM_DATA: object = {};

const q = queryString.parse(window.location.search, { parseBooleans: true, parseNumbers: true });
const urlConfig: Pick<DocumentContextData, 'validateOnBlur' | 'fpsMeter' | 'validateDebounce'> = {
  validateOnBlur: Boolean(q.validateOnBlur),
  validateDebounce: Number(q.validateDebounce) || 0,
  fpsMeter: Boolean(q.fpsMeter)
};

interface Props {
  queryParams: DocumentsPageRouteAndQueryModel;
  updateWorkflowStep: (status: DocumentStatusEnum) => void;
  documentDetail: DocumentFormAndDetailModel;
  jurisdictionId: JurisdictionsEnum;
  currentParticipant?: WorkspaceParticipantApiResponse;
  participants?: WorkspaceParticipantApiResponse[];
  numberOfDocuments: number;
  workflowPanelProps: Omit<DocumentWorkflowPanelProps<string, DocumentWorkflowStepsEnum>, 'disableMenu' | 'disableStepper'>;
}

function EditDocumentContainer({
  //
  queryParams,
  documentDetail,
  jurisdictionId,
  updateWorkflowStep,
  currentParticipant,
  participants,
  numberOfDocuments,
  workflowPanelProps
}: Props) {
  const workflowPanelRef = React.createRef<HTMLDivElement>();
  const navigate = useNavigate();
  let formikProps: FormikProps<FormikValues>;
  const { workspaceId, participantId } = queryParams;
  const {
    documentPermissions,
    documentWorkflowType,
    documentForm: { documentType, documentViewerType },
    parsedData
  } = documentDetail;
  const workspaceDocuments = useWorkspaceDocuments(workspaceId, participantId).items;

  const [hash] = React.useState(documentDetail.hash); // contains the initial document detail hash and used in submission, must be prevented from getting updated
  const isScaffoldedForm = documentViewerType === DocumentViewerTypeEnum.CustomReactForm;
  const [isWorkflowLoading, setIsWorkflowLoading] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [nextActionDisabled, setNextActionDisabled] = React.useState(false);
  const [formData, setFormData] = React.useState(parsedData ?? DEFAULT_FORM_DATA);
  const dispatch = useSafeDispatch(useDispatch());
  const dialogPortalId = _uniqueId();
  const hasPermissionToEdit = !!documentPermissions.includes(DocumentPermissionEnum.Write);
  const isLoading = isSubmitting || isWorkflowLoading;
  // WEB-17568
  // Cannot reliable disable access into the LI so disable document workflow button when LI is the only document in the workspace.
  // TODO ADD TEST
  const isLodgementInstructionsSingleDocument = numberOfDocuments === 1 && documentType === DocumentTypeIdentifierEnum.LodgementInstructions;
  const disabled = isLoading || !hasPermissionToEdit || isLodgementInstructionsSingleDocument;
  const nextActionLabel = documentWorkflowType === DocumentWorkflowTypeEnum.Standard ? 'Save and approve' : 'Save and review';

  React.useEffect(() => {
    const documentData = parsedData ?? DEFAULT_FORM_DATA;
    // update state only for initial back-end data preload
    if (formData === DEFAULT_FORM_DATA && documentData !== formData) {
      setFormData(documentData);
    }
  }, [parsedData, formData]);

  const handleRenderForm = (formik: FormikProps<any>) => {
    // save copy of formikProps
    formikProps = formik;

    // MR: we need to maintain local isSubmitting state to be able correctly turn off loader after submission has finished
    if (isSubmitting !== formikProps.isSubmitting) {
      setIsSubmitting(formikProps.isSubmitting);
    }

    Logger.console(SeverityEnum.Log, 'Values', formikProps.values);
    Logger.console(SeverityEnum.Log, 'Touched', formikProps.touched);
    Logger.console(SeverityEnum.Log, 'Errors', formikProps.errors);
  };

  const handleSaveClick = () => {
    dispatch(actionClearDocumentMessages());
    formikProps?.submitForm();
  };

  const handleOnFormSave = <T extends object>(formData: T): Promise<UpdateWorkspaceDocumentApiResponse> => {
    // explicitly return a promise so Formik can handle isSubmitting flag correctly
    return new Promise<UpdateWorkspaceDocumentApiResponse>((resolve, reject) => {
      // WEB-19733 - we don't want to trigger setFormData logic for scaffolded forms,
      // because data that we received in this handler have been already converted from form to api format (in case of scaffolded forms)
      // so passing them again as initial values can potentially cause a validation errors. This will happen if validateOnMount is enabled.
      if (!isScaffoldedForm) {
        // update data so we don't loss them
        setFormData(formData);
      }
      // now submit data to the server
      documentApi
        .saveDocumentForm(formData, queryParams, hash)
        .then(async (response: UpdateWorkspaceDocumentApiResponse) => {
          try {
            const {
              documentWorkflow: { isWorkspaceLocked }
            } = response;
            if (isWorkspaceLocked) {
              setIsWorkflowLoading(true); // explicitly show loader
            }

            docUpdateHelper(dispatch, queryParams, response);

            // resolve returned promise so Formik can handle isSubmitting flag correctly
            resolve(response);
          } catch (error) {
            Logger.console(SeverityEnum.Error, error);
            resolve(response);
          }
        })
        .catch((error: AxiosError) => {
          setIsWorkflowLoading(false);
          // see [WEB-12458]
          if (error.response?.status === 409) {
            const payload: GlobalErrorMessageModel = {
              title: 'Document cannot be saved',
              message: 'This document cannot be saved. Please refresh the page and try again.'
            };
            batch(() => {
              dispatch(actionCreateGlobalErrorMessage(payload));
              // explicitly trigger reset in order to get rid of stale data from redux
              dispatch(actionResetDocumentData(queryParams));
              // TODO change the global error message to Confirmation Dialog to offer user to automatically refresh the data
            });
          } else {
            dispatch(actionCreateGlobalErrorMessage(error));
          }

          reject(error);
        });
    });
  };

  // * Default post submit handler to update document step which follows normal workflow
  const handlePostSubmit = async <T extends object>(args: FormikPostSubmitArgs<T, UpdateWorkspaceDocumentApiResponse>) => {
    if (args.error) {
      Logger.console(SeverityEnum.Error, args.error.message);
      return;
    }

    const {
      response: {
        documentId,
        updatedData,
        status,
        documentWorkflow: { steps }
      }
    } = args;

    dispatch(actionUpdateDocumentDataInWorkspaceDocuments({ documentId, data: updatedData }));

    if (steps.every(step => step !== AccessOptionEnum.Review)) {
      const documentList = await fetchWorkspaceDocuments(queryParams);
      const nextDocumentTaskLink = resolveNextFinancialDocumentTaskLink(queryParams, documentList, documentId);

      if (nextDocumentTaskLink) {
        navigate(nextDocumentTaskLink);
        return;
      }
    }

    updateWorkflowStep(status);
  };

  const handleOnFormCancel = () => {
    const link = resolveWorkspaceDetailLink({ workspaceId, participantId });
    navigate(link);
  };

  const filesApi: DocumentContextData['filesApi'] = React.useMemo(
    () => ({
      async upload(files: File[]) {
        try {
          // explicitly block submit button while upload is in progress
          setNextActionDisabled(true);
          return await documentApi.uploadDocumentAttachments(files, queryParams);
        } catch (e) {
          // TODO logging
          return Promise.reject(e);
        } finally {
          // unblock it
          setNextActionDisabled(false);
        }
      },
      async download(attachmentId: string) {
        try {
          return await documentApi.downloadDocumentAttachment(attachmentId, queryParams);
        } catch (e) {
          // TODO logging
          return Promise.reject(e);
        }
      }
    }),
    [queryParams]
  );

  const documentWorkflowPanel = (
    <DocumentWorkflowPanel //
      disableStepper={isLoading}
      disableMenu={isLoading}
      {...workflowPanelProps}
      ref={workflowPanelRef}
    >
      <SympliButton //
        color="primary"
        variant="contained"
        onClick={handleSaveClick}
        arrowRight={true}
        isLoading={isLoading}
        disabled={disabled || nextActionDisabled}
      >
        {nextActionLabel}
      </SympliButton>
    </DocumentWorkflowPanel>
  );

  return (
    <DocumentContextProvider //
      isLoading={isLoading}
      disabled={disabled} // represents state of fields (except workflow panel)
      nextActionLabel={nextActionLabel}
      // next action is disabled if everything is disabled or it's explicitly stated that next action should be disabled
      // (such as we are in drag and drop mode or document upload is in progress)
      nextActionDisabled={disabled || nextActionDisabled} // represents state of next action button
      setNextActionDisabled={setNextActionDisabled} // reference to the updater function using which we can update state of next action button
      dialogPortalId={dialogPortalId}
      currentParticipant={currentParticipant}
      participants={participants}
      filesApi={filesApi}
      workspaceDocuments={workspaceDocuments}
      {...urlConfig}
    >
      {documentWorkflowPanel}

      <FocusLock
        //
        disabled={disabled}
        shards={[workflowPanelRef]}
        whiteList={node => {
          // exclude Dialog, Modal and Popover
          const nodes = Array.from(document.querySelectorAll('.MuiPickersPopper-root, .MuiDialog-root, .MuiModal-root, .MuiPopover-root, .MuiPopper-root'));
          if (nodes.some(n => n.contains(node))) return false;
          return document.getElementById('root')?.contains(node) || false;
        }}
      >
        <EditDocument
          queryParams={queryParams}
          documentDetail={documentDetail}
          jurisdictionId={jurisdictionId}
          formData={formData}
          onRenderForm={handleRenderForm}
          onSave={handleOnFormSave}
          onCancel={handleOnFormCancel}
          onPostSubmit={handlePostSubmit}
        />
      </FocusLock>
    </DocumentContextProvider>
  );
}

export default React.memo(EditDocumentContainer);
