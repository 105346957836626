import { createContextCheck } from '@sympli-mfe/document-forms-framework/utils';

import { DateOfDeathTypeEnum } from 'src/containers/documents/scaffolded-form/nsw/2-21/components/deceased-tenancy-detail/components/date-of-death/enums';
import { DateOfDeathModel } from 'src/containers/documents/scaffolded-form/nsw/2-21/components/deceased-tenancy-detail/models';

export const VISIBILITY_CHECK_DATE_OF_DEATH_DATE = createContextCheck(
  (context?: DateOfDeathModel): boolean => context !== undefined && ![DateOfDeathTypeEnum.DateRange, DateOfDeathTypeEnum.DateDescription].includes(context.type!)
);

export const VISIBILITY_CHECK_FROM_DATE_AND_TO_DATE = createContextCheck(
  (context?: DateOfDeathModel): boolean => context !== undefined && context.type === DateOfDeathTypeEnum.DateRange
);

export const VISIBILITY_CHECK_DATE_OF_DEATH_DESCRIPTION = createContextCheck(
  (context?: DateOfDeathModel): boolean => context !== undefined && context.type === DateOfDeathTypeEnum.DateDescription
);
