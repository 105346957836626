import * as yup from 'yup';

import msg from '@sympli/ui-framework/utils/messages';

import { ProfileImageModel } from 'src/containers/shared/profile-image/models';
import { REGEX_CHARACTER, yupMobileNumber as yupMobileNumberSchema } from 'src/utils/validation';
import { UserProfileForm } from './models';

const yupString = yup.string().default('').trim();

const yupStringRequired = yupString.required(msg.REQUIRED);

const nameCharacterLimit: number = 40;

const yupName = yupString
  .max(nameCharacterLimit, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(nameCharacterLimit))
  .matches(REGEX_CHARACTER, msg.CAN_ONLY_CONTAIN_LETTERS_DASHES_APOSTROPHES_AND_SPACES('name'));

const yupMobileNumber = yup.lazy((value: string) => (!value ? yupString.defined() : yupMobileNumberSchema));

export const getValidationSchema = () => {
  return yup
    .object<UserProfileForm>({
      jobTitle: yupString.max(100, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(100)).defined(),
      firstName: yupName.required(msg.REQUIRED),
      middleName: yupName.defined(),
      lastName: yupName.required(msg.REQUIRED),
      email: yupStringRequired,
      mobileNumber: yupMobileNumber,
      work: yupString.max(15, msg.LENGTH_MUST_BE_X_OR_LESS_NUMBERS(15)).defined(),
      timezone: yupStringRequired,
      avatarUrl: yupString.defined(),
      profileImage: yup
        .object<ProfileImageModel>({
          base64String: yupString.defined(),
          imageType: yupString.defined()
        })
        .defined()
    })
    .defined();
};
