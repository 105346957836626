import * as yup from 'yup';

import { NswDocumentPartyJustification } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { PartLandAffectedModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import {
  resolveTitleReferencesValidationSchema,
  yupTitleReferenceWithPartLandDescription
} from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new/validation-schema';
import { ProprietorGroupModel, TenancyDetail } from '@sympli-mfe/document-forms-framework/core/models';
import { ApiPartySourceModel } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { TitleReferenceModel } from 'src/containers/documents/scaffolded-form/shared/components/title-references/models';

export const PART_LAND_DESCRIPTION_LIMIT = 200;

const yupTitleItem = yupTitleReferenceWithPartLandDescription({ partDescriptionLimit: PART_LAND_DESCRIPTION_LIMIT, partDescriptionRequired: true });
const yupTitleReferences = resolveTitleReferencesValidationSchema<TitleReferenceModel<PartLandAffectedModel, NswDocumentPartyJustification>>(
  yup
    .object<TitleReferenceModel<PartLandAffectedModel, NswDocumentPartyJustification>>({
      ...yupTitleItem.fields,
      proprietor: yup.mixed<TenancyDetail<ProprietorGroupModel<ApiPartySourceModel<NswDocumentPartyJustification>>>>().defined()
    })
    .defined()
).defined();

export default yupTitleReferences;
