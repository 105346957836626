import * as React from 'react';

import { useFormikContext } from 'formik';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, getCharacterCountHelperText } from '@sympli-mfe/document-forms-framework/utils';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import CurrencyInputField from '@sympli/ui-framework/components/formik/currency-input-field';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { FREQUENCY_OPTIONS } from '../../enums';
import { Lease2_21_2Model, RentDetails as RentDetailsModel } from '../../models';
import { useStyles } from './styles';
import { RENT_DETAILS_DESCRIPTION_CHARACTER_LIMIT, RENT_DETAILS_TERMS_AND_CONDITIONS_CHARACTER_LIMIT } from './validationSchema';

interface Props {
  name: string;
}

function RentDetails({ name }: Props): JSX.Element {
  const classes = useStyles();
  const fieldName = createModelKeyAppender<RentDetailsModel>(name);
  const { disabled } = useDocumentContext();
  const { values } = useFormikContext<Lease2_21_2Model>();
  const { rentDetails } = values!;
  const { description, termsAndConditions } = rentDetails!;
  const descriptionCount = description?.trim().length ?? 0;
  const termsAndConditionsCount = termsAndConditions?.trim().length ?? 0;

  return (
    <Section title="Rent">
      <FormGroup title="Details" direction="row">
        <FlexLayout flexDirection="column">
          <FlexLayout flexDirection="row">
            <Field //
              name={fieldName('amount')}
              component={CurrencyInputField}
              label="Amount ($) (Optional)"
              format="number"
              disabled={disabled}
            />
            <Field //
              name={fieldName('frequency')}
              component={SelectField}
              options={FREQUENCY_OPTIONS}
              label="Frequency (Optional)"
              placeholder="Please select"
              className={classes.frequency}
              disabled={disabled}
            />
          </FlexLayout>
          <Field //
            name={fieldName('description')}
            component={InputField}
            label="Description"
            multiline
            minRows={2}
            fullWidth
            disabled={disabled}
            helperText={getCharacterCountHelperText(descriptionCount, RENT_DETAILS_DESCRIPTION_CHARACTER_LIMIT, true)}
          />

          <Field //
            name={fieldName('termsAndConditions')}
            component={InputField}
            label="Terms & conditions"
            multiline
            minRows={2}
            fullWidth
            disabled={disabled}
            helperText={getCharacterCountHelperText(termsAndConditionsCount, RENT_DETAILS_TERMS_AND_CONDITIONS_CHARACTER_LIMIT, true)}
          />
        </FlexLayout>
      </FormGroup>

      <FormGroup title="MCP Number (Optional)">
        <Field //
          name={fieldName('mcpNumber')}
          component={InputField}
          disabled={disabled}
          aria-label="mcp-number"
        />
      </FormGroup>
    </Section>
  );
}

export default React.memo(RentDetails);
