import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, resolveLabel } from '@sympli-mfe/document-forms-framework/utils';
import DatePickerField from '@sympli/ui-framework/components/formik/date-picker-field';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';

import { ClaimDetailModel } from '../../../models';
import { REQUIRED_CHECK_CLAIM_DATE, REQUIRED_CHECK_DETAILS_SUPPORTING_THE_CLAIM, VISIBILITY_CHECK_CLAIM_DATE, VISIBILITY_CHECK_DETAILS_SUPPORTING_THE_CLAIM } from '../checks';
import { useStyles } from '../styles';

export function ClaimDateAndDetails({
  //
  name,
  claim
}: {
  name: string;
  claim: ClaimDetailModel;
}) {
  const fieldName = createModelKeyAppender<ClaimDetailModel>(name);
  const classes = useStyles();
  const { disabled } = useDocumentContext();

  const claimDateIsVisible = VISIBILITY_CHECK_CLAIM_DATE(claim);
  const claimDetailsIsVisible = VISIBILITY_CHECK_DETAILS_SUPPORTING_THE_CLAIM(claim);
  const claimDateIsRequired = claimDateIsVisible && REQUIRED_CHECK_CLAIM_DATE(claim);
  const claimDetailsIsRequired = claimDetailsIsVisible && REQUIRED_CHECK_DETAILS_SUPPORTING_THE_CLAIM(claim);

  const claimDateIfPresent = claimDateIsVisible ? (
    <Field
      key="date" // Adding key as this will be rendered in a React.ReactNode[]
      disabled={disabled}
      className={classes.quarterWidth}
      label={resolveLabel('Date of interest claimed', claimDateIsRequired)}
      name={fieldName('claimDate')}
      component={DatePickerField}
      disableFuture
    />
  ) : null;

  const claimDetailsIfPresent = claimDetailsIsVisible ? (
    <Field
      key="details" // Adding key as this will be rendered in a React.ReactNode[]
      disabled={disabled}
      fullWidth
      multiline
      label={resolveLabel('Details supporting the claim', claimDetailsIsRequired)}
      name={fieldName('detailsSupportingTheClaim')}
      classes={{
        formControl: classes.multiline
      }}
      component={InputField}
    />
  ) : null;

  return claimDetailsIsRequired && !claimDateIsRequired ? (
    <>
      {claimDetailsIfPresent}
      {claimDateIfPresent}
    </>
  ) : (
    <>
      {claimDateIfPresent}
      {claimDetailsIfPresent}
    </>
  );
}
