import React from 'react';

import { DocumentStatusEnum, DocumentTypeIdentifierEnum, JurisdictionsEnum, LodgementCaseStatusEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDocumentSummaryApiResponse, WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import { LodgementCase, LodgementDetail, WorkspaceDateTimeModel } from '@sympli/api-gateway/shared';
import { MessageNotificationContainer } from '@sympli/ui-framework/components/message-notification';

import MessageNotificationV2 from 'src/components/message-notification';
import DeleteDocumentDialog from '../shared/document-list/components/delete-document-dialog';
import { DocumentLodgementDetail } from '../shared/document-list/models';
import OutsideOperationHoursMessageBox from '../workspace/registration-only/detail/components/outside-operation-hours';
import DocumentNotificationPanel from './components/document-notification-panel';
import WithdrawDialog from './components/withdraw-dialog';
import { getDocumentPrerequisite, getlodgementVerificationErrorMessage, isActionPermitted } from './helpers';
import { useDocumentIssues, useWorkflowPanel } from './hooks';
import { DocumentFormAndDetailModel, DocumentPageRouteParams, DocumentWorkflowStepsEnum } from './models';
import { DocumentMergedPdfState } from './reducers/documentMergedPdf';
import EditDocument from './views/edit-document';
import ExternalDocument from './views/external-document';
import LodgeDocument from './views/lodge-document';
import LodgedDocument from './views/lodged-document';
import ReviewDocument from './views/review-document';
import SignDocument from './views/sign-document';
import ViewDocument from './views/view-document';

interface Props {
  profileUserId: string;
  queryParams: DocumentPageRouteParams;
  workspaceDetail: {
    // workspace basic info
    jurisdictionId: JurisdictionsEnum;
    workspaceTypeId: WorkspaceTypeEnum;
    // workspace detail
    workspaceLodgementDate?: WorkspaceDateTimeModel;
    // LRS data
    // this is required only for non financial workspaces
    isValidOperatingHoursForRegOnly?: boolean;
    lodgementCases?: LodgementCase[];
    isLocked?: boolean;
    lodgementDetails?: LodgementDetail[];
  };
  detail: DocumentFormAndDetailModel;
  documentList: WorkspaceDocumentSummaryApiResponse[];
  documentMergedPdfState: DocumentMergedPdfState;
  verifying: boolean;
  currentParticipant?: WorkspaceParticipantApiResponse;
  participants?: WorkspaceParticipantApiResponse[];
  lodgementDetail?: DocumentLodgementDetail;
  lodgementCase: LodgementCase;
  isCriticalRolesEnabled: boolean;
  isDocumentCombinationEnabled: boolean;

  // workflow panel props
  workflowStep: DocumentWorkflowStepsEnum;
  isEditView: boolean;
  onStepClick: (_: React.MouseEvent<HTMLButtonElement>, targetStep: DocumentWorkflowStepsEnum) => void;
  updateWorkflowStep: (status: DocumentStatusEnum) => void;
  readOnlyViewMode: 'form' | 'pdf';
}

function DocumentsPage({
  profileUserId,
  queryParams,
  workspaceDetail: {
    //
    jurisdictionId,
    workspaceLodgementDate,
    isValidOperatingHoursForRegOnly,
    isLocked,
    workspaceTypeId,
    lodgementDetails
  },
  detail,
  documentList,
  documentMergedPdfState,
  verifying,
  currentParticipant,
  participants,
  lodgementDetail,
  lodgementCase,
  isCriticalRolesEnabled,
  isDocumentCombinationEnabled,
  // workflow panel props
  workflowStep,
  isEditView,
  readOnlyViewMode,
  onStepClick,
  updateWorkflowStep
}: Props) {
  const [openWithdrawDialog, setOpenWithdrawDialog] = React.useState(false);
  const { participantId, documentId } = queryParams;
  const {
    // documentWorkflowType,
    status,
    documentPermissions,
    documentForm: { documentType },
    parsedData,
    lodgementCaseId
  } = detail;
  const displayDocumentAsPdf = !!(currentParticipant?.displayDocumentAsPdf || DocumentTypeIdentifierEnum.LodgementInstructions === documentType);

  const documentPrerequisite = React.useMemo(() => getDocumentPrerequisite(parsedData), [parsedData]);
  const documentIds = React.useMemo(() => documentList.map(d => d.documentId), [documentList]);
  const lodgementCaseDocuments = React.useMemo(() => documentList.filter(e => e.lodgementCaseId === lodgementCase.id), [documentList, lodgementCase.id]);

  const isSignStep = workflowStep === DocumentWorkflowStepsEnum.Sign;
  const disableNextButton = !isActionPermitted(workflowStep, documentPermissions);

  // memorize all the document compliances
  useDocumentIssues(participantId, workflowStep, documentList, detail, lodgementDetail);

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const workflowPanelProps = useWorkflowPanel(
    queryParams,
    workflowStep,
    workspaceTypeId,
    detail,
    displayDocumentAsPdf,
    lodgementCase,
    lodgementCaseDocuments,
    onStepClick,
    setOpenWithdrawDialog,
    setOpenDeleteDialog,
    isCriticalRolesEnabled,
    isDocumentCombinationEnabled
  );

  function renderDocumentContent() {
    if (!workflowPanelProps) {
      return null;
    }

    switch (workflowStep) {
      case DocumentWorkflowStepsEnum.Resolve:
        return (
          <EditDocument //
            queryParams={queryParams}
            updateWorkflowStep={updateWorkflowStep}
            documentDetail={detail}
            jurisdictionId={jurisdictionId}
            currentParticipant={currentParticipant}
            participants={participants}
            numberOfDocuments={lodgementCaseDocuments.length}
            workflowPanelProps={workflowPanelProps}
          />
        );
      case DocumentWorkflowStepsEnum.Write:
        return !isEditView ? (
          <ViewDocument //
            queryParams={queryParams}
            documentDetail={detail}
            jurisdictionId={jurisdictionId}
            workflowPanelProps={workflowPanelProps}
            documentIds={documentIds}
            displayDocumentAsPdf={readOnlyViewMode === 'pdf'}
            currentParticipant={currentParticipant}
          />
        ) : (
          <EditDocument //
            queryParams={queryParams}
            updateWorkflowStep={updateWorkflowStep}
            documentDetail={detail}
            jurisdictionId={jurisdictionId}
            currentParticipant={currentParticipant}
            participants={participants}
            numberOfDocuments={lodgementCaseDocuments.length}
            workflowPanelProps={workflowPanelProps}
          />
        );
      case DocumentWorkflowStepsEnum.Review:
        return (
          <ReviewDocument
            queryParams={queryParams}
            displayDocumentAsPdf={displayDocumentAsPdf}
            documentDetail={detail}
            jurisdictionId={jurisdictionId}
            documentIds={documentIds}
            workflowPanelProps={workflowPanelProps}
          />
        );
      case DocumentWorkflowStepsEnum.Read:
        return (
          <ExternalDocument
            queryParams={queryParams}
            displayDocumentAsPdf={displayDocumentAsPdf}
            disableNextButton={disableNextButton}
            documentDetail={detail}
            jurisdictionId={jurisdictionId}
            documentMergedPdfState={documentMergedPdfState}
          />
        );
      case DocumentWorkflowStepsEnum.Sign:
        if (!lodgementCase.isPrimary && status === DocumentStatusEnum.Signed) {
          return (
            <LodgeDocument
              queryParams={queryParams}
              disableLodge={!isValidOperatingHoursForRegOnly || lodgementCase.isLocked || isLocked || currentParticipant?.id !== lodgementCase.responsibleParticipantId}
              displayDocumentAsPdf={displayDocumentAsPdf}
              disableNextButton={disableNextButton}
              documentDetail={detail}
              jurisdictionId={jurisdictionId}
              verifying={verifying}
              documentList={lodgementCaseDocuments}
              documentMergedPdfState={documentMergedPdfState}
              workflowPanelProps={workflowPanelProps}
              lodgementCaseId={lodgementCaseId}
            />
          );
        }

        return (
          <SignDocument
            queryParams={queryParams}
            displayDocumentAsPdf={displayDocumentAsPdf}
            disableNextButton={disableNextButton}
            documentDetail={detail}
            documentMergedPdfState={documentMergedPdfState}
            jurisdictionId={jurisdictionId}
            workflowPanelProps={workflowPanelProps}
          />
        );
      case DocumentWorkflowStepsEnum.Lodged:
        return (
          <LodgedDocument //
            queryParams={queryParams}
            lodgedId={lodgementDetail?.lrDocumentId}
            lodgedDate={workspaceLodgementDate}
          />
        );
      default:
        return 'Unknown document workflow step';
    }
  }

  const messages: React.ReactNode[] = [];
  /* Only show this office hour warning when signing lodge only documents and non-plc doc*/
  if (!lodgementCase.isPrimary && isSignStep && !isValidOperatingHoursForRegOnly) {
    messages.push(
      <OutsideOperationHoursMessageBox //
        jurisdictionId={jurisdictionId}
        key="1"
        style={{ top: 'auto' }}
      />
    );
  }

  if (isSignStep && lodgementCase.lodgementCaseStatusId === LodgementCaseStatusEnum.LodgementVerificationError) {
    const acknowledgementErrorCode = lodgementDetails?.find(ld => ld.lodgementCaseId === lodgementCase.id)?.acknowledgementErrorCode;
    if (acknowledgementErrorCode) {
      messages.push(
        <MessageNotificationV2 //
          variant="error"
          primary="Something went wrong."
          secondary={getlodgementVerificationErrorMessage(acknowledgementErrorCode)}
          key="2"
          style={{ top: 'auto' }}
        />
      );
    }
  }

  return (
    <>
      <DocumentNotificationPanel //
        profileUserId={profileUserId}
        currentWorkflowStep={workflowStep}
        queryParams={queryParams}
        detail={detail}
        documentType="document"
        workspaceDocuments={documentList}
        documentPrerequisite={documentPrerequisite}
      />
      {messages.length ? (
        <MessageNotificationContainer //
          className="top-[153px] z-3"
        >
          {messages.map((Message, index) => Message)}
        </MessageNotificationContainer>
      ) : null}

      {isCriticalRolesEnabled && openWithdrawDialog && currentParticipant && documentType === DocumentTypeIdentifierEnum.Transfer && (
        <WithdrawDialog workspaceRole={currentParticipant.workspaceRole.id} documentType={documentType} setOpenDialog={setOpenWithdrawDialog} />
      )}
      <DeleteDocumentDialog //
        isOpen={openDeleteDialog}
        setOpenDialog={setOpenDeleteDialog}
        workspaceTypeId={workspaceTypeId}
        documentId={documentId}
        documentType={documentType}
        documentName={detail.documentForm.name}
        lodgementCase={lodgementCase}
      />
      {renderDocumentContent()}
    </>
  );
}

export default React.memo(DocumentsPage);
