import * as React from 'react';

import { FormikProps } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { JurisdictionsEnum } from '@sympli/api-gateway/enums';
import { ExpectedSettlementDate } from '@sympli/api-gateway/shared';

import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import { useProfile } from 'src/containers/shared/auth/reducers';
import { actionFetchLinkedSettlementDetail } from 'src/containers/workspace/shared/detail/actions';
import { useLinkedSettlementWorkspaces } from 'src/containers/workspace/shared/detail/reducers/linkedSettlementDetail';
import { useWorkspaceDetail } from 'src/containers/workspace/shared/detail/reducers/workspaceDetail';
import { useFeatureFlag, useRouterParams, useSafeDispatch, useStoreSelector } from 'src/hooks';
import { RescindApprovalModel, SettlementDateFormModel } from '../../../models';
import { useSettlementDateDetails } from '../../../reducers/settlementDetail';
import { SettlementDateRouteParams } from '../../../SettlementDateContainer';
import RenderedEditForm from './RenderedEditForm';

interface Props {
  formikProps: FormikProps<SettlementDateFormModel>;
  workspaceSettlementDate?: string;
  expectedSettlementDate?: ExpectedSettlementDate;
  workspaceId: string;
  participantId: string;
  jurisdictionId: JurisdictionsEnum;
  reasonText?: string;
  declined?: boolean;
  onSettlementDateChange(newWorkspaceSettlementDate: string): void;
  settlementAcceptedByAll: boolean;
  rescindApprovalData: RescindApprovalModel;
  proposedSettlementDate?: string;
}

function RenderedEditFormContainer(props: Props) {
  const navigate = useNavigate();
  const dispatch = useSafeDispatch(useDispatch());
  const settlementEditFormState = useStoreSelector(store => store.settlementEditForm);
  const isRevokeSettlementDateApprovalEnabled: boolean = useFeatureFlag(FeatureToggleEnum.revokeSettlementDateApproval);

  const { workspaceId, participantId } = useRouterParams<SettlementDateRouteParams>();
  const settlementDateDetails = useSettlementDateDetails(workspaceId, participantId).detail;
  const clusterId = useWorkspaceDetail(workspaceId, participantId).detail?.linkedWorkspaceCluster?.clusterId;
  const linkedSettlementDetail = useLinkedSettlementWorkspaces(workspaceId, clusterId);
  const profileState = useProfile();

  React.useEffect(() => {
    if (!linkedSettlementDetail.detail && clusterId) {
      dispatch(actionFetchLinkedSettlementDetail.request({ workspaceId, clusterId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RenderedEditForm
      {...props}
      {...settlementEditFormState}
      dispatch={dispatch}
      navigate={navigate}
      isRevokeSettlementDateApprovalEnabled={isRevokeSettlementDateApprovalEnabled}
      isDateUnsupported={settlementDateDetails.isUnsupported}
      clusterId={clusterId}
      userId={profileState.data!.userId}
      subscriberId={profileState.data!.subscriberId}
    />
  );
}

export default React.memo(RenderedEditFormContainer);
