import React, { useEffect } from 'react';

import { Form, useFormikContext } from 'formik';
import _isEqual from 'lodash-es/isEqual';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import { DocumentAttachments } from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';
import FinalHoldings, { FinalTenancyHoldingsBuilder } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/final-holdings';
import { FinalTenancyHoldingsModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/final-holdings/models';
import TitleReferences from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';

import DeceasedTenancyDetail from '../../components/deceased-tenancy-detail/DeceasedTenancyDetail';
import { DeceasedTenancyDetailExtractor } from '../../components/deceased-tenancy-detail/models';
import PartyRoleCapacity from '../../components/party-role-capacity';
import { NSW_TAB_DECEASED_TENANCY_CONTEXT_CONFIG, NSW_TAB_PARTY_FORM_CONFIG, NSW_TAB_PARTY_FORM_CONFIG_WITH_NAME_CHANGE } from './config';
import { ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_OPTIONS, GRANTEE_CAPACITY_LOOKUP_OPTIONS } from './enums';
import { TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model } from './models';
import Applicants from './sections/applicants/Applicants';
import Grantees from './sections/grantees';
import { generateDeceasedTenancy } from './utils/common';
import { isDeceasedPartySelectedOnAnotherDocument } from './utils/isDeceasedPartySelectedOnAnotherDocument';

// this file was automatically generated from DocumentForm.tsx.mustache
const fieldName = modelKey<TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model>();

function RootForm({
  className, //
  hideActionsPanel,
  onSaveChanges,
  onCancel,
  queryParams: { documentId }
}: RootFormProps): JSX.Element {
  const { isLoading, disabled, nextActionLabel, workspaceDocuments } = useDocumentContext();
  const {
    values,
    setValues,
    setFieldValue,
    errors: { titleReferences: titleReferenceErrors }
  } = useFormikContext<TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model>();
  const focusOnTitleReferencesRef = React.useRef<ButtonBaseActions>(null);

  useEffect(() => {
    const focusOnFirstItem = focusOnTitleReferencesRef.current;
    if (!focusOnFirstItem) {
      return;
    }
    focusOnFirstItem.focusVisible();
  }, []);

  const handleSelectedTitleReferenceChange = React.useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, checkedIndexes: number[]) => {
      setValues((values: TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model) => generateDeceasedTenancy(values));
    },
    [setValues]
  );

  const finalTenancyHoldings: FinalTenancyHoldingsModel | undefined = React.useMemo(() => {
    const deceasedTenancyDetailExtractor = new DeceasedTenancyDetailExtractor(values.deceasedTenancyDetail /* transferrors*/, values.applicants /* transferees */);
    return FinalTenancyHoldingsBuilder.build(deceasedTenancyDetailExtractor, values.partyBook, NSW_TAB_PARTY_FORM_CONFIG);
  }, [values.applicants, values.deceasedTenancyDetail, values.partyBook]);

  const tenancyHoldingsHasChanged = !_isEqual(values.finalTenancyHoldings, finalTenancyHoldings);

  React.useEffect(() => {
    if (tenancyHoldingsHasChanged) {
      setFieldValue(fieldName('finalTenancyHoldings'), finalTenancyHoldings);
    }
  }, [setFieldValue, finalTenancyHoldings, tenancyHoldingsHasChanged]);

  const deceasedPartyWarning: string | undefined = isDeceasedPartySelectedOnAnotherDocument({
    //
    workspaceDocuments,
    documentId,
    context: values.context,
    deceasedTenancyDetail: values.deceasedTenancyDetail,
    partyBook: values.partyBook
  });

  return (
    <Form className={className}>
      <TitleReferences //
        name={fieldName('titleReferences')}
        onChange={handleSelectedTitleReferenceChange}
        skipTouchedCheck
      />

      <DeceasedTenancyDetail //
        deceasedPartyWarning={deceasedPartyWarning}
        config={NSW_TAB_DECEASED_TENANCY_CONTEXT_CONFIG}
        partyFormConfig={NSW_TAB_PARTY_FORM_CONFIG_WITH_NAME_CHANGE}
      />

      <PartyRoleCapacity //
        name={fieldName('granteeCapacity')}
        options={GRANTEE_CAPACITY_LOOKUP_OPTIONS}
        title="Grantee Capacity"
        partyRole="Grantee"
      />

      <Grantees name={fieldName('grantees')} />
      <Applicants name={fieldName('applicants')} />

      {!titleReferenceErrors && <FinalHoldings name={fieldName('finalTenancyHoldings')} />}

      <DocumentAttachments //
        name={fieldName('attachments')}
        minItems={0}
        attachmentDocumentTypes={ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_OPTIONS}
      />

      {!hideActionsPanel && (
        <DocumentActionPanel //
          isLoading={isLoading}
          disabled={disabled}
          onBack={onCancel}
          nextLabel={nextActionLabel}
          onSaveChanges={onSaveChanges}
        />
      )}
    </Form>
  );
}

export default React.memo(RootForm);
