import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    halfWidth: {
      width: '50%'
    },
    quarterWidth: {
      width: '25%'
    },
    multiline: {
      boxSizing: 'border-box',
      minHeight: 51 // two lines
    },
    marginTop: {
      marginTop: theme.spacing(1.5)
    },
    dutyAssessmentNumberTooltipList: {
      margin: '8px 0',
      paddingLeft: 16
    },
    dutyAssessmentNumberTooltipListItem: {
      lineHeight: '12px',
      '& + &': {
        paddingBottom: 4
      }
    },
    fixedWidthDropdown: {
      width: 490
    }
  }),
  {
    name: 'SectionClaimDetails'
  }
);
