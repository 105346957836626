import {
  appendShareQuantitiesToReceivingPartyGroups,
  convertShareQuantitiesToFractions
} from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving/components/transferee-group-array';
import { TenancyPartyModel as OldTenancyPartyModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/shared';
import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { TenancyPartyModel } from '@sympli-mfe/document-forms-framework/core/models';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { DataSource } from '@sympli-mfe/document-forms-framework/shared-config/common';

import PartyJustification from 'src/containers/documents/party-merge/PartyJustification';
import { getLegalEntityNameNSW } from '../../helpers';
import { fallbackMap } from './fallback';
import {
  calculateMortgagorsOnTitle,
  convertPartiesToFormModel2212,
  convertPartyBookFromFormToApiModel,
  createFormMortgagors,
  generateMortgagors,
  getPartyAdder,
  isMortgagorsResetTenancyAvailable
} from './helper';
import { IS_REQUIRED_CHECK_DOCUMENT_REFERENCE } from './isRequiredChecks';
import { ApiMortgage2_21_3Model, Mortgage2_21_3Model } from './models';

// this file was automatically generated from conversion.ts.mustache

function convertFromApiToFormModel(apiModel: ApiMortgage2_21_3Model, context: IConverterContext): Mortgage2_21_3Model {
  // Implement any necessary conversion from API to form values
  const risInvolvedPartyIds = apiModel.partyBook.filter(p => p.propertiesMappedFromRis?.length).map(p => p.id!);

  let formModel: Mortgage2_21_3Model = {
    ...apiModel,
    mortgagors: createFormMortgagors(apiModel.mortgagors), // this result may potentially be overridden under the check of "needToGeneratePartyBook" if this is the first time the document is being edited by the user.
    partyBookApi: apiModel.partyBook,
    partyBook: convertPartiesToFormModel2212(apiModel.partyBook, risInvolvedPartyIds, getPartyAdder(context.participants)),
    mortgagees: {
      // receiving
      ...apiModel.mortgagees,
      receivingProprietorGroups: appendShareQuantitiesToReceivingPartyGroups(apiModel.mortgagees.receivingProprietorGroups)
    },
    // custom ui only properties
    mortgagorsOnTitle: calculateMortgagorsOnTitle(apiModel.partyBook.filter(p => p.metadata?.source === DataSource.Title)),
    isMortgagorsResetTenancyAvailable: false
  };

  // this will be true only the first time the document is being edited by the user.
  if (apiModel.needToGeneratePartyBook) {
    formModel = generateMortgagors(formModel, getPartyAdder(context.participants));
  }
  formModel = applyDefaultMap(formModel, fallbackMap);

  // WEB-18683: The term number was not filled with the default one.
  // The following logic will check if the standard term dealing number is required or not.
  // If the user has provided one, or it provided the additional term number or uploaded documents, the standard term dealing number is not required.
  // It will fill the standard dealing term number with the default one in the user's setting only when it is empty and required.
  // The empty check is actually a safe guard just in case the require check failed to check the emptiness and overwrite the value already filled by the user.
  const termsAndConditions = formModel.termsAndConditions;
  const firstStandardTermsDealingNumber = termsAndConditions.standardTermsDealingNumbers[0];
  const originalTermNumber = firstStandardTermsDealingNumber.termNumber;
  const additionalAttachments = formModel.additionalAttachments;
  const isRequired = IS_REQUIRED_CHECK_DOCUMENT_REFERENCE(firstStandardTermsDealingNumber, termsAndConditions.additionalCovenants, additionalAttachments);

  if (isRequired && !originalTermNumber) {
    firstStandardTermsDealingNumber.termNumber = termsAndConditions.preRegisteredStandardTermsDealingNumbers?.[0] || originalTermNumber;
  }

  formModel.isMortgagorsResetTenancyAvailable = isMortgagorsResetTenancyAvailable(formModel);

  return formModel;
}

function convertFromFormToApiModel(formValues: Mortgage2_21_3Model, originalApiModel: ApiMortgage2_21_3Model): ApiMortgage2_21_3Model {
  const formModel = applyVisibilityFallbackMap(formValues, fallbackMap);
  const {
    // Pluck out any sections that
    // 1) need to be replaced, or
    // 2) are used by the form but should not be sent back to the server
    partyBook,
    partyBookApi,
    mortgagees,
    // custom ui only properties
    mortgagorsOnTitle,
    isMortgagorsResetTenancyAvailable,
    ...rest
  } = formModel;

  PartyJustification.adjustJustification(partyBook, getLegalEntityNameNSW);
  const mortgagors: OldTenancyPartyModel[] = rest.mortgagors.proprietorGroups
    .flatMap(pg => pg.parties)
    .map((p: TenancyPartyModel, i: number) => {
      const m: OldTenancyPartyModel = {
        id: i + 1 + '',
        isTransactingParty: p.isSelected,
        addressBookId: p.addressBookId,
        partyBookId: p.partyBookId,
        partyCapacity: p.partyCapacity.capacity,
        partyCapacityDetail: p.partyCapacity.details
      };
      return m;
    });
  const result: ApiMortgage2_21_3Model = {
    ...rest,
    mortgagors,
    needToGeneratePartyBook: false,
    partyBook: convertPartyBookFromFormToApiModel(partyBook, partyBookApi),
    mortgagees: {
      ...formModel.mortgagees,
      receivingProprietorGroups: convertShareQuantitiesToFractions(formModel.mortgagees.receivingProprietorGroups)
    },
    attachments: formModel.additionalAttachments
  };

  return result;
}

class Converter implements IConverter<Mortgage2_21_3Model, ApiMortgage2_21_3Model> {
  fromApiToFormModel = convertFromApiToFormModel;
  fromFormToApiModel = convertFromFormToApiModel;
}

export default new Converter();
