import * as React from 'react';

import { WorkspaceTypeEnum } from '@sympli/api-gateway/enums';

import { VerticalStatusLineProps } from 'src/containers/dashboard/shared/formatters/VerticalStatusLine';
import { useWorkspaceDetail } from 'src/containers/workspace/shared/detail/reducers/workspaceDetail';
import { useWorkspaceDocuments } from 'src/containers/workspace/shared/detail/reducers/workspaceDocuments';
import useRouterParams from 'src/hooks/useRouterParams';
import { RouteParams } from '../document-list/models';
import { resolveLodgementCaseStatus } from './conversion';
import LodgementCaseStatus from './LodgementCaseStatus';
import { LodgementCaseStatusSteps } from './models';

interface Props {
  lodgementCaseId?: string;
  isSLC?: boolean;
  showTooltip?: boolean;
  verticalStatusLineProps?: VerticalStatusLineProps;
  dataTestId?: string;
}

function LodgementCaseStatusContainer({ lodgementCaseId, verticalStatusLineProps, dataTestId }: Props) {
  const { workspaceId, participantId } = useRouterParams<RouteParams>();
  const workspaceDetail = useWorkspaceDetail(workspaceId, participantId);
  const workspaceDocumentsState = useWorkspaceDocuments(workspaceId, participantId);

  if (!(workspaceDetail.status === 'resolved' && workspaceDocumentsState.status === 'resolved')) {
    return null;
  }

  const { workspaceType, lodgementDetails, lodgementCases, workspaceStatus } = workspaceDetail.detail!;
  const lodgementCase = lodgementCases.find(x => x.id === lodgementCaseId);
  const lodgementCaseStatusId = lodgementCase?.lodgementCaseStatusId;
  if (lodgementCaseStatusId === undefined) {
    return null;
  }

  const workspaceDocuments = workspaceDocumentsState.items.filter(x => x.lodgementCaseId === lodgementCaseId);
  const isSLC = workspaceType.id !== WorkspaceTypeEnum.RegistrationOnly && !lodgementCase?.isPrimary;
  const lodgementDetail = lodgementDetails.find(x => x.lodgementCaseId === lodgementCaseId);

  const lodgementCaseStatusStatusProgress = resolveLodgementCaseStatus({
    //
    lodgementCaseStatusId,
    workspaceTypeId: workspaceType.id,
    workspaceStatusId: workspaceStatus.id,
    participantId,
    documents: workspaceDocuments,
    lodgementDetail,
    isSLC
  });

  return (
    <LodgementCaseStatus //
      currentProgress={lodgementCaseStatusStatusProgress}
      steps={LodgementCaseStatusSteps.All}
      verticalStatusLineProps={verticalStatusLineProps}
      dataTestId={dataTestId}
    />
  );
}

export default React.memo(LodgementCaseStatusContainer);
