import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { prepareReceivingTenancyDetailForApi } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving';
import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { DataSource } from '@sympli-mfe/document-forms-framework/shared-config/common';

import { fallbackMap } from './fallback';
import { calculateLessorsOnTitle, convertPartyBookFromApiToFormModel, convertPartyBookFromFormToApiModel, generateLessors } from './helpers';
import { ApiLease2_21_2Model, DatePeriod, FormDatePeriod, Lease2_21_2Model } from './models';

// this file was automatically generated from conversion.ts.mustache

class Converter implements IConverter<Lease2_21_2Model, ApiLease2_21_2Model> {
  fromApiToFormModel(apiModel: ApiLease2_21_2Model, _context: IConverterContext): Lease2_21_2Model {
    const { titleReferences, leaseDetails } = apiModel;
    // Implement any necessary conversion from API to form values

    // partyIds of lessors (that came from title)
    const partyBookFromTitle = apiModel.partyBook.filter(p => p.metadata?.source === DataSource.Title);
    const relinquishingIds = partyBookFromTitle.map(p => p.id!);
    // api lessors
    if (titleReferences.length === 1) {
      titleReferences[0].isSelected = true;
    }

    const partyBook: PartyModel[] = convertPartyBookFromApiToFormModel(apiModel.partyBook, relinquishingIds);

    let formModel: Lease2_21_2Model = {
      ...apiModel,
      partyBookApi: apiModel.partyBook,
      partyBook,
      leaseDetails: leaseDetails
        ? {
            ...leaseDetails,
            leasePeriod: this.convertDatePeriodWithoutTime(leaseDetails.leasePeriod),
            renewDetails: {
              ...leaseDetails.renewDetails,
              renewalPeriods: leaseDetails.renewDetails.optionToRenew ? leaseDetails.renewDetails.renewalPeriods.map(x => this.convertDatePeriodWithoutTime(x)) : []
            }
          }
        : undefined,
      // custom ui only properties
      lessorsOnTitle: calculateLessorsOnTitle(apiModel.partyBook.filter(p => p.metadata?.source === DataSource.Title)),
      isLessorsResetTenancyAvailable: false
    };

    if (apiModel.invalidParties) {
      formModel = generateLessors(formModel);
    }

    formModel = applyDefaultMap(formModel, fallbackMap);
    return formModel;
  }

  fromFormToApiModel(formValues: Lease2_21_2Model, _originalApiModel: ApiLease2_21_2Model): ApiLease2_21_2Model {
    const formModel = applyVisibilityFallbackMap(formValues, fallbackMap);
    const {
      // Pluck out any sections that
      // 1) need to be replaced, or
      // 2) are used by the form but should not be sent back to the server
      partyBook: partyBookFromForm,
      // ignore custom UI only properties
      lessorsOnTitle,
      isLessorsResetTenancyAvailable,
      mergeFailedReason,
      partyBookApi,
      lessees: lesseesFromForm,
      ...rest
    } = formModel;

    return {
      ...rest,
      partyBook: convertPartyBookFromFormToApiModel(partyBookFromForm, partyBookApi),
      lessees: prepareReceivingTenancyDetailForApi(lesseesFromForm),
      invalidParties: false
    };
  }

  private convertDatePeriodWithoutTime(datePeriod: DatePeriod): FormDatePeriod {
    if (!datePeriod || !datePeriod.startDate || !datePeriod.endDate) {
      return { startDate: null, endDate: null, dateLength: null };
    }

    return {
      ...datePeriod,
      startDate: new Date(new Date(datePeriod.startDate).toDateString()),
      endDate: new Date(new Date(datePeriod.endDate).toDateString())
    };
  }
}

export default new Converter();
