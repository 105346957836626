import { QldNameChange, QldPartyNameChangeConfig } from '@sympli-mfe/document-components/party-form/qld/2-24/components/party-justification';
import { QLD_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/qld/2-24/config';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import { TENANCY_COMPONENT_LABEL_CONFIG, TenancyComponentLabelConfig } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/config';
import { SHARE_TRANSFERRED_TYPE_OPTIONS, ShareTransferredTypeEnum } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/relinquishing/enums';
import { TenancyConfigContextData } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/shared/context/tenancyConfigContext';
import { restrictedLookupOptions } from '@sympli-mfe/enums-shared/helpers';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { formatNewName } from '../../helpers';

export const QLD_TRANSFER_PARTY_FORM_CONFIG: PartyFormConfig<QldNameChange> = {
  ...QLD_PARTY_FORM_CONFIG,
  nameChangeConfig: { ...QldPartyNameChangeConfig, formatNewName }
};

export const QLD_TRANSFER_RELINQUISHING_TENANCY_LABEL_CONFIG: TenancyComponentLabelConfig = { ...TENANCY_COMPONENT_LABEL_CONFIG, partyRole: 'Transferor' };
export const QLD_TRANSFER_RELINQUISHING_TENANCY_CONFIG: Partial<TenancyConfigContextData> = {
  componentLabels: QLD_TRANSFER_RELINQUISHING_TENANCY_LABEL_CONFIG,
  allowNewGroup: true
};

// TODO - Filter allowed values
export const QLD_TRANSFER_RELINQUISHING_TENANCY_SHARE_TRANSFERRED_TYPE: LookupEnumModel<ShareTransferredTypeEnum, string>[] = restrictedLookupOptions(
  SHARE_TRANSFERRED_TYPE_OPTIONS,
  {}
);
