import * as yup from 'yup';

import { FinalTenancyHoldingsModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/final-holdings/models';
import { TOTAL_SHARES_OVER_LIMIT_ERROR_MESSAGE } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/shared/components/total-shares/constants';
import { validateWhenVisible2 } from '@sympli-mfe/document-forms-framework/validation';

import { Transfer2_21_1Model } from '../../models';
import { VISIBILITY_CHECK_FINAL_TENANCY_HOLDINGS } from '../../visibilityChecks';

type FormModel = Transfer2_21_1Model;

const TOTAL_SHARE_LIMIT: number = 100000;

const yupFinalHoldings = yup.mixed<FinalTenancyHoldingsModel>().test(
  //
  'Total shares should be under limit',
  `${TOTAL_SHARES_OVER_LIMIT_ERROR_MESSAGE(TOTAL_SHARE_LIMIT)}; please check share fractions for all the parties.`,
  function test(this: yup.TestContext<FormModel>, finalTenancy: FormModel['finalTenancyHoldings']) {
    return finalTenancy ? finalTenancy.lcm === 0 || finalTenancy.lcm < TOTAL_SHARE_LIMIT : true;
  }
);

const validationSchema = validateWhenVisible2<FormModel['finalTenancyHoldings'], FormModel>({
  visibilityCheck: (parent: Transfer2_21_1Model) => VISIBILITY_CHECK_FINAL_TENANCY_HOLDINGS({ transferorsNew: parent.transferorsNew }),
  validationWhenVisible: yupFinalHoldings
});

export default validationSchema;
