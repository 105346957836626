import * as yup from 'yup';

import { resolveTitleReferencesValidationSchema } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new/validation-schema';
import { TenancyTypeEnum } from '@sympli-mfe/document-forms-framework/core/models';
import { PartyTypeEnum } from '@sympli-mfe/enums-shared/necds';

import { MergeFailedReasonsEnum } from 'src/containers/documents/party-merge/model';
import { TitleReferenceModel, TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model } from '../../models';

// const yupTitleReferenceItemSchema = yup
//   .object<TitleReferenceModel>({
//     isSelected: yup.boolean().defined(),
//     reference: yup.string(),
//     proprietor: yup.mixed()
//   })
//   .defined();

export const yupTitleReferences = resolveTitleReferencesValidationSchema<TitleReferenceModel>()
  .test(
    'Selected title with organisation proprietor validation',
    'The party type of proprietors is Organisation; such titles are not supported for the document. Please remove the mismatched titles.',
    function test(this: yup.TestContext<TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model>) {
      const { deceasedTenancyDetail, partyBook } = this.options.context!;
      if (deceasedTenancyDetail.proprietorGroups.length === 0) {
        return true;
      }

      return deceasedTenancyDetail.proprietorGroups.some(pg => pg.parties.every(p => partyBook.find(pb => pb.id === p.partyBookId)!.partyType === PartyTypeEnum.Individual));
    }
  )
  .test(
    'Multiple title selected: check for tenancy structure is same',
    'Party merge failed.',
    function test(this: yup.TestContext<TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model>) {
      const { mergeFailedReason } = this.options.context!;
      if (!mergeFailedReason) return true;

      if (mergeFailedReason === MergeFailedReasonsEnum.StructureNotSame)
        return this.createError({ message: 'The tenancy of the proprietors does not match; please remove the mismatched titles.' });

      if (mergeFailedReason === MergeFailedReasonsEnum.PartyTypeMismatch)
        return this.createError({ message: 'The party types of the proprietors do not match; please remove the mismatched titles.' });

      if (mergeFailedReason === MergeFailedReasonsEnum.ShareFractionMismatch)
        return this.createError({ message: 'The share fraction of the proprietors does not match; please remove the mismatched titles.' });

      return false;
    }
  )
  .test(
    'Selected title must not be Life Estate or Multi tenancy type',
    'The tenancy of proprietors is Multi or Life Estate; such title is not supported for the document. Please remove the mismatched titles.',
    function test(this: yup.TestContext, value: TitleReferenceModel[]) {
      const titleReferences: TitleReferenceModel[] = value ?? [];
      const selectedTitles = titleReferences.filter(({ isSelected }) => isSelected);
      return selectedTitles.every(({ proprietor }) => proprietor.tenancyType !== TenancyTypeEnum.LifeEstate && proprietor.tenancyType !== TenancyTypeEnum.Multi);
    }
  );
