import { DocumentStatusEnum } from '@sympli/api-gateway/enums';
import { AcknowledgementStatementModel, DocumentLodgementDetail, LodgementCase } from '@sympli/api-gateway/shared';

import { DocumentLodgementCompliance } from 'src/containers/shared/document-list/models';
import { SignedDocumentDataEntity } from '../shared/components/signing-provider';
import { SignedFinancialLineItem, SignedTrustAccountAuthorisationRecord, ToBeSignedDocumentCounterpart } from '../shared/components/signing-provider/helpers';
import { ErrorMessage } from './components/sign-error/SignError';

export interface DocumentMultiSignRequest {
  isLodgementRequired: boolean;
  signedDocuments: SignedDocumentDataEntity[];
  signedTrustAccountAuthorisationRecords: SignedTrustAccountAuthorisationRecord[];
  signedPayments: SignedFinancialLineItem[];
  signedSourceFunds: SignedFinancialLineItem[];
}

export interface DocumentMultiSignApiResponse {
  mergedPdfUrl: string;
  trustAccountAuthorisationRecordsXmlStrings: { [key: string]: string };
  documents: { [key: string]: ToBeSignedDocumentCounterpart[] };
  paymentXmlStrings: FinancialLineItemXml[];
  sourceFundXmlStrings: FinancialLineItemXml[];
  lodgementCaseFees: Array<LodgementCaseFeeItems>;
}

export interface LodgementCaseFeeItems {
  lodgementCaseId: string;
  feeItems: Array<FeeItem>;
}

export interface FeeItem {
  documentId: string;
  detail: string;
  type: string;
  addedBy: string;
  amount: number;
}

export type SignDocumentsViewMode = 'mergedPdf' | 'documentList';

export enum SignDocumentTypeEnum {
  Documents,
  FinancialSettlementSchedule,
  TrustAccountAuthorisationRecord,
  Payments,
  SourceFunds,
  SLCFeeBreakdown
}

export interface SignDocumentsRouteParams {
  workspaceId: string;
  participantId: string;
  returnUrl?: string;
}

export interface SignDocument {
  /**
   * identifier of the entity depending on SignDocumentTypeEnum
   * e.g. this can be documentId for documents....
   */
  id: string;
  name: string;
  type: SignDocumentTypeEnum;
  signed: boolean;
  certificationStatements: string[];
  acknowledgementStatements?: AcknowledgementStatementModel[];
  disabled: boolean;
  documentLodgementCompliances?: DocumentLodgementCompliance[];
  signingWarningMessage?: string;
  verificationErrors?: ErrorMessage[];
  totalAmount?: number;
  accountDescription?: string;
  toBeSignedByOtherSigners?: boolean;
  attachments?: DocumentAttachment[];
  documentStatus: DocumentStatusEnum;
  /**
   * we use special description text for Trust Account Authorisation Record, we might have other in the future,
   * when this prop is provided, we use it instead of other resolved text derived from documentStatus
   */
  documentDescription?: string;
  /**
   * link to where user will be navigated to in order to resolve compliance issues on the document
   * this link will be populated only if user has write permission on document
   */
  resolveIssuesLink?: string;
  isOptedOut?: boolean;
}

export interface FinancialModel {
  id: string;
  name: string;
  type: SignDocumentTypeEnum;
  signed: boolean;
  certificationStatements: string[];
  acknowledgementStatements?: AcknowledgementStatementModel[];
  disabled: boolean;
  toBeSignedByOtherSigners?: boolean;
  signingWarningMessage?: string;
  verificationErrors?: ErrorMessage[];
  totalAmount?: number;
  accountDescription?: string;
  documentStatus: DocumentStatusEnum;
  /**
   * we use special description text for Trust Account Authorisation Record, we might have other in the future,
   * when this prop is provided, we use it instead of other resolved text derived from documentStatus
   */
  documentDescription?: string;
  isOptedOut?: boolean;
}

export interface LodgementCaseDetailsModel {
  lodgementCase: LodgementCase;
  documents: DocumentModel[];
  lodgementCaseFees?: LodgementCaseFeeItems;
}

export interface DocumentModel {
  /**
   * identifier of the entity depending on SignDocumentTypeEnum
   * e.g. this can be documentId for documents....
   */
  id: string;
  name: string;
  signed: boolean;
  certificationStatements: string[];
  acknowledgementStatements?: AcknowledgementStatementModel[];
  toBeSignedByOtherSigners?: boolean;
  disabled: boolean;
  lodgementDetail?: DocumentLodgementDetail;
  signingWarningMessage?: string;
  verificationErrors?: ErrorMessage[];
  attachments?: DocumentAttachment[];
  documentStatus: DocumentStatusEnum;
  /**
   * link to where user will be navigated to in order to resolve compliance issues on the document
   * this link will be populated only if user has write permission on document
   */
  resolveIssuesLink?: string;
}

export interface DocumentAttachment {
  attachmentId: string;
  fileName: string;
}

export interface SignDocumentsFormModelMLC {
  documents: SignDocumentFormItemMLC[];
}

export interface SignDocumentFormItemMLC {
  // data: SignDocument;
  id: string;
  type: SignDocumentTypeEnum;
  toBeSignedByOtherSigners?: boolean;
  signed: boolean;
  disabled: boolean;
  isSelected: boolean;
}

export interface SignDocumentsFormModel {
  documents: SignDocumentFormItem[];
}

export interface SignDocumentFormItem {
  data: SignDocument;
  isSelected: boolean;
}

export interface FinancialLineItem {
  participantId: string;
  description: React.ReactNode;
  type: React.ReactNode;
  addedBy: string;
  amount: number;
}

export interface FinancialLineItemXml {
  id: string;
  xmlString: string;
}
