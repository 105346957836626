import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    divider: {
      marginTop: 0,
      marginBottom: 8
    },
    partyTitle: {
      fontSize: 13,
      fontWeight: 'bold',
      display: 'block'
    },
    partyDisplay: {
      marginBottom: theme.spacing(1.5),
      '&:last-child': {
        marginBottom: theme.spacing(2.5)
      }
    }
  }),
  {
    name: 'DeceasedProprietor'
  }
);
