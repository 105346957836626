import * as React from 'react';

import classNames from 'classnames';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useStyles } from './styles';

export interface ColumnHeader {
  id: string;
  name: string;
  width: number;
  uuid: string;
}

export interface FooterItem {
  width: number;
  value: string;
  uuid: string;
}

export interface GridCell {
  value: React.ReactNode;
  uuid: string;
}

export interface GridRow {
  cells: GridCell[];
  uuid: string;
}

export interface GridModel {
  title?: string;
  header: ColumnHeader[];
  footer: FooterItem[];
  rows: GridRow[];
  uuid: string;
}

interface Props {
  gridModel: GridModel;
  isOptedOut?: boolean;
}

function LineItemsGrid({ gridModel, isOptedOut }: Props) {
  const { title, header, footer, rows } = gridModel;
  const classes = useStyles();
  const columnCount = header.length;
  const gridUuid = gridModel.uuid;

  return (
    <div key={`${gridUuid}.root`} className={classNames(classes.root, isOptedOut && classes.optedOutGrid)}>
      {/* Title */}
      {title && (
        <div className={classes.gridTitle}>
          <Typography variant="body2_bold" key={`${gridUuid}.title`}>
            {title}
          </Typography>
        </div>
      )}

      {/* Grid header */}
      <Grid container key={`${gridUuid}.header`}>
        {header.map((header, colIndex) => {
          const gridClasses: string[] = [
            classes.cellSpacing,
            // Last column header black, rest grey
            colIndex === columnCount - 1 ? classes.black : classes.mediumGrey,
            // Last two columns headers right aligned, rest left aligned
            colIndex >= columnCount - 2 ? classes.alignRight : classes.alignLeft
          ];
          return (
            <Grid item xs={header.width} className={classNames(gridClasses)} key={header.uuid}>
              <Typography variant="body2_bold" key={`${header.uuid}-content`}>
                {header.name}
              </Typography>
            </Grid>
          );
        })}
      </Grid>

      {/* Grid rows */}
      {rows.map(row => {
        return (
          <React.Fragment key={`${row.uuid}.fragment`}>
            <Divider className={classes.dividerGray} key={`${row.uuid}.rowDivider`} />
            <Grid container key={`${row.uuid}.grid`}>
              {row.cells.map((cell, colIndex) => {
                const gridClasses: string[] = [
                  classes.cellSpacing,
                  // Last column black, rest grey
                  colIndex === columnCount - 1 ? classes.black : classes.darkGrey,
                  // Last two columns right aligned, rest left aligned
                  colIndex >= columnCount - 2 ? classes.alignRight : classes.alignLeft
                ];

                // Last column bold, rest normal
                const variant = colIndex === columnCount - 1 ? 'body3_bold' : 'body3';

                return (
                  <Grid item xs={header[colIndex].width} className={classNames(gridClasses)} key={cell.uuid}>
                    <Typography variant={variant} key={`${cell.uuid}-content`}>
                      {cell.value}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
          </React.Fragment>
        );
      })}

      {/* Grid footer */}
      <Divider className={classes.dividerBlack} key={`${gridUuid}.footerDivider`} />
      <Grid container key={`${gridUuid}.footer`}>
        {footer.map(item => {
          const gridClasses: string[] = [classes.cellSpacing, classes.alignRight];
          return (
            <Grid item xs={item.width} className={classNames(gridClasses)} key={item.uuid}>
              <Typography variant="subtitle3" key={`${item.uuid}-content`}>
                {item.value}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default LineItemsGrid;
