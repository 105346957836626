import { differenceInCalendarDays, isAfter, isBefore } from 'date-fns';
import * as yup from 'yup';

import { validateWhenVisible2 } from '@sympli-mfe/document-forms-framework/validation';
import { yupDatePicker } from '@sympli/ui-framework/components/formik/date-picker-field';
import msg from '@sympli/ui-framework/utils/messages';

import { DateLength, FormDatePeriod, FormRenewDetails, Lease2_21_2Model, FormLeaseDetails as LeaseDetailsModel } from '../../models';

const TODAY = new Date();
const yupDatePeriod = (isRenewalPeriod: boolean = false) =>
  yup
    // little hack to satisfy typings of yupDatePicker (Date | string | null) until we scrape support for string values
    .object<FormDatePeriod>({
      startDate: yupDatePicker //
        .required(msg.REQUIRED)
        .test(
          //
          'Start date must be after last end date',
          'Selected date requires revision.',
          function test(this: yup.TestContext<Lease2_21_2Model>, value: Date) {
            if (!isRenewalPeriod) return true;

            const lease = this.options.context!;
            const { leaseDetails } = lease;
            const {
              leasePeriod,
              renewDetails: { renewalPeriods }
            } = leaseDetails!;

            const renewPeriodIndex = renewalPeriods.findIndex(x => x.startDate === value);
            let prevEndDate = renewPeriodIndex > 0 ? renewalPeriods[renewPeriodIndex - 1].endDate : leasePeriod.endDate;
            if (prevEndDate !== null) {
              prevEndDate = typeof prevEndDate === 'string' ? new Date(prevEndDate) : prevEndDate;
              if (!isAfter(value, prevEndDate)) {
                return false;
              }
            }

            return true;
          }
        ),
      endDate: yupDatePicker //
        .required(msg.REQUIRED)
        .test(
          'End date future test', //
          'Must be future date',
          (endDate: Date) => differenceInCalendarDays(endDate, TODAY) >= 1
        )
        .test('End date should be after start date', 'Selected date requires revision.', function test(this: yup.TestContext<Lease2_21_2Model>, value: Date) {
          const lease = this.options.context!;
          const { leaseDetails } = lease;
          const {
            leasePeriod,
            renewDetails: { renewalPeriods }
          } = leaseDetails!;

          const renewPeriodIndex = renewalPeriods.findIndex(x => x.endDate === value);
          let startDate = renewPeriodIndex === -1 ? leasePeriod.startDate : renewalPeriods[renewPeriodIndex].startDate;
          if (startDate !== null) {
            startDate = typeof startDate === 'string' ? new Date(startDate) : startDate;
            if (isBefore(value, startDate)) {
              return false;
            }
          }
          return true;
        }),
      dateLength: yup.mixed<DateLength | null>()
    })
    .defined();

const yupLeaseDetails = yup
  .object<LeaseDetailsModel>({
    leasePeriod: yupDatePeriod(),
    renewDetails: yup
      .object<FormRenewDetails>({
        optionToRenew: yup.boolean(),
        renewalPeriods: validateWhenVisible2<FormDatePeriod[]>({
          visibilityCheck: (parent: FormRenewDetails) => {
            return Boolean(parent.optionToRenew);
          },
          validationWhenVisible: yup.array().of<FormDatePeriod>(yupDatePeriod(true)).defined(),
          isObjectOrArray: true,
          disableEarlyAbort: true
        })
      })
      .defined(),
    optionToPurchase: yup.boolean().nullable().required(msg.REQUIRED)
  })
  .defined();

export default yupLeaseDetails;
