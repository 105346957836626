import { useMemo } from 'react';
import * as React from 'react';

import { LinkedWorkspaceOverallStatusEnum } from '@sympli/api-gateway/enums';

import { IconLinked } from 'src/components/layout/v2/Icons';
import VerticalStatusLine from 'src/containers/dashboard/shared/formatters/VerticalStatusLine';
import useScreenSize from 'src/hooks/useScreenSize';
import { ScreenSizeVariant } from 'src/theme/screens';
import { FinancialLinkedWorkspaceStatus, resolveFinancialLinkedWorkspaceStatus } from 'src/utils/status-mapping/workspaceStatusHelper';

interface Props {
  linkedWorkspaceOverallStatus: LinkedWorkspaceOverallStatusEnum;
  extraContent?: React.ReactNode;
  extraContentClassName?: string;
  dataTestId?: string;
}

function FinancialLinkedWorkspaceStatusBadge({ linkedWorkspaceOverallStatus, extraContent, extraContentClassName, dataTestId }: Props) {
  const screenVariant: ScreenSizeVariant = useScreenSize();

  const wsStatusInfo: FinancialLinkedWorkspaceStatus | null = useMemo(() => {
    const wsStatus = resolveFinancialLinkedWorkspaceStatus(linkedWorkspaceOverallStatus);

    if (!wsStatus) {
      return null;
    }

    return { ...wsStatus };
  }, [linkedWorkspaceOverallStatus]);

  if (!wsStatusInfo) {
    return null;
  }

  const { variant, title } = wsStatusInfo;

  if (extraContent) {
    return (
      <div className="flex items-center">
        <VerticalStatusLine //
          text={
            <div className="flex items-center gap-[4px]">
              <div className="uppercase text-[var(--neutral-800)]">{title}</div>
              <IconLinked />
            </div>
          }
          dataTestId={dataTestId}
          variant={variant}
          textClassName={screenVariant === 'small-laptop' ? 'clip' : 'truncate'}
          extraContent={extraContent}
          extraContentClassName={extraContentClassName}
        />
      </div>
    );
  }

  return (
    <div className="flex items-center gap-[4px]">
      <VerticalStatusLine //
        text={title}
        variant={variant}
        dataTestId={dataTestId}
        textClassName={screenVariant === 'small-laptop' ? 'clip' : 'truncate'}
      />
      <IconLinked />
    </div>
  );
}

export default React.memo(FinancialLinkedWorkspaceStatusBadge);
