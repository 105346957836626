import { DocumentAttachmentLabelConfig } from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';
import { TENANCY_COMPONENT_LABEL_CONFIG, TenancyComponentLabelConfig } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/config';
import { CommonTenancyLabelsEnum } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/enums';

export { NSW_PARTY_FORM_CONFIG as NSW_LEASE_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/nsw/2-21/config';

export const NSW_LEASE_LESSEE_TENANCY_COMPONENT_LABEL_CONFIG: TenancyComponentLabelConfig = {
  ...TENANCY_COMPONENT_LABEL_CONFIG,
  partyRole: CommonTenancyLabelsEnum.Lessee
};

export const NSW_LEASE_LESSOR_TENANCY_COMPONENT_LABEL_CONFIG: TenancyComponentLabelConfig = {
  ...TENANCY_COMPONENT_LABEL_CONFIG,
  partyRole: 'Lessor',
  footer: { jointTenants: 'Add Lessor', tenantsInCommon: '' },
  tenancyChange: {
    warningMessage: 'The party details, such as party name, tenancy structure, do not match the title; this may cause an issue in lodgement.',
    dialogMessage: 'This will reset your party details, such as party name or tenancy structure, to match the title.'
  }
};

export const NSW_LEASE_DOCUMENT_ATTACHMENT_LABEL_CONFIG: DocumentAttachmentLabelConfig = {
  sectionTitle: 'Upload supporting document',
  uploaderTitle: 'Upload document',
  addButtonTitle: 'Add another document'
};
