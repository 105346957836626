import * as React from 'react';

import { FormikProps, getIn } from 'formik';
import _get from 'lodash-es/get';
import { Action } from 'redux';

import { PaymentMethodEnum } from '@sympli/api-gateway/enums';
import { UpdateWorkspaceDirectionsApiRequestBody } from '@sympli/api-gateway/models';
import { LinkedWorkspaceCluster } from '@sympli/api-gateway/shared';
import { BsbLookupApiResponse } from '@sympli/ui-framework/components/formik/bank-account-field';
import Logger, { BusinessLogicError } from '@sympli/ui-logger';

import { FinancialAccountApiResponse } from 'src/containers/settings/subscriber-profile/financial-accounts/models';
import { TrustAccountMap, TrustAccountOptionModel } from 'src/containers/workspace/financial/directions/models';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { createModelKeyAppender } from 'src/utils/formUtils';
import { linkedCheckFormikHelper } from '../../../../../components/direction-payee-detail/helper';
import { BankTransferDistribution, ConditionalDistributionModel } from '../../../../../components/direction-record/models';
import LineItemConfirmationDialog from '../../../../../components/lineItem-confirmation-Dialog';
import { updateTrustAccountDistributionMapping } from '../../../../../helper';
import { FinancialLineItemLabelEnum } from '../../../../../models';
import { CategoryEnum, DischargeDirectionsFormModel } from '../../../models';
import AcceptSurplusEditBankDetails from './AcceptSurplusEditBankDetails';
import AcceptSurplusSelectBankAccount from './AcceptSurplusSelectBankAccount';
import LinkedPaymentDetails from './LinkedPaymentDetails';
import LoanPayoutEditBankDetails from './LoanPayoutEditBankDetails';
import { CommonProps } from './models';
import OtherEditBankDetails from './OtherEditBankDetails';
import PrefilledAndManualEditBankDetails from './PrefilledAndManualEditBankDetails';

export interface EditBankDetailsProps {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  //form
  itemFieldName: string;
  formikProps: FormikProps<DischargeDirectionsFormModel>;
  //data
  trustAccountOptions: TrustAccountOptionModel[];
  financialAccounts?: FinancialAccountApiResponse[];
  //actions
  prePopulateBankDetails: (accountId: string, formikProps: FormikProps<DischargeDirectionsFormModel>, financialAccounts?: FinancialAccountApiResponse[]) => void;
  saveBankTransferDistribution: (
    itemFieldName: string,
    formikProps: FormikProps<DischargeDirectionsFormModel>,
    workspaceId: string,
    participantId: string,
    savingFssLineItem: boolean,
    keepDialogOpen?: boolean,
    distribution?: BankTransferDistribution
  ) => Promise<void | BsbLookupApiResponse>;
  //other
  restrictManualAccountDetailsEntryEnabled: boolean;
  workspaceId: string;
  participantId: string;
  trustAccountMap: TrustAccountMap;
  dispatch: SafeDispatch<Action>;
  workspaceClusterDetail?: LinkedWorkspaceCluster;
  setFocusLabel: (focusLabel: FinancialLineItemLabelEnum | null) => void;
  index: number;
}

function EditBankDetails(props: EditBankDetailsProps) {
  const {
    //
    isLoading,
    itemFieldName,
    formikProps,
    financialAccounts,
    trustAccountOptions,
    prePopulateBankDetails,
    saveBankTransferDistribution,
    restrictManualAccountDetailsEntryEnabled,
    workspaceId,
    participantId,
    trustAccountMap,
    dispatch,
    setFocusLabel,
    workspaceClusterDetail,
    index,
    setIsLoading
  } = props;

  const [openLineItemConfirmationDialog, setOpenLineItemConfirmationDialog] = React.useState(false);

  const disableSave = (formikProps: FormikProps<DischargeDirectionsFormModel>, itemFieldName: string) => {
    const { errors } = formikProps;

    // ! the validation schema for this part must be correct, CANNOT have redundant errors
    // ! careful about the amount field
    const error = _get(errors, itemFieldName);

    return Boolean(error);
  };

  const { values } = formikProps;

  const fieldName = createModelKeyAppender<ConditionalDistributionModel>(itemFieldName);

  const selectedCategory: string = _get(values, fieldName('category'));

  const toDisableSave = disableSave(formikProps, itemFieldName);

  const setFocusToAddNewRecord = React.useCallback(() => {
    setFocusLabel(FinancialLineItemLabelEnum.Payment);
  }, [setFocusLabel]);

  const handleOnProceed = React.useCallback(
    (noSavingFss?: boolean) => {
      const distribution: ConditionalDistributionModel = _get(formikProps.values, itemFieldName);
      const savingFssLineItem = !noSavingFss;

      switch (distribution.paymentMethod) {
        case PaymentMethodEnum.BankTransfer:
          // todo: the directionPayeeDetail has its own function for it, why?
          saveBankTransferDistribution(itemFieldName, formikProps, workspaceId, participantId, savingFssLineItem, false, distribution);
          break;
        // Comment out because this is not supported.
        // case PaymentMethodEnum.BPAY:
        //   // this is currently will never happens as it is always bank account. But in case we need to future support it.
        //   bpayCheckFormikHelper(workspaceId, participantId, itemFieldName, distribution.bpayDetails, distribution.amount, formikProps, savingFssLineItem);
        //   break;
        case PaymentMethodEnum.TrustAccount:
          // Close dialog, call save endpoint based on feature flag
          updateTrustAccountDistributionMapping(
            formikProps,
            trustAccountMap,
            distribution.bankAccountId,
            itemFieldName,
            workspaceId,
            participantId,
            dispatch,
            false,
            savingFssLineItem,
            distribution
          );
          setFocusToAddNewRecord();
          break;
        case PaymentMethodEnum.HoldingAccount:
          // if it is holding account we need to check the trustAccountMap to decide weather if it trust account or bank loan account
          const accountId = distribution.holdingAccountDetails.accountId;
          // Close dialog, call save endpoint based on feature flag
          updateTrustAccountDistributionMapping(
            //
            formikProps,
            trustAccountMap,
            accountId,
            itemFieldName,
            workspaceId,
            participantId,
            dispatch,
            false,
            savingFssLineItem,
            distribution
          );
          setFocusToAddNewRecord();
          break;
        case PaymentMethodEnum.Linked:
          linkedCheckFormikHelper(workspaceId, participantId, itemFieldName, formikProps, dispatch, true, distribution, setIsLoading);
          setFocusToAddNewRecord();
          break;
        default:
          Logger.captureException(new BusinessLogicError(`Invalid data for payment method`));
          break;
      }
    },
    [
      //
      formikProps,
      itemFieldName,
      saveBankTransferDistribution,
      trustAccountMap,
      workspaceId,
      participantId,
      dispatch,
      setFocusToAddNewRecord,
      setIsLoading
    ]
  );

  const handleOnConfirmClose = React.useCallback(
    (confirmed: boolean) => {
      setOpenLineItemConfirmationDialog(false);
      if (confirmed) {
        handleOnProceed();
      }
    },
    [setOpenLineItemConfirmationDialog, handleOnProceed]
  );

  const handleOnUpdate = React.useCallback(
    async (itemFieldName: string, formikProps: FormikProps<DischargeDirectionsFormModel>, byPassCheck?: boolean) => {
      // check if the data has been changed,
      const { touched, values } = formikProps;
      const distribution: ConditionalDistributionModel = _get(values, itemFieldName);

      // loan account will need to by pass the check... existing logic.
      if (!byPassCheck) {
        await saveBankTransferDistribution(
          itemFieldName,
          formikProps,
          workspaceId,
          participantId,
          false,
          Boolean(getIn(touched, itemFieldName)),
          distribution as BankTransferDistribution
        );
      }

      if (getIn(touched, itemFieldName)) {
        if (distribution.paymentMethod === PaymentMethodEnum.TrustAccount) {
          // Do not close dialog, do not call save endpoint. These should be done via confirmation dialog button
          updateTrustAccountDistributionMapping(formikProps, trustAccountMap, distribution.bankAccountId, itemFieldName, workspaceId, participantId, dispatch, true, false);
          // updateTrustAccountDistribution(itemFieldName, distribution.bankAccountId);
        }
        // holding account will be either trust account or bank account
        if (distribution.paymentMethod === PaymentMethodEnum.HoldingAccount) {
          const accountId = distribution.holdingAccountDetails.accountId;
          // Do not close dialog, do not call save endpoint. These should be done via confirmation dialog button
          updateTrustAccountDistributionMapping(formikProps, trustAccountMap, accountId, itemFieldName, workspaceId, participantId, dispatch, true, false);
        }

        if (distribution.paymentMethod === PaymentMethodEnum.Linked) {
          handleOnProceed();
        } else {
          setOpenLineItemConfirmationDialog(true);
        }
      } else {
        handleOnProceed(true);
      }
    },
    [
      //
      setOpenLineItemConfirmationDialog,
      handleOnProceed,
      saveBankTransferDistribution,
      trustAccountMap,
      workspaceId,
      participantId,
      dispatch
    ]
  );

  const commonProps: CommonProps = {
    itemFieldName,
    isLoading,
    formikProps,
    disableSave: toDisableSave,
    onUpdate: handleOnUpdate
  };

  function RenderContent() {
    switch (selectedCategory) {
      case CategoryEnum.LoanPayout:
        return (
          <LoanPayoutEditBankDetails //
            trustAccountOptions={trustAccountOptions}
            {...commonProps}
          />
        );
      case CategoryEnum.CustomerLoanAccount:
      case CategoryEnum.ProfessionalFees:
      case CategoryEnum.ThirdPartyBeneficiary:
        return (
          <PrefilledAndManualEditBankDetails //
            financialAccounts={financialAccounts}
            onAccountChange={prePopulateBankDetails}
            category={selectedCategory}
            {...commonProps}
          />
        );
      case CategoryEnum.Other: {
        return (
          <OtherEditBankDetails //
            {...commonProps}
          />
        );
      }
      case CategoryEnum.AcceptSurplus:
        if (restrictManualAccountDetailsEntryEnabled) {
          return (
            <AcceptSurplusSelectBankAccount //
              financialAccounts={financialAccounts}
              onAccountChange={prePopulateBankDetails}
              {...commonProps}
            />
          );
        }
        return (
          <AcceptSurplusEditBankDetails //
            onSave={handleOnUpdate}
            {...commonProps}
          />
        );
      case CategoryEnum.LinkedPayment:
        return (
          <LinkedPaymentDetails //
            {...commonProps}
            workspaceId={workspaceId}
            participantId={participantId}
            index={index}
            workspaceClusterDetail={workspaceClusterDetail}
          />
        );
      default:
        return null;
    }
  }

  function renderConfirmationDialog() {
    const item: UpdateWorkspaceDirectionsApiRequestBody['directions'][number] = _get(formikProps.values, itemFieldName);
    return <LineItemConfirmationDialog open={openLineItemConfirmationDialog} item={item} onClose={handleOnConfirmClose} />;
  }

  return (
    <>
      {renderConfirmationDialog()}
      {RenderContent()}
    </>
  );
}

export default React.memo(EditBankDetails);
