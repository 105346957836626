import * as yup from 'yup';

import { validateWhenVisible2 } from '@sympli-mfe/document-forms-framework/validation';
import * as v from '@sympli-mfe/document-forms-framework/validation/validators';
import { NecdsCountryEnum } from '@sympli-mfe/enums-2-24/necds';
import msg from '@sympli/ui-framework/utils/messages';

import { ForeignTypeEnum } from '../../enums';
import { ForeignShareholderModel, Form25_2_24_1Model } from '../../models';
import { VISIBILITY_CHECK_FOREIGN_SHAREHOLDER, VISIBILITY_CHECK_FOREIGN_SHAREHOLDER_PERCENT, VISIBILITY_CHECK_TRANSFEREE_IS_ORGANISATION } from '../../visibilityCheck';

export const yupForeignShareholders = validateWhenVisible2<ForeignShareholderModel[]>({
  validationWhenVisible: yup
    .array<ForeignShareholderModel>(
      yup
        .object<ForeignShareholderModel>({
          country: yup.mixed<NecdsCountryEnum>().required(msg.REQUIRED).oneOf(Object.values(NecdsCountryEnum), msg.REQUIRED),
          percent: validateWhenVisible2<number>({
            validationWhenVisible: v.integer().required(msg.REQUIRED).min(1, msg.VALUE_MUST_BE_AT_LEAST_X(1)),
            visibilityCheck: (_: ForeignShareholderModel[], context: Form25_2_24_1Model) => VISIBILITY_CHECK_FOREIGN_SHAREHOLDER_PERCENT(context)
          })
        })
        .defined()
    )
    .test(
      'Partial Foreign Company test',
      'Foreign percent total must be less than 100%',
      function test(this: yup.TestContext<Form25_2_24_1Model>, value: ForeignShareholderModel[]) {
        const { foreignType } = this.options.context!;
        if (foreignType === ForeignTypeEnum.WhollyForeignCompany || value.some(fs => !fs.country || !Number.isInteger(fs.percent))) {
          return true;
        }

        return value.map(fs => fs.percent).reduce((total, currentPercent) => (total += currentPercent), 0) < 100;
      }
    )
    .test('Wholly Foreign Company test', 'Foreign percent total must be 100%', function test(this: yup.TestContext<Form25_2_24_1Model>, value: ForeignShareholderModel[]) {
      const { foreignType } = this.options.context!;
      if (foreignType === ForeignTypeEnum.PartlyForeignCompany || value.length === 1 || value.some(fs => !fs.country || !Number.isInteger(fs.percent))) {
        return true;
      }

      return value.map(fs => fs.percent).reduce((total, currentPercent) => (total += currentPercent), 0) === 100;
    })
    .defined(),
  visibilityCheck: (_, context: Form25_2_24_1Model) => VISIBILITY_CHECK_TRANSFEREE_IS_ORGANISATION(context) && VISIBILITY_CHECK_FOREIGN_SHAREHOLDER(context),
  isObjectOrArray: true,
  disableEarlyAbort: true
});
