// this file was automatically generated from sections/validationSchema.ts.mustache
import * as yup from 'yup';

import { validateWhenVisible2 } from '@sympli-mfe/document-forms-framework/validation';

import { isMortgageeNameMismatch } from '../../helpers';
import { DischargeOfMortgage2_25_0Model } from '../../models';
import { VISIBILITY_CHECK_TITLE_REFERENCES_AND_MORTGAGEES } from '../../visibilityChecks';

// path: mortgagees

const yupMortgagees = validateWhenVisible2<DischargeOfMortgage2_25_0Model['mortgagees']>({
  visibilityCheck: (parentValue: DischargeOfMortgage2_25_0Model) => VISIBILITY_CHECK_TITLE_REFERENCES_AND_MORTGAGEES(parentValue),
  validationWhenVisible: yup.mixed<DischargeOfMortgage2_25_0Model['mortgagees']>().test(
    'mortgagees[*] name mismatch',
    'Mortgagee names must match. Please select Name differs to title and provide a reason for name difference.', //
    function test(this: yup.TestContext<DischargeOfMortgage2_25_0Model>, value: any) {
      const values = this.options.context!;
      return !isMortgageeNameMismatch(values);
    }
  )
});

export default yupMortgagees;
