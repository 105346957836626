import * as React from 'react';

import classNames from 'classnames';
import { FormikProps } from 'formik';
import Grid from '@mui/material/Grid';

import CurrencyInputField from '@sympli/ui-framework/components/formik/currency-input-field';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SympliButton from '@sympli/ui-framework/components/sympli-button';

import BsbNumberField from 'src/components/bsb-NumberInput';
import { FinancialAccountApiResponse } from 'src/containers/settings/subscriber-profile/financial-accounts/models';
import { BankDetailsModel } from 'src/containers/workspace/financial/directions/models';
import { createModelKeyAppender } from 'src/utils/formUtils';
import { ConditionalDistributionModel } from '../../../../../components/direction-record/models';
import SelectAccounts from '../../../../../components/select-accounts';
import { CategoryEnum, DischargeDirectionsFormModel } from '../../../models';
import { EditBankDetailsProps } from './EditBankDetails';
import { CommonProps } from './models';
import { useStyles } from './styles';

interface PrefilledAndManualEditBankDetailsProps {
  category: CategoryEnum;
  financialAccounts?: EditBankDetailsProps['financialAccounts'];
  itemFieldName: EditBankDetailsProps['itemFieldName'];
  formikProps: EditBankDetailsProps['formikProps'];
  isLoading: EditBankDetailsProps['isLoading'];
  disableSave: boolean;
  onUpdate: CommonProps['onUpdate'];
  onAccountChange: EditBankDetailsProps['prePopulateBankDetails'];
}

function PrefilledAndManualEditBankDetails(props: PrefilledAndManualEditBankDetailsProps) {
  const {
    //
    category,
    formikProps,
    itemFieldName,
    financialAccounts,
    isLoading,
    disableSave,
    onUpdate,
    onAccountChange
  } = props;

  const classes = useStyles();

  const fieldName = createModelKeyAppender<ConditionalDistributionModel>(itemFieldName);
  const bankDetailsFieldName = createModelKeyAppender<BankDetailsModel>(fieldName('bankDetails'));

  const onSelectAccountChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    formikProps: FormikProps<DischargeDirectionsFormModel>,
    financialAccounts?: FinancialAccountApiResponse[]
  ) => {
    const resolvedValue = event.target.value;

    if (resolvedValue) {
      onAccountChange(resolvedValue, formikProps, financialAccounts);
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <SelectAccounts //
            name={fieldName('prepopulateAccountId')}
            financialAccounts={financialAccounts}
            category={category}
            onChange={e => onSelectAccountChange(e, formikProps, financialAccounts)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Field //
            className={classes.fullWidth}
            label="Payee name"
            component={InputField}
            name={bankDetailsFieldName('accountName')}
          />
        </Grid>
        <Grid item xs={6}>
          <Field //
            className={classes.fullWidth}
            label="Reference (optional)"
            component={InputField}
            name={bankDetailsFieldName('reference')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <BsbNumberField name={bankDetailsFieldName('bsb')} label="BSB" className={classes.fullWidth} />
        </Grid>
        <Grid item xs={4}>
          <Field //
            className={classes.fullWidth}
            label="Account number"
            component={InputField}
            name={bankDetailsFieldName('accountNumber')}
          />
        </Grid>
        <Grid item xs={6}>
          <Field //
            label="Amount ($)"
            name={fieldName('amount')}
            component={CurrencyInputField}
            className={classNames(classes.fullWidth, classes.labelCenter)}
          />
        </Grid>
      </Grid>
      <SympliButton //
        className={classes.saveButton}
        color="primary"
        variant="contained"
        onClick={_ => onUpdate(itemFieldName, formikProps)}
        isLoading={isLoading}
        disabled={disableSave || isLoading}
      >
        Update
      </SympliButton>
    </React.Fragment>
  );
}

export default PrefilledAndManualEditBankDetails;
