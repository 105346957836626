import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { resolveUuid } from '@sympli-mfe/document-forms-framework/utils';

import { NoticeOfDeath2_21_1Model } from './models';

// this file was automatically generated from fallback.ts.mustache
export const fallbackMap: FallbackMap<NoticeOfDeath2_21_1Model> = {
  workspaceTitleReferences: NO_FALLBACK,
  relatedDocumentIds: NO_FALLBACK,
  titleReferences: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        isSelected: [false],
        reference: NO_FALLBACK,
        isPartLandAffected: NO_FALLBACK,
        landDescriptions: NO_FALLBACK,
        deceasedJointTenants: {
          $shouldAddDefaultItemToEmptyArray: true,
          $arrayItem: {
            $children: {
              isSelected: [false],
              deceasedProprietors: {
                $shouldAddDefaultItemToEmptyArray: true,
                $arrayItem: {
                  $children: {
                    deceasedProprietorId: [resolveUuid('string', 20)],
                    isAffectedProprietor: NO_FALLBACK,
                    partyBookId: [''],
                    dateOfDeath: NO_FALLBACK,
                    evidence: NO_FALLBACK
                  }
                }
              },
              tenancyType: NO_FALLBACK
            }
          }
        }
      }
    }
  },
  deceasedJointTenants: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        isSelected: [false],
        deceasedProprietors: {
          $shouldAddDefaultItemToEmptyArray: false,
          $arrayItem: {
            $children: {
              deceasedProprietorId: [resolveUuid('string', 20)],
              isAffectedProprietor: NO_FALLBACK,
              partyBookId: [''],
              dateOfDeath: NO_FALLBACK,
              evidence: NO_FALLBACK
            }
          }
        },
        tenancyType: NO_FALLBACK
      }
    }
  },
  partyBook: NO_FALLBACK,
  survivingJointTenants: {
    $shouldAddDefaultItemToEmptyArray: false,
    $arrayItem: {
      $children: {
        partyBookId: [''],
        isMakingApplication: [false]
      }
    }
  },
  // custom UI only properties
  context: NO_FALLBACK
};
