import { $noneOf } from '@sympli-mfe/document-forms-framework/utils';

import { ClaimCategoryEnum, EstateOfInterestClaimedEnum } from '../../enums';
import { ClaimDetailModel } from '../../models';

export const REQUIRED_CHECK_CLAIM_DATE = (values: ClaimDetailModel): boolean => $noneOf(values.claimCategory, [0, 1, 2, 3, 5, 4, 9, 10, 16, 17, 25]);

export const VISIBILITY_CHECK_CLAIM_DATE = (values: ClaimDetailModel): boolean =>
  values.claimCategory !== null && values.estateOrInterestClaimed !== EstateOfInterestClaimedEnum.RegisteredProprietor;

export const REQUIRED_CHECK_DETAILS_SUPPORTING_THE_CLAIM = (claimDetails: ClaimDetailModel): boolean => {
  const { claimCategory, estateOrInterestClaimed } = claimDetails!;
  return (
    estateOrInterestClaimed === EstateOfInterestClaimedEnum.RegisteredProprietor ||
    ![null, undefined, ClaimCategoryEnum.ContractForSaleOfLand, ClaimCategoryEnum.Transfer].includes(claimCategory)
  );
};

export const VISIBILITY_CHECK_DETAILS_SUPPORTING_THE_CLAIM = (claimDetails: ClaimDetailModel): boolean => {
  const estateOrInterestClaimed = claimDetails.estateOrInterestClaimed ?? null;
  return estateOrInterestClaimed !== null;
};
