import * as yup from 'yup';

import { NswDocumentPartyJustification, NswNameChange } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { PartyModel, resolveMemoizedContextBasedPartyBookValidationSchema, resolvePartyModelValidationSchema } from '@sympli-mfe/document-forms-framework/components/party-form';
import { ApiDocumentPartyModel } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { DeceasedTenancyDetailModel } from '../../components/deceased-tenancy-detail/models';
import { NSW_NOD_PARTY_FORM_CONFIG_WITH_NAME_CHANGE } from './configs';
import { NoticeOfDeath_2_21_2_Model } from './models';
import { yupDeceasedTenancyDetailSchema } from './sections/deceased-tenancy-detail/validationSchema';
import yupSurvivingJointTenants from './sections/surviving-joint-tenants/validationSchema';
import { yupTitleReferences } from './sections/title-references/validationSchema';

interface PartyBookValidationContext {
  deceasedTenancyDetail: DeceasedTenancyDetailModel;
}

const yupPartyItem: yup.ObjectSchema<PartyModel<NswNameChange>, PartyBookValidationContext> = resolvePartyModelValidationSchema<NswNameChange, PartyBookValidationContext>(
  NSW_NOD_PARTY_FORM_CONFIG_WITH_NAME_CHANGE
);

const resolveValidationContext = ({ deceasedTenancyDetail }: NoticeOfDeath_2_21_2_Model): PartyBookValidationContext => ({
  deceasedTenancyDetail
});

const checkShouldValidateItem = ({ deceasedTenancyDetail }: PartyBookValidationContext, partyIdForCheck: PartyModel['id']) => {
  const partySelectedInDeceasedTenancy = deceasedTenancyDetail.proprietorGroups.some(
    ({ parties, isSelected }) => parties.some(e => e.partyBookId === partyIdForCheck) && isSelected
  );
  return partySelectedInDeceasedTenancy;
};

const yupPartyBook = resolveMemoizedContextBasedPartyBookValidationSchema<PartyModel<NswNameChange>, PartyBookValidationContext>({
  yupPartyItem,
  checkShouldValidateItem,
  resolveValidationContext
});

export default yup
  .object<NoticeOfDeath_2_21_2_Model>()
  .partial({
    titleReferences: yupTitleReferences,
    partyBookApi: yup.array<ApiDocumentPartyModel<NswDocumentPartyJustification>>().defined(),
    deceasedTenancyDetail: yupDeceasedTenancyDetailSchema,
    survivingJointTenants: yupSurvivingJointTenants,
    partyBook: yupPartyBook
  })
  .defined()
  .log();
