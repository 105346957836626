import { Field } from 'formik';

import NumberInputField from '@sympli/ui-framework/components/formik/number-input-field';

export interface Props {
  label?: string;
  name: string;
  className?: string;
}

/**
 * @description: Used for displaying empty/success/failed states with optional actions to guide the user.
 */
function BsbNumberField(props: Props) {
  const { label = 'BSB', name, className } = props;

  return <Field label={label} component={NumberInputField} className={className} name={name} allowNegative={false} allowLeadingZeros={true} />;
}

export default BsbNumberField;
