import * as yup from 'yup';

import { ReceivingTenancyDetailModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving';
import { memoizeSchemaWithContext } from '@sympli-mfe/document-forms-framework/validation';

import { NoticeOfDeath_2_21_2_Model } from '../../models';

// path: survivingJointTenants
// If you want to use memoize, wrap your yup definition in memoizeSchema()
export const MAKING_APPLICATION_ERROR_MESSAGE: string = 'You must select at least 1 surviving tenant making application';

interface SurvivingJointTenantsContext {
  survivingJointTenants: NoticeOfDeath_2_21_2_Model['survivingJointTenants'];
}

const contextResolver = ({ survivingJointTenants }: NoticeOfDeath_2_21_2_Model): SurvivingJointTenantsContext => ({
  survivingJointTenants
});

const yupSurvivingJointTenants = yup
  .object<ReceivingTenancyDetailModel, SurvivingJointTenantsContext>()
  .test('Surviving Joint Tenant validation', 'Validate Surviving Joint Tenant', function test(this: yup.TestContext<SurvivingJointTenantsContext>) {
    const survivingJointTenants = this.options.context?.survivingJointTenants?.proprietorGroups.flatMap(pg => pg.parties) ?? [];

    if (survivingJointTenants.length < 2) {
      return true;
    }

    if (survivingJointTenants.every(s => !s.isSelected)) {
      return this.createError({ message: MAKING_APPLICATION_ERROR_MESSAGE });
    }

    return true;
  })
  .defined();

const yupSchema = memoizeSchemaWithContext(yupSurvivingJointTenants, contextResolver);
export default yupSchema;
