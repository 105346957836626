import * as yup from 'yup';

import { createPartyBookItemTestForPartyBookId } from '@sympli-mfe/document-forms-framework/components/party-form';
import {
  resolveProprietorGroupsValidationSchema,
  YUP_GENERIC_TENANCY_PARTY_BOOK_ID_REQUIRED
} from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/generic/validationSchema';
import { memoizeSchemaWithContext } from '@sympli-mfe/document-forms-framework/validation';

import PartyValidations from 'src/containers/documents/party-merge/PartyValidations';
import { getLegalEntityNameNSW } from '../../../../helpers';
import { PARTY_FORM_WITH_NAME_CHANGE_CONFIG } from '../../config';
import { Mortgage2_21_3Model } from '../../models';

type FormModel = Mortgage2_21_3Model;

interface ProprietorGroupsContext {
  partyBook: FormModel['partyBook'];
  titleReferences: FormModel['titleReferences'];
}

const yupProprietorGroups: yup.MixedSchema<FormModel['mortgagors']['proprietorGroups'], ProprietorGroupsContext> = memoizeSchemaWithContext(
  resolveProprietorGroupsValidationSchema<ProprietorGroupsContext>({
    yupPartyBookId: YUP_GENERIC_TENANCY_PARTY_BOOK_ID_REQUIRED.test(
      createPartyBookItemTestForPartyBookId(
        //
        PARTY_FORM_WITH_NAME_CHANGE_CONFIG
      )
    ),
    // turn off unnecessary validations
    yupAddressBookId: yup.mixed(),
    yupPartyCapacity: yup.mixed()
  }).testContextualRule({
    uniqueTestName: 'Mortgagors name mismatch test',
    message: 'Please be aware that the parties on the selected titles presently do not match.',
    onlyIf: (parent: never, ctx: ProprietorGroupsContext) => {
      return ctx.titleReferences.length > 1 && PartyValidations.verifyTenancyStructure(ctx.partyBook) === undefined;
    },
    requirement: (parent: never, ctx: ProprietorGroupsContext) => PartyValidations.verifyJustification(ctx.partyBook, getLegalEntityNameNSW)
  }),
  (parentContext: FormModel): ProprietorGroupsContext => {
    return {
      titleReferences: parentContext.titleReferences,
      partyBook: parentContext.partyBook // this is required here for party validation
    };
  }
);

const yupProprietorsEditable: yup.Schema<FormModel['mortgagors'], FormModel> = yup
  .object<FormModel['mortgagors']>({
    proprietorGroups: yupProprietorGroups,
    // turn off unnecessary validations
    tenancyType: yup.mixed()
  })
  .log()
  .defined();

export default yupProprietorsEditable;
