import { AddressBookEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { ReceivingTenancyDetailModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving';
import { PartLandAffectedModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { BaseAddress } from '@sympli-mfe/document-forms-framework/shared-config/address';
import { ApiDocumentPartyModel } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { MergeFailedReasonsEnum } from 'src/containers/documents/party-merge/model';
import { DeceasedTenancyPartyCapacityEnum } from 'src/containers/documents/scaffolded-form/shared/components/deceased-tenancy-detail/enums';
import { DeceasedTenancyDetailModel } from 'src/containers/documents/scaffolded-form/shared/components/deceased-tenancy-detail/models';
import { TitleReferenceModel } from '../../components/title-references/models';

export interface BaseApiTransmissionApplicationModel<TPartLandAffectedModel extends PartLandAffectedModel, TDocumentPartyJustification> {
  partyBook: ApiDocumentPartyModel<TDocumentPartyJustification>[];
  titleReferences: TitleReferenceModel<TPartLandAffectedModel, TDocumentPartyJustification>[];
  deceasedTenancyDetail: DeceasedTenancyDetailModel;
  applicantCapacity?: DeceasedTenancyPartyCapacityEnum | null;
  applicants: ReceivingTenancyDetailModel;
  applicantAddress: ApplicantAddressModel;
  invalidParties: boolean;
  addressBook: BaseAddress[];
}

export interface ApplicantAddressModel {
  addressBookId: string;
}

export const ADDRESS_BOOK_KEY = 'addressBook';
export type BaseTransmissionApplicationModel<TPartLandAffectedModel extends PartLandAffectedModel, TDocumentPartyJustification, TNameChange extends {}> = Omit<
  BaseApiTransmissionApplicationModel<TPartLandAffectedModel, TDocumentPartyJustification>,
  'partyBook' | 'addressBook'
> & {
  partyBookApi: ApiDocumentPartyModel<TDocumentPartyJustification>[];
  [ADDRESS_BOOK_KEY]: AddressBookEntityModel[];
  partyBook: PartyModel<TNameChange>[];
  mergeFailedReason?: MergeFailedReasonsEnum;
};
