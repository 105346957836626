import { LodgementCaseStatusSteps, StatusProgressModel } from 'src/containers/shared/lodgement-case-status/models';
import { NO_ADDRESS_SYNONYMS, titleAddressLine } from 'src/utils/formatters';
import { WorkspaceStatusColorVariantEnum } from 'src/utils/status-mapping/workspaceStatusHelper';
import { VerticalStatusLineVariant } from './VerticalStatusLine';

export const getVariant = (info: Pick<StatusProgressModel, 'step' | 'variant'>): 'ok' | 'warning' | 'error' => {
  return info.variant === 'success'
    ? info.step === LodgementCaseStatusSteps.Prepared || info.step === LodgementCaseStatusSteps.InPreparation // WEB-35011, the prepared and preparation status need to be yellow color.
      ? 'warning'
      : 'ok'
    : info.variant === 'failure'
      ? 'error'
      : 'warning';
};

export function getTitleAddress(address?: string): string | undefined {
  const result = titleAddressLine(address);
  const addressNotHeldText = 'ADDRESS NOT HELD';
  if (!result || NO_ADDRESS_SYNONYMS.has(result.toUpperCase())) {
    return addressNotHeldText;
  }
  return result;
}

export function convertWorkspaceStatusColorIntoVerticalStatusLineColor(colorVariant: WorkspaceStatusColorVariantEnum): VerticalStatusLineVariant {
  const verticalVariant: VerticalStatusLineVariant =
    colorVariant === WorkspaceStatusColorVariantEnum.Inactive
      ? 'neutral'
      : colorVariant === WorkspaceStatusColorVariantEnum.Success
        ? 'ok'
        : colorVariant === WorkspaceStatusColorVariantEnum.Failure
          ? 'error'
          : 'warning';

  return verticalVariant;
}
