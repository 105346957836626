import { defaultMemoize } from 'reselect';

import { NswNameChange } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { DocumentTypeIdentifierEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDocumentSummaryApiResponse } from '@sympli/api-gateway/models';

import { convertFromApiToFormModel as convertFromApiToFormModelNOD_2_21_1 } from 'src/containers/documents/scaffolded-form/nsw/2-21/notice-of-death/2-21-1/utils/convertFromApiToFormModel';
import { convertFromApiToFormModel as convertFromApiToFormModelTAB_2_21_2 } from 'src/containers/documents/scaffolded-form/nsw/2-21/transmission-application-beneficiary-devisee-next-of-kin/2-21-2/utils/convertFromApiToFormModel';
import { getOtherDocumentsOfSameTypeAndVersionParsed, getOtherDocumentsParsed } from 'src/containers/documents/scaffolded-form/shared/crossDocumentUtils';
import { resolveDeceasedPartyBookNamesOnNOD as resolveDeceasedPartyBookNamesOnNOD_2_21_1 } from '../../../notice-of-death/2-21-1/utils/resolveDeceasedPartyBookNamesOnNOD';
import { resolveDeceasedPartyBookNamesOnTAB as resolveDeceasedPartyBookNamesOnTAB_2_21_2 } from '../../../transmission-application-beneficiary-devisee-next-of-kin/2-21-2/utils/resolveDeceasedPartyBookNamesOnTAB';
import { TransmissionApplicationByExecutorAdministratorTrustee_2_21_2_Model } from '../models';
import { convertFromApiToFormModel } from './convertFromApiToFormModel';
import { resolveDeceasedPartyBookNamesOnTAE } from './resolveDeceasedPartyBookNamesOnTAE';

type FormModel = TransmissionApplicationByExecutorAdministratorTrustee_2_21_2_Model;

// explicitly memoize this call so we can call it multiple times without re-calculating
const resolveDeceasedPartyBookNamesOnCurrentDocument = defaultMemoize(
  (
    //
    deceasedTenancyDetail: FormModel['deceasedTenancyDetail'],
    partyBook: PartyModel<NswNameChange>[]
  ) => {
    return resolveDeceasedPartyBookNamesOnTAE(deceasedTenancyDetail, partyBook);
  }
);

const isDeceasedPartySelectedOnAnotherTAE = defaultMemoize(
  ({
    workspaceDocuments,
    documentId,
    context,
    deceasedTenancyDetail,
    partyBook
  }: {
    //
    workspaceDocuments: WorkspaceDocumentSummaryApiResponse[];
    /**
     * current documentId
     */
    documentId: string;
    /**
     * current form context
     */
    context: IConverterContext;
    /**
     * current form tenancyDetail
     */
    deceasedTenancyDetail: FormModel['deceasedTenancyDetail'];
    /**
     * current form partyBook
     */
    partyBook: FormModel['partyBook'];
  }): boolean => {
    // parse
    const otherDocumentsParsed: FormModel[] = getOtherDocumentsOfSameTypeAndVersionParsed(workspaceDocuments, documentId, context, convertFromApiToFormModel);
    // check
    if (otherDocumentsParsed.length) {
      const selectedPartyNamesInCurrentDocument: string[] = resolveDeceasedPartyBookNamesOnCurrentDocument(deceasedTenancyDetail, partyBook);
      const selectedPartyNamesInOtherDocuments: string[] = otherDocumentsParsed.flatMap(doc => resolveDeceasedPartyBookNamesOnTAE(doc.deceasedTenancyDetail, doc.partyBook));
      return selectedPartyNamesInCurrentDocument.some(p => selectedPartyNamesInOtherDocuments.includes(p));
    }

    return false;
  }
  //, shallowEqual
);

const isDeceasedPartySelectedOnTAB = defaultMemoize(
  ({
    workspaceDocuments,
    context,
    deceasedTenancyDetail,
    partyBook
  }: {
    //
    workspaceDocuments: WorkspaceDocumentSummaryApiResponse[];
    /**
     * current form context
     */
    context: IConverterContext;
    /**
     * current form tenancyDetail
     */
    deceasedTenancyDetail: FormModel['deceasedTenancyDetail'];
    /**
     * current form partyBook
     */
    partyBook: FormModel['partyBook'];
  }): boolean => {
    // parse NOD documents
    const tabDocumentsParsed: ReturnType<typeof convertFromApiToFormModelTAB_2_21_2>[] = getOtherDocumentsParsed(
      workspaceDocuments,
      {
        ...context,
        meta: {
          ...context.meta,
          // TODO update this to 2.21.2 once backend is updated
          schemaVersion: '2.21.1', // this version needs to match the source of convertFromApiToFormModelTAE method
          documentTypeId: DocumentTypeIdentifierEnum.TransmissionApplicationByBeneficiaryDeviseeNextOfKin
        }
      },
      convertFromApiToFormModelTAB_2_21_2
    );
    // check
    if (tabDocumentsParsed.length) {
      const selectedPartyNamesInCurrentDocument: string[] = resolveDeceasedPartyBookNamesOnCurrentDocument(deceasedTenancyDetail, partyBook);
      const selectedPartyNamesInOtherDocuments: string[] = tabDocumentsParsed.flatMap(doc => resolveDeceasedPartyBookNamesOnTAB_2_21_2(doc.deceasedTenancyDetail, doc.partyBook));
      return selectedPartyNamesInCurrentDocument.some(p => selectedPartyNamesInOtherDocuments.includes(p));
    }

    return false;
  }
  //, shallowEqual
);

const isDeceasedPartySelectedOnNOD = defaultMemoize(
  ({
    workspaceDocuments,
    context,
    deceasedTenancyDetail,
    partyBook
  }: {
    //
    workspaceDocuments: WorkspaceDocumentSummaryApiResponse[];
    /**
     * current form context
     */
    context: IConverterContext;
    /**
     * current form tenancyDetail
     */
    deceasedTenancyDetail: FormModel['deceasedTenancyDetail'];
    /**
     * current form partyBook
     */
    partyBook: FormModel['partyBook'];
  }): boolean => {
    // parse NOD documents
    const nodDocumentsParsed: ReturnType<typeof convertFromApiToFormModelNOD_2_21_1>[] = getOtherDocumentsParsed(
      workspaceDocuments,
      {
        ...context,
        meta: {
          ...context.meta,
          // TODO update this to 2.21.2 once backend is updated
          schemaVersion: '2.21.1', // this version needs to match the source of resolveDeceasedPartyBookNamesOnNOD method
          documentTypeId: DocumentTypeIdentifierEnum.NoticeOfDeath
        }
      },
      convertFromApiToFormModelNOD_2_21_1
    );
    // check
    if (nodDocumentsParsed.length) {
      const selectedPartyNamesInCurrentDocument: string[] = resolveDeceasedPartyBookNamesOnCurrentDocument(deceasedTenancyDetail, partyBook);
      const selectedPartyNamesInOtherDocuments: string[] = nodDocumentsParsed.flatMap(doc => resolveDeceasedPartyBookNamesOnNOD_2_21_1(doc.deceasedJointTenants, doc.partyBook));
      return selectedPartyNamesInCurrentDocument.some(p => selectedPartyNamesInOtherDocuments.includes(p));
    }

    return false;
  }
  //, shallowEqual
);

export const isDeceasedPartySelectedOnAnotherDocument = ({
  workspaceDocuments,
  documentId,
  context,
  deceasedTenancyDetail,
  partyBook
}: {
  //
  workspaceDocuments: WorkspaceDocumentSummaryApiResponse[];
  documentId: string;
  context: IConverterContext;
  deceasedTenancyDetail: FormModel['deceasedTenancyDetail'];
  partyBook: FormModel['partyBook'];
}): string | undefined => {
  const docNames: string[] = [];

  if (isDeceasedPartySelectedOnAnotherTAE({ workspaceDocuments, documentId, context, deceasedTenancyDetail, partyBook })) {
    docNames.push('Transmission Application');
  }

  if (docNames.length === 0 && isDeceasedPartySelectedOnTAB({ workspaceDocuments, context, deceasedTenancyDetail, partyBook })) {
    docNames.push('Transmission Application');
  }

  if (isDeceasedPartySelectedOnNOD({ workspaceDocuments, context, deceasedTenancyDetail, partyBook })) {
    docNames.push('Notice of Death');
  }

  if (docNames.length) {
    return `This party is selected on another ${docNames.join(' or ')} document in this workspace; selecting them again may cause an issue in lodgement.`;
  }
};
