import { CaveatModel_2_21_2 } from './models';

type FormValues = CaveatModel_2_21_2;

export const atLeastOneProprietorListedAsCaveator = ({
  //
  proprietors,
  caveators
}: {
  proprietors: FormValues['proprietors'];
  caveators: FormValues['caveators'];
}): boolean => {
  const proprietorPartyIds: string[] = proprietors.proprietorGroups.flatMap(pg => pg.parties.map(({ partyBookId }) => partyBookId)).filter(Boolean) as string[];
  const caveatorsPartyIds: string[] = caveators.proprietorGroups.flatMap(pg => pg.parties.map(({ partyBookId }) => partyBookId)).filter(Boolean) as string[];
  return caveatorsPartyIds.some(caveatorPartyId => proprietorPartyIds.includes(caveatorPartyId!));
};
