import * as React from 'react';

import { useFormikContext } from 'formik';
import _get from 'lodash-es/get';
import IconButton from '@mui/material/IconButton';
import { ClassNameMap } from '@mui/styles/withStyles';

import DocumentFieldArray, { DocumentAddButtonRenderProps, DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import ArrowBox from '@sympli/ui-framework/components/form/base-components/arrow-box';
import { FormGroupClassKeys } from '@sympli/ui-framework/components/form/layout/form-group';
import FormGroupFooter from '@sympli/ui-framework/components/form/layout/form-group-footer';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field';
import Field from '@sympli/ui-framework/components/formik/field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconDelete } from '@sympli/ui-framework/icons';

import { DatePeriod as DatePeriodModel, FormRenewDetails, Lease2_21_2Model, RenewDetails as RenewDetailsModel } from '../../../models';
import DatePeriod from '../date-period/DatePeriod';
import { getDateAfter } from '../helpers';
import { useStyles } from './styles';

interface Props {
  name: string;
}
const MINIMUM_RENEWAL_PERIODS = 1;
function RenewDetails({ name }: Props): JSX.Element {
  const classes = useStyles();
  const fieldName = createModelKeyAppender<RenewDetailsModel>(name);
  const { values } = useFormikContext<Lease2_21_2Model>();
  const { disabled } = useDocumentContext();
  const { leaseDetails } = values;
  const item: FormRenewDetails = _get(values, name);
  const formGroupClasses = React.useMemo<Partial<ClassNameMap<keyof ReturnType<FormGroupClassKeys>>>>(
    () => ({
      title: classes.formGroupTitle
    }),
    [classes]
  );
  const renderItem = React.useCallback(
    ({ itemBinding, itemCount, itemIndex, handleRemove, removeButtonFocusRef }: DocumentArrayItemRenderProps) => {
      const title = itemCount > 1 ? `Renew  period ${itemIndex + 1}` : 'Renew  period';
      const prevEndDate: Date | null | undefined = itemIndex === 0 ? leaseDetails?.leasePeriod.endDate : item.renewalPeriods[`${itemIndex - 1}`].endDate;
      const isShowingRemoveButton = itemCount > MINIMUM_RENEWAL_PERIODS;
      const canRemove = isShowingRemoveButton && !disabled;

      return (
        <FormGroup
          key={itemBinding}
          title={title}
          className={classes.arrowBoxContent}
          classes={formGroupClasses}
          direction="column"
          {...(isShowingRemoveButton && {
            iconPrefix: (
              <IconButton color="primary" disabled={!canRemove} onClick={handleRemove} action={removeButtonFocusRef} size="large">
                <IconDelete fill="currentColor" />
              </IconButton>
            )
          })}
        >
          <DatePeriod name={itemBinding} minStartDate={getDateAfter(prevEndDate)} />
        </FormGroup>
      );
    },
    [classes.arrowBoxContent, disabled, formGroupClasses, item.renewalPeriods, leaseDetails?.leasePeriod.endDate]
  );
  const createNewRenewalPeriod = React.useCallback((): DatePeriodModel => ({ startDate: null, endDate: null, dateLength: null }), []);
  const renderAddButton = React.useCallback(
    ({ addButtonFocusRef, onAdd }: DocumentAddButtonRenderProps) => {
      return (
        <FormGroupFooter //
          icon="add"
          onClick={onAdd}
          buttonActions={addButtonFocusRef}
          title="Add"
          className={classes.addButton}
        />
      );
    },
    [classes.addButton]
  );
  return (
    <FlexLayout flexDirection="column">
      <Field //
        name={fieldName('optionToRenew')}
        component={CheckboxField}
        label="Yes"
        disabled={disabled}
      />
      {item.optionToRenew && (
        <ArrowBox backgroundColor="darkGrey" className={classes.arrowBox}>
          <DocumentFieldArray //
            arrayBinding={fieldName('renewalPeriods')}
            itemStyle="none"
            disabled={disabled}
            renderItem={renderItem}
            minItems={MINIMUM_RENEWAL_PERIODS}
            createNewItem={createNewRenewalPeriod}
            renderAddButton={renderAddButton}
          />
        </ArrowBox>
      )}
    </FlexLayout>
  );
}
export default React.memo(RenewDetails);
