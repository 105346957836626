import React from 'react';

import { WorkspaceRoleEnum, WorkspaceStatusEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { LodgementCase } from '@sympli/api-gateway/shared';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import { Exclusive } from '@sympli/ui-framework/utils/type';

import AbandonLodgementOnlyDialog from '../abandon-lodgement-only-dialog';
import { ReassignWorkspaceGroupContainer } from '../reassign-workspace-group-container/ReassignWorkspaceGroupContainer';
import WithdrawFinancialParticipantDialogContainer from '../withdraw-financial-participant-dialog';
import { useAdditionalOptionType } from './help';
import { useStyles } from './styles';

type ManageWorkspaceBoxContainerProps = {
  // route params
  workspaceId: string;
  participantId: string;
  // redux

  workspaceStatusId: WorkspaceStatusEnum;
  lodgementCases: LodgementCase[];
} & Exclusive<
  {
    // LO ws does not need info about role
    workspaceTypeId: WorkspaceTypeEnum.RegistrationOnly;
  },
  {
    workspaceRoleId: WorkspaceRoleEnum;
    workspaceTypeId: WorkspaceTypeEnum.FinancialSettlement;
  }
>;

function ManageWorkspaceBoxContainer({
  // route params
  workspaceId,
  participantId,
  // redux
  workspaceRoleId,
  workspaceStatusId,
  workspaceTypeId,
  lodgementCases
}: ManageWorkspaceBoxContainerProps) {
  const classes = useStyles();
  const [dialogType, setDialogType] = React.useState<'reassign' | 'withdraw' | 'abandon' | null>(null);

  const additionalOptionType = useAdditionalOptionType(workspaceId, participantId, workspaceStatusId, lodgementCases);

  const ReassignOnly = React.useMemo(
    () => (
      <>
        <ul className={classes.options}>
          <li className={classes.optionItem}>
            <SympliButton //
              variant="text"
              onClick={() => {
                setDialogType('reassign');
              }}
              aria-label="Reassign workspace group"
              color="inherit"
              className={classes.optionItem}
              data-testid="reassign-workspace-group"
            >
              <FlexLayout alignItems="center">
                <span className={classes.optionText}>Reassign workspace group</span>
              </FlexLayout>
            </SympliButton>
          </li>
        </ul>
        {/* related dialogs */}
        <ReassignWorkspaceGroupContainer //
          workspaceId={workspaceId}
          participantId={participantId}
          open={dialogType === 'reassign'}
          onClose={() => setDialogType(null)}
        />
      </>
    ),
    [classes, dialogType, participantId, workspaceId]
  );

  const AllOptions = React.useMemo(() => {
    const isWithdraw = additionalOptionType === 'withdraw';
    return (
      <>
        {/* menu items */}
        <ul className={classes.options}>
          <li className={classes.optionItem}>
            <SympliButton //
              variant="text"
              onClick={() => {
                setDialogType('reassign');
              }}
              aria-label="Reassign workspace group"
              color="inherit"
              className={classes.optionItem}
              data-testid="reassign-workspace-group"
            >
              <FlexLayout alignItems="center">
                <span className={classes.optionText}>Reassign workspace group</span>
              </FlexLayout>
            </SympliButton>
          </li>
          <li className={classes.optionItem}>
            <SympliButton //
              variant="text"
              onClick={() => {
                setDialogType(isWithdraw ? 'withdraw' : 'abandon');
              }}
              aria-label={isWithdraw ? 'Withdraw from workspace' : 'Abandon workspace'}
              color="inherit"
              className={classes.optionItem}
              data-testid={isWithdraw ? 'withdraw-from-workspace' : 'abandon-workspace'}
            >
              <FlexLayout alignItems="center">
                <span className={classes.optionText}>{isWithdraw ? 'Withdraw from workspace' : 'Abandon workspace'}</span>
              </FlexLayout>
            </SympliButton>
          </li>
        </ul>

        {/* related dialogs */}
        <ReassignWorkspaceGroupContainer //
          workspaceId={workspaceId}
          participantId={participantId}
          open={dialogType === 'reassign'}
          onClose={() => setDialogType(null)}
        />

        {workspaceTypeId !== WorkspaceTypeEnum.RegistrationOnly ? (
          <WithdrawFinancialParticipantDialogContainer // financial ws allows both withdraw and abandoning
            // route params
            workspaceId={workspaceId}
            participantId={participantId}
            //redux data
            workspaceRoleId={workspaceRoleId}
            // dialog
            open={dialogType === 'withdraw' || dialogType === 'abandon'}
            onClose={() => setDialogType(null)}
            // other
            isWithdraw={isWithdraw}
          />
        ) : (
          <AbandonLodgementOnlyDialog // LO ws allows only abandon
            // route params
            workspaceId={workspaceId}
            participantId={participantId}
            // redux
            workspaceTypeId={workspaceTypeId}
            // dialog
            open={dialogType === 'abandon'}
            onClose={() => setDialogType(null)}
          />
        )}
      </>
    );
  }, [setDialogType, classes, dialogType, participantId, workspaceId, additionalOptionType, workspaceRoleId, workspaceTypeId]);

  if (additionalOptionType === 'reassignOnly') {
    return ReassignOnly;
  }

  return AllOptions;
}

export default ManageWorkspaceBoxContainer;
