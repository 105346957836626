import * as yup from 'yup';

import { validateWhenVisible2 } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { DeceasedProprietorModel, EvidenceModel } from 'src/containers/documents/scaffolded-form/nsw/2-21/components/deceased-tenancy-detail/models';
import { yupDateOfDeath } from '../date-of-death/validationSchema';
import { yupEvidence } from '../evidence/validationSchema';
import { VISIBILITY_CHECK_DATE_OF_DEATH_AND_EVIDENCE } from './visibilityChecks';

export const resolveDeceasedProprietorSchema = () =>
  yup
    .object<DeceasedProprietorModel>({
      isSelected: yup.bool(),
      partyBookId: yup.mixed(),
      dateOfDeath: validateWhenVisible2<DeceasedProprietorModel['dateOfDeath']>({
        visibilityCheck: VISIBILITY_CHECK_DATE_OF_DEATH_AND_EVIDENCE,
        validationWhenVisible: yupDateOfDeath,
        isObjectOrArray: true,
        disableEarlyAbort: true
      }),
      evidences: validateWhenVisible2({
        visibilityCheck: VISIBILITY_CHECK_DATE_OF_DEATH_AND_EVIDENCE,
        validationWhenVisible: yup.array<EvidenceModel>(yupEvidence).required(msg.REQUIRED).min(1, msg.MIN_ITEMS(1)).max(20, msg.MAX_ITEMS(20)),
        isObjectOrArray: true,
        disableEarlyAbort: true
      })
    })
    .defined();
