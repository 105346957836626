import * as React from 'react';

import { useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import { NswAddressSelectField_2_21 as AddressSelectField } from '@sympli-mfe/document-components/address-field/nsw/2-21';
import { NSW_PARTY_FORM_CONFIG as NSW_CAVEAT_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/nsw/2-21/config';
import DocumentFieldArray from '@sympli-mfe/document-forms-framework/components/document-field-array';
import PartySelectField from '@sympli-mfe/document-forms-framework/components/party-select-field';
import { TenancyPartyModel } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, getPartyOptionsExcludingSiblings } from '@sympli-mfe/document-forms-framework/utils';
import { usePartyBookOptions } from '@sympli-mfe/document-forms-framework/utils/hooks';
import { LookupItemModel } from '@sympli/ui-framework/models';

import { NSW_CAVEAT_ADDRESS_FORM_CONFIG, NSW_CAVEAT_SERVICE_OF_NOTICE_ADDRESS_FORM_INITIAL_VALUES } from '../../config';
import { ADDRESS_BOOK_KEY, CaveatModel_2_21_2, PARTY_BOOK_KEY } from '../../models';
import { resolveDefaultItem } from './models';

interface Props {
  name: string;
  focusRef?: React.RefObject<ButtonBaseActions>;
}

type FormValues = CaveatModel_2_21_2;

function SectionContactDetails(props: Props) {
  const { name, focusRef } = props;
  const { values } = useFormikContext<FormValues>();
  const { disabled, dialogPortalId } = useDocumentContext();
  const items: TenancyPartyModel[] = values.contactDetails || [];
  const partyBookOptions = usePartyBookOptions(values.partyBook, NSW_CAVEAT_PARTY_FORM_CONFIG);

  return (
    <DocumentFieldArray
      arrayBinding={name}
      createNewItem={resolveDefaultItem}
      itemTitle="Contact name"
      addButtonTitle="Add contact name"
      minItems={1}
      maxItems={2}
      disabled={disabled}
      isSimpleType={false}
      itemStyle="formGroup"
      focusRef={focusRef}
      renderItem={({ itemIndex, itemBinding, itemFocusRef, nextItemFocusRef }) => (
        <ContactItem //
          name={itemBinding}
          itemIndex={itemIndex}
          items={items}
          focusRef={itemFocusRef}
          nextItemFocusRef={nextItemFocusRef}
          disabled={disabled}
          partyBookOptions={partyBookOptions}
          dialogPortalId={dialogPortalId}
        />
      )}
    />
  );
}

interface CaveatorItemProps {
  name: string;
  disabled: boolean;
  dialogPortalId: string;
  partyBookOptions: LookupItemModel[];
  items: TenancyPartyModel[];
  itemIndex: number;
  focusRef: React.RefObject<ButtonBaseActions>;
  nextItemFocusRef?: React.RefObject<ButtonBaseActions>;
}

function ContactItem({
  //
  name,
  itemIndex,
  items,
  dialogPortalId,
  disabled,
  focusRef,
  nextItemFocusRef,
  partyBookOptions
}: CaveatorItemProps): JSX.Element {
  const filteredPartyOptions = getPartyOptionsExcludingSiblings(
    partyBookOptions,
    items.map(p => ({ partyBookId: p.partyBookId ?? null })),
    itemIndex
  );
  const fieldName = createModelKeyAppender<TenancyPartyModel>(name);

  const addressFieldFocusRef = React.useRef<ButtonBaseActions>(null);

  return (
    <>
      <PartySelectField
        name={fieldName('partyBookId')}
        partyFormConfig={NSW_CAVEAT_PARTY_FORM_CONFIG}
        disabled={disabled}
        bookRef={PARTY_BOOK_KEY}
        dialogPortalId={dialogPortalId}
        optionsOverride={filteredPartyOptions}
        focusRef={focusRef}
        nextFocusRef={addressFieldFocusRef}
      />
      <AddressSelectField
        name={fieldName('addressBookId')}
        disabled={disabled}
        bookRef={ADDRESS_BOOK_KEY}
        dialogPortalId={dialogPortalId}
        focusRef={addressFieldFocusRef}
        nextFocusRef={nextItemFocusRef}
        addressFormConfig={NSW_CAVEAT_ADDRESS_FORM_CONFIG}
        initialValuesForNew={NSW_CAVEAT_SERVICE_OF_NOTICE_ADDRESS_FORM_INITIAL_VALUES}
      />
    </>
  );
}

export default SectionContactDetails;
