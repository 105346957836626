import Typography from '@mui/material/Typography';

import { colors } from '@sympli/ui-framework/theme';
import Logger, { BusinessLogicError } from '@sympli/ui-logger';

import { StatusProgressModel } from 'src/containers/shared/lodgement-case-status/models';
import { FinancialSettlementStatusEnum, FinancialSettlementStatusSteps } from 'src/containers/workspace/financial/directions/components/settlement-directions-list/models';
import { WorkspaceStatusColorVariantEnum } from 'src/utils/status-mapping/workspaceStatusHelper';

export function resolveFinancialSettlementStepAndVariant({
  financialSettlementStatusId,
  displayInDashboardGrid
}: {
  financialSettlementStatusId: string;
  displayInDashboardGrid?: boolean;
}): Omit<StatusProgressModel, 'tooltipContent'> | null {
  switch (financialSettlementStatusId.toLowerCase()) {
    case FinancialSettlementStatusEnum.Initial:
    case FinancialSettlementStatusEnum.InPreparation:
      return {
        step: FinancialSettlementStatusSteps.InPreparation,
        variant: displayInDashboardGrid ? WorkspaceStatusColorVariantEnum.Inactive : WorkspaceStatusColorVariantEnum.Warning
      };
    case FinancialSettlementStatusEnum.Signed:
      return {
        step: FinancialSettlementStatusSteps.InPreparation,
        // based on the https://tickleme.atlassian.net/browse/WEB-30229 signed will be returned and is the only status requires red pill
        variant: WorkspaceStatusColorVariantEnum.Failure
      };
    case FinancialSettlementStatusEnum.Ready:
      return {
        step: FinancialSettlementStatusSteps.Ready,
        variant: WorkspaceStatusColorVariantEnum.Success
      };
    case FinancialSettlementStatusEnum.Settling:
      return {
        step: FinancialSettlementStatusSteps.Settling,
        variant: WorkspaceStatusColorVariantEnum.Success
      };
    case FinancialSettlementStatusEnum.Settled:
      return {
        step: FinancialSettlementStatusSteps.Settled,
        variant: WorkspaceStatusColorVariantEnum.Success
      };
    case FinancialSettlementStatusEnum.Disbursing:
      return {
        step: FinancialSettlementStatusSteps.Disbursing,
        variant: WorkspaceStatusColorVariantEnum.Success
      };
    case FinancialSettlementStatusEnum.Disbursed:
      return {
        step: FinancialSettlementStatusSteps.Disbursed,
        variant: WorkspaceStatusColorVariantEnum.Success
      };
    default: {
      const scope = Logger.scopeWithCustomAttributes({
        financialSettlementStatusId: FinancialSettlementStatusEnum[financialSettlementStatusId] || financialSettlementStatusId
      });
      Logger.captureException(new BusinessLogicError('Unmapped financial settlement status'), scope);
      return null;
    }
  }
}

export function resolveFinancialSettlementStatus({
  //
  financialSettlementStatusId
}: {
  financialSettlementStatusId: string;
}): StatusProgressModel | null {
  const fsInfo: Omit<StatusProgressModel, 'tooltipContent'> | null = resolveFinancialSettlementStepAndVariant({
    financialSettlementStatusId
  });
  if (!fsInfo) {
    return null;
  }

  const { step, variant } = fsInfo;

  switch (financialSettlementStatusId.toLowerCase()) {
    case FinancialSettlementStatusEnum.Initial:
    case FinancialSettlementStatusEnum.InPreparation: {
      return {
        step,
        variant,
        tooltipContent: (
          <>
            <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
              The workspace is <b>now active</b> for you and other participants to start collaborating.
            </Typography>

            <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
              When <b>all participants have signed</b> their Financial Settlement Schedule and the <b>funds are balanced</b> then this status will progress to 'Ready'.
            </Typography>
          </>
        )
      };
    }
    case FinancialSettlementStatusEnum.Signed: {
      return {
        step,
        variant,
        tooltipContent: (
          <>
            <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
              The financials are <b>now signed</b>.
            </Typography>

            <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
              However, the <b>funds are not balanced</b>, please review the financial line items on your Financial Settlement Schedule.
            </Typography>
          </>
        )
      };
    }
    case FinancialSettlementStatusEnum.Ready: {
      return {
        step,
        variant,
        tooltipContent: (
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            The financials are now <b>ready for settlement</b>.
          </Typography>
        )
      };
    }
    case FinancialSettlementStatusEnum.Settling: {
      return {
        step,
        variant,
        tooltipContent: (
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            <b>The settlement process has started</b> and may take 30 minutes. The statuses will update as the settlement progresses.
          </Typography>
        )
      };
    }
    case FinancialSettlementStatusEnum.Settled: {
      return {
        step,
        variant,
        tooltipContent: (
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            The matter has now <b>been settled</b>.
          </Typography>
        )
      };
    }
    case FinancialSettlementStatusEnum.Disbursing: {
      return {
        step,
        variant,
        tooltipContent: (
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            The funds in this matter are currently <b>being disbursed</b>.
          </Typography>
        )
      };
    }
    case FinancialSettlementStatusEnum.Disbursed: {
      return {
        step,
        variant,
        tooltipContent: (
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            The funds in this matter have <b>been disbursed</b>.
          </Typography>
        )
      };
    }
    default:
      return null;
  }
}
