import DocumentFormWrapper from '@sympli-mfe/document-forms-framework/components/document-form-wrapper';
import { DocumentFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { useMemoizableValidation } from '@sympli-mfe/document-forms-framework/validation';

import converter from './conversion';
import { ApiNoticeOfDeathModel_2_21_2, NoticeOfDeath_2_21_2_Model } from './models';
import RootForm from './RootForm';
import validationSchema from './validationSchema';

export default function DocumentForm(
  //
  props: DocumentFormProps<NoticeOfDeath_2_21_2_Model, ApiNoticeOfDeathModel_2_21_2>
): JSX.Element {
  const { validateDebounce } = useDocumentContext();
  const validate = useMemoizableValidation<NoticeOfDeath_2_21_2_Model>(validationSchema, validateDebounce);

  return (
    <DocumentFormWrapper //
      {...props}
      converter={converter}
      validate={validate}
      RootForm={RootForm}
      validateOnMount
    />
  );
}
