import * as React from 'react';

import { Action } from 'redux';
import { MenuItemProps } from '@mui/material/MenuItem';

import { DocumentActionTypeEnum } from '@sympli/api-gateway/enums';
import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import { UserProfileModel } from '@sympli/api-gateway/shared';

import DocumentWorkflowPanel from 'src/containers/documents/components/document-workflow-panel';
import { DocumentWorkflowStepsEnum } from 'src/containers/documents/models';
import SwitchFormView from 'src/containers/workspace/shared/components/menu-items/switch-form-view';
import UnsignPayments from 'src/containers/workspace/shared/components/menu-items/unsign-payments';
import UnsignSourceFunds from 'src/containers/workspace/shared/components/menu-items/unsign-source-funds';
import SignButton from 'src/containers/workspace/shared/components/sign-button';
import { resolveWorkflowPanelMode } from 'src/containers/workspace/shared/components/workflow-panel/helpers';
import { actionToggleCurrentParticipantDocDisplay } from 'src/containers/workspace/shared/detail/actions';
import { resolveSignDocumentsLink } from 'src/containers/workspace/sign-documents/helpers';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import DirectionsPreviewContainer from '../../components/directions-preview';
import DirectionsSummary from '../../components/directions-summary';
import SignFinancialsActionLabel from '../../components/sign-financials-action-label/SignFinancialsActionLabel';
import { havePurchaserParticipantInWorkspace } from '../../helpers';
import { DirectionCommonProps } from '../../models';
import { trustAccountOptionsSelector } from '../edit-directions/selectors';
import ReadonlyDirections from '../readonly-directions';

interface Props extends DirectionCommonProps {
  userProfile: UserProfileModel;
  workspaceParticipants: WorkspaceParticipantApiResponse[];
  requiresStampDuty: boolean;
  dispatch: SafeDispatch<Action<any>>;
}

interface State {
  isWorkflowLoading: boolean;
}

class SignDirections extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    isWorkflowLoading: false
  };

  private currentStep = DocumentWorkflowStepsEnum.Sign;

  render() {
    return (
      <>
        {this.renderDocumentStepper()}
        {this.renderDocumentContent()}
        {this.renderDirectionSummary()}
      </>
    );
  }

  private renderDocumentStepper() {
    const {
      detail: { documentSummary },
      steps,
      queryParams,
      paymentsPermissions,
      sourceFundPermissions,
      currentParticipant
    } = this.props;
    const { isWorkflowLoading } = this.state;
    const { financialActions, paymentsSummary, sourceFundsSummary } = documentSummary;
    const disableSignButton = !paymentsPermissions[this.currentStep] && !sourceFundPermissions[this.currentStep];
    const mode = resolveWorkflowPanelMode(currentParticipant.displayDocumentAsPdf);

    return (
      <DocumentWorkflowPanel //
        mode={mode}
        steps={steps}
        disableMenu={isWorkflowLoading}
        disableStepper={isWorkflowLoading}
        currentStep={this.currentStep}
        onStepClick={this.handleOnStepClick}
        menuItems={this.renderMenuList()}
      >
        <SignFinancialsActionLabel
          mode={mode}
          paymentsSummary={paymentsSummary}
          sourceFundsSummary={sourceFundsSummary}
          financialActions={financialActions.filter(action => action.type === DocumentActionTypeEnum.Sign)}
          currentParticipantId={currentParticipant.id}
        />
        <SignButton disabled={disableSignButton} href={resolveSignDocumentsLink(queryParams)}>
          Sign
        </SignButton>
      </DocumentWorkflowPanel>
    );
  }

  private renderDocumentContent() {
    const { detail, currentParticipant, workspaceParticipants, directionsCategoriesDetail, queryParams, hasManageWorkspacePermission } = this.props;
    if (detail == null) {
      return null;
    }
    const { displayDocumentAsPdf } = currentParticipant;
    if (displayDocumentAsPdf) {
      return <DirectionsPreviewContainer queryParams={queryParams} />;
    }
    return (
      <ReadonlyDirections
        workspaceParticipants={workspaceParticipants}
        detail={detail}
        currentParticipant={currentParticipant}
        directionsCategoriesDetail={directionsCategoriesDetail}
        hasManageWorkspacePermission={hasManageWorkspacePermission}
      />
    );
  }

  private renderDirectionSummary() {
    const { workspaceType, detail, currentParticipant, portalIdForDirectionSummary, requiresStampDuty, workspaceParticipants } = this.props;
    const havePurchaserParticipant = havePurchaserParticipantInWorkspace(workspaceParticipants);
    const workspaceRole = currentParticipant.workspaceRole.id;
    const { directionsSummary } = detail;
    const { trustAccountBankDetailMap } = trustAccountOptionsSelector(detail);

    return (
      <DirectionsSummary
        workspaceTypeId={workspaceType}
        workspaceRoleId={workspaceRole}
        directionsSummary={directionsSummary}
        trustAccounts={detail.trustAccounts}
        trustAccountBankDetailMap={trustAccountBankDetailMap}
        sourceFunds={detail.sourceFunds}
        portalIdForDirectionSummary={portalIdForDirectionSummary}
        requiresStampDuty={requiresStampDuty}
        havePurchaserParticipant={havePurchaserParticipant}
      />
    );
  }

  private handleOnStepClick = (e: React.MouseEvent<HTMLButtonElement>, stepValue: DocumentWorkflowStepsEnum) => {
    this.props.onStepChange && this.props.onStepChange(stepValue);
  };

  private renderMenuList = (): React.ReactElement<MenuItemProps>[] => {
    const { queryParams, paymentsPermissions, sourceFundPermissions, currentParticipant, dispatch } = this.props;
    const menuItems: React.ReactElement<MenuItemProps>[] = [];
    if (paymentsPermissions[DocumentWorkflowStepsEnum.Unsign]) {
      menuItems.push(<UnsignPayments queryParams={queryParams} setIsWorkflowLoading={this.setIsWorkflowLoading} dispatch={dispatch} />);
    }
    if (sourceFundPermissions[DocumentWorkflowStepsEnum.Unsign]) {
      menuItems.push(<UnsignSourceFunds queryParams={queryParams} setIsWorkflowLoading={this.setIsWorkflowLoading} dispatch={dispatch} />);
    }
    const { displayDocumentAsPdf } = currentParticipant;
    menuItems.push(<SwitchFormView displayDocumentAsPdf={displayDocumentAsPdf} onClick={this.handleOnViewChange} />);
    return menuItems;
  };

  private setIsWorkflowLoading = (isWorkflowLoading: boolean) => {
    this.setState({ isWorkflowLoading });
  };

  private handleOnViewChange = () => {
    const { participantId } = this.props.queryParams;
    this.props.dispatch(actionToggleCurrentParticipantDocDisplay(participantId));
  };
}

export default SignDirections;
