import * as React from 'react';

import { Form, FormikProps } from 'formik';
import * as yup from 'yup';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { WorkspaceRoleEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDirectionsApiResponse, WorkspaceDirectionsCategoriesApiResponse, WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';

import Formik from 'src/components/formik';
import { Box } from 'src/components/layout';
import FeatureToggle from 'src/containers/shared/auth/profile-feature-toggle';
import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import WorkspacePageContentWrapper from 'src/containers/workspace/shared/WorkspacePageContentWrapper';
import { modelKey } from 'src/utils/formUtils';
import DirectionHeaderBox from '../../components/direction-header-box';
import PurchaserPaysVendorBox from '../../components/purchaser-pays-vendor-box';
import { getVendorAmountDirectionDesc } from '../../helpers';
import { SourceFundFormikModel, TrustAccountMap } from '../../models';
import DirectionRecord from '../edit-directions/components/direction-record';
import FormGridHead from '../edit-directions/components/form-grid-head';
import SourceFund from '../edit-directions/components/source-fund';
import { trustAccountOptionsSelector } from '../edit-directions/selectors';
import { getReadOnlyLoanAdvanceSourceFund } from './helper';
import { ReadonlyFormikModel } from './models';
import styles, { ClassKeys } from './styles';

interface OwnProps {
  detail: WorkspaceDirectionsApiResponse;
  currentParticipant: WorkspaceParticipantApiResponse;
  workspaceParticipants: WorkspaceParticipantApiResponse[];
  directionsCategoriesDetail: WorkspaceDirectionsCategoriesApiResponse;
  hasManageWorkspacePermission: boolean;
}
type Props = OwnProps & WithStyles<ClassKeys>;

const fieldName = modelKey<ReadonlyFormikModel>();

class ReadonlyDirections extends React.PureComponent<Props> {
  private trustAccountMap: TrustAccountMap = {};

  private getInitialValues = (): ReadonlyFormikModel => {
    const { detail, hasManageWorkspacePermission } = this.props;
    const { directions, sourceFunds, purchaserPayAmount, loanAdvance, directionsSummary } = detail;

    const sourceFundsList: SourceFundFormikModel[] = sourceFunds.map(item => ({ isEditorOpen: false, ...item }));
    if (loanAdvance) {
      // display the loan advance in the source funds list
      sourceFundsList.unshift({ ...getReadOnlyLoanAdvanceSourceFund(loanAdvance, directionsSummary.autoBalancedLoanAdvanceAmount) });
    }

    return {
      distributions: directions.map(item => ({ ...item })),
      sourceFunds: sourceFundsList,
      purchaserPayAmount,
      hasManageWorkspacePermission
    };
  };

  private getValidationSchema = () => {
    return yup.mixed();
  };

  render() {
    const { detail, classes } = this.props;
    const { trustAccountBankDetailMap } = trustAccountOptionsSelector(detail);
    this.trustAccountMap = trustAccountBankDetailMap;

    return (
      <WorkspacePageContentWrapper className={undefined}>
        <Typography variant="h1" className={classes.title}>
          Financial Settlement Schedule
        </Typography>
        <Formik //
          getInitialValues={this.getInitialValues}
          validationSchema={this.getValidationSchema}
          onSubmit={() => {
            return;
          }}
        >
          {(formikProps: FormikProps<ReadonlyFormikModel>) => this.renderForm(formikProps)}
        </Formik>
      </WorkspacePageContentWrapper>
    );
  }

  private renderForm(formikProps: FormikProps<ReadonlyFormikModel>) {
    return <Form>{this.renderDisplayItems(formikProps)}</Form>;
  }

  private renderDisplayItems(formikProps: FormikProps<ReadonlyFormikModel>) {
    const { currentParticipant } = this.props;
    const workspaceRole = currentParticipant.workspaceRole.id;

    switch (workspaceRole) {
      case WorkspaceRoleEnum.Purchaser:
        return (
          <>
            {this.renderPurchaserPaysVendor()}
            {this.renderDistributionsWithAsteriskDesc(formikProps)}
            {this.renderSourceFunds(formikProps)}
          </>
        );
      case WorkspaceRoleEnum.Vendor:
        return (
          <>
            {this.renderPurchaserPaysVendor()}
            {this.renderDistributionsWithAsteriskDesc(formikProps)}
            {this.renderSourceFunds(formikProps)}
          </>
        );
      case WorkspaceRoleEnum.IncomingMortgagee:
      case WorkspaceRoleEnum.SourceFunder:
        return (
          <>
            {this.renderSourceFunds(formikProps)}
            {this.renderDistributions(formikProps)}
          </>
        );
      case WorkspaceRoleEnum.IncomingCaveator:
      case WorkspaceRoleEnum.ToDealWithAnInterest:
        return (
          <>
            {this.renderDistributions(formikProps)}
            {this.renderSourceFunds(formikProps)}
          </>
        );
      case WorkspaceRoleEnum.DischargingMortgagee:
        return (
          <>
            {this.renderDistributions(formikProps)}
            {/* // TODO-remove feature dischargeMortgageeSourceFundEnabled */}
            <FeatureToggle featureToggleType={FeatureToggleEnum.dischargeMortgageeSourceFundEnabled}>{this.renderSourceFunds(formikProps)}</FeatureToggle>
          </>
        );
      default:
        return <div />;
    }
  }
  private renderPurchaserPaysVendor() {
    return <PurchaserPaysVendorBox canEdit={false} />;
  }

  private renderDistributionsWithAsteriskDesc(formikProps: FormikProps<ReadonlyFormikModel>) {
    const { classes, currentParticipant } = this.props;
    const { distributions } = formikProps.values;
    const workspaceRole = currentParticipant.workspaceRole.id;
    const description = getVendorAmountDirectionDesc(workspaceRole, distributions);

    return (
      <DirectionHeaderBox header="Payments" description={description} className={classes.boxContainer}>
        <FeatureToggle featureToggleType={FeatureToggleEnum.breakdownLineItems} fallback={this.renderDistributionsList(formikProps, false)}>
          {this.renderDistributionsList(formikProps, true)}
        </FeatureToggle>
      </DirectionHeaderBox>
    );
  }

  private renderDistributions(formikProps: FormikProps<ReadonlyFormikModel>) {
    const { classes } = this.props;

    return (
      <DirectionHeaderBox header="Payments" className={classes.boxContainer}>
        <FeatureToggle featureToggleType={FeatureToggleEnum.breakdownLineItems} fallback={this.renderDistributionsList(formikProps, false)}>
          {this.renderDistributionsList(formikProps, true)}
        </FeatureToggle>
      </DirectionHeaderBox>
    );
  }

  private renderDistributionsList(formikProps: FormikProps<ReadonlyFormikModel>, showBreakdownsCol: boolean) {
    const { classes, workspaceParticipants } = this.props;
    const { distributions, hasManageWorkspacePermission } = formikProps.values;
    return (
      <>
        <FormGridHead paymentDetailHeader="Payee and payment details" hasBreakdownsCol={showBreakdownsCol} />
        {distributions.map((item, index) => {
          return (
            <React.Fragment key={`distribution-${index}`}>
              {index !== 0 && <Divider className={classes.divider} />}
              <DirectionRecord
                formikProps={formikProps}
                arrayFieldName={fieldName('distributions')}
                index={index}
                isEditable={false}
                trustAccountMap={this.trustAccountMap}
                allParticipants={workspaceParticipants}
                showBreakdownsCol={showBreakdownsCol}
                hasManageWorkspacePermission={hasManageWorkspacePermission}
              />
            </React.Fragment>
          );
        })}
      </>
    );
  }

  private renderSourceFunds = (formikProps: FormikProps<ReadonlyFormikModel>) => {
    const { classes, currentParticipant, detail } = this.props;
    const { sourceFunds, hasManageWorkspacePermission } = formikProps.values;

    return (
      <Box title="Source funds" className={classes.boxContainer}>
        <FormGridHead paymentDetailHeader="Account details" />
        {sourceFunds.map((item, index) => {
          const showItemDivider = index !== 0;
          return (
            <React.Fragment key={`source-funds-row-${index}`}>
              {showItemDivider && <Divider className={classes.divider} />}
              <SourceFund
                isEditable={false}
                formikProps={formikProps}
                arrayFieldName={fieldName('sourceFunds')}
                index={index}
                currentParticipant={currentParticipant}
                trustAccountMap={this.trustAccountMap}
                canSetAutoBalanceForIM={detail.canSetAutoBalanceForIM}
                hasManageWorkspacePermission={hasManageWorkspacePermission}
              />
            </React.Fragment>
          );
        })}
      </Box>
    );
  };
}

const styledComponent = withStyles(styles)(ReadonlyDirections);

export default styledComponent;
