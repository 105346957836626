import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},

    noSurvivingsMessage: {
      padding: theme.spacing(2.5),
      fontSize: 13,
      fontStyle: 'italic'
    }
  }),

  {
    name: 'SurvivingJointTenants'
  }
);
