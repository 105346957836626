import { columnFormatterCheck, ColumnsModel } from '@sympli/ui-framework/components/table';

import {
  financialWorkspaceStatusFormatter,
  jurisdictionFormatter,
  settlementTimeFormatter,
  titleFormatter,
  workspaceStatusBarFormatter
} from 'src/containers/dashboard/shared/formatters';
import { isCurrentWorkspaceFormatter, orderFormatter } from 'src/containers/workspace/shared/detail/components/linked-settlement-detail/linked-workspace-table/helpers';
import { LinkedWorkspaceTableModel } from 'src/containers/workspace/shared/detail/components/linked-settlement-detail/linked-workspace-table/models';
import { modelKey } from 'src/utils/formUtils';

const cf = columnFormatterCheck<LinkedWorkspaceTableModel>();

const fieldName = modelKey<LinkedWorkspaceTableModel>();

export function resolveTableColumns() {
  const columns: ColumnsModel<LinkedWorkspaceTableModel> = {
    isCurrent: {
      thClassName: 'w-[5px] pr-[15px]',
      label: '',
      name: fieldName('isCurrentWorkspace'),
      formatter: cf<LinkedWorkspaceTableModel['isCurrentWorkspace']>(isCurrentWorkspaceFormatter)
    },
    order: {
      thClassName: 'w-[20px]',
      label: 'WS',
      name: fieldName('order'),
      formatter: cf<LinkedWorkspaceTableModel['order']>(orderFormatter)
    },
    setlDate: {
      thClassName: 'w-[90px]',
      className: 'py-[4px]', // special padding to support 3 lines of text
      label: 'SETL DATE',
      name: fieldName('settlementDate'),
      get: ({ settlementDate, workspaceStatus, pendingProposedSettlementDate, expectedSettlementDate, isUnsupportedSettlementDate }: LinkedWorkspaceTableModel) => ({
        settlementDate,
        workspaceStatusId: workspaceStatus,
        pendingProposedSettlementDate,
        expectedSettlementDate,
        isUnsupportedSettlementDate
      }),
      formatter: cf<{
        //
        settlementDate: LinkedWorkspaceTableModel['settlementDate'];
        workspaceStatusId: LinkedWorkspaceTableModel['workspaceStatus'];
        pendingProposedSettlementDate: LinkedWorkspaceTableModel['pendingProposedSettlementDate'];
        expectedSettlementDate: LinkedWorkspaceTableModel['expectedSettlementDate'];
        isUnsupportedSettlementDate: LinkedWorkspaceTableModel['isUnsupportedSettlementDate'];
      }>(settlementTimeFormatter)
    },
    title: {
      label: 'Title',
      thClassName: 'w-[100px]',
      name: fieldName('sympliId'),
      get: ({ titles, sympliId }: LinkedWorkspaceTableModel) => ({
        titles,
        sympliId
      }),
      formatter: cf<{ titles: string[]; sympliId: string }>(titleFormatter)
    },
    jdx: {
      thClassName: 'w-[50px]',
      label: 'JDX',
      name: fieldName('jurisdiction'),
      formatter: cf<LinkedWorkspaceTableModel['jurisdiction']>(jurisdictionFormatter)
    },
    wsStatusText: {
      thClassName: 'w-[95px]',
      label: 'WS STATUS',
      name: fieldName('workspaceStatus'),
      formatter: cf<LinkedWorkspaceTableModel['workspaceStatus']>(financialWorkspaceStatusFormatter)
    },
    workspaceStatusBar: {
      thClassName: 'w-[6px]',
      label: '',
      name: fieldName('workspaceStatus'),
      padding: 'none',
      formatter: cf<LinkedWorkspaceTableModel['workspaceStatus']>(workspaceStatusBarFormatter)
    }
  };

  return columns;
}
