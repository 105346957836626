import * as yup from 'yup';

import { NswDocumentPartyJustification } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { PartLandAffectedWithDescriptionsModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { resolveTitleReferencesValidationSchema } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new/validation-schema';

import { TitleReferenceModel } from 'src/containers/documents/scaffolded-form/shared/components/title-references/models';
import { getSelectedTitleReferences } from '../../helpers';
import { CaveatModel_2_21_2 } from '../../models';

const yupTitleReferences = resolveTitleReferencesValidationSchema<TitleReferenceModel<PartLandAffectedWithDescriptionsModel, NswDocumentPartyJustification>>().test(
  //
  'Title references requires common proprietors',
  'The proprietors on the selected titles do not match. Please review the selected titles.',
  function test(this: yup.TestContext<CaveatModel_2_21_2>) {
    const { titleReferences, proprietors } = this.options.context!;
    const selectedTitleReferences = getSelectedTitleReferences(titleReferences);
    if (selectedTitleReferences.length <= 1) return true;

    return proprietors.proprietorGroups.flatMap(p => p.parties).length > 0;
  }
);
export default yupTitleReferences;
