import * as React from 'react';

import { Tenancy as OldRelinquishingTenancySection } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/relinquishing';
import { StateEnum } from '@sympli-mfe/enums-2-21/nsw';

import { NSW_TRANSFER_PARTY_FORM_CONFIG, NSW_TRANSFER_RELINQUISHING_TENANCY_CONFIG, NSW_TRANSFER_RELINQUISHING_TENANCY_SHARE_TRANFERRED_TYPE } from '../../config';

interface Props {
  name: string;
}
/**
 * @deprecated - use TransferorsNew.tsx
 */
function Transferors({ name }: Props) {
  return (
    <OldRelinquishingTenancySection //
      name={name}
      jurisdiction={StateEnum.NSW}
      partyFormConfig={NSW_TRANSFER_PARTY_FORM_CONFIG}
      componentLabels={NSW_TRANSFER_RELINQUISHING_TENANCY_CONFIG}
      shareTransferredTypeOptions={NSW_TRANSFER_RELINQUISHING_TENANCY_SHARE_TRANFERRED_TYPE}
    />
  );
}

export default React.memo(Transferors);
