import React, { useCallback } from 'react';

import { useFormikContext } from 'formik';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { PartyTypeEnum } from '@sympli-mfe/enums-2-23/necds';

import { DeceasedProprietorModel, NoticeOfDeath2_21_1Model } from '../../models';
import { DEFAULT_DEATH_OF_DATE, DEFAULT_EVIDENCE, extractSurvivingJointTenants } from '../../utils/common';
import DeceasedProprietor from '../deceased-proprietor/DeceasedProprietor';
import { useStyles } from './styles';

interface Props {
  name: string;
  className?: string;
  numberOfPartiesInPreceedingGroups: number;
}

function DeceasedProprietorReadonlyArray({ name, className, numberOfPartiesInPreceedingGroups }: Props): JSX.Element {
  const { disabled } = useDocumentContext();
  const classes = useStyles();
  const formikProps = useFormikContext<NoticeOfDeath2_21_1Model>();

  const { values, setValues } = formikProps;
  const renderItem = ({ itemBinding }: DocumentArrayItemRenderProps<DeceasedProprietorModel>) => {
    return (
      <DeceasedProprietor //
        name={itemBinding}
      />
    );
  };
  const handleOnRadioButtonChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const convertedGroups = values.deceasedJointTenants.map(group => {
        return {
          ...group,
          deceasedProprietors: group.deceasedProprietors.map(proprietor => {
            const isAffectedProprietor = proprietor.deceasedProprietorId! === event.target.value;
            const updatedProprietor: DeceasedProprietorModel = {
              ...proprietor,
              isAffectedProprietor: isAffectedProprietor,
              dateOfDeath: proprietor.dateOfDeath ? { ...proprietor.dateOfDeath } : { ...DEFAULT_DEATH_OF_DATE },
              evidence: proprietor.evidence ? { ...proprietor.evidence } : { ...DEFAULT_EVIDENCE }
            };
            return updatedProprietor;
          })
        };
      });
      setValues(values => {
        return {
          ...values,
          deceasedJointTenants: convertedGroups,
          survivingJointTenants: extractSurvivingJointTenants(convertedGroups, event.target.value)
        };
      });
    },
    [values.deceasedJointTenants, setValues]
  );

  const renderWorkflowRadioButton = useCallback(
    (deceasedProprietorId: string, labelName: string, disable: boolean, checked: boolean | undefined) => {
      return (
        <FormControlLabel
          className={classes.radioButtonRoot}
          classes={{ label: classes.radioButtonLabel }}
          key={deceasedProprietorId}
          value={deceasedProprietorId}
          control={<Radio color="primary" aria-label={`Select ${labelName}`} />}
          label={labelName}
          disabled={disable}
          onChange={handleOnRadioButtonChange}
          checked={checked}
        />
      );
    },
    [classes.radioButtonLabel, classes.radioButtonRoot, handleOnRadioButtonChange]
  );

  const renderItemTitle = useCallback(
    ({ itemIndex, item }: DocumentArrayItemRenderProps<DeceasedProprietorModel>) => {
      const label = `Joint tenant ${numberOfPartiesInPreceedingGroups + itemIndex + 1}`;
      const { values } = formikProps;
      const partyBook: PartyModel[] = values.partyBook ?? [];
      const disable = partyBook.find(party => party.id === item.partyBookId)?.partyType === PartyTypeEnum.Organisation;
      return renderWorkflowRadioButton(item.deceasedProprietorId!, label, disable, item.isAffectedProprietor);
    },
    [formikProps, numberOfPartiesInPreceedingGroups, renderWorkflowRadioButton]
  );

  return (
    <div data-section={name} className={className}>
      <DocumentFieldArray //
        arrayBinding={name}
        renderItem={renderItem}
        itemTitle={renderItemTitle}
        disabled={disabled}
        itemStyle="formGroup"
        mode="fixed"
      />
    </div>
  );
}

export default React.memo(DeceasedProprietorReadonlyArray);
