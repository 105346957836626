import { WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDirectionsApiResponse, WorkspaceDirectionsCategoriesApiResponse, WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';

import { WorkspaceDetailRouteParams } from 'src/pages/workspace/detail/WorkspaceDetailPageContainer';

export enum FinancialLineItemLabelEnum {
  Payment = 'destination',
  SourceFund = 'source fund'
}

export enum UnlinkLineItemTypeEnum {
  UnlinkPayment = 0,
  UnlinkSourceFund = 1,
  Other = 2
}

export interface EditDirectionCommonProps {
  canEdit: boolean;
  queryParams: WorkspaceDetailRouteParams;
  detail: WorkspaceDirectionsApiResponse;
  currentParticipant: WorkspaceParticipantApiResponse;
  workspaceType: WorkspaceTypeEnum;
  directionsCategoriesDetail: WorkspaceDirectionsCategoriesApiResponse;
  portalIdForDirectionSummary: string;
  portalIdForFormSubmit: string;
  requiresStampDuty: boolean;
  onFormCancel(): void;
  havePurchaserParticipant?: boolean;
  numberOfLinkedWorkspaces?: number;
  hasManageWorkspacePermission: boolean;
}
