import * as React from 'react';

import classNames from 'classnames';
import { FieldArray, getIn, useFormikContext } from 'formik';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { EvidenceModel } from 'src/containers/documents/scaffolded-form/nsw/2-21/components/deceased-tenancy-detail/models';
import Evidence from '../../components/evidence';
import { useStyles } from './styles';

interface Props {
  name: string;
  disabled: boolean;
}

function EvidenceArray({ name, disabled }: Props): JSX.Element {
  const { values } = useFormikContext();
  const classes = useStyles();
  const evidences: EvidenceModel[] = getIn(values, name) ?? [];
  return (
    <div data-section={name}>
      <FieldArray
        name={name}
        render={({ remove }) => (
          <>
            {evidences.map((item, index) => {
              const canDelete = index !== 0 && !item.defaultAdded;
              return (
                <FlexLayout
                  className={classNames(canDelete && classes.flexEvidence)}
                  justifyContent="flex-start"
                  flexDirection="row"
                  alignContent="stretch"
                  alignItems="baseline"
                  key={index}
                >
                  <Evidence name={`${name}[${index}]`} disabled={disabled} />
                </FlexLayout>
              );
            })}
          </>
        )}
      />
    </div>
  );
}

export default React.memo(EvidenceArray);
