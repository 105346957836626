import { NswNameChange, nswPartyNameChangeConfig } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { NSW_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/nsw/2-21/config';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import { TenancyComponentLabelConfig } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail';
import { DEFAULT_PARTY_FORM_CONFIG } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { DeceasedTenancyContextData, PartyCapacityLookup } from '../../components/deceased-tenancy-detail/context/DeceasedTenancyContext';
import { DeceasedTenancyPartyCapacityEnum } from '../../components/deceased-tenancy-detail/enums';
import { formatNewName } from '../../helpers';
import { TAE_FIRST_EVIDENCE_TYPE_LOOKUP_OPTIONS, TAE_NAME_SUFFIX_LOOKUP_OPTIONS } from './enums';

// this file was automatically generated from config.ts.mustache
// Define any form-specific configuration in this file
export const NSW_TAE_PARTY_FORM_CONFIG: PartyFormConfig = {
  ...NSW_PARTY_FORM_CONFIG,
  individualPartyFormConfig: {
    ...DEFAULT_PARTY_FORM_CONFIG.individualPartyFormConfig,
    nameSuffixLookupEnum: TAE_NAME_SUFFIX_LOOKUP_OPTIONS
  }
};

export const NSW_TAE_PARTY_FORM_CONFIG_WITH_NAME_CHANGE: PartyFormConfig<NswNameChange> = {
  ...NSW_PARTY_FORM_CONFIG,
  nameChangeConfig: { ...nswPartyNameChangeConfig, formatNewName }
};

export const NSW_TAE_RECEIVING_TENANCY_LABEL_CONFIG: TenancyComponentLabelConfig = {
  partyRole: 'Applicant',
  footer: { jointTenants: 'Add Applicant', tenantsInCommon: '' },
  header: {
    jointTenants: 'Applicant',
    tenantsInCommon: ''
  },
  items: {
    jointTenants: 'Applicant',
    tenantsInCommon: ''
  }
};

const TAE_PARTY_CAPACITY_LOOKUPS: PartyCapacityLookup[] = [
  DeceasedTenancyPartyCapacityEnum.Trustee,
  DeceasedTenancyPartyCapacityEnum.Administrator,
  DeceasedTenancyPartyCapacityEnum.Executor,
  DeceasedTenancyPartyCapacityEnum.ExecutorByRepresentation
].map((capacity: DeceasedTenancyPartyCapacityEnum) => {
  return {
    capacity,
    evidenceTypes: TAE_FIRST_EVIDENCE_TYPE_LOOKUP_OPTIONS
  };
});

export const NSW_TAE_DECEASED_TENANCY_CONTEXT_CONFIG: DeceasedTenancyContextData = new DeceasedTenancyContextData(TAE_PARTY_CAPACITY_LOOKUPS, false, true);
