import * as React from 'react';

import { useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import SectionRelinquishingTenancy from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/relinquishing';

import { NSW_TRANSFER_PARTY_FORM_CONFIG, NSW_TRANSFER_RELINQUISHING_TENANCY_SHARE_TRANFERRED_TYPE, NSW_TRANSFER_TRANSFERORS_TENANCY_COMPONENT_LABEL_CONFIG } from '../../config';
import { getSelectedTitleReferences, isTransferorsResetTenancyAvailable, recalculateFormValues } from '../../helpers';
import { Transfer2_21_1Model } from '../../models';

interface Props {
  name: string;
  focusRef: React.RefObject<ButtonBaseActions>;
}

type FormValues = Transfer2_21_1Model;

const INCLUDE_CUSTOM_PARTIES = true;

function Transferors({ name, focusRef }: Props) {
  const { values, setValues } = useFormikContext<FormValues>();

  React.useEffect(() => {
    setValues(values => {
      const enableResetTenancyButton = isTransferorsResetTenancyAvailable({
        partyBook: values.partyBook,
        transferorsNew: values.transferorsNew,
        transferorsOnTitle: values.transferorsOnTitle
      });
      if (values.isTransferorsResetTenancyAvailable !== enableResetTenancyButton) {
        return {
          ...values,
          isTransferorsResetTenancyAvailable: enableResetTenancyButton
        };
      }
      return values;
    });
  }, [
    // if any of the following values has changed, we need to recalculate the reset tenancy button availability
    values.partyBook,
    values.transferorsNew,
    values.transferorsOnTitle,
    setValues
  ]);

  const handleOnTenancyReset = React.useCallback(() => {
    setValues(values => {
      return {
        ...values,
        ...recalculateFormValues(
          {
            titleReferences: values.titleReferences,
            partyBook: values.partyBook,
            transfereesNew: values.transfereesNew
          },
          !INCLUDE_CUSTOM_PARTIES
        ),
        isTransferorsResetTenancyAvailable: false
      };
    });
  }, [setValues]);

  const hasSelectedTitle = getSelectedTitleReferences(values.titleReferences).length > 0;

  return (
    <SectionRelinquishingTenancy //
      name={name}
      focusRef={focusRef}
      partyFormConfig={NSW_TRANSFER_PARTY_FORM_CONFIG}
      // disablePartyEditConfig={disablePartyFieldEditConfig}
      componentLabels={NSW_TRANSFER_TRANSFERORS_TENANCY_COMPONENT_LABEL_CONFIG}
      // enable adding additional joint group
      allowNewGroup={true}
      groupSelectionMode="select-when-in-common"
      noDataMessage="There are no transferors to display. Please ensure that a title is selected."
      // enable adding new parties only when title is selected
      tenancyPartiesMode={hasSelectedTitle ? 'dynamic' : 'fixed'}
      // display share transferred type dropdown
      shareTransferredTypeOptions={NSW_TRANSFER_RELINQUISHING_TENANCY_SHARE_TRANFERRED_TYPE}
      // enable tenancy reset
      onTenancyReset={values.isTransferorsResetTenancyAvailable ? handleOnTenancyReset : undefined}
    />
  );
}

export default React.memo(Transferors);
