import { WorkspaceStatusEnum } from '@sympli/api-gateway/enums';

import { useRouterParams } from 'src/hooks';
import { WorkspaceDetailRouteParams } from 'src/pages/workspace/detail/WorkspaceDetailPageContainer';
import { useWorkspaceDetail, WorkspaceDetailState } from '../../detail/reducers/workspaceDetail';
import FinancialLinkedWorkspaceStatusBadge from '../financial-linked-workspace-status-badge';
import FinancialWorkspaceStatusBadgeNew from './FinancialWorkspaceStatusBadgeNew';

interface Props {
  workspaceStatusId: WorkspaceStatusEnum;
  extraContent?: React.ReactNode;
}

function FinancialWorkspaceStatusBadgeContainer(props: Props) {
  const { workspaceStatusId, extraContent } = props;
  const queryParams = useRouterParams<WorkspaceDetailRouteParams>();
  const { workspaceId, participantId } = queryParams;
  const workspaceDetailState: WorkspaceDetailState = useWorkspaceDetail(workspaceId, participantId);

  //TODO the way we decide whether it's a linked workspace is through workspace detail now, we should move to workspace basic info
  const linkedWorkspaceCluster = workspaceDetailState.detail?.linkedWorkspaceCluster;

  //TODO add loading UI based on product feedback later

  if (extraContent) {
    return <FinancialWorkspaceStatusBadgeNew dataTestId="workspace-overview-status-banner" extraContent={extraContent} workspaceStatusId={workspaceStatusId} />;
  }

  return (
    <>
      <FinancialWorkspaceStatusBadgeNew dataTestId="workspace-overview-status" workspaceStatusId={workspaceStatusId} verticalStatusLineClassName="h-[14px]" />
      {linkedWorkspaceCluster && (
        <FinancialLinkedWorkspaceStatusBadge dataTestId="linked-workspace-overview-status" linkedWorkspaceOverallStatus={linkedWorkspaceCluster.linkedWorkspaceOverallStatus} />
      )}
    </>
  );
}

export default FinancialWorkspaceStatusBadgeContainer;
