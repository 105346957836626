import _xor from 'lodash-es/xor';
import { defaultMemoize } from 'reselect';

import { NswDocumentPartyJustification, NswNameChange, nswNameChangeConversion } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { formatPartyName, PartyFormConfig, PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { GenericTenancyDetailModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/generic';
import { PartyGroup, TenancyPartyModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/shared';
import { ProprietorGroupTypeEnum, TenancyTypeEnum } from '@sympli-mfe/document-forms-framework/core/models';
import { DataSource } from '@sympli-mfe/document-forms-framework/shared-config/common';
import { ApiDocumentPartyModel, resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';
import { WorkspaceRoleEnum } from '@sympli/api-gateway/enums';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { Container, ProprietorGroup, TenancyDetail } from 'src/containers/documents/party-merge/model';
import PartyMerger from 'src/containers/documents/party-merge/PartyMerger';
import { convertPartiesToFormModel } from '../../helpers';
import { PARTY_FORM_WITH_NAME_CHANGE_CONFIG } from './config';
import { EMPTY_MORTGAGOR_PARTY, Mortgage2_21_3Model, TitleReferenceModel } from './models';

export const { convertPartyBookFromFormToApiModel, convertPartyBookFromApiToFormModel } = resolvePartyBookConversion({
  partyFormConfig: PARTY_FORM_WITH_NAME_CHANGE_CONFIG,
  nameChangeConversion: nswNameChangeConversion
});

// export function calculateMortgagorPartyBookOnTitle(apiModel: ApiMortgage2_21_3Model): PartyModel<NswNameChange>[] {
//   const mortgagorsOnTitle: ApiDocumentPartyModel<NswDocumentPartyJustification, {}>[] = apiModel.titleReferences
//     .filter(tr => tr.isSelected)
//     .flatMap(tr => {
//       return tr.mortgagors.flatMap(m => {
//         return m.parties;
//       });
//     });
//   const mortgagorPartyBookOnTitle: PartyModel<NswNameChange>[] = convertPartyBookFromApiToFormModel(mortgagorsOnTitle);
//   return mortgagorPartyBookOnTitle;
// }

const PARTY_FORM_WITH_NAME_CHANGE_CONFIG_WITHOUT_NAME_CHANGE: PartyFormConfig = {
  ...PARTY_FORM_WITH_NAME_CHANGE_CONFIG,
  nameChangeConfig: undefined
};

export function calculateMortgagorsOnTitle(apiMortgagors: ApiDocumentPartyModel<NswDocumentPartyJustification, {}>[]): Mortgage2_21_3Model['mortgagorsOnTitle'] {
  const partyBook: PartyModel<NswNameChange>[] = convertPartyBookFromApiToFormModel(apiMortgagors); //.filter(p => p.metadata?.source === )  calculateMortgagorPartyBookOnTitle(apiModel);
  const partyBookIds: string[] = partyBook.map(p => p.id);
  const partyNamesMap: Map<string, string> = new Map<string, string>(partyBook.map(p => [p.id, formatPartyName(p, PARTY_FORM_WITH_NAME_CHANGE_CONFIG_WITHOUT_NAME_CHANGE)]));
  return {
    partyBook,
    partyBookIds,
    partyNamesMap,
    isOriginal(party: PartyModel<NswNameChange>): boolean {
      if (!this.partyBookIds.includes(party.id)) {
        return false;
      }
      return partyNamesMap.get(party.id) === formatPartyName(party, PARTY_FORM_WITH_NAME_CHANGE_CONFIG_WITHOUT_NAME_CHANGE);
    }
  };
}

function isPartyDefined<T>(value: T | undefined): value is T {
  return !!value;
}

export function isMortgagorsResetTenancyAvailable({
  //
  partyBook,
  mortgagors,
  mortgagorsOnTitle
}: {
  partyBook: Mortgage2_21_3Model['partyBook'];
  mortgagors: Mortgage2_21_3Model['mortgagors'];
  mortgagorsOnTitle: Mortgage2_21_3Model['mortgagorsOnTitle'];
}): boolean {
  const displayedMortgagorPartyBookIds = mortgagors.proprietorGroups.flatMap(pg => pg.parties.map(p => p.partyBookId));
  const displayedMortgagorParties: PartyModel<NswNameChange>[] = displayedMortgagorPartyBookIds
    .map(id => partyBook.find(p => p.id === id))
    .filter(isPartyDefined<PartyModel<NswNameChange>>);

  //* 1. has any tenant been added/removed by the user?
  // returns items that are not present across both arrays
  if (_xor(displayedMortgagorPartyBookIds, mortgagorsOnTitle.partyBookIds).length > 0) {
    return true;
  }

  //* 2. has any tenant been updated by the user?
  const isMortgagorUpdated = displayedMortgagorParties.some(p => !mortgagorsOnTitle.isOriginal(p));

  return isMortgagorUpdated;
}

export function generateMortgagors(
  formModel: Mortgage2_21_3Model, //
  partyAdder?: string,
  includeCustomParties: boolean = true
): Mortgage2_21_3Model {
  //
  const customParties: PartyModel<NswNameChange>[] = formModel.partyBook.filter(
    party => party.metadata?.source === DataSource.Custom || party.metadata?.source === DataSource.Subscriber
  );

  const customMortgagors: Array<{
    id: string;
    partyBookId: string;
  }> = includeCustomParties ? getCustomMortgagors(customParties, formModel.mortgagors) : [];
  const selectedTitles: TitleReferenceModel[] = formModel.titleReferences.filter(tr => tr.isSelected);

  if (!selectedTitles.length) {
    const mortgagors = customMortgagors.length ? customMortgagors : [EMPTY_MORTGAGOR_PARTY];
    return { ...formModel, mortgagors: createFormMortgagors(mortgagors) };
  }

  const createContainer = (key: string, mortgagors: PartyGroup<ApiDocumentPartyModel<NswDocumentPartyJustification>>[]) => {
    return new Container(key, new TenancyDetail(mortgagors.map(g => new ProprietorGroup(g.parties))));
  };

  const containers: Container<ApiDocumentPartyModel<NswDocumentPartyJustification>>[] = [];
  const titleWithTransfer = selectedTitles.filter(t => t.hasTransfer);

  if (titleWithTransfer.length) {
    const key = titleWithTransfer.map(t => t.reference).join();
    containers.push(createContainer(key, formModel.transferees));
  }

  const containersForNonTransferTitles = selectedTitles
    .filter(t => !t.hasTransfer)
    .map(title => {
      return createContainer(title.reference!, title.mortgagors);
    });

  const result = PartyMerger.merge(containers.concat(containersForNonTransferTitles));

  const apiMortgagors = result.containers.flatMap(c => c.tenancyDetail.proprietorGroups.flatMap(pg => pg.mergedParties));
  const partyBook = convertPartiesToFormModel2212(
    apiMortgagors,
    apiMortgagors.map(p => p.id!),
    partyAdder
  ).concat(customParties);

  const risMortgagors: Array<{
    id: string;
    partyBookId: string;
  }> = apiMortgagors.map(p => ({ id: p.id!, partyBookId: p.id! }));

  const mortgagors: GenericTenancyDetailModel = createFormMortgagors(risMortgagors.concat(customMortgagors));

  const mortgagorsOnTitle = calculateMortgagorsOnTitle(apiMortgagors);

  return {
    ...formModel, //
    partyBookApi: apiMortgagors,
    partyBook: partyBook,
    mortgagors,
    mortgagorsOnTitle
  };
}

export function convertPartiesToFormModel2212(
  parties: ApiDocumentPartyModel<NswDocumentPartyJustification>[],
  relinquishingIds?: string[],
  partyAdder?: string
): PartyModel<NswNameChange>[] {
  const uniqueParties = Array.from(
    parties
      .reduce((entryMap, e) => {
        if (!entryMap.has(e.id!)) entryMap.set(e.id!, e);
        return entryMap;
      }, new Map<string, ApiDocumentPartyModel<NswDocumentPartyJustification>>())
      .values()
  );

  const dataSourceToNameMap = partyAdder
    ? new Map<DataSource, string>([
        [DataSource.Transfer, partyAdder],
        [DataSource.TransmissionApplication, partyAdder]
      ])
    : undefined;

  return convertPartiesToFormModel(
    {
      partyFormConfig: PARTY_FORM_WITH_NAME_CHANGE_CONFIG,
      nameChangeConversion: nswNameChangeConversion
    },
    uniqueParties,
    relinquishingIds,
    dataSourceToNameMap
  );
}

/**
 * returns selected title reference items
 */
export const getSelectedTitleReferences = defaultMemoize((titleReferences: TitleReferenceModel[]) => {
  return titleReferences.filter(({ isSelected }) => isSelected);
});

export function filterOutSelectedParties(
  partyBook: PartyModel<{}, {}>[], //
  currentSelectedParty: TenancyPartyModel,
  selectedPartyIds?: string[]
) {
  const partyOptions = partyBook
    .filter(party => {
      const isCurrentlySelected = party.id === currentSelectedParty.partyBookId;
      const hasBeenSelected = (selectedPartyIds ?? []).includes(party.id);

      /*
       * We need to return current selected party too;
       * otherwise, the selected value of the dropdown list will be empty.
       */
      return isCurrentlySelected || !hasBeenSelected;
    })
    .map(party => ({ id: party.id, name: formatPartyName(party) }));

  return partyOptions;
}

function getCustomMortgagors(
  customParties: PartyModel<NswNameChange, {}>[],
  mortgagors: Mortgage2_21_3Model['mortgagors']
): Array<{
  //
  id: string;
  partyBookId: string;
}> {
  const customMortgagors: PartyModel<NswNameChange, {}>[] = customParties.filter(p => mortgagors.proprietorGroups.flatMap(pg => pg.parties).some(m => m.partyBookId === p.id));
  return customMortgagors.map(p => ({ id: p.id!, partyBookId: p.id! }));
}

/**
 *
 * @param parties tenants in the group
 * @param groupId defaults to 1 when only one group can exist in mortgagors section
 * @returns converted form model
 */
export function createFormMortgagors(oldParties: TenancyPartyModel[], groupId: string = '1'): GenericTenancyDetailModel {
  const parties: GenericTenancyDetailModel['proprietorGroups'][number]['parties'] = oldParties.map(p => {
    return {
      isSelected: !!p.isTransactingParty,
      partyBookId: p.partyBookId,
      addressBookId: p.addressBookId,
      partyCapacity: {
        capacity: p.partyCapacity,
        details: p.partyCapacityDetail
      }
    };
  });
  const tenancy: GenericTenancyDetailModel = {
    tenancyType: TenancyTypeEnum.None,
    proprietorGroups: [
      {
        isSelected: parties.length ? parties.every(p => p.isSelected) : false,
        proprietorGroupType: ProprietorGroupTypeEnum.None,
        parties: parties,
        shareFraction: {
          numerator: null,
          denominator: null
        }
      }
    ]
  };
  return tenancy;
}

export function getPartyAdder(
  participants:
    | {
        id: string;
        name: string;
        workspaceRole: LookupEnumModel<WorkspaceRoleEnum>;
      }[]
    | undefined
): string | undefined {
  return participants?.find(p => p.workspaceRole.id === WorkspaceRoleEnum.Purchaser || p.workspaceRole.id === WorkspaceRoleEnum.Beneficiary)?.name;
}
