import { FormikProps } from 'formik';
import { Action } from 'redux';

import { WorkspaceDirectionsApiResponse, WorkspaceDirectionsCategoriesApiResponse } from '@sympli/api-gateway/models';
import { LinkedWorkspaceCluster } from '@sympli/api-gateway/shared';

import { GeneralAccountUsageApiResponse } from 'src/containers/settings/subscriber-profile/financial-accounts/components/general-account-detail/models';
import { FinancialAccountApiResponse } from 'src/containers/settings/subscriber-profile/financial-accounts/models';
import { TrustAccountMap } from 'src/containers/workspace/financial/directions/models';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { FinancialLineItemLabelEnum } from '../../../models';
import { trustAccountOptionsSelector } from '../../../selectors';
import { DischargeDirectionsFormModel } from '../models';
import EditBankDetails from './components/edit-bank-details';
import SelectDirectionCategory from './components/select-direction-category';
import useMortgageEditor from './hooks/useMortgageEditor';
import { useStyles } from './styles';

type MortgageEditorProps = {
  formikProps: FormikProps<DischargeDirectionsFormModel>;
  index: number;
  detail: WorkspaceDirectionsApiResponse;
  arrayFieldName?: string;
  directionsCategoriesDetail: WorkspaceDirectionsCategoriesApiResponse;
  workspaceClusterDetail?: LinkedWorkspaceCluster;
  // other
  usage?: GeneralAccountUsageApiResponse;
  financialAccounts?: FinancialAccountApiResponse[];
  restrictManualAccountDetailsEntryEnabled: boolean;
  workspaceId: string;
  participantId: string;
  trustAccountMap: TrustAccountMap;
  dispatch: SafeDispatch<Action>;
  setFocusLabel: (focusLabel: FinancialLineItemLabelEnum | null) => void;
};

function MortgageEditor(props: MortgageEditorProps) {
  const classes = useStyles();

  const {
    //
    arrayFieldName = 'distributions',
    index,
    formikProps,
    detail,
    financialAccounts,
    directionsCategoriesDetail,
    usage,
    restrictManualAccountDetailsEntryEnabled,
    workspaceId,
    participantId,
    trustAccountMap,
    dispatch,
    setFocusLabel,
    workspaceClusterDetail
  } = props;

  const itemFieldName = `${arrayFieldName}[${index}]`;

  const {
    //
    isLoading,
    setIsLoading,
    actions: {
      //
      prePopulateBankDetails,
      saveBankTransferDistribution,
      autoSelectAccount,
      clearLinkedDetails,
      clearBankDetails
    }
  } = useMortgageEditor(itemFieldName, dispatch);

  const { trustAccountOptions } = trustAccountOptionsSelector(detail);

  return (
    <div className={classes.editorBox}>
      <SelectDirectionCategory //
        //form
        itemFieldName={itemFieldName}
        formikProps={formikProps}
        //detail
        usage={usage}
        directionsCategoriesDetail={directionsCategoriesDetail}
        financialAccounts={financialAccounts}
        trustAccountOptions={trustAccountOptions}
        //actions
        autoSelectAccount={autoSelectAccount}
        clearLinkedDetails={clearLinkedDetails}
        clearBankDetails={clearBankDetails}
        //other
        restrictManualAccountDetailsEntryEnabled={restrictManualAccountDetailsEntryEnabled}
      />
      <EditBankDetails //
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        //form
        itemFieldName={itemFieldName}
        formikProps={formikProps}
        //data
        trustAccountOptions={trustAccountOptions}
        financialAccounts={financialAccounts}
        workspaceClusterDetail={workspaceClusterDetail}
        //actions
        prePopulateBankDetails={prePopulateBankDetails}
        saveBankTransferDistribution={saveBankTransferDistribution}
        //other
        restrictManualAccountDetailsEntryEnabled={restrictManualAccountDetailsEntryEnabled}
        workspaceId={workspaceId}
        participantId={participantId}
        trustAccountMap={trustAccountMap}
        dispatch={dispatch}
        setFocusLabel={setFocusLabel}
        index={index}
      />
    </div>
  );
}

export default MortgageEditor;
