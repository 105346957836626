import React, { useEffect } from 'react';

import { Form, FormikTouched, useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import TitleReferences from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import { PortalTarget } from '@sympli/ui-framework/components/portal';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import { NSW_LEASE_LESSEE_TENANCY_COMPONENT_LABEL_CONFIG } from './config';
import { generateLessors } from './helpers';
import { Lease2_21_2Model } from './models';
import DocumentAttachments from './sections/document-attachments';
import LeaseDetails from './sections/lease-details';
import Lessees from './sections/lessees';
import LessorsEditable from './sections/lessors';
import RentDetails from './sections/rent-details';
import { PART_LAND_DESCRIPTION_LIMIT } from './sections/title-references/validationSchema';

const SHOULD_VALIDATE: boolean = true;
// this file was automatically generated from DocumentForm.tsx.mustache
const fieldName = modelKey<Lease2_21_2Model>();

const touchLessors = (touched: FormikTouched<Lease2_21_2Model>) => {
  if (touched.lessors?.proprietorGroups) {
    return touched;
  }
  return {
    ...touched,
    lessors: {
      proprietorGroups: []
    }
  };
};

function RootForm({
  className, //
  hideActionsPanel,
  onSaveChanges,
  onCancel
}: RootFormProps): JSX.Element {
  const { isLoading, disabled, dialogPortalId, nextActionLabel } = useDocumentContext();
  const { setValues, setTouched } = useFormikContext<Lease2_21_2Model>();

  const focusOnTitleReferencesRef = React.useRef<ButtonBaseActions>(null);
  const focusOnLessorsRef = React.useRef<ButtonBaseActions>(null);
  const focusOnLesseesRef = React.useRef<ButtonBaseActions>(null);

  useEffect(() => {
    const focusOnFirstItem = focusOnTitleReferencesRef.current || focusOnLessorsRef.current || focusOnLesseesRef.current;
    if (!focusOnFirstItem) {
      Logger.console(SeverityEnum.Warning, 'Document has nothing to focus on. Please fix this');
      return;
    }
    focusOnFirstItem.focusVisible();
  }, []);

  useEffect(() => {
    // immediately trigger validation on lessors section to trigger Lessors merge check test
    setTouched(touchLessors, SHOULD_VALIDATE);
  }, [setTouched]);

  const handleTitleReferenceSelectionChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // make sure lessors is marked as touched to display possible error on lessors
      setTouched(touchLessors, !SHOULD_VALIDATE); // no need to validate, since we will be running setValues
      setValues(generateLessors);
    },
    [setValues, setTouched]
  );

  return (
    <>
      <Form className={className}>
        <TitleReferences //
          name={fieldName('titleReferences')}
          onChange={handleTitleReferenceSelectionChange}
          skipTouchedCheck
          partLandAffectedType="description"
          partLandDescriptionLimit={PART_LAND_DESCRIPTION_LIMIT}
          focusRef={focusOnTitleReferencesRef}
          data-testid="titleReferences"
        />
        <LessorsEditable name={fieldName('lessors')} focusRef={focusOnLessorsRef} />
        <Lessees name={fieldName('lessees')} labelConfig={NSW_LEASE_LESSEE_TENANCY_COMPONENT_LABEL_CONFIG} focusRef={focusOnLesseesRef} />
        <LeaseDetails name={fieldName('leaseDetails')} />
        <RentDetails name={fieldName('rentDetails')} />
        <DocumentAttachments name={fieldName('attachments')} />
        {!hideActionsPanel && (
          <DocumentActionPanel //
            isLoading={isLoading}
            disabled={disabled}
            onBack={onCancel}
            nextLabel={nextActionLabel}
            onSaveChanges={onSaveChanges}
          />
        )}
      </Form>
      <PortalTarget id={dialogPortalId} />
    </>
  );
}

export default React.memo(RootForm);
