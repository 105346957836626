import * as React from 'react';

import { FormikProps, FormikValues, getIn } from 'formik';
import { Action } from 'redux';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { BankAccountTypeEnum } from '@sympli/api-gateway/enums';
import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import { LookupItemModel } from '@sympli/ui-framework/models';

import { Box } from 'src/components/layout';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { modelKey } from 'src/utils/formUtils';
import { DirectionTrustAccountLookupItemModel, SourceFundFormikModel, TrustAccountMap } from '../../../../models';
import { PurchaserDirectionsFormModel } from '../../forms/purchaser-directions/models';
import { FinancialLineItemLabelEnum } from '../../models';
import AddNewRecord from '../add-new-record';
import FormGridHead from '../form-grid-head';
import SourceFund from '../source-fund';
import SourceFundEditor from '../source-fund-editor';
import styles, { ClassKeys } from './styles';

export type FormModel = FormikValues & { sourceFunds: Array<SourceFundFormikModel> };

export interface OwnProps<T extends FormModel> {
  workspaceId: string;
  participantId: string;
  canEdit: boolean;
  isMultipleEditorsOpen: boolean;
  formikProps: FormikProps<T>;
  defaultValue: SourceFundFormikModel;
  currentParticipant: WorkspaceParticipantApiResponse;
  trustAccountOptions: Array<DirectionTrustAccountLookupItemModel>;
  sourceFundCategories: Array<LookupItemModel>;
  trustAccountBankDetailMap: TrustAccountMap;
  depositFormPdfUrl?: string;
  canSetAutoBalanceForIM?: boolean;
  autoBalanceDefaultValue?: boolean;
  dispatch: SafeDispatch<Action>;
}

type Props<T extends FormModel> = OwnProps<T> & WithStyles<ClassKeys>;

// be aware, since all parties are having the same key name, so it is safe to use the below type
const sourceFundsFieldName = modelKey<PurchaserDirectionsFormModel>()('sourceFunds');

class SourceFundList<T extends FormModel> extends React.PureComponent<Props<T>> {
  // TEMPORARILY remove one account per workspace restriction as requested
  // private limitOneAccountPerWorkspace = true;
  private limitOneAccountPerWorkspace = false; // Only remove the restriction on test2

  render() {
    const { formikProps, currentParticipant, isMultipleEditorsOpen, trustAccountOptions, classes, defaultValue, sourceFundCategories, canEdit } = this.props;

    defaultValue.reference = '';

    if (sourceFundCategories.length === 1) {
      defaultValue.category = sourceFundCategories[0].id;
    }

    if (trustAccountOptions.length === 1) {
      defaultValue.trustAccountId = trustAccountOptions[0].id;
    }

    const {
      values: { sourceFunds, hasManageWorkspacePermission }
    } = formikProps;

    // Only allow to add new source fund account if there is no restriction or there is none
    const canAddNewAccount = (!this.limitOneAccountPerWorkspace || sourceFunds.length === 0) && canEdit;

    return (
      <Box
        title={
          <Typography variant="subtitle1" className={classes.header}>
            Source funds
          </Typography>
        }
        className={classes.boxContainer}
      >
        <FormGridHead paymentDetailHeader="Account details" />
        {sourceFunds.map((fund, index, funds) => {
          return this.renderSourceFundsRow(fund, index, funds, isMultipleEditorsOpen, hasManageWorkspacePermission);
        })}

        {canAddNewAccount && (
          <AddNewRecord
            formikProps={formikProps}
            fieldName={sourceFundsFieldName}
            defaultValue={defaultValue}
            currentParticipant={currentParticipant}
            subscriberName={currentParticipant.name}
            recordName={FinancialLineItemLabelEnum.SourceFund}
            onClick={this.handleOnAddNewRecordClick}
          />
        )}
      </Box>
    );
  }

  private handleOnAddNewRecordClick = (event: React.MouseEvent<HTMLButtonElement>, newFieldValues: Array<SourceFundFormikModel>) => {
    const { formikProps, trustAccountBankDetailMap } = this.props;
    const { touched, values, setFieldTouched } = formikProps;
    const newRecordIndex = newFieldValues.length - 1;
    const name = `sourceFunds[${newRecordIndex}].reference`;
    // in the case there is a value pre-populated for the reference field
    // we want to set the field as touched so that if the pre-populated field has an invalid character in it
    // field error could be shown to the user
    const reference = getIn(values, name, '');
    const trustAccountId = getIn(values, `sourceFunds[${newRecordIndex}].trustAccountId`);
    const selectedTrustAccountBankDetail = trustAccountBankDetailMap[trustAccountId];
    // * Only show reference field if selected source fund account is NOT Sympli source account
    // * Use bank account number as reference for Sympli source account (See onPreSubmit function)
    const showReferenceField = selectedTrustAccountBankDetail == null || selectedTrustAccountBankDetail.bankAccountType !== BankAccountTypeEnum.SympliSourceAccount;
    if (reference && showReferenceField) {
      // we want to show errors immediately, therefore field needs to be marked as touched
      // prevent unnecessary render of Formik tree by calling setFieldTouched only when it
      !getIn(touched, name) && setFieldTouched(name, true, false /* don't trigger validation */);
    }
  };

  private renderSourceFundsRow = (
    fund: SourceFundFormikModel,
    index: number,
    sourceFunds: Array<SourceFundFormikModel>,
    isMultipleEditorsOpen: boolean,
    hasManageWorkspacePermission: boolean
  ) => {
    const {
      workspaceId,
      participantId,
      classes,
      formikProps,
      currentParticipant,
      trustAccountBankDetailMap: trustAccountMap,
      trustAccountOptions,
      canEdit,
      canSetAutoBalanceForIM,
      autoBalanceDefaultValue,
      trustAccountBankDetailMap,
      dispatch
    } = this.props;
    const openEditor = canEdit && fund.isEditorOpen;
    const addDivider = canEdit || index !== sourceFunds.length - 1;

    return (
      <React.Fragment key={`source-funds-row-${index}`}>
        <SourceFund
          isEditable={canEdit}
          formikProps={formikProps}
          arrayFieldName={sourceFundsFieldName}
          index={index}
          currentParticipant={currentParticipant}
          trustAccountMap={trustAccountMap}
          canSetAutoBalanceForIM={canSetAutoBalanceForIM}
          workspaceId={workspaceId}
          participantId={participantId}
          dispatch={dispatch}
          isMultipleEditorsOpen={isMultipleEditorsOpen}
          hasManageWorkspacePermission={hasManageWorkspacePermission}
        />
        <Collapse in={openEditor}>
          <SourceFundEditor
            workspaceId={workspaceId}
            participantId={participantId}
            formikProps={formikProps}
            index={index}
            formFieldName={`${sourceFundsFieldName}[${index}]`}
            currentParticipant={currentParticipant}
            trustAccountOptions={trustAccountOptions}
            trustAccountMap={trustAccountMap}
            canSetAutoBalanceForIM={canSetAutoBalanceForIM}
            autoBalanceDefaultValue={autoBalanceDefaultValue}
            trustAccountBankDetailMap={trustAccountBankDetailMap}
          />
        </Collapse>
        {addDivider && <Divider className={classes.divider} />}
      </React.Fragment>
    );
  };
}

const styledComponent = withStyles(styles)(SourceFundList);
export default styledComponent;
