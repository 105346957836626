import * as React from 'react';

import classNames from 'classnames';
import dateFormat from 'dateformat';
import { Form, FormikProps } from 'formik';
import { Action } from 'redux';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { JurisdictionsEnum } from '@sympli/api-gateway/enums';
import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';
import msg from '@sympli/ui-framework/utils/messages';

import Formik from 'src/components/formik';
import InvitationsCardDetailContainer from 'src/containers/dashboard/financial-workspaces/table/row-detail/InvitationsCardDetailContainer';
import { resolveWorkspaceDetailLink } from 'src/containers/workspace/shared/detail/helpers';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { DATE_DISPLAY_FORMAT } from 'src/utils/formatters';
import withRedirectRouter, { RouteRedirectProps } from 'src/utils/withRedirectRouter';
import { actionFetchSettlementDetails, actionSetEditStatus } from '../../actions';
import { DateFormatEnum, SettlementDateFormModel } from '../../models';
import MonthTable from '../month-table/MonthTable';
import SettlementTimeAndReasonReview from '../settlement-time-and-reason-review';
import { SettlementTimeTypeEnum } from '../settlement-time-and-reason-review/SettlementTimeAndReasonReview';
import styles, { ClassKeys } from './styles';

function getValidationSchema() {
  return yup.object<SettlementDateFormModel>({
    selectedDate: yup.string().required(msg.REQUIRED), // TODO review this
    selectedTime: yup.string().required(msg.REQUIRED),
    reasonId: yup.number().nullable().defined()
  });
}

interface OwnProps {
  // for return link
  workspaceId: string;
  participantId: string;
  jurisdictionId: JurisdictionsEnum;

  isAcceptedByUser: boolean;
  settlementAcceptedByAll: boolean;
  proposedByParticipant?: WorkspaceParticipantApiResponse;
  settlementDateProposalId?: string;
  proposedSettlementDate?: string;
  localProposedSettlementDate?: string;
  reasonText?: string;
  workspaceSettlementDate?: string;

  onRedirect?: (message: string) => void;
  dispatch: SafeDispatch<Action>;
}
type Props = OwnProps & WithStyles<ClassKeys> & RouteRedirectProps;

class SettlementDateReviewForm extends React.PureComponent<Props> {
  // make sure resetForm is only triggered when the calendarYearAndMonth is changed
  private getInitialValues = (): SettlementDateFormModel => {
    const { localProposedSettlementDate } = this.props;

    return {
      selectedDate: dateFormat(localProposedSettlementDate || '', DateFormatEnum.DATE),
      selectedTime: dateFormat(localProposedSettlementDate || '', DateFormatEnum.TIME),
      reasonId: null
    };
  };

  componentDidMount() {
    const { isAcceptedByUser } = this.props;
    if (isAcceptedByUser) {
      this.enableEditMode();
    }
  }

  render() {
    return (
      <Formik //
        method="post" // For accept
        action={`/workspaces/${encodeURIComponent(this.props.workspaceId)}/settlement-date-approval`}
        getInitialValues={this.getInitialValues}
        validationSchema={getValidationSchema}
        onPreSubmit={this.handleOnPreSubmit}
        onPostSubmit={this.handleOnPostSubmit}
      >
        {(formikProps: FormikProps<SettlementDateFormModel>) => this.renderForm(formikProps)}
      </Formik>
    );
  }

  private renderForm(formikProps: FormikProps<SettlementDateFormModel>) {
    const {
      classes,
      localProposedSettlementDate,
      workspaceSettlementDate,
      workspaceId,
      participantId,
      jurisdictionId,
      reasonText,
      settlementAcceptedByAll,
      proposedByParticipant
    } = this.props;

    return (
      <Form>
        <FlexLayout flexDirection="row" flexWrap="wrap" justifyContent="space-between">
          <FlexLayout flexDirection="column" className={classNames(classes.calendarContainer)}>
            <FlexLayout className={classes.pagination}>
              <strong>{dateFormat(localProposedSettlementDate, 'mmmm yyyy')}</strong>
            </FlexLayout>
            <MonthTable
              className={classes.flexGrow}
              yearAndMonth={localProposedSettlementDate || new Date().toString()}
              currentSettlementDate={workspaceSettlementDate}
              selectedDate={localProposedSettlementDate}
              jurisdictionId={jurisdictionId}
              settlementAcceptedByAll={settlementAcceptedByAll}
            />
          </FlexLayout>

          <SettlementTimeAndReasonReview
            dateTimeDisplay={dateFormat(localProposedSettlementDate, DATE_DISPLAY_FORMAT)}
            settlementTimeType={SettlementTimeTypeEnum.Proposed}
            reasonText={reasonText}
            proposedByParticipant={proposedByParticipant}
          />

          <InvitationsCardDetailContainer workspaceId={workspaceId} participantId={participantId} className="w-[332px] pt-[32px] large-screen:pt-[0px]" />
        </FlexLayout>
        <Divider className={classes.divider} />

        {this.renderStepper(formikProps)}
      </Form>
    );
  }

  private renderStepper(formikProps: FormikProps<SettlementDateFormModel>) {
    const { classes, isAcceptedByUser, proposedSettlementDate } = this.props;
    // * if this user has accepted the date and,
    // * is waiting for other to accept the date
    // * there is only an edit button on the stepper
    let hasProposedSettlementDateElapsed = false;

    if (proposedSettlementDate) {
      const proposedSettlementDateObject = new Date(proposedSettlementDate);
      hasProposedSettlementDateElapsed = proposedSettlementDateObject.valueOf() < Date.now();
    }

    const actionLabel = isAcceptedByUser || hasProposedSettlementDateElapsed ? 'Propose new date and time' : 'Decline';

    return (
      <WizardStepper
        isLoading={formikProps.isSubmitting}
        backLabel="Cancel"
        onBack={this.goToWorkspaceDetail}
        showNext={!isAcceptedByUser && !hasProposedSettlementDateElapsed}
        nextLabel="Accept date and time"
      >
        <div className={classNames(classes.declineAndEdit, !isAcceptedByUser && !hasProposedSettlementDateElapsed && classes.marginRight)}>
          <Button variant="outlined" color="primary" onClick={this.enableEditMode}>
            {actionLabel}
          </Button>
        </div>
      </WizardStepper>
    );
  }

  private enableEditMode = () => {
    return this.props.dispatch(actionSetEditStatus(true));
  };

  // Handle the post values for accepting
  private handleOnPreSubmit = () => {
    // TODO https://github.com/ticklesource/tickle-ui/issues/2781
    const { settlementDateProposalId, participantId, proposedSettlementDate } = this.props;

    return {
      participantId,
      settlementDateProposalId,
      settlementDate: proposedSettlementDate!.replace(/\+.*$/, 'Z')
    };
  };

  private handleOnPostSubmit = () => {
    // TODO error handling ???
    const { dispatch, workspaceId, participantId, onRedirect } = this.props;
    dispatch(actionFetchSettlementDetails.request({ workspaceId, participantId }));
    onRedirect && onRedirect("You've accepted the settlement date and time");
  };

  private goToWorkspaceDetail = () => {
    const { workspaceId, participantId, navigate } = this.props;
    const link = resolveWorkspaceDetailLink({ workspaceId, participantId });
    navigate(link);
  };
}

export default withStyles(styles)(withRedirectRouter(SettlementDateReviewForm));
