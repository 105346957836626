import React from 'react';

import { FormikProps } from 'formik';

import FpsMeter from '@sympli-mfe/document-forms-framework/components/fps-meter';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { DocumentTypeIdentifierEnum, DocumentViewerTypeEnum, JurisdictionsEnum } from '@sympli/api-gateway/enums';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import { Header } from 'src/components/layout';
import DocumentDataNotification from 'src/containers/documents/components/document-data-notification';
import JsonSchemaForm from 'src/containers/documents/dynamic-form';
import ScaffoldedForm from 'src/containers/documents/scaffolded-form';
import WorkspacePageContentWrapper from 'src/containers/workspace/shared/WorkspacePageContentWrapper';
import { DocumentFormAndDetailModel, DocumentsPageRouteAndQueryModel } from '../../models';
import { useStyles } from './styles';

const IS_DEV = import.meta.env.DEV;

interface Props {
  queryParams: DocumentsPageRouteAndQueryModel;
  documentDetail: DocumentFormAndDetailModel;
  jurisdictionId: JurisdictionsEnum;
  formData: object;
  onRenderForm: (formikProps: FormikProps<any>) => void;
  onSave: (data: object, formikProps: FormikProps<any>) => Promise<any>;
  onCancel: () => void;
  onPostSubmit: (args: FormikPostSubmitArgs<any, any>) => void | Promise<any>;
}

function EditDocument({
  //
  queryParams,
  documentDetail,
  jurisdictionId,
  formData,
  onRenderForm,
  onSave,
  onCancel,
  onPostSubmit
}: Props) {
  const {
    parsedData: { documentNotification },
    parsedSchema,
    documentForm: { documentType, name, documentViewerType, version: documentFormVersion }
  } = documentDetail;
  // parsedSchema?.version is populated when documentViewerType is FormGenerator,
  // and it will be undefined for CustomReactForm.
  // Using fallback to documentFormVersion for better compatibility
  const documentVersion = parsedSchema?.version ?? documentFormVersion;

  React.useEffect(() => {
    Logger.capturePageAction(PageActionEnum.DocumentEditing, {
      jurisdiction: Number.isInteger(jurisdictionId) ? JurisdictionsEnum[jurisdictionId!] : undefined,
      documentType: DocumentTypeIdentifierEnum[documentType],
      schemaVersion: documentVersion,
      documentViewerType: DocumentViewerTypeEnum[documentViewerType]
    });
  }, [documentType, documentViewerType, jurisdictionId, documentVersion]);

  const { isLoading, disabled, nextActionLabel, fpsMeter } = useDocumentContext();
  const classes = useStyles();

  const documentHeader = (
    <React.Fragment>
      <Header>{IS_DEV ? `${name} (${documentVersion})` : name}</Header>
      {!!documentNotification && <DocumentDataNotification notification={documentNotification} />}
    </React.Fragment>
  );

  const scaffoldedForm = (
    <ScaffoldedForm
      documentTypeId={documentType}
      version={documentVersion}
      jurisdictionId={jurisdictionId}
      //
      data={formData}
      isLoading={isLoading}
      disabled={disabled}
      nextActionLabel={nextActionLabel}
      //
      onSave={onSave}
      onCancel={onCancel}
      onRenderForm={onRenderForm}
      onPostSubmit={onPostSubmit}
      //
      queryParams={queryParams}
    />
  );

  const jsonSchemaForm = (
    <JsonSchemaForm
      schema={parsedSchema!} // parsedSchema is always populated for DocumentViewerType.FormGenerator
      //
      data={formData}
      isLoading={isLoading}
      disabled={disabled}
      nextActionLabel={nextActionLabel}
      //
      onSave={onSave}
      onCancel={onCancel}
      onRenderForm={onRenderForm}
      onPostSubmit={onPostSubmit}
      //
      queryParams={queryParams}
    />
  );

  return (
    <WorkspacePageContentWrapper className={classes.content}>
      {documentHeader}
      {documentViewerType === DocumentViewerTypeEnum.CustomReactForm ? scaffoldedForm : jsonSchemaForm}
      {fpsMeter ? <FpsMeter /> : null}
    </WorkspacePageContentWrapper>
  );
}

export default React.memo(EditDocument);
