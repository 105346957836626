import * as React from 'react';

import { useFormikContext } from 'formik';
import Typography from '@mui/material/Typography';

import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import SectionGenericTenancy from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/generic';
import { DisableTenancyFieldConfig } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/shared/config';
import { DataSource } from '@sympli-mfe/document-forms-framework/shared-config/common';
import { PartyTypeEnum } from '@sympli-mfe/enums-2-21/nsw';
import Section from '@sympli/ui-framework/components/form/base-components/section';

import {
  NSW_CAVEAT_ADDRESS_FORM_CONFIG as ADDRESS_FORM_CONFIG,
  NSW_CAVEAT_PARTY_FORM_CONFIG as PARTY_FORM_CONFIG,
  NSW_CAVEAT_REGISTERED_PROPRIETOR_TENANCY_COMPONENT_LABEL_CONFIG as TENANCY_COMPONENT_LABEL_CONFIG
} from '../../config';
import { generateProprietors, getSelectedTitleReferences, hasCommonProprietors, isProprietorsResetTenancyAvailable } from '../../helpers';
import { CaveatModel_2_21_2 } from '../../models';

type FormValues = CaveatModel_2_21_2;

interface Props {
  name: string;
}

const INCLUDE_CUSTOM_PARTIES = true;

export const disablePartyFieldEditConfig: DisableTenancyFieldConfig<PartyModel> = {
  disabled: (party: PartyModel) => party.partyType === PartyTypeEnum.Individual && party.metadata?.source === DataSource.Title,
  disabledTooltip: () => <span>This party cannot be edited as it has been returned from the title. Please add a new party.</span>
};

function SectionRegisteredProprietor({ name }: Props): JSX.Element {
  const { values, setValues } = useFormikContext<FormValues>();

  React.useEffect(() => {
    setValues(values => {
      const enableResetTenancyButton = isProprietorsResetTenancyAvailable({
        partyBook: values.partyBook,
        proprietors: values.proprietors,
        proprietorsOnTitle: values.proprietorsOnTitle
      });
      if (values.isProprietorsResetTenancyAvailable !== enableResetTenancyButton) {
        return {
          ...values,
          isProprietorsResetTenancyAvailable: enableResetTenancyButton
        };
      }
      return values;
    });
  }, [
    // if any of the following values has changed, we need to recalculate the reset tenancy button availability
    values.partyBook,
    values.proprietors,
    values.proprietorsOnTitle,
    setValues
  ]);

  const handleOnTenancyReset = () => {
    setValues(values => {
      const updatedValues = generateProprietors(values, !INCLUDE_CUSTOM_PARTIES);
      return {
        ...updatedValues,
        isProprietorsResetTenancyAvailable: false
      };
    });
  };

  if (values.proprietors.proprietorGroups.flatMap(p => p.parties).length === 0) {
    const selectedTitles = getSelectedTitleReferences(values.titleReferences);
    const sectionMessage =
      selectedTitles.length === 0
        ? `Please select a title reference to identify the ${TENANCY_COMPONENT_LABEL_CONFIG.partyRole}`
        : `The proprietors on the selected titles do not match. Please review the selected titles.`;

    return (
      <Section //
        title={`${TENANCY_COMPONENT_LABEL_CONFIG.partyRole}s`}
        className="mb-[10px]"
        data-name={name}
      >
        <Typography className="text-sm italic p-5">{sectionMessage}</Typography>
      </Section>
    );
  }

  const warnings =
    !values.isProprietorsResetTenancyAvailable && hasCommonProprietors(values.titleReferences)
      ? [
          'The proprietors displayed are the common proprietors from the selected titles. Please add a caveat document for each title if you intend to claim against all registered proprietors on a title.'
        ]
      : [];

  return (
    <SectionGenericTenancy //
      name={name}
      partyFormConfig={PARTY_FORM_CONFIG}
      addressFormConfig={ADDRESS_FORM_CONFIG}
      disablePartyEditConfig={disablePartyFieldEditConfig}
      componentLabels={TENANCY_COMPONENT_LABEL_CONFIG}
      // disable adding additional joint group
      allowNewGroup={false}
      partySelectionMode="select-when-joint"
      groupSelectionMode="select-all"
      // enable adding new parties
      tenancyPartiesMode="dynamic"
      // enable tenancy reset
      onTenancyReset={values.isProprietorsResetTenancyAvailable ? handleOnTenancyReset : undefined}
      warnings={warnings}
    />
  );
}

export default SectionRegisteredProprietor;
