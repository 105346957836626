import { NswDocumentPartyJustification } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { PartLandAffectedModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';

import { TitleReferenceModel } from 'src/containers/documents/scaffolded-form/shared/components/title-references/models';
import { DateLength } from './models';

export const IS_PLAN_DOCUMENT_REQUIRED = <T extends TitleReferenceModel<PartLandAffectedModel, NswDocumentPartyJustification>>(
  selectedTitles: T[],
  leaseLength: DateLength | null
): boolean => {
  const hasPartLandAffected = selectedTitles.some(tr => tr.partLandAffected?.isPartLandAffected);
  return Boolean(hasPartLandAffected && leaseLength && (leaseLength.years > 25 || (leaseLength.years === 25 && leaseLength.days > 0)));
};
