import * as yup from 'yup';
import { TestContext } from 'yup';

import { getLookupValuesAllowingEmpty, memoizeSchemaWithContext, validateWhenVisible2 } from '@sympli-mfe/document-forms-framework/validation';
import * as v from '@sympli-mfe/document-forms-framework/validation/validators';
import msg from '@sympli/ui-framework/utils/messages';

import { VISIBILITY_CHECK_CAVEATORS, VISIBILITY_CHECK_EXTENT_OF_PROHIBITIONS_LR_DOCUMENT_ID, VISIBILITY_CHECK_QUALIFICATION } from '../../checks';
import { ActionProhibitedEnum, ENUM_ACTION_PROHIBITED_OPTIONS } from '../../enums';
import { CaveatModel_2_21_2, ExtentOfProhibition } from '../../models';
import { atLeastOneProprietorListedAsCaveator } from '../../sharedValidation';

type FormValues = CaveatModel_2_21_2;

interface ExtentOfProhibitionsArrayContext {
  isCaveatorSectionVisible: boolean;
  isAnyProprietorListedAsCaveator: boolean;
}

interface ExtentOfProhibitionItemContext extends ExtentOfProhibitionsArrayContext {
  extentOfProhibitions: ExtentOfProhibition[];
}

const yupExtentOfProhibitionItem = memoizeSchemaWithContext<ExtentOfProhibition, ExtentOfProhibitionsArrayContext, ExtentOfProhibitionItemContext>(
  yup
    .object<FormValues['extentOfProhibitions'][number], ExtentOfProhibitionItemContext>({
      actionProhibitedOption: v
        .number()
        .required(msg.REQUIRED)
        .oneOf(getLookupValuesAllowingEmpty(ENUM_ACTION_PROHIBITED_OPTIONS), msg.INVALID_VALUE)
        .testContextualRule({
          message: 'Cannot select the same action more than once',
          uniqueTestName: '"ACTION ALREADY SELECTED CHECK"',
          requirement: (parent: FormValues['extentOfProhibitions'][number], ctx: ExtentOfProhibitionItemContext) => {
            if (Number.isInteger(parent.actionProhibitedOption)) {
              return ctx.extentOfProhibitions.filter(extentOfProhibition => extentOfProhibition.actionProhibitedOption === parent.actionProhibitedOption).length <= 1;
            }
            return true;
          }
        })
        .testContextualRule({
          message: 'You must have at least one Registered Proprietor listed as a Caveator',
          uniqueTestName: '"actionProhibitedOption" required check',
          onlyIf: (_parent: FormValues['extentOfProhibitions'][number], ctx: ExtentOfProhibitionItemContext) => {
            return _parent.actionProhibitedOption === ActionProhibitedEnum.ActionProhibited5 && ctx.isCaveatorSectionVisible;
          },
          requirement: (_parent: FormValues['extentOfProhibitions'][number], ctx: ExtentOfProhibitionItemContext) => {
            return ctx.isAnyProprietorListedAsCaveator;
          }
        }),

      qualification: validateWhenVisible2<FormValues['extentOfProhibitions'][number]['qualification'], ExtentOfProhibitionItemContext>({
        visibilityCheck: (parent: FormValues['extentOfProhibitions'][number]) => VISIBILITY_CHECK_QUALIFICATION(parent),
        validationWhenVisible: yup.string().default('').trim().max(255, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(255))
      }),
      lrDocumentId: validateWhenVisible2<FormValues['extentOfProhibitions'][number]['lrDocumentId'], ExtentOfProhibitionItemContext>({
        visibilityCheck: (parent: FormValues['extentOfProhibitions'][number]) => VISIBILITY_CHECK_EXTENT_OF_PROHIBITIONS_LR_DOCUMENT_ID(parent),
        validationWhenVisible: yup.string().default('').trim().required(msg.REQUIRED)
      })
    })
    .log({ message: 'extentOfProhibitions item' })
    .defined(),
  function contextResolver(parentContext: ExtentOfProhibitionsArrayContext, parentValue: ExtentOfProhibition[]): ExtentOfProhibitionItemContext {
    return {
      isCaveatorSectionVisible: parentContext.isCaveatorSectionVisible,
      isAnyProprietorListedAsCaveator: parentContext.isAnyProprietorListedAsCaveator,
      extentOfProhibitions: parentValue
    };
  }
);

// path: extentOfProhibitions
const yupExtentOfProhibitionsArray = yup
  .array<FormValues['extentOfProhibitions'][number], ExtentOfProhibitionsArrayContext>() //
  // .min(1, msg.MIN_ITEMS(1)) // user is not able to remove the last item, this validation is not needed
  .max(20, msg.MAX_ITEMS(ENUM_ACTION_PROHIBITED_OPTIONS.length))
  .of<FormValues['extentOfProhibitions'][number]>(yupExtentOfProhibitionItem)
  .test(
    'if 7 then either 1 or 5 is required',
    "Additional prohibition required, either Action 1 (Registry Instruments Prohibited) or Action 5 (Caveator's Estate Protected).",
    function test(this: TestContext<ExtentOfProhibitionsArrayContext>, items: ExtentOfProhibition[] = []) {
      const usedIds = items.map(({ actionProhibitedOption }) => actionProhibitedOption);
      if (usedIds.includes(ActionProhibitedEnum.ActionProhibited7)) {
        return usedIds.includes(ActionProhibitedEnum.ActionProhibited1) || usedIds.includes(ActionProhibitedEnum.ActionProhibited5);
      }
      return true;
    }
  )
  .log({ message: 'extentOfProhibitions array' })
  .defined();

const yupSchema = memoizeSchemaWithContext<FormValues['extentOfProhibitions'], FormValues, ExtentOfProhibitionsArrayContext>(
  //
  yupExtentOfProhibitionsArray,
  function contextResolver(parentContext: FormValues): ExtentOfProhibitionsArrayContext {
    const { proprietors, caveators } = parentContext;
    return {
      isCaveatorSectionVisible: VISIBILITY_CHECK_CAVEATORS(parentContext),
      isAnyProprietorListedAsCaveator: atLeastOneProprietorListedAsCaveator({ proprietors, caveators })
    };
  }
);

export default yupSchema;
