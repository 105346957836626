import React from 'react';

import { useFormikContext } from 'formik';
import Typography from '@mui/material/Typography';

import PartyForm, { formatPartyName, formatPartyType } from '@sympli-mfe/document-forms-framework/components/party-form';
import { TenancyPartyModel } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import { PartyTypeEnum } from '@sympli-mfe/enums-2-21/nsw';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field/CheckboxField';
import Field from '@sympli/ui-framework/components/formik/field/Field';
import Subform from '@sympli/ui-framework/components/formik/subform';

import { NSW_NOD_PARTY_FORM_CONFIG, NSW_NOD_PARTY_FORM_CONFIG_WITHOUT_NAME_CHANGE } from '../../../../configs';
import { NoticeOfDeath_2_21_2_Model } from '../../../../models';
import { useStyles } from './styles';

export const SURVIVING_TENANT_MAKING_APPLICATION_LABEL: string = 'Surviving tenant making application';

interface Props {
  name: string;
  proprietor: TenancyPartyModel;
  totalSurvivingProprietors: number;
}

function SurvivingProprietor({ name, proprietor, totalSurvivingProprietors }: Props): JSX.Element {
  const { disabled } = useDocumentContext();
  const classes = useStyles();

  const { values } = useFormikContext<NoticeOfDeath_2_21_2_Model>();
  const { partyBook } = values;
  const fieldName = createModelKeyAppender<TenancyPartyModel>(name);
  const partyBookIndex = partyBook.findIndex(party => party.id === proprietor.partyBookId);
  const partyBindingPath = `partyBook[${partyBookIndex}]`;

  const party = partyBook[partyBookIndex];
  const isOrg = party.partyType === PartyTypeEnum.Organisation;

  return (
    <>
      {isOrg && (
        <Subform subformBindingPath={partyBindingPath} component={PartyForm} disabled={disabled} config={NSW_NOD_PARTY_FORM_CONFIG_WITHOUT_NAME_CHANGE} showFormGroups={false} />
      )}
      {!isOrg && (
        <>
          <Typography className={classes.partyTitle}>{formatPartyType(party.partyType)}</Typography>
          <Typography className={classes.partyDisplay}>{formatPartyName(party, NSW_NOD_PARTY_FORM_CONFIG)}</Typography>
        </>
      )}
      {totalSurvivingProprietors > 1 && (
        <Field //
          name={fieldName('isSelected')}
          label={SURVIVING_TENANT_MAKING_APPLICATION_LABEL}
          component={CheckboxField}
          disabled={disabled}
          format="boolean"
        />
      )}
    </>
  );
}

export default React.memo(SurvivingProprietor);
