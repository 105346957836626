import { resolveMemoizedReceivingTenancyValidation } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving/validation-schema';

import { NSW_LEASE_PARTY_FORM_CONFIG } from '../../config';

const yupLessees = resolveMemoizedReceivingTenancyValidation({
  //
  isAddressRequired: false,
  partyFormConfig: NSW_LEASE_PARTY_FORM_CONFIG,
  forceDobVisibilityOnReceivingIndividual: false
});

export default yupLessees;
