import { NswNameChange } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { PartyFormConfig, PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { DataSource } from '@sympli-mfe/document-forms-framework/shared-config/common';
import { AddressChangeConversion, ApiDocumentPartyModel, NameChangeConversion, resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';
import { PartyTypeEnum } from '@sympli-mfe/enums-2-21/nsw';

interface ResolvePartyConversionArgs<ApiNameChangeModel, FormNameChangeModel, ApiAddressChangeModel, FormAddressChangeModel> {
  partyFormConfig: PartyFormConfig<FormNameChangeModel, FormAddressChangeModel>;
  nameChangeConversion?: NameChangeConversion<ApiNameChangeModel, FormNameChangeModel>;
  addressChangeConversion?: AddressChangeConversion<ApiAddressChangeModel, FormAddressChangeModel>;
}

export function convertPartiesToFormModel<
  ApiNameChangeModel = {}, //
  FormNameChangeModel = {},
  ApiAddressChangeModel = {},
  FormAddressChangeModel = {}
>(
  resolvePartyConversion: ResolvePartyConversionArgs<ApiNameChangeModel, FormNameChangeModel, ApiAddressChangeModel, FormAddressChangeModel>,
  parties: ApiDocumentPartyModel<ApiNameChangeModel, ApiAddressChangeModel>[],
  relinquishingIds?: string[],
  dataSourceToNameMap?: Map<DataSource, string>
): PartyModel<FormNameChangeModel, FormAddressChangeModel>[] {
  const { convertPartyBookFromApiToFormModel } = resolvePartyBookConversion(resolvePartyConversion);

  let partyBook = convertPartyBookFromApiToFormModel(parties, relinquishingIds, dataSourceToNameMap);
  // ToDo: Remove after backend type is updated as int/NameSuffixEnum in NSW
  // Updating backend type should be done as part of the next schema upgrade for NSW
  partyBook = partyBook.map(p => {
    if (p.nameSuffixValue) {
      return { ...p, nameSuffixValue: parseInt(p.nameSuffixValue.toString()) };
    }
    return p;
  });

  return partyBook;
}

export function getLegalEntityNameNSW(party: any): string | undefined {
  const partyModel = party as PartyModel<NswNameChange>;
  const isChangingName = Boolean(partyModel.receivingOrRelinquishingDetails.isRelinquishing && party.receivingOrRelinquishingDetails.isChangingName);
  if (!isChangingName) return partyModel.legalEntityName;

  if (partyModel.receivingOrRelinquishingDetails?.isRelinquishing) {
    const nameChange = partyModel.receivingOrRelinquishingDetails?.nameChange;
    if (party.partyType === PartyTypeEnum.Individual) {
      return [nameChange?.firstName, nameChange?.lastName].filter(e => e?.trim()).join(' ');
    } else {
      return nameChange?.organisationName;
    }
  }
}

export function formatNewName(partyType: PartyTypeEnum, nameChangeModel: NswNameChange): string {
  if (partyType === PartyTypeEnum.Individual) {
    const { firstName, lastName } = nameChangeModel;
    return [firstName, lastName].filter(Boolean).join(' ');
  } else if (partyType === PartyTypeEnum.Organisation) {
    const { organisationName } = nameChangeModel;
    return organisationName;
  } else {
    throw new Error('Unsupported party type.');
  }
}
