import * as React from 'react';

import classNames from 'classnames';
import Typography from '@mui/material/Typography';

import { WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { TransactionType } from '@sympli/api-gateway/shared';
import Logger from '@sympli/ui-logger';

import { getTransactionTypeString } from 'src/pages/workspace/shared/left-panel/helpers';
import { useStyles } from './styles';

export interface Props {
  workspaceId?: string;
  textClassName?: string;
  workspaceTypeId: WorkspaceTypeEnum;
  transactionType?: TransactionType;
}

function TransactionTypeBanner({ workspaceId, workspaceTypeId, transactionType, textClassName }: Props) {
  const classes = useStyles();
  if (workspaceTypeId !== WorkspaceTypeEnum.RegistrationOnly) {
    if (transactionType) {
      return <Typography className={classNames(classes.transactionTypeTitle, textClassName)}>Financial • {getTransactionTypeString(transactionType.type)}</Typography>;
    }

    if (workspaceId) {
      // report error to NewRelics
      const scope = Logger.getDefaultScope();
      scope.setExtraAttributes({ workspaceId });
      Logger.captureException(new Error('Financial workspace does not have transaction type'), scope);
    }
  }
  return <Typography className={classNames(classes.transactionTypeTitle, textClassName)}>Standalone</Typography>;
}

export default React.memo(TransactionTypeBanner);
