import React, { useEffect } from 'react';

import { Form, useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import Section from '@sympli/ui-framework/components/form/base-components/section';

import { getFormikError, modelKey } from 'src/utils/formUtils';
import { isDeceasedPartySelectedOnAnotherDocument } from './helpers';
import { NoticeOfDeath_2_21_2_Model } from './models';
import DeceasedTenancyDetail from './sections/deceased-tenancy-detail';
import SurvivingJointTenants from './sections/surviving-joint-tenants';
import TitleReferences from './sections/title-references';

const fieldName = modelKey<NoticeOfDeath_2_21_2_Model>();

function RootForm({ className, hideActionsPanel, onSaveChanges, onCancel, queryParams: { documentId } }: RootFormProps): JSX.Element {
  const { isLoading, disabled, nextActionLabel, workspaceDocuments } = useDocumentContext();
  const formikProps = useFormikContext<NoticeOfDeath_2_21_2_Model>();

  const focusOnTitleReferencesRef = React.useRef<ButtonBaseActions>(null);

  const sectionErrorMessage = getFormikError(formikProps, fieldName('survivingJointTenants'), true);
  const showWarning = isDeceasedPartySelectedOnAnotherDocument({
    //
    workspaceDocuments,
    documentId,
    context: formikProps.values.context,
    deceasedTenancyDetail: formikProps.values.deceasedTenancyDetail,
    partyBook: formikProps.values.partyBook
  });

  useEffect(() => {
    const focusOnFirstItem = focusOnTitleReferencesRef.current;

    if (!focusOnFirstItem) {
      return;
    }

    focusOnFirstItem.focusVisible();
  }, []);

  return (
    <Form className={className}>
      <TitleReferences name={fieldName('titleReferences')} focusRef={focusOnTitleReferencesRef} />

      <DeceasedTenancyDetail showWarning={showWarning} />

      <Section error={sectionErrorMessage} title="Surviving joint tenants" data-name={fieldName('survivingJointTenants')}>
        <SurvivingJointTenants />
      </Section>

      {!hideActionsPanel && (
        <DocumentActionPanel //
          isLoading={isLoading}
          disabled={disabled}
          onBack={onCancel}
          nextLabel={nextActionLabel}
          onSaveChanges={onSaveChanges}
        />
      )}
    </Form>
  );
}

export default React.memo(RootForm);
