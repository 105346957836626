import React, { useMemo } from 'react';

import { useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';
import { SelectProps } from '@mui/material/Select';

import DocumentFieldArray from '@sympli-mfe/document-forms-framework/components/document-field-array';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import {
  createModelKeyAppender,
  resolveLabel,
  resolveSelectPlaceholder,
  resolveVisibleEnumOptions,
  useSelectRefCallbackToAssignFauxButtonActions
} from '@sympli-mfe/document-forms-framework/utils';
import InfoIconWithTooltip from '@sympli/ui-framework/components/form/base-components/info-icon-with-tooltip';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';

import { VISIBILITY_CHECK_EXTENT_OF_PROHIBITIONS_LR_DOCUMENT_ID, VISIBILITY_CHECK_QUALIFICATION } from '../../checks';
import { ENUM_ACTION_PROHIBITED_OPTIONS } from '../../enums';
import { CaveatModel_2_21_2, ExtentOfProhibition } from '../../models';
import { resolveDefaultItem } from './models';
import { useStyles } from './styles';

const debug = !import.meta.env.PROD;

type FormValues = CaveatModel_2_21_2;

interface Props {
  name: string;
}

function SectionActionProhibited({ name }: Props) {
  const { values } = useFormikContext<FormValues>();
  const { disabled } = useDocumentContext();
  const items: ExtentOfProhibition[] = values.extentOfProhibitions;

  return (
    <DocumentFieldArray
      arrayBinding={name}
      minItems={1}
      maxItems={ENUM_ACTION_PROHIBITED_OPTIONS.length}
      createNewItem={resolveDefaultItem}
      itemTitle="Action prohibited"
      addButtonTitle="Add action prohibited"
      disabled={disabled}
      isSimpleType={false}
      itemStyle="formGroup"
      renderItem={({ itemIndex, itemBinding, itemFocusRef }) => (
        <ActionProhibitedItem //
          name={itemBinding}
          items={items}
          values={values}
          itemIndex={itemIndex}
          focusRef={itemFocusRef}
          disabled={disabled}
        />
      )}
    />
  );
}

interface ActionProhibitedItemProps {
  focusRef: React.RefObject<ButtonBaseActions>;
  name: string;
  disabled: boolean;
  items: ExtentOfProhibition[];
  itemIndex: number;
  values: FormValues;
}

function ActionProhibitedItem({ name, disabled, items, itemIndex, focusRef, values }: ActionProhibitedItemProps): JSX.Element {
  const item = items[itemIndex];
  const fieldName = createModelKeyAppender<ExtentOfProhibition>(name);

  const visibleActionProhibitedEnumOptions = resolveVisibleEnumOptions<number>(ENUM_ACTION_PROHIBITED_OPTIONS, values, fieldName('actionProhibitedOption'));

  const classes = useStyles();

  const selectRefCallback = useSelectRefCallbackToAssignFauxButtonActions(focusRef);
  const selectProps = useMemo(
    (): SelectProps => ({
      inputRef: selectRefCallback
    }),
    [selectRefCallback]
  );

  const labelQualification: JSX.Element = React.useMemo(
    () => (
      <>
        {resolveLabel('Qualification', false)}
        <InfoIconWithTooltip flow="inline" tooltipContent="Qualification can exempt or add or provide additional criteria to the prohibitions of the caveat" />
      </>
    ),
    []
  );

  return (
    <>
      <Field //
        aria-label="Action prohibited"
        disabled={disabled}
        className={classes.fixedWidthDropdown}
        name={fieldName('actionProhibitedOption')}
        format="number"
        options={visibleActionProhibitedEnumOptions}
        placeholder={resolveSelectPlaceholder(true)}
        component={SelectField}
        debug={debug}
        SelectProps={selectProps}
      />
      {VISIBILITY_CHECK_QUALIFICATION(item) && (
        <Field //
          disabled={disabled}
          fullWidth
          label={labelQualification}
          name={fieldName('qualification')}
          component={InputField}
        />
      )}
      {VISIBILITY_CHECK_EXTENT_OF_PROHIBITIONS_LR_DOCUMENT_ID(item) && (
        <Field //
          disabled={disabled}
          className={classes.halfWidth}
          label={resolveLabel('LRS Dealing number', true)}
          name={fieldName('lrDocumentId')}
          component={InputField}
        />
      )}
    </>
  );
}

export default SectionActionProhibited;
