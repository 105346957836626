import Typography from '@mui/material/Typography';

import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field';
import Field from '@sympli/ui-framework/components/formik/field';

import { useStyles } from './styles';

interface Props {
  name: string;
}

const acks: string[] = [
  'The Caveator, to the best knowledge of the Subscriber identified in the execution of the Caveat document, has a good and valid claim to the estate or interest claimed as specified in this Caveat.',
  'This Caveat, to the best knowledge of the Subscriber identified in the execution of the Caveat document, does not require the leave of the Supreme Court.',
  'This Caveat, to the best knowledge of the Subscriber identified in the execution of the Caveat document, does not require the written consent of the Registered Proprietor Of Estate or possessory applicant (as applicable) for the purposes of section 740 Real Property Act 1900.',
  'The Caveator, to the best knowledge of the Subscriber identified in the execution of the Caveat document, has provided the correct address of the Registered Proprietor as specified in this Caveat.'
];

function CaveatStatementLabel({ name }: Props) {
  const classes = useStyles();
  const { disabled } = useDocumentContext();
  return (
    <div className={classes.root}>
      <ul className={classes.caveatStatementList}>
        {acks.map((text, index) => (
          <Typography component="li" className={classes.caveatStatementListItem} key={index}>
            {text}
          </Typography>
        ))}
      </ul>
      <Field //
        name={name}
        disabled={disabled}
        label="I have read and agree to the above statements"
        component={CheckboxField}
      />
    </div>
  );
}

export default CaveatStatementLabel;
