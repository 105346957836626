import { DocumentPermissionEnum, DocumentStatusEnum } from '@sympli/api-gateway/enums';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { DocumentActionModel, DocumentDenyPermissionModel } from 'src/containers/documents/models';
import { ProgressStep } from 'src/containers/shared/lodgement-case-status/models';

// MR - moved
// export enum StampDutyStatusEnum {
//   WaitingForClaim = 0,
//   Claimed = 1,
//   ReadyToSubmit = 2,
//   Submitted = 3,
//   Allocated = 4,
//   Returned = 5,
//   ReadyToCertify = 6,
//   CertifiedAndReadyToSettle = 7,
//   Assessed = 8,
//   Cancelled = 9,
//   Finalised = 10,
//   Completed = 11,
//   RequestToCancel = 12,
//   ExtendSettlementDate = 13,
//   Review = 14,
//   AwaitingStampDutyDocuments = 15,
//   ReadyToCreateTransaction = 16,
//   ReadyToVerify = 17,
//   Verified = 18
// }

/**
 * MR - svc moved
 * Tickle.Application.Domain.Workspaces.Model.Directions.SettlementDistributionsStatus
 */
// export interface DirectionsApiResponse {
//   distributionsStatus: WorkspaceDirectionsStatusApiResponse['distributionsStatus'];
//   stampDutyStatus?: WorkspaceDirectionsStatusApiResponse['stampDutyStatus'];
//   trustAccountAuthorisationRecordStatuses?: WorkspaceDirectionsStatusApiResponse['trustAccountAuthorisationRecordStatuses'];
//   areDistributionsBalanced: boolean;
// }

/**
 * MR - svc moved
 * Tickle.Application.Domain.Workspaces.Model.Directions.DistributionsStatus
 */
export interface DistributionsStatus {
  workspaceParticipant: WorkspaceParticipantAvatarModel;
  status: DocumentStatusEnum;
  distributionSummarySigners: number;
  documentPermissions: Array<DocumentPermissionEnum>;
  documentDenyPermissions: Array<DocumentDenyPermissionModel>;
  documentActions: Array<DocumentActionModel>;
  certificationStatements: string[];
}

/**
 * MR - svc moved (WorkspaceDirectionsStatusApiResponse['stampDutyStatus'])
 * Tickle.Application.Domain.Workspaces.Model.Directions.StampDutyStatus
 */
// export interface StampDutyStatusModel {
//   workspaceParticipants: Array<WorkspaceParticipantAvatarModel>;
//   status: StampDutyStatusEnum;
// }

/**
 * MR - svc moved
 * Tickle.Application.Domain.Workspaces.Model.Directions.AuthorisationRecordStatus
 */
// export interface AuthorisationRecordStatus {
//   accountId: string;
//   accountName: string;
//   status: DocumentStatusEnum;
//   workspaceParticipant: WorkspaceParticipantAvatarModel;
//   documentPermissions: Array<DocumentPermissionEnum>;
//   documentActions: Array<AuthorisationRecordAction>;
//   certificationStatements: string[];
//   acknowledgementStatements: string[];
//   requiredSigners: number;
//   documentDenyPermissions: Array<DocumentDenyPermissionModel>;
//   totalAmount: number;
// }

export interface AuthorisationRecordAction extends DocumentActionModel {
  signAmountLimit?: number;
}

export interface WorkspaceParticipantAvatarModel {
  reference: string;
  participantId: string;
  subscriberId: string;
  subscriberName: string;
  workspaceRoles: Array<LookupEnumModel>;
  avatarUrl?: string;
}

export enum SelectedSettlementDistributionsTypeEnum {
  Distributions = 0,
  StampDuty = 1,
  TrustAccountAuthorisationRecords = 3
}

export class FinancialSettlementStatusSteps {
  static InPreparation: ProgressStep = {
    value: 1,
    description: 'In Preparation'
  };

  static Ready: ProgressStep = {
    value: 2,
    description: 'Ready'
  };

  static Settling: ProgressStep = {
    value: 3,
    description: 'Settling'
  };

  static Settled: ProgressStep = {
    value: 4,
    description: 'Settled'
  };

  static Disbursing: ProgressStep = {
    value: 5,
    description: 'Disbursing'
  };

  static Disbursed: ProgressStep = {
    value: 6,
    description: 'Disbursed'
  };

  static All: ProgressStep[] = [this.InPreparation, this.Ready, this.Settling, this.Settled, this.Disbursing, this.Disbursed];
}

// data returned
export enum FinancialSettlementStatusEnum {
  Initial = 'initial',
  InPreparation = 'inpreparation',
  Signed = 'signed',
  Ready = 'ready',
  Settling = 'settling',
  Settled = 'settled',
  Disbursing = 'disbursing',
  Disbursed = 'disbursed'
}

export enum FinancialSettlementScheduleStatusEnum {
  InPreparation = 'IN PREPARATION',
  Approved = 'APPROVED',
  Signed = 'SIGNED'
}

export enum AllParticipantFinancialSettlementScheduleStatusEnum {
  Invited = 'INVITED',
  InPreparation = 'IN PREPARATION',
  Approved = 'APPROVED',
  PartiallySigned = 'PARTIALLY SIGNED',
  Signed = 'SIGNED',
  NotApplicable = 'N/A'
}

export enum TrustAccountAuthorisationRecordStatusEnum {
  Prepared = 'PREPARED',
  Signed = 'SIGNED'
}

export const TrustAccountAuthorisationRecordSteps: string[] = [TrustAccountAuthorisationRecordStatusEnum.Prepared, FinancialSettlementScheduleStatusEnum.Signed];

export enum SectionTypeEnum {
  Payments = 0,
  Sources = 1
}
