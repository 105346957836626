import React from 'react';

import { batch, useDispatch } from 'react-redux';

import GeneralCrashedContent from 'src/containers/app-crashed-page/components/content';
import { actionFetchDirections, actionFetchDirectionsCategories } from 'src/containers/workspace/financial/directions/actions';
import DirectionsContainer, { DirectionsRouteParams } from 'src/containers/workspace/financial/directions/DirectionsContainer';
import { useDirectionsCategories } from 'src/containers/workspace/financial/directions/reducers/categories';
import { useDirectionsBreakdown } from 'src/containers/workspace/financial/directions/reducers/directionsBreakdown';
import { actionFetchLinkedSettlementDetail, actionFetchWorkspaceParticipantSetting } from 'src/containers/workspace/shared/detail/actions';
import { useWorkspaceBasicInfo } from 'src/containers/workspace/shared/detail/reducers/workspaceBasicInfo';
import { useWorkspaceDetail } from 'src/containers/workspace/shared/detail/reducers/workspaceDetail';
import { useRouterParams, useSafeDispatch } from 'src/hooks';

export default function WorkspaceDirectionsPageContainer() {
  const { workspaceId, participantId } = useRouterParams<DirectionsRouteParams>();

  const dispatch = useSafeDispatch(useDispatch());
  const workspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const workspaceDetailState = useWorkspaceDetail(workspaceId, participantId);
  const directionsBreakdownState = useDirectionsBreakdown(workspaceId, participantId);
  const directionsCategoriesState = useDirectionsCategories(workspaceId, participantId);
  const linkedWorkspaceClusterId: string | undefined = workspaceDetailState.detail?.linkedWorkspaceCluster?.clusterId;

  React.useEffect(() => {
    batch(() => {
      dispatch(actionFetchDirections.request({ workspaceId, participantId }));
      dispatch(actionFetchDirectionsCategories.request({ workspaceId, participantId }));
      //TODO explain why we need workspaceParticipantSetting here?
      dispatch(actionFetchWorkspaceParticipantSetting.request({ workspaceId, participantId }));
    });
  }, [dispatch, participantId, workspaceId]);

  // if it is linked settlement workspace we will need to fetch the linked details
  React.useEffect(() => {
    if (linkedWorkspaceClusterId) {
      dispatch(actionFetchLinkedSettlementDetail.request({ workspaceId, clusterId: linkedWorkspaceClusterId }));
    }
  }, [dispatch, workspaceId, linkedWorkspaceClusterId]);

  if (directionsBreakdownState.error || workspaceBasicInfoState.error || workspaceDetailState.error || directionsCategoriesState.error) {
    return <GeneralCrashedContent />;
  }

  return <DirectionsContainer />;
}
