// this file was automatically generated from validationSchema.ts.mustache
import * as yup from 'yup';

import { Lease2_21_2Model } from './models';
import yupDocumentAttachments from './sections/document-attachments/validationSchema';
import yupLeaseDetails from './sections/lease-details/validationSchema';
import yupLessees from './sections/lessees/validationSchema';
import { yupLessorsEditable } from './sections/lessors/validationSchema';
import yupRentDetails from './sections/rent-details/validationSchema';
import yupTitleReferences from './sections/title-references/validationSchema';

export default yup
  .object<Lease2_21_2Model>({
    titleReferences: yupTitleReferences,
    lessees: yupLessees,
    lessors: yupLessorsEditable,
    leaseDetails: yupLeaseDetails,
    rentDetails: yupRentDetails,
    attachments: yupDocumentAttachments,
    // turn off validation for these fields
    invalidParties: yup.mixed<boolean>(),
    partyBookApi: yup.mixed(),
    workspaceTitleReferences: yup.mixed(), // no validation
    partyBook: yup.mixed(),
    // custom UI only properties
    lessorsOnTitle: yup.mixed(),
    isLessorsResetTenancyAvailable: yup.mixed<boolean>()
  })
  .defined()
  .log();
