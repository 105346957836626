import React from 'react';

import { useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import TitleReferences from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { TitleLabelWithDescriptions } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new/components/title-label/TitleLabel';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';

import { generateDeceasedTenancy } from '../../helpers';
import { NoticeOfDeath_2_21_2_Model } from '../../models';

interface Props {
  name: string;
  focusRef: React.RefObject<ButtonBaseActions>;
}

function SectionTitleReferences({ name, focusRef }: Props): JSX.Element {
  const { disabled } = useDocumentContext();
  const formikProps = useFormikContext<NoticeOfDeath_2_21_2_Model>();
  const { setValues } = formikProps;

  const handleSelectedTitleReferenceChange = () => {
    setValues(values => {
      return {
        ...generateDeceasedTenancy(values)
      };
    });
  };

  return (
    <TitleReferences
      name={name}
      disabled={disabled}
      TitleLabelComponent={TitleLabelWithDescriptions}
      skipTouchedCheck
      focusRef={focusRef}
      data-name={name}
      data-testid={name}
      onChange={handleSelectedTitleReferenceChange}
    />
  );
}

export default React.memo(SectionTitleReferences);
