import React, { useEffect } from 'react';

import { Form, useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import { DocumentAttachments } from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';
import TitleReferences from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';

import DeceasedTenancyDetail from '../../components/deceased-tenancy-detail/DeceasedTenancyDetail';
import { NSW_TAE_DECEASED_TENANCY_CONTEXT_CONFIG, NSW_TAE_PARTY_FORM_CONFIG_WITH_NAME_CHANGE } from './config';
import { ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_OPTIONS } from './enums';
import { TransmissionApplicationByExecutorAdministratorTrustee_2_21_2_Model } from './models';
import Applicants from './sections/applicants/Applicants';
import { generateDeceasedTenancy } from './utils/common';
import { isDeceasedPartySelectedOnAnotherDocument } from './utils/isDeceasedPartySelectedOnAnotherDocument';

// this file was automatically generated from DocumentForm.tsx.mustache
const fieldName = modelKey<TransmissionApplicationByExecutorAdministratorTrustee_2_21_2_Model>();

function RootForm({
  className, //
  hideActionsPanel,
  onSaveChanges,
  onCancel,
  queryParams: { documentId }
}: RootFormProps): JSX.Element {
  const { isLoading, disabled, workspaceDocuments, nextActionLabel } = useDocumentContext();
  const { setValues, values } = useFormikContext<TransmissionApplicationByExecutorAdministratorTrustee_2_21_2_Model>();
  const focusOnTitleReferencesRef = React.useRef<ButtonBaseActions>(null);

  useEffect(() => {
    const focusOnFirstItem = focusOnTitleReferencesRef.current;
    if (!focusOnFirstItem) {
      return;
    }
    focusOnFirstItem.focusVisible();
  }, []);

  const handleSelectedTitleReferenceChange = React.useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, checkedIndexes: number[]) => {
      setValues((values: TransmissionApplicationByExecutorAdministratorTrustee_2_21_2_Model) => generateDeceasedTenancy(values));
    },
    [setValues]
  );

  const deceasedPartyWarning: string | undefined = isDeceasedPartySelectedOnAnotherDocument({
    //
    workspaceDocuments,
    documentId,
    context: values.context,
    deceasedTenancyDetail: values.deceasedTenancyDetail,
    partyBook: values.partyBook
  });

  return (
    <Form className={className}>
      <TitleReferences //
        name={fieldName('titleReferences')}
        onChange={handleSelectedTitleReferenceChange}
        skipTouchedCheck
      />

      <DeceasedTenancyDetail //
        deceasedPartyWarning={deceasedPartyWarning}
        config={NSW_TAE_DECEASED_TENANCY_CONTEXT_CONFIG}
        partyFormConfig={NSW_TAE_PARTY_FORM_CONFIG_WITH_NAME_CHANGE}
      />

      <Applicants name={fieldName('applicants')} />

      <DocumentAttachments //
        name={fieldName('attachments')}
        minItems={0}
        attachmentDocumentTypes={ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_OPTIONS}
      />

      {!hideActionsPanel && (
        <DocumentActionPanel //
          isLoading={isLoading}
          disabled={disabled}
          onBack={onCancel}
          nextLabel={nextActionLabel}
          onSaveChanges={onSaveChanges}
        />
      )}
    </Form>
  );
}

export default React.memo(RootForm);
