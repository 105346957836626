import { resolveGenericTenancyDetailFallback } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/generic';
import { resolveReceivingTenancyDetailFallback } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving';
import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { AttachmentTypeEnum } from '@sympli-mfe/enums-2-21/nsw';

import { Lease2_21_2Model } from './models';

// this file was automatically generated from fallback.ts.mustache
export const fallbackMap: FallbackMap<Lease2_21_2Model> = {
  workspaceTitleReferences: NO_FALLBACK,
  partyBook: NO_FALLBACK,
  lessors: resolveGenericTenancyDetailFallback(),
  titleReferences: {
    $shouldAddDefaultItemToEmptyArray: false,
    $arrayItem: {
      $children: {
        partLandAffected: { $children: { isPartLandAffected: [false], partDescription: [''] } },
        proprietor: NO_FALLBACK,
        newProprietor: NO_FALLBACK,
        isSelected: NO_FALLBACK,
        reference: NO_FALLBACK,
        sltReference: NO_FALLBACK,
        subJurisdiction: NO_FALLBACK,
        shareFraction: NO_FALLBACK,
        type: NO_FALLBACK
      }
    }
  },
  lessees: resolveReceivingTenancyDetailFallback({ shouldAddDefaultItemToEmptyArray: true }),
  leaseDetails: {
    $children: {
      leasePeriod: {
        $children: {
          startDate: [null],
          endDate: [null],
          dateLength: [null]
        }
      },
      renewDetails: {
        $children: {
          optionToRenew: [false],
          renewalPeriods: {
            $shouldAddDefaultItemToEmptyArray: true,
            $arrayItem: {
              $children: {
                startDate: [null],
                endDate: [null],
                dateLength: [null]
              }
            }
          }
        }
      },
      optionToPurchase: [null]
    }
  },
  rentDetails: {
    $children: {
      amount: [null],
      description: [''],
      frequency: [''],
      termsAndConditions: [''],
      mcpNumber: ['']
    }
  },
  attachments: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        documentType: [AttachmentTypeEnum.ConditionsAndProvisions],
        attachment: {
          $shouldAddDefaultItemToEmptyArray: false,
          $arrayItem: {
            $children: {
              fileName: [null],
              fileType: [null],
              id: [null],
              uploadedByParticipantId: NO_FALLBACK,
              uploadedDateTimeUtc: NO_FALLBACK
            }
          }
        }
      }
    }
  },
  // custom UI only properties
  isLessorsResetTenancyAvailable: [false],
  lessorsOnTitle: NO_FALLBACK,

  invalidParties: NO_FALLBACK,
  partyBookApi: NO_FALLBACK
};
