import * as React from 'react';

import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';

import ButtonLink from '@sympli/ui-framework/components/button-link';

import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import { useWorkspaceGroups } from 'src/containers/dashboard/shared/reducers/workspaceGroups';
import { getWorkspaceFinancialSettlementScheduleUrl } from 'src/containers/router/helpers';
import { useSafeDispatch } from 'src/hooks';
import { colors } from 'src/theme';

function ViewLinkedSettlement({
  //
  linkedToGroupId,
  linkedToWorkspaceId,
  linkedToParticipantId
}: {
  linkedToGroupId: string | undefined;
  linkedToWorkspaceId: string;
  linkedToParticipantId: string;
}) {
  //

  const dispatch = useSafeDispatch(useDispatch());
  const useWorkspaceGroupsState = useWorkspaceGroups();
  const userGroupIds = useWorkspaceGroupsState.items.map(d => d.id);

  const handleViewClick = React.useCallback(() => {
    if (!linkedToGroupId || userGroupIds.indexOf(linkedToGroupId) === -1) {
      dispatch(
        actionOpenGlobalSimpleNotification({
          message: <Typography className="text-[14px] text-[var(--neutral-000)]">You do not have permission to view the workspace</Typography>,
          variant: 'new-error',
          autoHideDuration: 6 * 1000
        })
      );
    } else {
      window.open(getWorkspaceFinancialSettlementScheduleUrl(linkedToWorkspaceId, linkedToParticipantId));
    }
  }, [userGroupIds, linkedToGroupId, dispatch, linkedToWorkspaceId, linkedToParticipantId]);

  return (
    <ButtonLink //
      onClick={handleViewClick}
      color="inherit"
      className="px-0"
      sx={{
        fontSize: 14,
        fontWeight: 700,
        color: colors.SYMPLI_GREEN,
        paddingLeft: 0
      }}
    >
      View Linked Workspace
    </ButtonLink>
  );
}

export default React.memo(ViewLinkedSettlement);
