import { NswNameChange, nswPartyNameChangeConfig } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { NSW_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/nsw/2-21/config';
import { PartyFormConfig, PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { TENANCY_COMPONENT_LABEL_CONFIG, TenancyComponentLabelConfig } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/config';
import { CommonTenancyLabelsEnum, ReceivingTenancyComponentLabelConfig } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';

import { NSW_TENANCY_COMPONENT_LABEL_CONFIG } from 'src/containers/documents/scaffolded-form/nsw/2-21/components/tenancy/config';
import { modelKey } from 'src/utils/formUtils';

const fieldName = modelKey<PartyModel>();

export const PARTY_FORM_WITH_NAME_CHANGE_CONFIG: PartyFormConfig<NswNameChange> = {
  ...NSW_PARTY_FORM_CONFIG,
  nameChangeConfig: { ...nswPartyNameChangeConfig, hideWhenEditable: true },
  upperCaseFields: [fieldName('firstName'), fieldName('lastName'), fieldName('organisationName'), fieldName('nameSuffixValue')]
};

export const NSW_MORTGAGE_TENANCY_COMPONENT_LABEL_CONFIG: ReceivingTenancyComponentLabelConfig = {
  ...NSW_TENANCY_COMPONENT_LABEL_CONFIG,
  receivingPartyRole: CommonTenancyLabelsEnum.Mortgagee
};

// export const NSW_MORTGAGOR_TENANCY_COMPONENT_LABEL_CONFIG: TenancyComponentLabelConfig = {
//   ...NSW_TENANCY_COMPONENT_LABEL_CONFIG,
//   partyRole: 'Mortgagor',
//   footer: { jointTenants: 'Add Mortgagor', tenantsInCommon: '' }
// };

export const NSW_MORTGAGOR_TENANCY_COMPONENT_LABEL_CONFIG: TenancyComponentLabelConfig = {
  ...TENANCY_COMPONENT_LABEL_CONFIG,
  partyRole: 'Mortgagor',
  footer: { jointTenants: 'Add Mortgagor', tenantsInCommon: '' },
  tenancyChange: {
    warningMessage: 'The party details, such as party name or tenancy structure, do not match the title; this may cause an issue in lodgement.',
    dialogMessage: 'This will reset your party details, such as party name or tenancy structure, to match the title.'
  }
};
