import { LookupItemModel } from '@sympli/ui-framework/models';

export enum EvidenceTypeEnum {
  Probate = 'Probate',
  LettersOfAdministration = 'Letters of Administration',
  DeedOfAppointment = 'Deed of Appointment',
  StatutoryDeclaration = 'Statutory Declaration',
  DeathCertificate = 'Death Certificate',
  CertificateUnderNSWTrusteeAndGuardianAct = 'Certificate under NSW Trustee and Guardian Act',
  CoronersReport = 'Coroners Report',
  MedicalCertificateCertifyingDeath = 'Medical Certificate certifying death'
}

export const EVIDENCE_TYPE_VALUE_LOOKUP_OPTIONS: LookupItemModel<EvidenceTypeEnum>[] = Object.values(EvidenceTypeEnum)
  .filter(name => name !== EvidenceTypeEnum.CoronersReport && name !== EvidenceTypeEnum.MedicalCertificateCertifyingDeath)
  .map(name => ({ id: name, name }));
