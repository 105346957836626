import * as React from 'react';

import SectionReceivingTenancy from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { StateEnum } from '@sympli-mfe/enums-2-21/nsw';

import { NSW_RECEIVING_TENANCY_TOTAL_SHARE_CONFIG } from '../../../../components/tenancy/config';
import { NSW_TRANSFER_PARTY_FORM_CONFIG, NSW_TRANSFER_RECEIVING_TENANCY_LABEL_CONFIG_OLD } from '../../config';

interface Props {
  name: string;
}
/**
 *
 * @deprecated TODO remove later
 */
function Transferees({ name }: Props) {
  return (
    <SectionReceivingTenancy //
      name={name}
      jurisdiction={StateEnum.NSW}
      partyFormConfig={NSW_TRANSFER_PARTY_FORM_CONFIG}
      componentLabels={NSW_TRANSFER_RECEIVING_TENANCY_LABEL_CONFIG_OLD}
      totalShareConfig={NSW_RECEIVING_TENANCY_TOTAL_SHARE_CONFIG}
      forceDobVisibilityOnReceivingIndividual={true}
    />
  );
}

export default React.memo(Transferees);
