import * as yup from 'yup';

import { validateWhenVisible } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { CaveatorPartyModel, WithdrawalOfCaveat_2_26_0_Model } from '../../models';
import { VISIBILITY_CHECK_CAVEATORS, VISIBILITY_CHECK_WITHDRAW_CAVEAT } from '../../visibilityChecks';

export default yup.mixed<CaveatorPartyModel[]>().test(
  validateWhenVisible({
    isObjectOrArray: true,
    visibilityCheck: (parentValue: WithdrawalOfCaveat_2_26_0_Model) => VISIBILITY_CHECK_WITHDRAW_CAVEAT(parentValue) && VISIBILITY_CHECK_CAVEATORS(parentValue),
    validationWhenVisible: yup.array<CaveatorPartyModel>().of(
      yup.object<CaveatorPartyModel>().shape({
        addressBookId: yup.string().default('').trim().required(msg.REQUIRED)
      })
    )
  })
);
