import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Breadcrumbs from 'src/components/workspace-status-rebuild/breadcrumbs';
import { VerticalStatusLineVariant } from 'src/containers/dashboard/shared/formatters/VerticalStatusLine';
import { colors } from 'src/theme';
import { FinancialWorkspaceStatusTitle } from 'src/utils/status-mapping/workspaceStatusHelper';

export const financialWorkspaceStatuses: string[] = ['in preparation', 'ready', 'settling', 'settled'];

export const financialWorkspaceStatusTooltipContent = (
  //
  variant: VerticalStatusLineVariant,
  title: FinancialWorkspaceStatusTitle
): NonNullable<React.ReactNode> | undefined => {
  switch (variant) {
    case 'warning':
      return title === 'in preparation' ? (
        <Box display="flex" flexDirection="column">
          <Typography variant="body2_bold" sx={{ color: colors.WHITE, textTransform: 'uppercase' }}>
            in preparation
          </Typography>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
            The workspace is <b>now active</b> for you and other participants to start collaborating.
          </Typography>
          <Breadcrumbs //
            currentStep={1}
            steps={financialWorkspaceStatuses}
            sxBox={{ margin: theme => theme.spacing(2, 0, 0, 0) }}
          />
        </Box>
      ) : undefined;
    case 'ok':
      switch (title) {
        case 'ready':
          return (
            <Box display="flex" flexDirection="column">
              <Typography variant="body2_bold" sx={{ color: colors.WHITE, textTransform: 'uppercase' }}>
                ready
              </Typography>
              <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
                The workspace is now <b>ready for Settlement</b>. The process will automatically commence at the defined settlement date & time.
              </Typography>
              <Breadcrumbs //
                currentStep={2}
                steps={financialWorkspaceStatuses}
                sxBox={{ margin: theme => theme.spacing(2, 0, 0, 0) }}
              />
            </Box>
          );
        case 'settling':
          return (
            <Box display="flex" flexDirection="column">
              <Typography variant="body2_bold" sx={{ color: colors.WHITE, textTransform: 'uppercase' }}>
                settling
              </Typography>
              <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
                <strong>The settlement process has started</strong> and may take 30 minutes. The statuses will update as the settlement progresses.
              </Typography>
              <Breadcrumbs //
                currentStep={3}
                steps={financialWorkspaceStatuses}
                sxBox={{ margin: theme => theme.spacing(2, 0, 0, 0) }}
              />
            </Box>
          );
        case 'settled':
          return (
            <Box display="flex" flexDirection="column">
              <Typography variant="body2_bold" sx={{ color: colors.WHITE, textTransform: 'uppercase' }}>
                settled
              </Typography>
              <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
                The matter has now <b>been settled</b>!
              </Typography>
              <Breadcrumbs //
                currentStep={4}
                steps={financialWorkspaceStatuses}
                sxBox={{ margin: theme => theme.spacing(2, 0, 0, 0) }}
              />
            </Box>
          );
        default:
          return undefined;
      }
    case 'error':
      if (title === 'in preparation') {
        return (
          <Box display="flex" flexDirection="column">
            <Typography variant="body2_bold" sx={{ color: colors.WHITE, textTransform: 'uppercase' }}>
              in preparation
            </Typography>
            <Typography variant="body2_bold" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
              Something went wrong.
            </Typography>
            <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(0.5, 0, 0, 0) }}>
              This workspace has <b>attempted to settle</b> with no success. It has returned to ‘In Preparation’ state.
            </Typography>
            <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
              <b>Review the errors</b> and edit this workspace to progress it further.
            </Typography>
            <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
              Note: This workspace needs to be rebooked.
            </Typography>
            <Breadcrumbs //
              currentStep={1}
              steps={financialWorkspaceStatuses}
              sxBox={{ margin: theme => theme.spacing(2, 0, 0, 0) }}
            />
          </Box>
        );
      }

      if (title === 'settling') {
        return (
          <Box display="flex" flexDirection="column">
            <Typography variant="body2_bold" sx={{ color: colors.WHITE, textTransform: 'uppercase' }}>
              settling
            </Typography>
            <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
              This Workspace <b>attempted to settle</b>.
            </Typography>
            <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
              However, it has encountered technical issues. We are working hard to resolve those issues. When its all done this workspace will continue settling.
            </Typography>
            <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
              If the workspace status doesn't move in the following 20min please don't hesitate to call our Sympli Customer Support on <b>1300 796 754</b> to help you settle in
              time.
            </Typography>
            <Breadcrumbs //
              currentStep={3}
              steps={financialWorkspaceStatuses}
              sxBox={{ margin: theme => theme.spacing(2, 0, 0, 0) }}
            />
          </Box>
        );
      }

      return undefined;

    case 'neutral':
      switch (title) {
        case 'abandoned':
          return (
            <Box display="flex" flexDirection="column">
              <Typography variant="body2_bold" sx={{ color: colors.WHITE, textTransform: 'uppercase' }}>
                abandoned
              </Typography>
              <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
                You have <b>abandoned this workspace</b> or this workspace was inactive for 120 days.
              </Typography>
              <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
                Note: Abandoned workspaces can not be reactivated.
              </Typography>
            </Box>
          );
        case 'archived':
          return (
            <Box display="flex" flexDirection="column">
              <Typography variant="body2_bold" sx={{ color: colors.WHITE, textTransform: 'uppercase' }}>
                archived
              </Typography>
              <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
                The system <b>automatically archives completed workspaces</b> after 90 days.
              </Typography>
              <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
                Note: Archived workspaces can not be reactivated.
              </Typography>
            </Box>
          );
        case 'withdrawn':
          return (
            <Box display="flex" flexDirection="column">
              <Typography variant="body2_bold" sx={{ color: colors.WHITE, textTransform: 'uppercase' }}>
                withdrawn
              </Typography>
              <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
                You have <b>withdrawn from this workspace</b>.
              </Typography>
              <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
                Note: Other participants can continue working in this workspace.
              </Typography>
            </Box>
          );
        default:
          return undefined;
      }
    default:
      return undefined;
  }
};
