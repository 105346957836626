import * as React from 'react';

import { useFormikContext } from 'formik';
import _isEqual from 'lodash-es/isEqual';

import FinalHoldings from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/final-holdings';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';

import { Transfer2_21_1Model } from '../../models';
import { calculateFinalTenancyHoldings } from './helpers';

const fieldName = modelKey<Transfer2_21_1Model>();
interface Props {
  name: string;
}

function FinalTenancyHoldings({ name }: Props) {
  const { values, setFieldValue } = useFormikContext<Transfer2_21_1Model>();
  const {
    //
    partyBook,
    transferorsNew,
    transfereesNew,
    finalTenancyHoldings: finalTenancyHoldingsFromForm
  } = values;

  const finalTenancyHoldings = React.useMemo(() => {
    return calculateFinalTenancyHoldings({
      partyBook,
      transferorsNew,
      transfereesNew
    });
  }, [partyBook, transferorsNew, transfereesNew]);
  const tenancyHoldingsHasChanged = !_isEqual(finalTenancyHoldingsFromForm, finalTenancyHoldings);

  React.useEffect(() => {
    if (tenancyHoldingsHasChanged) {
      setFieldValue(fieldName('finalTenancyHoldings'), finalTenancyHoldings);
    }
  }, [setFieldValue, finalTenancyHoldings, tenancyHoldingsHasChanged]);

  return (
    <FinalHoldings //
      name={name}
    />
  );
}
export default React.memo(FinalTenancyHoldings);
