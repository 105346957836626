import DocumentFormWrapper from '@sympli-mfe/document-forms-framework/components/document-form-wrapper';
import { DocumentFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { DndContextProvider } from '@sympli-mfe/document-forms-framework/providers/dnd-context';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { useMemoizableValidation } from '@sympli-mfe/document-forms-framework/validation';

import converter from './conversion';
import { ApiLease2_21_2Model, Lease2_21_2Model } from './models';
import RootForm from './RootForm';
import validationSchema from './validationSchema';

type Props = DocumentFormProps<Lease2_21_2Model, ApiLease2_21_2Model> & {
  disableValidateOnMount?: boolean;
};

export default function DocumentForm({ disableValidateOnMount = false, ...props }: Props): JSX.Element {
  const { validateDebounce } = useDocumentContext();

  const validate = useMemoizableValidation<Lease2_21_2Model>(validationSchema, validateDebounce);
  return (
    <DndContextProvider>
      <DocumentFormWrapper //
        {...props}
        converter={converter}
        validate={validate}
        RootForm={RootForm}
        validateOnMount={!disableValidateOnMount}
      />
    </DndContextProvider>
  );
}
