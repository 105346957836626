import React from 'react';

import classNames from 'classnames';
import { useFormikContext } from 'formik';
import pluralize from 'pluralize';
import Typography from '@mui/material/Typography';

import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import SectionLike from '@sympli-mfe/document-forms-framework/components/section-like';
import { TenancyTypeEnum } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, getFormikError, modelKey } from '@sympli-mfe/document-forms-framework/utils';
import { ProprietorGroupTypeEnum } from '@sympli/api-gateway/enums';
import Section from '@sympli/ui-framework/components/form/base-components/section';

import { BaseDeceasedTenancyDetailComponentModel, DeceasedProprietorGroupModel, DeceasedTenancyDetailModel } from '../../../../components/deceased-tenancy-detail/models';
import { PARTY_SELECTED_ON_ANOTHER_DOCUMENT } from '../../configs';
import DeceasedProprietorArray from './components/deceased-proprietor-array';
import { useStyles } from './styles';
import { VISIBILITY_CHECK_DECEASED_PROPRIETOR_GROUP } from './visibilityChecks';

const fieldName = modelKey<BaseDeceasedTenancyDetailComponentModel>();
const deceasedTenancySectionName = fieldName('deceasedTenancyDetail');
interface Props {
  showWarning?: boolean;
}

function DeceasedTenancyDetail() {
  const fieldName = createModelKeyAppender<DeceasedTenancyDetailModel>(deceasedTenancySectionName);
  const formikProps = useFormikContext<BaseDeceasedTenancyDetailComponentModel>();
  const { deceasedTenancyDetail } = formikProps.values;
  const classes = useStyles();

  const { disabled } = useDocumentContext();

  const renderItem = ({ itemBinding, itemIndex, item }: DocumentArrayItemRenderProps<DeceasedProprietorGroupModel>) => {
    const numberOfPartiesInPreceedingGroups = deceasedTenancyDetail.proprietorGroups.slice(0, itemIndex).flatMap(group => group.parties ?? []).length;
    const isFirstGroup = itemIndex === 0;

    if (
      item.parties.length === 1 ||
      (deceasedTenancyDetail.tenancyType !== TenancyTypeEnum.JointTenants &&
        item.proprietorGroupType !== ProprietorGroupTypeEnum.JointTenantsInterse &&
        item.proprietorGroupType !== ProprietorGroupTypeEnum.JointTenantsWithNoSurvivorship)
    ) {
      return null;
    }

    return (
      <SectionLike //
        className={classNames(isFirstGroup && classes.deceasedGroupFirst, classes.marginBottomIfNotLast)}
        key={itemBinding}
        data-testid={itemBinding}
      >
        <div key={itemBinding} className={classes.bottomDivider}>
          <DeceasedProprietorArray //
            itemBinding={itemBinding}
            numberOfPartiesInPreceedingGroups={numberOfPartiesInPreceedingGroups}
          />
        </div>
      </SectionLike>
    );
  };

  return (
    <DocumentFieldArray //
      arrayBinding={fieldName('proprietorGroups')}
      renderItem={renderItem}
      disabled={disabled}
      itemStyle="none"
      mode="fixed"
    />
  );
}

function SectionDeceasedTenancyDetail({ showWarning }: Props): JSX.Element {
  const classes = useStyles();
  const formikProps = useFormikContext<BaseDeceasedTenancyDetailComponentModel>();
  const { deceasedTenancyDetail } = formikProps.values;
  const { proprietorGroups } = deceasedTenancyDetail;
  const sectionErrorMessage = getFormikError(formikProps, deceasedTenancySectionName, true);
  const sectionTitle = `Deceased joint ${pluralize('tenant', proprietorGroups.flatMap(group => group.parties).length)}`;

  return (
    <Section //
      warning={showWarning ? PARTY_SELECTED_ON_ANOTHER_DOCUMENT : undefined}
      title={sectionTitle}
      className={classNames(proprietorGroups.length && classes.section)}
      error={sectionErrorMessage}
      data-name={deceasedTenancySectionName}
      data-testid={deceasedTenancySectionName}
    >
      {!VISIBILITY_CHECK_DECEASED_PROPRIETOR_GROUP(deceasedTenancyDetail) ? (
        <Typography className={classes.noDeceasedGroupsMessage}>There are no deceased registered parties to display. Please ensure that a title is selected.</Typography>
      ) : (
        <DeceasedTenancyDetail />
      )}
    </Section>
  );
}

export default React.memo(SectionDeceasedTenancyDetail);
