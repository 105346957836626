import * as React from 'react';

import { useDispatch } from 'react-redux';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { Box } from 'src/components/layout';
import { actionFetchLinkedSettlementDetail } from 'src/containers/workspace/shared/detail/actions';
import { LinkedWorkspaceTableModel } from 'src/containers/workspace/shared/detail/components/linked-settlement-detail/linked-workspace-table/models';
import { useLinkedSettlementWorkspaces } from 'src/containers/workspace/shared/detail/reducers/linkedSettlementDetail';
import { useWorkspaceDetail } from 'src/containers/workspace/shared/detail/reducers/workspaceDetail';
import { useSafeDispatch } from 'src/hooks';
import { UnlinkLineItemTypeEnum } from '../../models';
import DeleteLinkedWorkspacesTable from './linked-settlement-workspace-table/DeleteLinkedWorkspacesTable';

interface DeleteLinkedSettlementDetailContainerProps {
  workspaceId: string;
  participantId: string;
  unlinkLineItemType: UnlinkLineItemTypeEnum;
}

function DeleteLinkedSettlementDetailContainer(props: DeleteLinkedSettlementDetailContainerProps) {
  const { workspaceId, participantId, unlinkLineItemType } = props;

  const workspaceDetailState = useWorkspaceDetail(workspaceId, participantId);
  const workspaceDetail = workspaceDetailState.detail!;
  const clusterId = workspaceDetail.linkedWorkspaceCluster?.clusterId;

  const linkedSettlementDetail = useLinkedSettlementWorkspaces(workspaceId, clusterId);
  const dispatch = useSafeDispatch(useDispatch());

  // fire the request if it does not fetched before
  React.useEffect(() => {
    if (linkedSettlementDetail.status === 'idle' && clusterId) {
      dispatch(actionFetchLinkedSettlementDetail.request({ workspaceId, clusterId }));
    }
  }, [dispatch, workspaceId, clusterId, linkedSettlementDetail.status]);

  const { isLoading, detail } = linkedSettlementDetail;

  if (isLoading || !detail) {
    return (
      <div>
        <Box title="Linked Settlement" className="mt-[30px]">
          <DeleteLinkedWorkspacesTable rows={[]} isLoading={true} />
        </Box>
      </div>
    );
  }

  // is there is no item, maybe show nothing then why do we need the api status?
  if (unlinkLineItemType === UnlinkLineItemTypeEnum.Other) {
    return null;
  }

  const { linkedWorkspaceDetails } = detail;

  // backend does not return the order but instead it returns all the chained workspace
  const currentWorkspaceIndex = linkedWorkspaceDetails.findIndex(d => d.workspaceId === workspaceId);
  const nextHighLightRowIndex = unlinkLineItemType === UnlinkLineItemTypeEnum.UnlinkPayment ? currentWorkspaceIndex + 1 : currentWorkspaceIndex - 1;
  const rows =
    linkedWorkspaceDetails.map(
      (d, index) =>
        ({
          //
          ...d,
          order: index + 1,
          isCurrentWorkspace: d.workspaceId === workspaceId,
          className: d.workspaceId === workspaceId || index === nextHighLightRowIndex ? 'bg-[var(--neutral-025)]' : 'opacity-40;'
        }) as LinkedWorkspaceTableModel
    ) ?? [];

  const currentWorkspaceOrder = rows[currentWorkspaceIndex].order;
  const nextWorkspaceOrder = rows[nextHighLightRowIndex].order;

  const lockedRow = unlinkLineItemType === UnlinkLineItemTypeEnum.UnlinkPayment ? currentWorkspaceIndex : currentWorkspaceIndex - 1;

  return (
    <FlexLayout flexDirection="column">
      <FlexLayout flexDirection="column" className="font-[400] text-[14px] leading-5 mt-[20px]">
        <Typography className="pt-[5px]">
          This workspace is linked to <b>Workspace {nextWorkspaceOrder}</b>. This is part of a chain of <b>{linkedWorkspaceDetails.length} linked workspaces</b>.
        </Typography>
        <Typography className="pt-[5px]">
          Please click the "Unlink" button to permanently remove the link and related Financial Line item between workspaces {currentWorkspaceOrder} and {nextWorkspaceOrder}.
        </Typography>
        <Typography className="pt-[5px]"> Below is the list of linked workspaces for better visual reference.</Typography>
      </FlexLayout>
      <Box title="Linked Settlement" className="mt-[30px] pb-[40px]">
        <DeleteLinkedWorkspacesTable rows={rows} lockedRows={[lockedRow]} />
      </Box>
      <Divider className="bg-[var(--neutral-100)]" />
    </FlexLayout>
  );
}

export default React.memo(DeleteLinkedSettlementDetailContainer);
