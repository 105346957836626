import * as React from 'react';

import { FormikProps, FormikValues } from 'formik';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';

import { WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { LinkedSettlementItemModel } from '@sympli/api-gateway/shared';
import DeleteButton, { DeleteButtonProps } from '@sympli/ui-framework/components/formik/buttons/delete-button';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { ColumnsModel, TableV2 as Table } from '@sympli/ui-framework/components/table';

import { Box } from 'src/components/layout';
import { IconClose } from 'src/components/layout/v2/Icons';
import { getConfirmationDialogProps } from 'src/containers/workspace/financial/directions/views/edit-directions/helper';
import { UnlinkLineItemTypeEnum } from 'src/containers/workspace/financial/directions/views/edit-directions/models';
import { useSafeDispatch } from 'src/hooks';
import useScreenSize from 'src/hooks/useScreenSize';
import { ScreenSizeVariant } from 'src/theme/screens';
import { SearchTableContext, SearchTableModel } from '../search-linked-workspace/components/linked-workspace-search-table/models';
import { resolveColumns } from './helpers';
import { useStyles } from './styles';

interface LinkedWorkspaceDisplayContainerProps<T extends FormikValues> {
  isLoading: boolean;
  linkedWorkspaceItem: LinkedSettlementItemModel;
  id: string;
  unlinkLineItemTypeEnum: UnlinkLineItemTypeEnum;
  workspaceId: string;
  participantId: string;
  formikProps: FormikProps<T>;
  arrayFieldName: string;
  index: number;
}

function LinkedWorkspaceDisplayContainer<T extends FormikValues>({
  //
  isLoading,
  linkedWorkspaceItem,
  id,
  unlinkLineItemTypeEnum,
  workspaceId,
  participantId,
  formikProps,
  arrayFieldName,
  index
}: LinkedWorkspaceDisplayContainerProps<T>) {
  const screenVariant: ScreenSizeVariant = useScreenSize();
  const tableContext = React.useMemo<SearchTableContext>(() => ({ screenVariant }), [screenVariant]);
  const [isDeleteConfirmationLoading, setIsDeleteConfirmationLoading] = React.useState(false);
  const dispatch = useSafeDispatch(useDispatch());
  const classes = useStyles();

  const columns: ColumnsModel<SearchTableModel> = resolveColumns({
    screenVariant
  });

  if (unlinkLineItemTypeEnum === UnlinkLineItemTypeEnum.Other) {
    return null;
  }

  //setup the confirmation props and re-use the same one Delete icon
  const confirmationDialogProps: Pick<DeleteButtonProps, 'confirmBeforeDelete' | 'confirmationContent' | 'confirmationProps' | 'confirmationAsyncClose'> =
    getConfirmationDialogProps(
      id,
      classes.optionIcon,
      isDeleteConfirmationLoading,
      workspaceId,
      participantId,
      () => {
        setIsDeleteConfirmationLoading(true);
      },
      () => {
        setIsDeleteConfirmationLoading(false);
      },
      dispatch,
      <span>
        <b>A Link</b> has been successfully removed.
      </span>,
      unlinkLineItemTypeEnum,
      classes.deleteLinkedItemTitle
    );

  const row: SearchTableModel = {
    sympliId: linkedWorkspaceItem.linkedToSympliId,
    settlementDateTime: linkedWorkspaceItem.linkedToSettlementDate,
    address: linkedWorkspaceItem.linkedToWorkspaceAddress,
    reference: linkedWorkspaceItem.linkedToParticipantMatter ?? '',
    jurisdiction: linkedWorkspaceItem.linkedToWorkspaceJurisdiction,
    titles: linkedWorkspaceItem.linkedToTitles,
    workspaceId: linkedWorkspaceItem.linkedToWorkspaceId,
    participantId: linkedWorkspaceItem.linkedToParticipantId,
    role: linkedWorkspaceItem.linkedToWorkspaceRole,
    id: id,
    workspaceType: WorkspaceTypeEnum.FinancialSettlement
  };

  const titleComponent = (
    <FlexLayout flexDirection="row" justifyContent="center" className={'pt-[10px] pb-[5px]'}>
      <Typography className={'font-[400] text-[16px]'}>Linked Workspace</Typography>
      <DeleteButton
        //

        className={classes.unlinkButton}
        formikProps={formikProps}
        fieldName={arrayFieldName}
        removeIndex={index}
        isButtonBase={true}
        buttonBaseContent={
          <>
            <IconClose height={16} width={16} data-testid="unlink-button" /> Unlink
          </>
        }
        {...confirmationDialogProps}
      />

      {/* <ButtonBase className={classes.unlinkButton} onClick={handleOnDeleteClick}>
        <IconClose height={16} width={16} data-testid="unlink-button" /> Unlink
      </ButtonBase> */}
    </FlexLayout>
  );

  return (
    <Box title={titleComponent} className="mt-[10px] border-[0] border-t-[1px] border-solid border-[var(--neutral-200)] ">
      <Table //
        noFooter
        context={tableContext}
        columns={columns}
        rows={[row]}
        isLoading={isLoading}
      />
    </Box>
  );
}

export default React.memo(LinkedWorkspaceDisplayContainer);
