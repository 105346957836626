import * as React from 'react';

import RelinquishingTenancySection from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/relinquishing';
import { useRootFormContext } from '@sympli-mfe/document-forms-framework/providers/root-form-context';

import { TransferRootFormContext } from '../../models';

interface Props {
  name: string;
  /**
   * Allow user to mark tenancy party as selected
   * Options:
   *  - none - no selection allowed
   *  - select-when-joint - allow selecting parties only when multiple parties are present within the group (Joint Tenants)
   * @default "none"
   */
  partySelectionMode?: 'none' | 'select-when-joint';
  /**
   * Allow user to mark tenancy group as selected?
   * Options:
   *  - none - no selection allowed
   *  - select-when-in-common - allow selecting groups only when multiple groups are present (Tenant in Common)
   *  - all - allow selecting parties as the entire group. Only when multiple tenants exists in the group. (Joint Tenants)
  
   * @default "none"
   */
  groupSelectionMode?: 'none' | 'select-when-in-common' | 'select-all';
}

function Transferors<TDocumentPartyJustification, TNameChange extends {}>({ name, partySelectionMode }: Props) {
  const { relinquishingTenancyConfig, shareTransferredTypeOptions } = useRootFormContext<TransferRootFormContext<TDocumentPartyJustification, TNameChange>>();
  return (
    <RelinquishingTenancySection //
      name={name}
      partySelectionMode={partySelectionMode}
      shareTransferredTypeOptions={shareTransferredTypeOptions}
      {...relinquishingTenancyConfig}
    />
  );
}

export default React.memo(Transferors);
