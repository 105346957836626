import * as React from 'react';

import { LodgementCaseStatusEnum, WorkspaceStatusEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';

import FinancialWorkspaceStatusBadgeContainer from 'src/containers/workspace/shared/components/financial-workspace-status-badge/FinancialWorkspaceStatusBadgeContainer';
import LodgementOnlyWorkspaceStatusProgress from 'src/containers/workspace/shared/components/lodgement-only-workspace-status-progress';

export interface WorkspaceStatusProps {
  // basic workspace info
  workspaceTypeId: WorkspaceTypeEnum;
  lodgementCaseStatusId: LodgementCaseStatusEnum;
  workspaceStatusId: WorkspaceStatusEnum;
  className?: string;
}

function WorkspaceStatus(props: WorkspaceStatusProps) {
  const {
    //
    workspaceTypeId,
    lodgementCaseStatusId,
    workspaceStatusId
  } = props;

  const renderWorkspaceStatus = () => {
    if (workspaceTypeId === WorkspaceTypeEnum.RegistrationOnly) {
      return (
        <LodgementOnlyWorkspaceStatusProgress // this is the new component
          workspaceStatusId={workspaceStatusId}
          lodgementCaseStatusId={lodgementCaseStatusId}
        />
      );
    }

    return (
      <FinancialWorkspaceStatusBadgeContainer //
        workspaceStatusId={workspaceStatusId}
      />
    );
  };

  return <div className={props.className}>{renderWorkspaceStatus()}</div>;
}

export default React.memo(WorkspaceStatus);
