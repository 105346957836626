import * as yup from 'yup';

import { Transfer2_21_1Model } from './models';
import yupConsideration from './sections/consideration/validationSchema';
import yupDocumentAttachments from './sections/document-attachments/validationSchema';
import yupFinalTenancyHoldings from './sections/final-tenancy-holdings/validationSchema';
import yupTitleReferences from './sections/title-references/validationSchema';
import yupTransfereesNew from './sections/transferees/validationSchema';
import yupTransferorsNew from './sections/transferors/validationSchema';

export default yup
  .object<Transfer2_21_1Model>({
    titleReferences: yupTitleReferences,
    consideration: yupConsideration,
    documentAttachments: yupDocumentAttachments,
    finalTenancyHoldings: yupFinalTenancyHoldings,
    // unused props
    transferees: yup.mixed(),
    partyBook: yup.mixed(),
    partyBookApi: yup.mixed(),
    attachments: yup.mixed(),
    mergeFailedReason: yup.mixed(),
    workspaceTitleReferences: yup.mixed(),
    needToGeneratePartyBook: yup.mixed(),
    mortgagors: yup.mixed(),
    transferors: yup.mixed(), // TODO remove later
    // custom UI only props
    transfereesNew: yupTransfereesNew,
    transferorsNew: yupTransferorsNew,
    isTransferorsResetTenancyAvailable: yup.mixed(),
    transferorsOnTitle: yup.mixed()
  })
  .defined()
  .log();
