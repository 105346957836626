import classNames from 'classnames';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import PartySelectField from '@sympli-mfe/document-forms-framework/components/party-select-field';
import { createModelKeyAppender, getPartyOptionsExcludingSiblings, resolveSelectPlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import Field from '@sympli/ui-framework/components/formik/field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import { LookupItemModel } from '@sympli/ui-framework/models';

import { NSW_CAVEAT_PARTY_FORM_CONFIG } from '../../../config';
import { ENUM_CLAIM_PARTY_CAPACITY_OPTIONS } from '../../../enums';
import { ClaimParty, PARTY_BOOK_KEY } from '../../../models';

interface ClaimPartyItemProps {
  name: string;
  disabled: boolean;
  dialogPortalId: string;
  partyOptions: LookupItemModel[];
  focusRef: React.RefObject<ButtonBaseActions>;
  nextItemFocusRef?: React.RefObject<ButtonBaseActions>;
  itemIndex: number;
  items: ClaimParty[];
  dropdownClassName: string;
}

export function ClaimPartyItem({
  //
  name,
  disabled,
  dialogPortalId,
  partyOptions,
  focusRef,
  nextItemFocusRef,
  items,
  itemIndex,
  dropdownClassName
}: ClaimPartyItemProps): JSX.Element {
  const fieldName = createModelKeyAppender<ClaimParty>(name);
  const filteredPartyOptions = getPartyOptionsExcludingSiblings(
    partyOptions,
    items.map(p => ({ partyBookId: p.partyBookId ?? null })),
    itemIndex
  );
  return (
    <>
      <PartySelectField
        name={fieldName('partyBookId')}
        partyFormConfig={NSW_CAVEAT_PARTY_FORM_CONFIG}
        disabled={disabled}
        bookRef={PARTY_BOOK_KEY}
        dialogPortalId={dialogPortalId}
        optionsOverride={filteredPartyOptions}
        focusRef={focusRef}
        nextFocusRef={nextItemFocusRef}
      />
      <Field
        disabled={disabled}
        name={fieldName('claimPartyCapacity')}
        format="number"
        options={ENUM_CLAIM_PARTY_CAPACITY_OPTIONS}
        label="Claim party capacity"
        placeholder={resolveSelectPlaceholder(true)}
        className={classNames(dropdownClassName)}
        component={SelectField}
      />
    </>
  );
}
