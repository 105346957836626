import React from 'react';

import { SettlementDateDetailsState, useSettlementDateDetails } from 'src/containers/workspace/financial/settlement-date/reducers/settlementDetail';
import { useWorkspaceBasicInfo, WorkspaceBasicInfoState } from 'src/containers/workspace/shared/detail/reducers/workspaceBasicInfo';
import { useWorkspaceParticipants, WorkspaceParticipantsState } from 'src/containers/workspace/shared/detail/reducers/workspaceParticipants';
import InvitationsCardDetail from './InvitationsCardDetail';

interface Props {
  participantId: string;
  workspaceId: string;
  className?: string;
}

function InvitationsCardDetailContainer({ participantId, workspaceId, className }: Props) {
  const workspaceBasicInfoState: WorkspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const workspaceParticipantsState: WorkspaceParticipantsState = useWorkspaceParticipants(workspaceId);
  const settlementDateDetailsState: SettlementDateDetailsState = useSettlementDateDetails(workspaceId, participantId);

  return (
    <InvitationsCardDetail //
      workspaceParticipantsState={workspaceParticipantsState}
      settlementDateDetailsState={settlementDateDetailsState}
      workspaceBasicInfoState={workspaceBasicInfoState}
      className={className}
    />
  );
}

export default React.memo(InvitationsCardDetailContainer);
