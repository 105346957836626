import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { NoticeOfDeath_2_21_2_Model } from './models';

export const fallbackMap: FallbackMap<NoticeOfDeath_2_21_2_Model> = {
  partyBookApi: NO_FALLBACK,
  partyBook: NO_FALLBACK,
  invalidParties: NO_FALLBACK,
  titleReferences: NO_FALLBACK,
  deceasedTenancyDetail: NO_FALLBACK,
  survivingJointTenants: NO_FALLBACK,
  context: NO_FALLBACK
};
