import React from 'react';

import { useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import SectionGenericTenancy from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/generic';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';

import { NSW_MORTGAGOR_TENANCY_COMPONENT_LABEL_CONFIG, PARTY_FORM_WITH_NAME_CHANGE_CONFIG } from '../../config';
import { generateMortgagors, getPartyAdder, isMortgagorsResetTenancyAvailable } from '../../helper';
import { Mortgage2_21_3Model } from '../../models';

interface Props {
  name: string;
  focusRef?: React.RefObject<ButtonBaseActions>;
}

const INCLUDE_CUSTOM_PARTIES = true;

function SectionMortgagors({ name, focusRef }: Props): JSX.Element {
  const { values, setValues } = useFormikContext<Mortgage2_21_3Model>();
  const { participants } = useDocumentContext();
  // const partyBookIdsOfAllMortgagors = useMemo(
  //   () =>
  //     values.mortgagees.receivingProprietorGroups
  //       .flatMap(x => x.parties)
  //       .map(mortgagor => mortgagor.partyBookId)
  //       .filter(Boolean) as string[], //
  //   [mortgagees]
  // );

  React.useEffect(() => {
    setValues(values => {
      const enableResetTenancyButton = isMortgagorsResetTenancyAvailable({
        partyBook: values.partyBook,
        mortgagors: values.mortgagors,
        mortgagorsOnTitle: values.mortgagorsOnTitle
      });
      if (values.isMortgagorsResetTenancyAvailable !== enableResetTenancyButton) {
        return {
          ...values,
          isMortgagorsResetTenancyAvailable: enableResetTenancyButton
        };
      }
      return values;
    });
  }, [
    // if any of the following values has changed, we need to recalculate the reset tenancy button availability
    values.partyBook,
    values.mortgagors,
    values.mortgagorsOnTitle,
    setValues
  ]);

  const handleOnTenancyReset = () => {
    setValues(values => {
      const updatedValues = generateMortgagors(values, getPartyAdder(participants), !INCLUDE_CUSTOM_PARTIES);
      return {
        ...updatedValues,
        isMortgagorsResetTenancyAvailable: false
      };
    });
  };

  return (
    <SectionGenericTenancy //
      name={name}
      focusRef={focusRef}
      partyFormConfig={PARTY_FORM_WITH_NAME_CHANGE_CONFIG}
      // disablePartyEditConfig={disablePartyFieldEditConfig}
      componentLabels={NSW_MORTGAGOR_TENANCY_COMPONENT_LABEL_CONFIG}
      // disable adding additional joint group
      allowNewGroup={false}
      // enable adding new parties
      tenancyPartiesMode="dynamic"
      // enable tenancy reset
      onTenancyReset={values.isMortgagorsResetTenancyAvailable ? handleOnTenancyReset : undefined}
    />
  );
}

export default React.memo(SectionMortgagors);
