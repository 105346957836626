import { BankAccountTypeEnum, DocumentStatusEnum, LoanAdvanceCategoryEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDirectionsApiResponse, WorkspaceDirectionsCategoriesApiResponse, WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import { SingleDirectionUpsert } from '@sympli/api-gateway/shared';
import { StepObjectModel } from '@sympli/ui-framework/components/workflow-stepper';
import { LookupEnumModel, LookupItemModel } from '@sympli/ui-framework/models';

import { DocumentDenyPermissionModel, DocumentSummary, DocumentWorkflowStepsEnum } from 'src/containers/documents/models';

/**
 * MR - moved
 * Tickle.Application.Domain.Workspaces.Model.Directions.Categories
 */
export interface DirectionsCategoriesDetailApiResponse {
  directionCategories: Array<LookupEnumModel<string>>;
  purchaserAddToVendorCategories: Array<LookupEnumModel<string>>;
  sourceFundsCategories: Array<LookupEnumModel<string>>;
}

/**
 * MR - svc moved
 * Tickle.Application.Service.Workspace.Model.DistributionsDocument
 */
// export interface DirectionsDetailApiResponse {
//   workspaceId: string;
//   versionId: string;

//   sourceFunds: Array<SourceFundModel>;
//   directions: Array<DistributionModel>;

//   // * for banks they are "the real" bankAccounts
//   trustAccounts: Array<DirectionsTrustAccountModel>;
//   purchaserPayAmount: number;
//   directionsSummary: DirectionsSummaryModel;
//   documentSummary: DocumentSummaryApiResponse;

//   directionPaymentMethods: Array<LookupEnumModel<PaymentMethodEnum>>;

//   // * only for im/p role
//   loanAdvanceRequiredAmount: number;
//   loanAdvance?: LoanAdvanceModel;
//   distributionWorkflowType: DocumentWorkflowTypeEnum;
// }

export interface DirectionsTrustAccountModel {
  id: string;
  bankDetails: BankDetailsModel;
  bankAccountType: BankAccountTypeEnum;
  canUseAsLoanPayout: boolean;
  canUseAsLoanAdvance: boolean;
  balance: number;
}

// MR - moved
// export enum BankAccountTypeEnum {
//   SympliSourceAccount = 0,
//   BankAccount = 1,
//   TrustAccount = 2
// }

export interface DirectionTrustAccountLookupItemModel extends LookupItemModel {
  canUseAsLoanPayout: boolean;
  canUseAsLoanAdvance: boolean;
}
/**
 * MR - svc moved
 * Tickle.Application.Service.Workspace.Model.SourceFund
 */
export interface SourceFundModel {
  trustAccountId: string;
  amount: string | number;

  // for IM only
  advanceLimitAmount?: string;

  // reference for SourceFund
  reference?: string;
  category: string;
  categoryOther?: string;

  subscriberName: string;
  participantId: string;
  bankDetails?: BankDetailsModel;
  id?: string; // when ui added a new record, backend will assign the value
  depositFormPdfUrl?: string;
  accountType?: BankAccountTypeEnum;
}

export type SourceFundFormikModel = WorkspaceDirectionsApiResponse['sourceFunds'][number] & {
  isEditorOpen: boolean;
  // for IM only
  initialLoanAdvanceAmount?: number;
  displayLoanAdvanceAmount?: number;
  isAutoBalancingSurplusForIMAllowed?: boolean;
  // for linked source fund only
  linkedDetails?: LinkedSettlementItemModel;
  linkedToParticipantMatterReference?: string;
};

/**
 * MR - svc moved (WorkspaceDirectionsApiResponse['directions'][number])
 * Tickle.Application.Service.Workspace.Model.Direction
 */
// export interface DistributionModel {
//   id?: string; // when ui added a new record, backend will assign the value
//   bankDetails?: BankDetailsModel;
//   bpayDetails?: BpayDetailsModel;
//   holdingAccountDetails?: HoldingAccountDetailsModel;
//   paymentMethod: PaymentMethodEnum;
//   bankAccountId?: string;
//   amount: number | string;
//   directionCategory: DirectionCategoryEnum;
//   category: string;
//   categoryOther?: string;
//   isLocked: boolean;
//   hasEditPermission: boolean;
//   subscriberName: string;
//   participantId: string;
//   prepopulateAccountId: string; // pre-populates bankDetails for the selected account id
// }

export type DistributionFormikModel = WorkspaceDirectionsApiResponse['directions'][number] & {
  disableAddAmountAsVendor: boolean;
  isEditorOpen: boolean;
  prepopulateAccountId: string; // MR TODO this field does not exists in the BE
  linkedSettlementFundsNotRequired?: boolean; // This field does not exist in the Backend
};

// MR - moved
// export enum PaymentMethodEnum {
//   BankTransfer = 0,
//   BPAY = 1,
//   TrustAccount = 2,
//   DirectDebit = 3,
//   HoldingAccount = 4
// }

/**
 * MR - svc moved
 * Tickle.Application.Service.Workspace.Model.BankDetails
 */
export interface BankDetailsModel {
  accountNumber: string;
  bsb: string;
  bankName: string;
  accountName: string;
  reference?: string;
  accountDisplayName?: string;
  accountDescription?: string;
}

export interface LinkedSettlementItemModel {
  reference?: string;
  linkedWorkspaceId: string;
  linkedParticipantId: string;
  linkedItemId?: string;
  linkedToParticipantMatterReference?: string;
}

export interface BpayDetailsModel {
  billerCode: string;
  billerReference: string;
  billerName: string;
  // optional field
  description?: string;
}

export interface HoldingAccountDetailsModel {
  accountId: string;
  reference?: string;

  // special for DM, auto-balancing
  shortfallAmount?: string;
  acceptSurplus?: boolean;
}

// MR - moved
// export enum DirectionCategoryEnum {
//   Settlement = 0,
//   Purchaser = 1
// }

export const DEFAULT_SOURCE_FUND: SourceFundModel = {
  trustAccountId: '',
  amount: '',
  reference: '',
  category: '',
  categoryOther: '',
  subscriberName: '',
  participantId: '',
  accountType: BankAccountTypeEnum.SympliSourceAccount
};

export interface DocumentSummaryApiResponse extends DocumentSummary {
  expiry?: string;
  pdfUrl: string;
  xmlString: string;
}

export const DirectionDocumentWorkflowMapping: Partial<Record<DocumentStatusEnum, DocumentWorkflowStepsEnum>> = {
  [DocumentStatusEnum.InPreparation]: DocumentWorkflowStepsEnum.Write,
  [DocumentStatusEnum.Reviewing]: DocumentWorkflowStepsEnum.Review,
  [DocumentStatusEnum.Signing]: DocumentWorkflowStepsEnum.Sign,
  [DocumentStatusEnum.Signed]: DocumentWorkflowStepsEnum.Sign,
  [DocumentStatusEnum.LodgementVerificationInProgress]: DocumentWorkflowStepsEnum.Write,
  [DocumentStatusEnum.LodgementInProgress]: DocumentWorkflowStepsEnum.Sign,
  [DocumentStatusEnum.Lodged]: DocumentWorkflowStepsEnum.Sign
};

export interface TrustAccountMap {
  [id: string]: DirectionsTrustAccountModel;
}

/**
 * MR - svc moved
 * Tickle.Application.Service.Workspace.Model.LoanAdvance
 */
export interface LoanAdvanceModel {
  accountId: string;
  bankDetails: BankDetailsModel;
  accountType: BankAccountTypeEnum;
  amount: number;
  category: LoanAdvanceCategoryEnum;
  reference?: string;
  categoryReferenceDescription?: string;
  subscriberName: string;
  participantId: string;
  id?: string; // when ui added a new record, backend will assign the value
  isAutoBalancingSurplusForIMAllowed: boolean;
}

// MR - moved
// export enum LoanAdvanceCategoryEnum {
//   Fixed = 0,
//   Range = 1
// }

export interface DirectionQueryParams {
  workspaceId: string;
  participantId: string;
  workflowStep?: DocumentWorkflowStepsEnum;
  showDialog?: boolean;
}

export interface DirectionCommonProps {
  queryParams: DirectionQueryParams;
  detail: WorkspaceDirectionsApiResponse;
  currentParticipant: WorkspaceParticipantApiResponse;
  workspaceParticipants: WorkspaceParticipantApiResponse[];
  workspaceType: WorkspaceTypeEnum;
  directionsCategoriesDetail: WorkspaceDirectionsCategoriesApiResponse;
  steps: StepObjectModel<string, DocumentWorkflowStepsEnum>[];
  onStepChange?(targetStep: DocumentWorkflowStepsEnum): void;
  portalIdForDirectionSummary: string;
  paymentsPermissions: Partial<Record<DocumentWorkflowStepsEnum, boolean>>;
  sourceFundPermissions: Partial<Record<DocumentWorkflowStepsEnum, boolean>>;

  requiresStampDuty: boolean;
  hasManageWorkspacePermission: boolean;
}

export enum AuthorisationDenyPermissionReasonCodeEnum {
  APD01_MissingSignDocumentPermission = 1,
  APD02_HasBeenSignedCannotSignAgain = 2,
  APD03_OneOfTheSignersCannotSignAgain = 3
}

export type AuthorisationDenyPermissionModel = DocumentDenyPermissionModel<AuthorisationDenyPermissionReasonCodeEnum>;

export interface TrustAccountOptionModel {
  id: string;
  name: string;
  canUseAsLoanPayout: boolean;
  canUseAsLoanAdvance: boolean;
}

export enum SympliSourceAccountStatusEnum {
  Unverified,
  Shortfall,
  Surplus,
  Cleared
}

export interface UpdateWorkspaceDirectionLineItemRequestModel {
  workspaceId: string;
  participantId: string;
  requestPayload: SingleDirectionUpsert;
}

export interface DeleteWorkspaceDirectionLineItemRequestModel {
  workspaceId: string;
  participantId: string;
  directionItemId: string;
  isLinkedSettlementItem: boolean;
}
