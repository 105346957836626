import { TenancyPartyModel } from '@sympli-mfe/document-forms-framework/core/models';

export const resolveDefaultItem = () => {
  const defaultItem: TenancyPartyModel = {
    // custom attributes to support address/client book
    partyBookId: '', // reference to ClientNameStore
    addressBookId: '', // reference to AddressStore;
    isSelected: true,
    partyCapacity: {}
  };

  return defaultItem;
};
