import * as React from 'react';

import classNames from 'classnames';
import { FormikProps, getIn, setIn } from 'formik';
import _get from 'lodash-es/get';
import { Action } from 'redux';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { DirectionCategoryEnum, PaymentMethodEnum, WorkspaceRoleEnum } from '@sympli/api-gateway/enums';
import { UpdateWorkspaceDirectionsApiRequestBody, WorkspaceDirectionsCategoriesApiResponse, WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import { LinkedSettlementItemModel as LinkedSettlementItemModelResponse, LinkedWorkspaceCluster } from '@sympli/api-gateway/shared';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field';
import CurrencyInputField from '@sympli/ui-framework/components/formik/currency-input-field';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import { NewIconView } from '@sympli/ui-framework/icons';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import BsbNumberField from 'src/components/bsb-NumberInput';
import { GeneralAccountUsageApiResponse } from 'src/containers/settings/subscriber-profile/financial-accounts/components/general-account-detail/models';
import { FinancialAccountApiResponse } from 'src/containers/settings/subscriber-profile/financial-accounts/models';
import LinkedWorkspaceDisplayContainer from 'src/containers/shared/app-bar/components/display-linked-workspace/LinkedWorkspaceDisplayContainer';
import LinkedWorkspaceSearchContainer from 'src/containers/shared/app-bar/components/search-linked-workspace';
import { SearchTableModel } from 'src/containers/shared/app-bar/components/search-linked-workspace/components/linked-workspace-search-table/models';
import {
  BankDetailsModel,
  BpayDetailsModel,
  DirectionTrustAccountLookupItemModel,
  DistributionFormikModel,
  LinkedSettlementItemModel,
  TrustAccountMap
} from 'src/containers/workspace/financial/directions/models';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { createArrayItemModelKeyAppender, createModelKeyAppender, resolveSelectPlaceholder } from 'src/utils/formUtils';
import SelectAccounts from '../../components/select-accounts';
import { CategoryEnum } from '../../forms/discharge-mortgage-directions/models';
import VendorDistributionCheckboxField from '../../forms/purchaser-directions/vendor-distribution-checkbox-field';
import { categoryUsageMappings, getBankDetails, getDefaultAccountId, updateTrustAccountDistributionMapping } from '../../helper';
import { FinancialLineItemLabelEnum, UnlinkLineItemTypeEnum } from '../../models';
import { BankTransferDistribution, BpayDistribution, ConditionalDistributionModel, LinkedDistribution, TrustAccountDistribution } from '../direction-record/models';
import LineItemConfirmationDialog from '../lineItem-confirmation-Dialog/LineItemConfirmationDialog';
import { bpayCheckFormikHelper, bsbCheckFormikHelper, linkedCheckFormikHelper } from './helper';
import { categoryOptionsSelector } from './selector';
import styles, { ClassKeys } from './styles';

export interface DirectionsPayeeDetailProps {
  formikProps: FormikProps<{ distributions: Array<DistributionFormikModel> }>;
  index: number;
  arrayFieldName: string;
  workspaceId: string;
  participantId: string;
  trustAccountOptions: Array<DirectionTrustAccountLookupItemModel>;
  trustAccountMap: TrustAccountMap;
  paymentMethodOptions: Array<LookupEnumModel<PaymentMethodEnum>>;
  onAddAmountAsVendor?: () => void;

  directionsCategoriesDetail?: WorkspaceDirectionsCategoriesApiResponse;
  currentParticipant?: WorkspaceParticipantApiResponse;
  isLoading: boolean;
  error?: string;
  dispatch: SafeDispatch<Action>;

  usage?: GeneralAccountUsageApiResponse;
  financialAccounts?: FinancialAccountApiResponse[];
  setFocusLabel: (focusLabel: FinancialLineItemLabelEnum | null) => void;
  workspaceClusterDetail?: LinkedWorkspaceCluster;
}
type Props = DirectionsPayeeDetailProps & WithStyles<ClassKeys>;

interface State {
  isLoading: boolean;
  openLineItemConfirmationDialog: boolean;
  selectedLinkedSettlementRow?: number;
}

class DirectionPayeeDetail extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    isLoading: false,
    openLineItemConfirmationDialog: false
  };

  private get fieldName() {
    const { arrayFieldName, index } = this.props;
    return `${arrayFieldName}[${index}]`;
  }

  private arrayItemModelKeyAppender = createArrayItemModelKeyAppender<DistributionFormikModel>(this.props.arrayFieldName);

  private arrayItemModelKeyAppenderForLinked = createArrayItemModelKeyAppender<LinkedDistribution>(this.props.arrayFieldName);

  componentDidUpdate(prevProps: Props) {
    this.ensureCategorySelectionOnEdit(prevProps);
  }

  // ensures category field has a valid value when editing, otherwise will set it to default
  // why this? if product decides to remove some of already selected values from the list
  // so in-flight workspaces won't show blank as category and it will be defaulted to the default value and label
  // then user has to choose a new category on update
  private ensureCategorySelectionOnEdit(prevProps: Props) {
    const {
      index,
      formikProps: { setFieldValue, values }
    } = this.props;
    const currentIsEditorOpen: boolean = _get(values, this.arrayItemModelKeyAppender(index, 'isEditorOpen'));
    const prevIsEditorOpen: boolean = _get(prevProps.formikProps.values, this.arrayItemModelKeyAppender(index, 'isEditorOpen'));

    if (currentIsEditorOpen === prevIsEditorOpen || !currentIsEditorOpen) return;

    const categories = this.resolveCategoryOptions();
    const category: string = _get(values, this.arrayItemModelKeyAppender(index, 'category'));

    if (!categories.some(e => e.id === category)) {
      setFieldValue(this.arrayItemModelKeyAppender(index, 'category'), '');
    }
  }

  render() {
    const { index, isLoading, directionsCategoriesDetail, error, classes } = this.props;

    if (isLoading) {
      return <BlockLoader />;
    }

    if (error) {
      return error;
    }

    if (!directionsCategoriesDetail) {
      return null;
    }

    return (
      <div className={classes.editorBox}>
        {this.renderDirectionCategory(index)}
        {this.renderDistributionDetail(index)}
        {this.renderConfirmationDialog()}
      </div>
    );
  }

  private renderConfirmationDialog() {
    const { formikProps } = this.props;
    const { openLineItemConfirmationDialog } = this.state;

    const item: UpdateWorkspaceDirectionsApiRequestBody['directions'][number] = _get(formikProps.values, this.fieldName);

    return <LineItemConfirmationDialog open={openLineItemConfirmationDialog} item={item} onClose={this.handleConfirmationClose} />;
  }

  private resolveCategoryOptions(): LookupEnumModel<string, string>[] {
    const {
      formikProps: { values },
      index,
      directionsCategoriesDetail,
      currentParticipant
    } = this.props;

    const directionCategory: DirectionCategoryEnum = _get(values, this.arrayItemModelKeyAppender(index, 'directionCategory'));
    const categories = categoryOptionsSelector({
      directionCategory,
      currentParticipant,
      directionsCategoriesDetail
    });

    return categories;
  }

  private renderDirectionCategory(index: number) {
    const { formikProps, classes, directionsCategoriesDetail, currentParticipant } = this.props;
    const category = _get(formikProps.values, this.arrayItemModelKeyAppender(index, 'category'));
    const disableAddAmountAsVendor = Boolean(_get(formikProps.values, this.arrayItemModelKeyAppender(index, 'disableAddAmountAsVendor')));

    if (!directionsCategoriesDetail || !currentParticipant) {
      return null;
    }

    const workspaceRole = currentParticipant.workspaceRole.id;
    const categories = this.resolveCategoryOptions();
    const isLinkedPaymentSaved = Boolean(_get(formikProps.values, this.arrayItemModelKeyAppender(index, 'id'))) && category === CategoryEnum.LinkedPayment;

    return (
      <FlexLayout flexDirection="column">
        {workspaceRole === WorkspaceRoleEnum.Purchaser && category !== CategoryEnum.LinkedPayment && (
          <Field
            component={VendorDistributionCheckboxField}
            name={this.arrayItemModelKeyAppender(index, 'directionCategory')}
            disabled={disableAddAmountAsVendor}
            onChange={() => this.handleOnCheckboxChange(index)}
            label="Add amount as vendor's direction"
          />
        )}
        <FlexLayout flexDirection="row">
          <Field
            label="Category"
            component={SelectField}
            placeholder={resolveSelectPlaceholder(true)}
            name={this.arrayItemModelKeyAppender(index, 'category')}
            className={classes.category}
            options={categories}
            readOnly={categories.length === 1 || isLinkedPaymentSaved}
            onChange={this.handleOnCategoryChange}
          />
          {category === 'Other' && (
            <Field label="Category name" component={InputField} className={classes.categoryName} name={this.arrayItemModelKeyAppender(index, 'categoryOther')} />
          )}

          {category === CategoryEnum.LinkedPayment && (
            <Field //
              label="Reference (optional)"
              component={InputField}
              className={classes.categoryName}
              name={this.arrayItemModelKeyAppender(index, 'reference')}
            />
          )}
        </FlexLayout>

        {category === CategoryEnum.LinkedPayment && !isLinkedPaymentSaved && (
          <FlexLayout flexDirection="row" className="mt-[-6px]">
            <NewIconView fill="#D6AB00" />
            <Typography variant="body2" className="text-[var(--neutral-600)] pl-[8px] mb-[8px]">
              You have selected a <b>shared line item</b> that transfers funds to another workspace by creating a link (maximum 10). Note that only 1 source and 1 payment per
              workspace is permitted.{' '}
            </Typography>
          </FlexLayout>
        )}
        {category === CategoryEnum.LinkedPayment && isLinkedPaymentSaved && (
          <FlexLayout flexDirection="row" className="mt-[-6px]">
            <Typography variant="body2" className="text-[var(--neutral-600)] text-[13px] pl-[8px] mb-[8px]">
              The Linked Payment category can no longer be changed. To remove this line item please delete it.
            </Typography>
          </FlexLayout>
        )}
      </FlexLayout>
    );
  }

  private renderDistributionDetail(index: number) {
    const { classes, paymentMethodOptions, formikProps } = this.props;
    const paymentMethod: PaymentMethodEnum = _get(formikProps.values, this.arrayItemModelKeyAppender(index, 'paymentMethod'));
    const category: string = _get(formikProps.values, this.arrayItemModelKeyAppender(index, 'category'));

    // ToDo: [Tech Debt] WEB-24105 Use category id in future
    const displaySelectAccounts = paymentMethod === PaymentMethodEnum.BankTransfer && categoryUsageMappings.has(category);

    return (
      <FlexLayout alignItems="center" flexWrap="wrap">
        {category !== CategoryEnum.LinkedPayment && (
          <FlexLayout alignItems="center" className={classes.halfWidth}>
            <div>
              <Field
                label="Payment type"
                component={SelectField}
                placeholder="Please select"
                name={this.arrayItemModelKeyAppender(index, 'paymentMethod')}
                options={paymentMethodOptions}
                onChange={this.handleOnPaymentMethodChange}
                className={classNames(classes.smallField, classes.marginRight)}
              />
            </div>
            {displaySelectAccounts && (
              <SelectAccounts //
                name={this.arrayItemModelKeyAppender(index, 'prepopulateAccountId')}
                financialAccounts={this.props.financialAccounts}
                category={category}
                onChange={this.handleOnSelectAccountChange}
              />
            )}
          </FlexLayout>
        )}
        <FlexLayout fullWidth alignItems="center">
          {this.renderDistributionDetailField(index)}
        </FlexLayout>
      </FlexLayout>
    );
  }

  private handleOnCheckboxChange = (index: number) => {
    const {
      formikProps: { setFieldValue }
    } = this.props;
    setFieldValue(this.arrayItemModelKeyAppender(index, 'category'), '');
  };

  private handleOnLinkedPaymentCheckboxChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      formikProps: { setFieldValue }
    } = this.props;

    setFieldValue(this.arrayItemModelKeyAppender(index, 'amount'), event.target.checked ? 0 : 1);
  };

  private disableSave = () => {
    const { isLoading } = this.state;
    const { formikProps, arrayFieldName, index } = this.props;

    // * the validation schema for this part must be correct, CANNOT have redundant errors
    const error = _get(formikProps.errors, `${arrayFieldName}[${index}]`);
    if (error) {
      return true;
    }
    return isLoading;
  };

  private handleOnLinkedWorkspaceRowClick = (index: number, rowIndex: number, rowData: SearchTableModel) => {
    const { formikProps } = this.props;
    const linkedSettlementItemField = this.arrayItemModelKeyAppenderForLinked(index, 'linkedSettlementItem');
    const linkedSettlementItem: LinkedSettlementItemModel = _get(formikProps.values, linkedSettlementItemField);
    formikProps.setFieldValue(linkedSettlementItemField, {
      ...linkedSettlementItem,
      linkedWorkspaceId: rowData.workspaceId,
      linkedParticipantId: rowData.participantId,
      linkedToParticipantMatterReference: rowData.reference
    });

    const linkedDetailsField = this.arrayItemModelKeyAppenderForLinked(index, 'linkedDetails');

    formikProps.setFieldValue(linkedDetailsField, {
      ...linkedSettlementItem,
      linkedWorkspaceId: rowData.workspaceId,
      linkedParticipantId: rowData.participantId,
      linkedToParticipantMatterReference: rowData.reference
    });

    this.setState({ selectedLinkedSettlementRow: rowIndex });
  };

  private handleClearSelectedRow = () => {
    this.setState({ selectedLinkedSettlementRow: undefined });
  };

  private renderDistributionDetailField(index: number) {
    const { classes, formikProps, trustAccountOptions, workspaceId, participantId, arrayFieldName } = this.props;
    const paymentMethod = _get(formikProps.values, this.arrayItemModelKeyAppender(index, 'paymentMethod'));
    const { isLoading } = this.state;
    const disableSave = this.disableSave();

    switch (paymentMethod) {
      case PaymentMethodEnum.BankTransfer: {
        const bankDetailsFieldName = createModelKeyAppender<BankDetailsModel>(this.arrayItemModelKeyAppender(index, 'bankDetails'));
        return (
          <FlexLayout alignItems="center" flexWrap="wrap" className={classes.flexGrow}>
            <Field //
              label="Payee name"
              component={InputField}
              className={classNames(classes.growField, classes.marginRight)}
              name={bankDetailsFieldName('accountName')}
            />
            <Field //
              label="Reference (optional)"
              component={InputField}
              className={classes.largeField}
              name={bankDetailsFieldName('reference')}
            />
            <FlexLayout fullWidth alignItems="center">
              <BsbNumberField name={bankDetailsFieldName('bsb')} label="BSB" className={classNames(classes.smallField, classes.marginRight)} />

              <Field //
                label="Account number"
                component={InputField}
                className={classNames(classes.growField, classes.marginRight)}
                name={bankDetailsFieldName('accountNumber')}
              />
              <Field //
                label="Amount ($)"
                name={this.arrayItemModelKeyAppender(index, 'amount')}
                component={CurrencyInputField}
                className={classNames(classes.smallField, classes.marginRight)}
              />
              <SympliButton
                className={classes.saveButton}
                color="primary"
                variant="contained"
                onClick={this.handleOnUpdateClick}
                isLoading={isLoading}
                disabled={disableSave || isLoading}
                data-distributions-index={index}
              >
                Update
              </SympliButton>
            </FlexLayout>
          </FlexLayout>
        );
      }
      case PaymentMethodEnum.BPAY: {
        const bpayFieldName = this.arrayItemModelKeyAppender(index, 'bpayDetails');
        const bpayFieldModelKeyAppender = createModelKeyAppender<BpayDetailsModel>(bpayFieldName);
        return (
          <FlexLayout alignItems="center" flexWrap="wrap" className={classes.flexGrow}>
            <Field //
              label="Biller code"
              component={InputField}
              className={classNames(classes.smallField, classes.marginRight)}
              name={bpayFieldModelKeyAppender('billerCode')}
            />
            {/* // * this reference number is the billerReference field for bpayDetails*/}
            <Field
              label="Reference number"
              component={InputField}
              className={classNames(classes.growField, classes.marginRight)}
              name={bpayFieldModelKeyAppender('billerReference')}
            />
            <Field //
              label="Description (optional)"
              component={InputField}
              className={classes.largeField}
              name={bpayFieldModelKeyAppender('description')}
            />
            <FlexLayout justifyContent="flex-end" fullWidth alignItems="center">
              <Field //
                label="Amount ($)"
                name={this.arrayItemModelKeyAppender(index, 'amount')}
                component={CurrencyInputField}
                className={classNames(classes.smallField, classes.marginRight)}
              />
              <SympliButton
                className={classes.saveButton}
                color="primary"
                variant="contained"
                onClick={this.handleOnUpdateClick}
                isLoading={isLoading}
                disabled={disableSave || isLoading}
                data-distributions-index={index}
              >
                Update
              </SympliButton>
            </FlexLayout>
          </FlexLayout>
        );
      }
      case PaymentMethodEnum.TrustAccount: {
        const bankDetailsFieldName = createModelKeyAppender<BankDetailsModel>(this.arrayItemModelKeyAppender(index, 'bankDetails'));

        return (
          <FlexLayout alignItems="center" flexWrap="wrap" className={classes.flexGrow}>
            <Field
              readOnly={trustAccountOptions.length === 1}
              label="Account"
              component={SelectField}
              placeholder={resolveSelectPlaceholder(true)}
              name={this.arrayItemModelKeyAppender(index, 'bankAccountId')}
              options={trustAccountOptions}
              className={classNames(classes.growField, classes.marginRight)}
            />
            <Field //
              label="Reference (optional)"
              component={InputField}
              className={classes.largeField}
              name={bankDetailsFieldName('reference')}
            />
            <FlexLayout justifyContent="flex-end" fullWidth alignItems="center">
              <Field //
                label="Amount ($)"
                name={this.arrayItemModelKeyAppender(index, 'amount')}
                component={CurrencyInputField}
                className={classNames(classes.smallField, classes.marginRight)}
              />
              <SympliButton
                className={classes.saveButton}
                color="primary"
                variant="contained"
                onClick={this.handleOnUpdateClick}
                isLoading={isLoading}
                disabled={disableSave || isLoading}
                data-distributions-index={index}
              >
                Update
              </SympliButton>
            </FlexLayout>
          </FlexLayout>
        );
      }
      case PaymentMethodEnum.Linked: {
        const disableAmount = _get(formikProps.values, this.arrayItemModelKeyAppender(index, 'linkedSettlementFundsNotRequired'));
        const id = _get(formikProps.values, this.arrayItemModelKeyAppender(index, 'id'));
        const isLinkedPaymentSaved = Boolean(id);

        const linkedSettlementItemResponse: LinkedSettlementItemModelResponse = _get(formikProps.values, this.arrayItemModelKeyAppender(index, 'linkedSettlementItem'));
        const { workspaceClusterDetail } = this.props;
        return (
          <FlexLayout alignItems="center" flexWrap="wrap" className={classes.flexGrow}>
            <FlexLayout justifyContent="flex-start" fullWidth alignItems="center">
              <Field //
                label="Amount ($)"
                name={this.arrayItemModelKeyAppender(index, 'amount')}
                component={CurrencyInputField}
                className={classNames(classes.smallField, classes.marginRight, 'w-[315px]')}
                disabled={disableAmount}
              />
            </FlexLayout>
            {!isLinkedPaymentSaved && (
              <FlexLayout flexDirection="row" className="mt-[-6px]">
                <Typography variant="body2" className="text-[var(--neutral-600)] mb-[8px]">
                  The <b>'Amount'</b> entered is <b>shared across</b>, and can be <b>edited in, both workspaces.</b> A <b>$1.00 placeholder amount</b> has been provided in order to
                  complete the link where the final amount is not yet known.
                </Typography>
              </FlexLayout>
            )}
            <Field
              component={CheckboxField}
              disabled={isLinkedPaymentSaved}
              name={this.arrayItemModelKeyAppender(index, 'linkedSettlementFundsNotRequired')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleOnLinkedPaymentCheckboxChange(index, event)}
              label="Funds not required"
            />
            {!isLinkedPaymentSaved && (
              <FlexLayout flexDirection="row" className="mt-[-6px]">
                <Typography variant="body2" className="text-[var(--neutral-600)] mb-[8px] ml-[26px]">
                  By checking this option you are choosing not to have any financial demands from the linked workspace. You will still be able to see a line item in both workspaces
                  but with $0 value.
                </Typography>
              </FlexLayout>
            )}
            <FlexLayout flexDirection="column" fullWidth alignItems="center">
              {!isLinkedPaymentSaved ? (
                <LinkedWorkspaceSearchContainer
                  selectedRow={this.state.selectedLinkedSettlementRow}
                  clearSelectedRow={this.handleClearSelectedRow}
                  onRowClick={(rowIndex: number, rowData: SearchTableModel) => this.handleOnLinkedWorkspaceRowClick(index, rowIndex, rowData)}
                  searchType="excludeLinkedSourceFunds"
                  excludeWorkspaceId={workspaceId}
                  excludeLinkedWorkspaceClusterId={workspaceClusterDetail?.clusterId}
                />
              ) : (
                <LinkedWorkspaceDisplayContainer //
                  isLoading={isLoading}
                  linkedWorkspaceItem={linkedSettlementItemResponse}
                  id={id}
                  workspaceId={workspaceId}
                  participantId={participantId}
                  unlinkLineItemTypeEnum={UnlinkLineItemTypeEnum.UnlinkPayment}
                  formikProps={formikProps}
                  index={index}
                  arrayFieldName={arrayFieldName}
                />
              )}
            </FlexLayout>
            {isLinkedPaymentSaved && (
              <FlexLayout flexDirection="row" className="mt-[-6px] pt-[10px] w-full bg-[var(--greek-waters-translucent)] mb-[32px] mt-[8px]">
                <NewIconView fill="var(--sympli-green)" />
                <Typography variant="body2" className="text-[var(--neutral-600)] pl-[8px] mb-[8px]">
                  This workspace is part of a <b>linked settlement</b> which includes <b>{workspaceClusterDetail?.numberOfLinkedWorkspaces} linked workspaces.</b>
                  To unlink this workspace and delete the line item simply click the <b>unlink</b> or <b>delete</b> buttons.
                </Typography>
              </FlexLayout>
            )}

            <SympliButton
              className={classNames(classes.saveButton, isLinkedPaymentSaved ? 'w-[120px]' : 'w-[64px]')}
              color="primary"
              variant="contained"
              onClick={this.handleOnUpdateClick}
              isLoading={isLoading}
              disabled={disableSave || isLoading || (this.state.selectedLinkedSettlementRow === undefined && !isLinkedPaymentSaved)}
              data-distributions-index={index}
            >
              {isLinkedPaymentSaved ? 'Update' : 'Link'}
            </SympliButton>
          </FlexLayout>
        );
      }
      default: {
        return null;
      }
    }
  }

  private handleConfirmationClose = (confirmed: boolean) => {
    this.setState({ openLineItemConfirmationDialog: false });
    if (confirmed) {
      this.handleOnProceed();
    }
  };

  private handleOnUpdateClick = () => {
    // check if the data has been changed,
    const {
      formikProps: { touched }
    } = this.props;

    if (getIn(touched, this.fieldName)) {
      const { formikProps, arrayFieldName = '', index, trustAccountMap, workspaceId, participantId, dispatch } = this.props;
      const itemFieldName = `${arrayFieldName}[${index}]`;
      const distribution: ConditionalDistributionModel = _get(formikProps.values, itemFieldName);
      if (distribution.paymentMethod === PaymentMethodEnum.TrustAccount) {
        // Do not close dialog, do not call save endpoint. These should be done via confirmation dialog button
        updateTrustAccountDistributionMapping(
          formikProps,
          trustAccountMap,
          distribution.bankAccountId!,
          itemFieldName,
          workspaceId,
          participantId,
          dispatch,
          true,
          false,
          distribution
        );
      }

      if (distribution.paymentMethod === PaymentMethodEnum.Linked) {
        this.handleOnProceed();
      } else {
        this.setState({ openLineItemConfirmationDialog: true });
      }
    } else {
      this.handleOnProceed(true);
    }
  };

  private handleOnProceed = (skipSaving?: boolean) => {
    const { formikProps, arrayFieldName = '', workspaceId, participantId, index } = this.props;
    const itemFieldName = `${arrayFieldName}[${index}]`;
    const distribution: ConditionalDistributionModel = _get(formikProps.values, itemFieldName);

    switch (distribution.paymentMethod) {
      case PaymentMethodEnum.BankTransfer:
        this.saveBankTransferDistribution(itemFieldName, distribution.bankDetails, distribution as BankTransferDistribution, skipSaving);
        break;
      case PaymentMethodEnum.BPAY:
        this.saveBpayDistribution(workspaceId, participantId, itemFieldName, distribution.bpayDetails, distribution.amount, distribution as BpayDistribution, skipSaving);
        break;
      case PaymentMethodEnum.TrustAccount:
        this.saveTrustAccountDistribution(itemFieldName, distribution.bankAccountId, distribution as TrustAccountDistribution, skipSaving);
        break;
      case PaymentMethodEnum.Linked:
        const linkedDistribution = distribution as LinkedDistribution;
        this.saveLinkedDistribution(workspaceId, participantId, itemFieldName, linkedDistribution, skipSaving);
        break;

      default:
        formikProps.setFieldValue(this.arrayItemModelKeyAppender(Number(index), 'isEditorOpen'), false);
    }
  };

  private setFocusToAddNewRecord() {
    this.props.setFocusLabel(FinancialLineItemLabelEnum.Payment);
  }

  private saveBankTransferDistribution(itemFieldName: string, bankDetails: BankDetailsModel, distribution: BankTransferDistribution, skipSaving?: boolean) {
    const { workspaceId, participantId, dispatch } = this.props;
    this.setState({ isLoading: true });
    // not using finally because the component will un-mount first, we do not want to setState
    bsbCheckFormikHelper(
      //
      itemFieldName,
      bankDetails,
      this.props.formikProps,
      workspaceId,
      participantId,
      dispatch,
      !skipSaving,
      false,
      distribution
    ) //
      .finally(() => {
        this.setState({ isLoading: false });
        this.setFocusToAddNewRecord();
      });
  }

  private saveTrustAccountDistribution(itemFieldName: string, bankAccountId: string, distribution: TrustAccountDistribution, skipSaving?: boolean) {
    const { workspaceId, participantId, dispatch } = this.props;
    this.setState({ isLoading: true });
    // Close dialog, call save endpoint based on feature flag
    updateTrustAccountDistributionMapping(
      this.props.formikProps,
      this.props.trustAccountMap,
      bankAccountId,
      itemFieldName,
      workspaceId,
      participantId,
      dispatch,
      false,
      !skipSaving,
      distribution
    );
    this.setState({ isLoading: false });
    this.setFocusToAddNewRecord();
  }

  private saveLinkedDistribution(workspaceId: string, participantId: string, itemFieldName: string, distribution: LinkedDistribution, skipSaving?: boolean) {
    const { dispatch } = this.props;
    this.setState({ isLoading: true });
    linkedCheckFormikHelper(workspaceId, participantId, itemFieldName, this.props.formikProps, dispatch, !skipSaving, distribution).finally(() => {
      this.setState({ isLoading: false });
      this.setFocusToAddNewRecord();
    });
  }

  private handleOnPaymentMethodChange = (event: React.ChangeEvent<HTMLInputElement>, resolvedValue: PaymentMethodEnum) => {
    const {
      index,
      trustAccountOptions,
      formikProps: { setValues, values }
    } = this.props;
    let newValues: { distributions: Array<DistributionFormikModel> } = { ...values };

    if (resolvedValue === PaymentMethodEnum.TrustAccount && trustAccountOptions.length === 1) {
      const fieldName = this.arrayItemModelKeyAppender(index, 'bankAccountId');
      newValues = setIn(newValues, fieldName, trustAccountOptions[0].id);
    }
    newValues = setIn(newValues, this.arrayItemModelKeyAppender(index, 'paymentMethod'), resolvedValue);
    setValues(newValues);

    if (resolvedValue === PaymentMethodEnum.BankTransfer) {
      const category: string = _get(values, this.arrayItemModelKeyAppender(index, 'category'));
      this.autoSelectAccount(category);
    }
  };

  private saveBpayDistribution(
    workspaceId: string,
    participantId: string,
    itemFieldName: string,
    bpayDetails: BpayDetailsModel,
    amount: string | number,
    distribution: BpayDistribution,
    skipSaving?: boolean
  ) {
    const { dispatch } = this.props;
    this.setState({ isLoading: true });
    // not using finally because the component will un-mount first, we do not want to setState
    bpayCheckFormikHelper(workspaceId, participantId, itemFieldName, bpayDetails, amount, this.props.formikProps, dispatch, !skipSaving, distribution).finally(() => {
      this.setState({ isLoading: false });
      this.setFocusToAddNewRecord();
    });
  }

  private autoSelectAccount(category) {
    const {
      index,
      formikProps: { setFieldValue, values, setValues },
      usage
    } = this.props;

    // linked payment / source funds set the new payment method as linked
    if (category === CategoryEnum.LinkedPayment) {
      setValues(values => {
        const distributionItems: Array<DistributionFormikModel> = [...values.distributions];
        const newDistributionItem: DistributionFormikModel = {
          ...distributionItems[index], //
          category: CategoryEnum.LinkedPayment,
          paymentMethod: PaymentMethodEnum.Linked,
          amount: 1, // always default linked payment to 1 dollar
          linkedSettlementFundsNotRequired: false,
          linkedSettlementItem: undefined
        };

        distributionItems[index] = newDistributionItem;

        return {
          ...values,
          distributions: distributionItems
        };
      });
    } else {
      // todo: this should be reset for the above method as the paymentMethod for link is hidden one
      const paymentMethod = _get(values, this.arrayItemModelKeyAppender(index, 'paymentMethod'));
      if (paymentMethod === PaymentMethodEnum.Linked) {
        setFieldValue(this.arrayItemModelKeyAppender(index, 'paymentMethod'), null);
        setFieldValue(this.arrayItemModelKeyAppender(index, 'amount'), undefined);
        setFieldValue(this.arrayItemModelKeyAppender(index, 'reference'), undefined);
        setFieldValue(this.arrayItemModelKeyAppender(index, 'linkedSettlementItem'), null);
        setFieldValue(this.arrayItemModelKeyAppender(index, 'linkedSettlementFundsNotRequired'), false);
        this.handleClearSelectedRow();
      }
    }

    const selectAccountsField = this.arrayItemModelKeyAppender(index, 'prepopulateAccountId');
    const defaultAccountId = getDefaultAccountId(category, usage);

    if (defaultAccountId) {
      setFieldValue(selectAccountsField, defaultAccountId);
      this.prePopulateBankDetails(defaultAccountId);
      return;
    }
    setFieldValue(selectAccountsField, '');
    this.clearBankDetails();
  }

  private prePopulateBankDetails(accountId: string) {
    const {
      index,
      formikProps: { values, setFieldValue, setFieldTouched },
      financialAccounts
    } = this.props;
    const bankDetailsField = this.arrayItemModelKeyAppender(index, 'bankDetails');
    const defaultBankDetails = getBankDetails(accountId, financialAccounts ?? []);
    const bankDetails = _get(values, bankDetailsField);

    const updatedBankDetails: BankDetailsModel = {
      ...bankDetails,
      accountName: defaultBankDetails?.accountName,
      accountNumber: defaultBankDetails?.accountNumber,
      bsb: defaultBankDetails?.bsbNumber
    };
    setFieldValue(bankDetailsField, updatedBankDetails);
    setFieldTouched(bankDetailsField);
  }

  private clearBankDetails() {
    const {
      index,
      formikProps: { values, setFieldValue, setFieldTouched }
    } = this.props;
    const bankDetailsField = this.arrayItemModelKeyAppender(index, 'bankDetails');
    const bankDetails = _get(values, bankDetailsField);
    const updatedBankDetails: BankDetailsModel = {
      ...bankDetails,
      accountName: '',
      reference: '',
      bsb: '',
      accountNumber: ''
    };
    setFieldValue(bankDetailsField, updatedBankDetails);
    setFieldTouched(bankDetailsField);
  }

  private handleOnCategoryChange = (
    _event: React.ChangeEvent<HTMLInputElement>, //
    resolvedValue: string
  ) => this.autoSelectAccount(resolvedValue);

  private handleOnSelectAccountChange = (_event: React.ChangeEvent<HTMLInputElement>, resolvedValue: string) => {
    if (resolvedValue) {
      this.prePopulateBankDetails(resolvedValue);
    }
  };
}

const styledComponent = withStyles(styles)(DirectionPayeeDetail);
export default styledComponent;
