import dateFormat from 'dateformat';

import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { DateFormatEnum } from '@sympli-mfe/document-forms-framework/models';
import { DataSource } from '@sympli-mfe/document-forms-framework/shared-config/common';

import { caveatFallbackMap } from './fallback';
import {
  calculateProprietorsOnTitle,
  convertAddressBookFromApiToFormModel,
  convertAddressBookFromFormToApiModel,
  convertPartyBookFromApiToFormModel,
  convertPartyBookFromFormToApiModel,
  generateProprietors,
  hasCommonProprietors
} from './helpers';
import { ApiCaveatModel_2_21_2, CaveatModel_2_21_2, ClaimDetailModel } from './models';

export function convertFromApiToFormModel(apiModel: ApiCaveatModel_2_21_2): CaveatModel_2_21_2 {
  let formModel: CaveatModel_2_21_2 = applyDefaultMap(apiModel, caveatFallbackMap);
  const proprietors = apiModel.proprietors.proprietorGroups.flatMap(pg => pg.parties);
  const relinquishingIds = proprietors.map(p => p.partyBookId).filter(Boolean) as string[];
  const partyBook = convertPartyBookFromApiToFormModel(apiModel.partyBook, relinquishingIds);
  formModel = {
    ...formModel,
    partyBook,
    addressBook: convertAddressBookFromApiToFormModel(apiModel.addressBook),
    // custom UI properties
    isProprietorsResetTenancyAvailable: false,
    proprietorsOnTitle: calculateProprietorsOnTitle(
      proprietors,
      partyBook.filter(p => p.metadata?.source === DataSource.Title)
    )
  };

  if (formModel.invalidParties) {
    formModel = generateProprietors(formModel);
  }

  return formModel;
}

export function convertFromFormToApiModel(formModel: CaveatModel_2_21_2, originalApiModel: ApiCaveatModel_2_21_2): ApiCaveatModel_2_21_2 {
  formModel = applyVisibilityFallbackMap(formModel, caveatFallbackMap);
  const {
    //
    partyBook: formPartyBook,
    addressBook: formAddressBook,
    claimDetails,
    // custom UI properties
    isProprietorsResetTenancyAvailable,
    proprietorsOnTitle,
    ...rest
  } = formModel;

  const amendedClaimDetails: ClaimDetailModel | undefined =
    claimDetails && isDate(claimDetails.claimDate)
      ? {
          ...claimDetails,
          claimDate: dateFormat(claimDetails.claimDate as Date, DateFormatEnum.DATE)
        }
      : claimDetails;

  return {
    ...rest,
    claimDetails: amendedClaimDetails,
    partyBook: convertPartyBookFromFormToApiModel(formPartyBook, originalApiModel.partyBook),
    addressBook: convertAddressBookFromFormToApiModel(formAddressBook, originalApiModel.addressBook),
    invalidParties: false,
    hasCommonProprietors: !isProprietorsResetTenancyAvailable && hasCommonProprietors(formModel.titleReferences)
  };
}

function isDate(possibleDate: unknown) {
  return possibleDate instanceof Date;
}

class Converter implements IConverter<CaveatModel_2_21_2, ApiCaveatModel_2_21_2> {
  fromApiToFormModel(initialApiValues: ApiCaveatModel_2_21_2, context: IConverterContext): CaveatModel_2_21_2 {
    return convertFromApiToFormModel(initialApiValues);
  }
  fromFormToApiModel(values: CaveatModel_2_21_2, initialApiValues: ApiCaveatModel_2_21_2): ApiCaveatModel_2_21_2 {
    return convertFromFormToApiModel(values, initialApiValues);
  }
}

export default new Converter();
