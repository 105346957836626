import { endOfYesterday, isBefore } from 'date-fns';
import * as yup from 'yup';

import { isNotNullOrEmpty } from '@sympli-mfe/document-forms-framework/utils';
import { validateWhenVisible2 } from '@sympli-mfe/document-forms-framework/validation';
import { yupDatePicker } from '@sympli/ui-framework/components/formik/date-picker-field';
import msg from '@sympli/ui-framework/utils/messages';

import { DateOfDeathTypeEnum } from 'src/containers/documents/scaffolded-form/nsw/2-21/components/deceased-tenancy-detail/components/date-of-death/enums';
import { DateOfDeathModel } from 'src/containers/documents/scaffolded-form/nsw/2-21/components/deceased-tenancy-detail/models';
import { VISIBILITY_CHECK_DATE_OF_DEATH_DATE, VISIBILITY_CHECK_DATE_OF_DEATH_DESCRIPTION, VISIBILITY_CHECK_FROM_DATE_AND_TO_DATE } from './visibilityChecks';

export const END_OF_YESTERDAY = endOfYesterday();

export const yupDateOfDeath = yup.object<DateOfDeathModel>({
  type: yup.mixed<DateOfDeathTypeEnum>().required(msg.REQUIRED).defined().oneOf(Object.values(DateOfDeathTypeEnum), msg.REQUIRED),
  date: validateWhenVisible2({
    visibilityCheck: VISIBILITY_CHECK_DATE_OF_DEATH_DATE,
    validationWhenVisible: yupDatePicker //
      .required(msg.REQUIRED)
      .max(END_OF_YESTERDAY, msg.DATE_MUST_BE_PAST_DATE)
  }),
  from: validateWhenVisible2({
    visibilityCheck: VISIBILITY_CHECK_FROM_DATE_AND_TO_DATE,
    validationWhenVisible: yupDatePicker //
      .required(msg.REQUIRED)
      .max(END_OF_YESTERDAY, msg.DATE_MUST_BE_PAST_DATE)
  }),
  to: validateWhenVisible2({
    visibilityCheck: VISIBILITY_CHECK_FROM_DATE_AND_TO_DATE,
    validationWhenVisible: yupDatePicker //
      .required(msg.REQUIRED)
      .max(END_OF_YESTERDAY, msg.DATE_MUST_BE_PAST_DATE)
      .testContextualRule({
        uniqueTestName: '"deceasedProprietorGroups[*].deceasedProprietors[*].dateOfDeath.toDate" contextual validation rule #1',
        onlyIf: (deathOfDate: DateOfDeathModel, _) => isNotNullOrEmpty(deathOfDate.from) && isBefore(new Date(deathOfDate.from!), END_OF_YESTERDAY),
        requirement: (deathOfDate: DateOfDeathModel, _): boolean => isBefore(new Date(deathOfDate.from!), new Date(deathOfDate.to!)),
        message: 'To date cannot be equal to or earlier than From date'
      })
      .defined()
  }),
  description: validateWhenVisible2({
    visibilityCheck: VISIBILITY_CHECK_DATE_OF_DEATH_DESCRIPTION,
    validationWhenVisible: yup
      .string()
      .default('')
      .trim()
      .nullable()
      .required(msg.REQUIRED)
      .max(200, ({ max }) => msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(max))
  })
});
