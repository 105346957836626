import { UpdateWorkspaceDirectionLineItemApiResponse } from '@sympli/api-gateway/models';

import { GeneralAccountUsageApiResponse } from 'src/containers/settings/subscriber-profile/financial-accounts/components/general-account-detail/models';
import http from 'src/utils/http';
import { DeleteWorkspaceDirectionLineItemRequestModel, UpdateWorkspaceDirectionLineItemRequestModel } from './models';

export const getGeneralAccountUsages = () => {
  return http.get<GeneralAccountUsageApiResponse>('/workspaces/financial-accounts/general/usages');
};

export const updateWorkspaceDirectionLineItem = (payload: UpdateWorkspaceDirectionLineItemRequestModel) => {
  const { workspaceId, participantId, requestPayload } = payload;
  const uri = `/workspaces/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/direction-lineitem`;
  return http.post<UpdateWorkspaceDirectionLineItemApiResponse>(uri, requestPayload);
};

export const deleteWorkspaceDirectionLineItem = (payload: DeleteWorkspaceDirectionLineItemRequestModel) => {
  const { workspaceId, participantId, directionItemId } = payload;
  const uri = `/workspaces/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/direction-lineitem/${directionItemId}?isLinkedSettlementItem=${payload.isLinkedSettlementItem}`;
  return http.delete<UpdateWorkspaceDirectionLineItemApiResponse>(uri);
};
