import { useFormikContext } from 'formik';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { resolveLabel } from '@sympli-mfe/document-forms-framework/utils';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';

import { modelKey } from 'src/utils/formUtils';
import { VISIBILITY_CHECK_DUTY_ASSESSMENT_NUMBER } from '../../../checks';
import { CaveatModel_2_21_2, DutyAssessmentModel } from '../../../models';
import { useStyles } from '../styles';

type FormValues = CaveatModel_2_21_2;
const fieldName = modelKey<DutyAssessmentModel>();

export function DutyAssessment() {
  const classes = useStyles();
  const { values } = useFormikContext<FormValues>();
  const { disabled } = useDocumentContext();
  if (!VISIBILITY_CHECK_DUTY_ASSESSMENT_NUMBER(null, values)) {
    return null;
  }

  return (
    <FormGroup
      title={resolveLabel('Duty assessment number', true)}
      formTip={
        <span>
          This is required where the estate or interest claimed is pursuant to an unregistered mortgage, loan agreement, or deed of charge dated on or after 1/1/1987 & before
          1/7/2016, except where:
          <ul className={classes.dutyAssessmentNumberTooltipList}>
            <li className={classes.dutyAssessmentNumberTooltipListItem}>the claim involves bail that includes a reference to an unregistered mortgage or charge.</li>
            <li className={classes.dutyAssessmentNumberTooltipListItem}>the caveat is by State Debt Recovery pursuant to a charge.</li>
            <li className={classes.dutyAssessmentNumberTooltipListItem}>the claim is a charge pursuant to s355 Water Management Act 2000.</li>
          </ul>
          If this caveat has these exceptions, it needs to be lodged in paper with the Land Registry.
        </span>
      }
      fieldAreaDirection="column"
    >
      {({ titleId }) => (
        <Field //
          aria-labelledby={titleId}
          name={fieldName('dutyAssessmentNumber')}
          disabled={disabled}
          component={InputField}
        />
      )}
    </FormGroup>
  );
}
