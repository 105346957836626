import * as React from 'react';

import { DocumentTypeIdentifierEnum, ParticipantStatusEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';

import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import { useWorkspaceDetail } from 'src/containers/workspace/shared/detail/reducers/workspaceDetail';
import { useWorkspaceParticipants } from 'src/containers/workspace/shared/detail/reducers/workspaceParticipants';
import { useFeatureFlag } from 'src/hooks';
import { DocumentListAction, LodgementCaseDetail } from '../../models';
import DocumentListToolbar from './DocumentListToolbar';
import { isAllowedAdditionalDocumentsForParticipant, isAllowedToDeletePrimaryLodgementCaseDocuments } from './helpers';

// DOCS: https://tickleme.atlassian.net/wiki/spaces/DEV/pages/2083291137/document+list+-+data+dependency+insights
interface Props {
  // route
  workspaceId: string;
  participantId: string;
  // lodgement cases
  lodgementCases: LodgementCaseDetail[];
  // other
  onActionClick: (mode: Omit<DocumentListAction, 'default'>) => void;
}

function DocumentListToolbarContainer({ workspaceId, participantId, onActionClick, lodgementCases }: Props) {
  const workspaceDetailState = useWorkspaceDetail(workspaceId, participantId);
  const workspaceParticipantsState = useWorkspaceParticipants(workspaceId);
  const documentCombination = useFeatureFlag(FeatureToggleEnum.documentCombination);
  const isCriticalRolesEnabled = useFeatureFlag(FeatureToggleEnum.criticalRoles);

  const isAdditionalDocumentsEnabled = isAllowedAdditionalDocumentsForParticipant({
    workspaceType: workspaceDetailState.detail?.workspaceType.id,
    currentParticipantId: workspaceDetailState.detail?.currentParticipantId,
    workspaceParticipants: workspaceParticipantsState,
    titleReferences: workspaceDetailState.detail?.titleReferences ?? []
  });

  const mainLodgementCase = lodgementCases.find(x => x.lodgementCase?.isPrimary) ?? lodgementCases[0];

  if (!mainLodgementCase) {
    return (
      <DocumentListToolbar //
        onActionClick={onActionClick}
        addEnabled={isAdditionalDocumentsEnabled}
        reorderEnabled={false}
        deleteEnabled={false}
        disabled={workspaceDetailState.detail?.isLocked}
      />
    );
  }

  /*
    This feature is only available if user has added an additional document to an existing workspace.
    It is calculated by backend.
    each jurisdiction has its own rules around it.
    This flag controls ability to delete document as well.
  */
  const reorderEnabled =
    workspaceDetailState.detail?.canRearrangeLodgementInstructions &&
    /*
        We add this check here because backend flag canRearrangeLodgementInstructions is now sent only in workspaceDetail
        We don't want to re-fetch the whole workspace detail
        Backend should return this flag as the response of deleting a document or re-order a document
        The temporary way is to let front end does this check
        WEB-18187 will fix this
      */
    mainLodgementCase.documents.filter(item => !item.isLI).length > 1;

  return (
    <DocumentListToolbar //
      onActionClick={onActionClick}
      addEnabled={isAdditionalDocumentsEnabled}
      reorderEnabled={reorderEnabled}
      deleteEnabled={isDeleteEnabled()}
      disabled={workspaceDetailState.detail?.isLocked}
    />
  );
  function isDeleteEnabled() {
    if (documentCombination) {
      return lodgementCases
        .flatMap(lc => lc.documents)
        .some(d => d.canRemove && !d.isLI && d.participants.filter(p => p.participantStatusId === ParticipantStatusEnum.Accepted).length === 1);
    }

    const isParticipantAllowedToDeletePlcDoc = isAllowedToDeletePrimaryLodgementCaseDocuments({
      workspaceType: workspaceDetailState.detail?.workspaceType.id,
      currentParticipantId: workspaceDetailState.detail?.currentParticipantId,
      workspaceParticipants: workspaceParticipantsState
    });

    const mainLodgementCase = lodgementCases.find(x => x.lodgementCase?.isPrimary) ?? lodgementCases[0];
    const hasDeletableDocuments = mainLodgementCase.documents.some(x => x.canRemove && !x.isLI);

    // Purchaser, Vendor and Beneficiary participants should only be able to delete their SLC documents,
    // They are not allowed to delete PLC documents even though they have Remove permission at document level
    return (
      (isParticipantAllowedToDeletePlcDoc && hasDeletableDocuments) ||
      (workspaceDetailState.detail?.workspaceType.id !== WorkspaceTypeEnum.RegistrationOnly &&
        lodgementCases
          .filter(
            lc =>
              !lc.lodgementCase?.isPrimary || //
              (isCriticalRolesEnabled &&
                lc.documents.some(
                  d =>
                    d.documentIdentifier === DocumentTypeIdentifierEnum.Transfer &&
                    d.participants.filter(p => p.participantStatusId === ParticipantStatusEnum.Accepted).length === 1
                ))
          )
          .flatMap(l => l.documents)
          .some(x => x.canRemove && !x.isLI))
    );
  }
}

export default React.memo(DocumentListToolbarContainer);
