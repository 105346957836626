import { useFormikContext } from 'formik';

import DocumentFieldArray from '@sympli-mfe/document-forms-framework/components/document-field-array';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import { usePartyBookOptions } from '@sympli-mfe/document-forms-framework/utils/hooks';
import FormGroupFooter from '@sympli/ui-framework/components/form/layout/form-group-footer';

import { VISIBILITY_CHECK_CLAIM_PARTIES } from '../../../checks';
import { NSW_CAVEAT_PARTY_FORM_CONFIG } from '../../../config';
import { CaveatModel_2_21_2, ClaimDetailModel, ClaimParty } from '../../../models';
import { resolveDefaultItem } from '../models';
import { useStyles } from '../styles';
import { ClaimPartyItem } from './ClaimPartyItem';

type FormValues = CaveatModel_2_21_2;

interface Props {
  name: string;
}

export function ClaimParties(props: Props): JSX.Element | null {
  const fieldName = createModelKeyAppender<ClaimDetailModel>(props.name);
  const classes = useStyles();
  const { disabled, dialogPortalId } = useDocumentContext();
  const { values } = useFormikContext<FormValues>();
  const partyBookOptions = usePartyBookOptions(values.partyBook, NSW_CAVEAT_PARTY_FORM_CONFIG);

  if (!VISIBILITY_CHECK_CLAIM_PARTIES(values.claimDetails!)) {
    return null;
  }

  const bindingPath = fieldName('claimParties');
  const items: ClaimParty[] = values.claimDetails.claimParties ?? [];
  // TODO: Set max items
  return (
    <DocumentFieldArray
      arrayBinding={bindingPath}
      createNewItem={resolveDefaultItem}
      minItems={0}
      itemTitle="Other affected party"
      disabled={disabled}
      isSimpleType={false}
      itemStyle="formGroup"
      renderItem={({ itemIndex, itemBinding, itemFocusRef, nextItemFocusRef }) => (
        <ClaimPartyItem //
          name={itemBinding}
          itemIndex={itemIndex}
          items={items}
          focusRef={itemFocusRef}
          nextItemFocusRef={nextItemFocusRef}
          disabled={disabled}
          partyOptions={partyBookOptions}
          dialogPortalId={dialogPortalId}
          dropdownClassName={classes.fixedWidthDropdown}
        />
      )}
      renderAddButton={({ addButtonFocusRef, onAdd }) => (
        <FormGroupFooter
          buttonActions={addButtonFocusRef}
          icon="add"
          onClick={onAdd}
          title="Add other affected party (optional)"
          formTip="Other affected parties are those with a subsisting interest that haven't already been mentioned in this form"
        />
      )}
    />
  );
}
